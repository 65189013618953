import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import RecommendedItemsSwiper from 'components/organisms/RecommendedItems/RecommendedItemsSwiper';
import Meal from 'components/organisms/Meal/Meal';

import { useOvermindState } from 'overmind/index';
import { getRecipe } from 'components/helpers/planHelper';
import Box from 'components/atoms/Layout/Box/Box';
import { useTranslation } from 'react-i18next';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function DailyPlan(props) {
  const overmindState = useOvermindState();
  const isDesktop = useCheckScreenSize().isDesktop;
  const meals = ['breakfast', 'lunch', 'dinner', 'snack'];
  const { t } = useTranslation();
  const findRecipe = (mealCode) => {
    return getRecipe(overmindState.mealPlan, mealCode, props.dateIndex, true);
  };

  const areSimilarRecipesLoaded = (productType) => {
    const recipe = findRecipe(productType);
    const productId = recipe && recipe.id;
    return (
      overmindState.similarRecipes &&
      overmindState.similarRecipes[productId] != null
    );
  };

  const hasSimilarRecipes = (productType) => {
    const recipe = findRecipe(productType);
    const productId = recipe && recipe.id;
    return (
      overmindState.similarRecipes &&
      overmindState.similarRecipes[productId] &&
      overmindState.similarRecipes[productId].length > 0
    );
  };

  const renderRecommendedProduct = (productType) => {
    const recipe = findRecipe(productType);

    return (
      <Meal
        recipe={recipe}
        onClicked={(id) =>
          props.onRecipeSelected && props.onRecipeSelected(id, productType)
        }
        parentComponent="meal"
        isHorizontal={true}
        largeImage={isDesktop}
      />
    );
  };

  const renderProductsSwiper = (productType) => {
    const recipe = findRecipe(productType);
    const productId = recipe && recipe.id;
    return (
      <RecommendedItemsSwiper
        onItemSelected={(id) =>
          props.onRecipeSelected && props.onRecipeSelected(id, productType)
        }
        onSimilarItemSelected={(id, mainRecipeId) =>
          props.onSimilarRecipeSelected &&
          props.onSimilarRecipeSelected(id, mainRecipeId, productType)
        }
        mainItem={recipe}
        similarItems={
          overmindState.similarRecipes &&
          overmindState.similarRecipes[productId]
            ? overmindState.similarRecipes[productId]
            : []
        }
        maxSimilarItems={3}
        parentComponent="meal"
        showRecommendedItem={false}
        showBookmarks={false}
      />
    );
  };

  const renderCategoryTitle = (title) => {
    return (
      <Typography
        variant={'subtitle1'}
        style={{
          fontSize: isDesktop ? '14px' : '12px',
          lineHeight: isDesktop ? '17px' : '14px',
          textTransform: 'uppercase',
          color: '#342f38',
          marginBottom: '12px',
        }}
      >
        {title}
      </Typography>
    );
  };

  return meals.map((meal, ix) => (
    <Box key={ix}>
      <Typography
        variant={'h5'}
        style={{
          textAlign: isDesktop ? 'center' : 'left',
          marginTop: isDesktop ? '70px' : '30px',
          marginBottom: isDesktop ? '30px' : '6px',
          fontSize: isDesktop ? '24px' : '20px',
          lineHeight: isDesktop ? '29px' : '24px',
          textTransform: 'uppercase',
        }}
      >
        {meal}
      </Typography>
      <GridContainer direction={isDesktop ? 'row' : 'column'} spacing={3}>
        <GridItem xs={isDesktop ? 6 : 12}>
          {renderCategoryTitle(t('mealPlan.recipesTitle'))}
          {renderRecommendedProduct(meal)}
        </GridItem>

        <GridItem xs={isDesktop ? 6 : 12}>
          {areSimilarRecipesLoaded(meal) &&
            renderCategoryTitle(
              hasSimilarRecipes(meal)
                ? t('mealPlan.alternativeRecipesTitle')
                : t('mealPlan.missingAlternativeRecipesMessage')
            )}
          <Box>{renderProductsSwiper(meal)}</Box>
        </GridItem>
      </GridContainer>
    </Box>
  ));
}
