import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useActions, useOvermindState } from 'overmind/index';
import { ROUTE_ONBOARDING } from 'routes';

import { ThemeProvider } from '@material-ui/core/styles';
import { dnaTheme } from 'themes';
import { LogoBlue } from 'assets/imagesList';

import Footer from 'components/molecules/Footer/Footer';
import Header from 'components/molecules/Header/Header';
import Slider from 'components/molecules/Slider/Slider';

import HasDnaPage from 'components/pages/Onboarding/DNA/HasDnaPage';
import KitNumberInputPage from 'components/pages/Onboarding/DNA/KitNumberInputPage';

import { isIntroStep } from 'components/helpers/progressIndicatorHelper';
import { getStepIndex } from 'components/helpers/onboardingHelper';
import { useTranslation } from 'react-i18next';

import Box from 'components/atoms/Layout/Box';
import SectionIntro from 'components/molecules/SectionIntro/SectionIntro';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import KitNotReceived from 'components/pages/Onboarding/DNA/KitNotReceived';
import FileToUploadPage from 'components/pages/Onboarding/DNA/FileToUploadPage';
import DnaProvidersPage from 'components/pages/Onboarding/DNA/DnaProvidersPage';
import DnaUploadExplanationPage from 'components/pages/Onboarding/DNA/DnaUploadExplanationPage';

export default function KitRegistrationPage() {
  const overmindState = useOvermindState();
  const actions = useActions();

  useEffect(() => {
    if (overmindState.metaData.user_metadata.dnaKitReceived !== true) {
      actions.goToStep(getStepIndex('our_genomics_intro'));
    }
  }, [overmindState.metaData.user_metadata.dnaKitReceived, actions]);

  useEffect(() => {
    if (
      overmindState.profile == null ||
      Object.keys(overmindState.profile).length === 0
    ) {
      actions.fetchProfile();
    }
  }, [overmindState.profile, actions]);

  const isIntroPage = () => {
    return (
      overmindState.progressStep === getStepIndex('intro_dna') ||
      overmindState.progressStep === getStepIndex('our_genomics_intro') ||
      overmindState.progressStep === getStepIndex('onboarding_finished')
    );
  };

  return (
    <>
      {overmindState.profile && Object.keys(overmindState.profile).length > 0 && (
        <Box
          style={{
            backgroundImage: isIntroPage()
              ? 'radial-gradient(ellipse 80% 450px, #9CAFAD, #40635F)'
              : '',
            height: '100%',
            minHeight: '100vh',
            width: '100%',
          }}
        >
          <HeaderWrapper />
          {window.innerWidth > 800 ? (
            <GridContainer
              direction="column"
              justify="center"
              alignItems="center"
            >
              <GridItem
                style={{
                  maxWidth: '1200px',
                }}
              >
                <SliderWrapper />
              </GridItem>
            </GridContainer>
          ) : (
            <>
              <SliderWrapper />
            </>
          )}

          <FooterWrapper />
        </Box>
      )}
    </>
  );
}

export const HeaderWrapper = () => {
  const overmindState = useOvermindState();

  return (
    <Header
      hideHeader={overmindState.hideHeader}
      hideLeftButton={
        overmindState.hideBackButton ||
        overmindState.progressStep === getStepIndex('our_genomics_intro')
          ? true
          : false
      }
      adjustMargin={isIntroStep(overmindState.progressStep)}
      logo={LogoBlue}
    />
  );
};

export const FooterWrapper = () => {
  const actions = useActions();

  return (
    <Footer
      onBackClick={() => {
        actions.goBack();
      }}
      onNextClick={() => {
        actions.goNext();
      }}
      buttonBackgroundColor="#97d3c5"
      transparent={true}
    />
  );
};

export function SliderWrapper() {
  const overmindState = useOvermindState();
  const { t } = useTranslation();

  const renderStep = (step) => {
    switch (step) {
      case getStepIndex('intro_dna'):
      case getStepIndex('our_genomics_intro'):
      case getStepIndex('has_dna_page'):
      case getStepIndex('dna_providers_page'):
      case getStepIndex('dna_explanation_page'):
      case getStepIndex('dna_file_upload_page'):
      case getStepIndex('kit_number_input_page'):
      case getStepIndex('kit_not_received_page'):
        return (
          <ThemeProvider theme={dnaTheme}>
            {renderDnaSectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('onboarding_finished'):
        return (
          <ThemeProvider theme={dnaTheme}>
            <SectionIntro
              title={t('onboarding.finished', {
                name: overmindState.metaData.user_metadata.firstName,
              })}
            />
          </ThemeProvider>
        );
      default:
        return <></>;
    }
  };

  const renderDnaSectionStep = (step) => {
    switch (step) {
      case getStepIndex('our_genomics_intro'):
        return (
          <SectionIntro
            title={t('onboarding.dnaSection.ourGenomicsIntroTitle')}
            text={t('onboarding.dnaSection.ourGenomicsIntro')}
          />
        );
      case getStepIndex('has_dna_page'):
        return <HasDnaPage />;
      case getStepIndex('dna_providers_page'):
        return <DnaProvidersPage />;
      case getStepIndex('dna_explanation_page'):
        return <DnaUploadExplanationPage />;
      case getStepIndex('dna_file_upload_page'):
        return <FileToUploadPage />;
      case getStepIndex('kit_number_input_page'):
        return <KitNumberInputPage />;
      case getStepIndex('kit_not_received_page'):
      default:
        return <KitNotReceived />;
    }
  };

  let qs = '';
  if (window.localStorage.getItem('qs') != null) {
    qs = window.localStorage.getItem('qs');
  }

  return overmindState.progressStep <
    getStepIndex('ready_to_use_the_app_page') ? (
    <Redirect to={ROUTE_ONBOARDING + qs} />
  ) : overmindState.progressStep > getStepIndex('onboarding_finished') ? (
    <Redirect to={ROUTE_ONBOARDING + qs} />
  ) : window.innerWidth < 800 ? (
    <Slider
      isForward={overmindState.forwardNavigation}
      index={overmindState.progressStep}
    >
      {renderStep(overmindState.progressStep)}
    </Slider>
  ) : (
    <>{renderStep(overmindState.progressStep)}</>
  );
}
