import React from 'react';

import { useProfileTilesStyles } from '../InfoContainer/profileTilesStyles';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export default function ProfileCard(props) {
  const classes = useProfileTilesStyles();

  return (
    <GridContainer
      onClick={props.onClicked && (() => props.onClicked())}
      className={classes.tile}
      style={{ cursor: 'pointer' }}
    >
      <GridItem style={{ textAlign: 'left' }} xs={12}>
        <Typography className={classes.tileTitle} variant="body1">
          {props.title}
        </Typography>
      </GridItem>
      <GridItem>
        <GridContainer direction="row">
          <GridItem
            xs={
              props.actionText && props.showClickIndicator
                ? 6
                : props.actionText
                ? 7
                : 12
            }
            style={{
              textAlign: 'left',
              width: '100%',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: '12px',
                overflowWrap: 'break-word',
              }}
            >
              {props.infoText}
            </Typography>
          </GridItem>
          {props.actionText && (
            <GridItem
              xs={5}
              style={{
                textAlign: 'right',
              }}
            >
              <Typography
                variant="body1"
                className={classes.tileInfo}
                style={{ marginTop: '10px', marginLeft: '0' }}
              >
                {props.actionText}
              </Typography>
            </GridItem>
          )}
          {props.showClickIndicator && (
            <GridItem xs={1} style={{ textAlign: 'right' }}>
              {props.showClickIndicator && (
                <NavigateNextIcon style={{ marginTop: '10px' }} />
              )}
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}

ProfileCard.propTypes = {
  title: PropTypes.string,
  infoText: PropTypes.string,
  actionText: PropTypes.string,
  onClicked: PropTypes.func,
};
