import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import InfoContainer from 'components/molecules/Common/InfoContainer/InfoContainer';
import { ThemeProvider } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { environmentTheme } from 'themes';

export default function EnvironmentSection(props) {
  const { t } = useTranslation();

  const handleOpenPopup = (component) => {
    props.onTileClick && props.onTileClick(component);
  };

  return (
    <ThemeProvider theme={environmentTheme}>
      <GridContainer>
        <GridContainer
          direction="row"
          style={{
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          <GridItem
            style={{
              width: '50%',
              textAlign: 'left',
            }}
          >
            <Typography
              variant="h4"
              style={{
                textTransform: 'uppercase',
                fontSize: '15px',
              }}
            >
              {t('myProfile.subtitleEnvironment')}
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <InfoContainer
            title={t('myProfile.environmentArea')}
            value={props.area && props.area}
            color="primary"
            tallContainer={true}
            onTileClick={() => handleOpenPopup('environmentArea')}
          />
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <InfoContainer
            title={t('myProfile.environmentExposureLevel')}
            value={props.sunExposureLevel && props.sunExposureLevel}
            color="primary"
            tallContainer={true}
            onTileClick={() => handleOpenPopup('environmentExposure')}
          />
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <InfoContainer
            title={t('myProfile.environmentSpfUsageLevel')}
            value={props.spfUsageLevel && props.spfUsageLevel}
            color="primary"
            tallContainer={true}
            onTileClick={() => handleOpenPopup('environmentSpf')}
          />
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <InfoContainer
            title={t('myProfile.environmentAirconUsageLevel')}
            value={props.airconUsageLevel && props.airconUsageLevel}
            color="primary"
            tallContainer={true}
            onTileClick={() => handleOpenPopup('environmentAirCon')}
          />
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <InfoContainer
            title={t('myProfile.environmentBlueLightExposureLevel')}
            value={props.bluelightExposureLevel && props.bluelightExposureLevel}
            color="primary"
            tallContainer={true}
            onTileClick={() => handleOpenPopup('environmentScreens')}
          />
        </GridContainer>
      </GridContainer>
    </ThemeProvider>
  );
}
