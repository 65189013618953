import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useActions } from 'overmind/index';
import { uploadDNATheme } from 'themes';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import Typography from 'components/atoms/Typography/Typography';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Button from 'components/atoms/Inputs/Buttons/Button';
import SvgIcon from 'components/atoms/SvgIcon/SvgIcon';
import { UploadDNAIcon } from 'assets/iconsList';
import ModalEditWindow from 'components/organisms/Profile/ModalEditWindow';
import CloseIcon from '@material-ui/icons/Close';
import { ROUTE_ASSESSMENT } from 'routes';
import { useHistory } from 'react-router-dom';

export default function AddDNA() {
  const { t } = useTranslation();
  const actions = useActions();
  const [openedModal, setOpenedModal] = useState(false);
  const [hideSaveInModal, setHideSaveInModal] = useState(false);
  const history = useHistory();

  const openModal = () => {
    actions.setComponentForModalWindow('dnaFile');
    actions.fileUploadInitialize();
    setHideSaveInModal(true);
    setOpenedModal(true);
  };

  return (
    <ThemeProvider theme={uploadDNATheme}>
      <GridContainer justify="center" alignItems="center">
        <GridItem
          xs={12}
          style={{
            textAlign: 'right',
            marginRight: '10px',
            marginTop: '-20px',
            cursor: 'pointer',
          }}
          onClick={() => {
            let qs = '';
            if (window.localStorage.getItem('qs') != null) {
              qs = window.localStorage.getItem('qs');
            }
            history.push(ROUTE_ASSESSMENT + qs);
          }}
        >
          <CloseIcon width={20} height={20} style={{ color: '#1b4658' }} />
        </GridItem>
        <GridItem
          xs={12}
          style={{
            textAlign: 'center',
          }}
        >
          <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
            {t('home.uploadDNA.title')}
          </Typography>
        </GridItem>
        <GridContainer
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            margin: '20px auto',
            maxWidth: '1010px',
          }}
        >
          <GridItem xs={useCheckScreenSize().isDesktop ? 2 : 4}>
            <SvgIcon height={70} icon={UploadDNAIcon} />
          </GridItem>
          <GridItem xs={useCheckScreenSize().isDesktop ? 6 : 8}>
            <Typography
              variant="body2"
              style={{
                width: '90%',
              }}
            >
              {t('home.uploadDNA.dnaText')}
            </Typography>
          </GridItem>
          <GridItem
            xs={useCheckScreenSize().isDesktop ? 4 : 12}
            style={{
              margin: '20px auto 0 auto',
              textAlign: 'center',
            }}
          >
            <Button
              color="secondary"
              onClick={() => openModal()}
              component="span"
              variant="contained"
              style={{
                margin: '20px auto',
                width: '80%',
              }}
            >
              {t('home.uploadDNA.uploadButtonText')}
            </Button>
          </GridItem>
        </GridContainer>
      </GridContainer>
      <ModalEditWindow
        opened={openedModal}
        onClose={() => setOpenedModal(false)}
        hideSave={hideSaveInModal}
      />
    </ThemeProvider>
  );
}
