import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import DnaProvidersQuestion from 'components/organisms/Questions/DNA/DnaProvidersQuestion';

import { usePageStyles } from 'components/pages/commonStyles';

/**
 * Questionnaire page asking user about DNA providers to which user paid for DNA analysis.
 * Exists for presentation in StoryBook. Actual page implemented as DnaProvidersPage component.
 */
export default function DnaProviders(props) {
  const classes = usePageStyles(props);

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem style={{ marginLeft: '12px', marginRight: '12px' }}>
        <DnaProvidersQuestion
          selectedProviders={props.selectedProviders}
          onQuestionAnswered={(val) => {
            if (props.onQuestionAnswered) {
              props.onQuestionAnswered(val);
            }
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
