import React, { useEffect, useState } from 'react';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import SignUp from 'components/organisms/SingUp/SignUp';
import SignIn from 'components/organisms/SignIn/SignIn';
import BarLoader from 'react-spinners/BarLoader';
import { LogoWhite } from 'assets/imagesList';
import { useAuth0 } from '@auth0/auth0-react';
import { ROUTE_INTRO, ROUTE_ASSESSMENT } from 'routes';
import { useTranslation } from 'react-i18next';
import { useLandingPageStyles } from './landingPageStyles';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import { BBLogo } from 'assets/iconsList';
import { useOvermindState } from 'overmind/index';
import { useActions } from 'overmind/index';
import * as auth0 from 'auth0-js';
import {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  PUBLIC_URL,
  resetHash,
  isCookieError,
} from 'components/helpers/auth0Helper';
import ResetPassword from 'components/organisms/ResetPassword/ResetPassword';
import LandingModal from 'components/molecules/LandingModal/LandingModal';

export default function LandingPage(props) {
  const overmindState = useOvermindState();
  const landingPageStyles = useLandingPageStyles(props);
  const actions = useActions();
  const { t } = useTranslation();

  const { getAccessTokenSilently, user, isAuthenticated, logout } = useAuth0();

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const [showWarning, setShowWarning] = useState(false);
  const [warningContent, setWarningContent] = useState(null);

  const [webAuth] = useState(
    new auth0.WebAuth({
      domain: REACT_APP_AUTH0_DOMAIN || 'auth.lifenome.com',
      clientID: REACT_APP_AUTH0_CLIENT_ID || 'tSw6gdmNrsPDUalcY3aKOfsY2fI4M9eS',
      redirectUri: `${window.location.origin}${PUBLIC_URL}`,
      responseType: 'code token',
    })
  );

  const hasAuthenticationToken = () => {
    return window.localStorage.getItem('access_token') != null;
  };

  const isReturnedFromAuth0SignIn = () => {
    return (
      window.location.search &&
      window.location.search.startsWith('?code=') &&
      window.location.search.indexOf('state=') === -1
    );
  };

  const isReturnedFromAuth0CustomSignIn = () => {
    return (
      window.location.search &&
      window.location.search.startsWith('?code=') &&
      window.location.search.indexOf('state=') !== -1
    );
  };

  const isNewAuthencationRequested = () => {
    return (
      window.location.search && window.location.search.indexOf('auth=') !== -1
    );
  };

  const processCodeAndFetchMetaData = () => {
    let code = window.location.search.substring(
      window.location.search.indexOf('?code=') + 6
    );
    if (code.indexOf('&state=') !== -1) {
      code = code.substring(0, code.indexOf('&state='));
    }
    const verifier = window.localStorage.getItem('verifier');
    const queryString = new URLSearchParams();
    queryString.append('grant_type', 'authorization_code');
    queryString.append('client_id', REACT_APP_AUTH0_CLIENT_ID);
    queryString.append('code_verifier', verifier);
    queryString.append('code', code);
    queryString.append('redirect_uri', getRedirectUriForSignin());
    actions.getAuthencationTokensAndMetaData(queryString);
  };

  useEffect(() => {
    const hash = window.location.hash;
    webAuth.parseHash({ hash: hash }, (err, authResult) => {
      if (err) {
        if (isCookieError(err.errorDescription)) {
          setWarningContent(
            'Sign up is not possible because 3rd party cookies are disabled!'
          );
          setShowWarning(true);
        } else {
          setWarningContent(err.description);
          setShowWarning(true);
        }
        // we need to reset hash in case user does not delete the hash from URL
        resetHash();
      } else if (authResult) {
        window.localStorage.setItem('access_token', authResult.accessToken);
        signIn();
      }
    });
  }, [window.location]);

  useEffect(() => {
    if (overmindState.signInRequired) {
      setShowSignIn(true);
    }
  }, [overmindState.signInRequired]);

  useEffect(() => {
    if (overmindState.hasFetchedMetaData) {
      trackEvent('Signin', 'Completed');

      setTimeout(function () {
        let qs = '';
        if (window.localStorage.getItem('qs') != null) {
          qs = window.localStorage.getItem('qs');
        }

        // redirect user to app
        if (overmindState.metaData?.user_metadata?.completed_onboarding_BPB) {
          props.history.push(ROUTE_ASSESSMENT + qs);
        } else {
          props.history.push(ROUTE_INTRO + qs);
        }
      }, 500);
    }
  }, [overmindState.hasFetchedMetaData]);

  useEffect(() => {
    if (
      window.location.search &&
      window.location.search.indexOf('utm_campaign') !== -1
    ) {
      window.localStorage.setItem('qs', window.location.search);
    }

    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((newToken) => {
          window.localStorage.setItem('access_token', newToken);
          signIn({ silentSignIn: false });
        })
        .catch(() => {
          if (user) {
            let url = getRedirectUriForSignin();
            logout({
              returnTo: url,
            });
          }
        });
    }
  }, [isAuthenticated]);

  const signInSilently = async () => {
    try {
      const token = await getAccessTokenSilently();
      window.localStorage.setItem('access_token', token);
      signIn({ silentSignIn: false });
    } catch (e) {
      console.log('e ', e.error);
      if (e.error === 'login_required') {
        // if Login is required, then we have to do that
        // (that's what Auth0 does in https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4-create-a-useapi-hook-for-accessing-protected-apis-with-an-access-token)
        setIsLoadingUserData(false);
      }
      if (e.error === 'consent_required') {
        // If Consent is required, then we have to do that
        // (that's what Auth0 does in https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4-create-a-useapi-hook-for-accessing-protected-apis-with-an-access-token)
        setIsLoadingUserData(false);
      }
    }
  };

  const signIn = (options) => {
    if (hasAuthenticationToken() && !isNewAuthencationRequested()) {
      actions.onInitializeOvermindState({ fetchEmail: user == null });
    } else {
      if (isReturnedFromAuth0CustomSignIn() && options.silentSignIn) {
        signInSilently();
      } else if (isReturnedFromAuth0SignIn()) {
        // user which isn't authenticated, but is returning from Auth0 SignIn
        processCodeAndFetchMetaData();
      } else {
        // stay here
        setIsLoadingUserData(false);
      }
    }
  };

  useEffect(() => {
    signIn({ silentSignIn: true });
  }, []);

  const getRedirectUriForSignin = () => {
    return window.location.origin;
  };

  const handleLandingModalClick = () => {
    setShowWarning(false);
  };

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'radial-gradient(ellipse 80% 450px, #9CAFAD, #40635F)',
        minHeight: '100vh',
      }}
    >
      <LandingModal
        content={warningContent}
        opened={showWarning}
        close={() => setShowWarning(false)}
        onClick={() => handleLandingModalClick()}
      />
      {isLoadingUserData && !showSignIn && (
        <Box
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{ marginTop: '60px' }}
            src={LogoWhite}
            alt="Blueprint beauty logo"
          />
          <Typography
            style={{ marginTop: '30px' }}
            className={landingPageStyles.title}
          >
            {t('onboarding.landing.title')}
          </Typography>
          <Typography
            style={{ marginTop: '20px' }}
            className={landingPageStyles.subtitle}
          >
            {t('onboarding.landing.message')}
          </Typography>
          <BarLoader
            loading={true}
            color={'#ffffff'}
            css={{
              display: 'block',
              margin: '0 auto',
              height: 4,
              width: 100,
            }}
          />
        </Box>
      )}
      {(!isLoadingUserData || showSignIn) && (
        <Box justifyContent="center" align="center">
          <Box
            style={{
              marginTop: '60px',
            }}
          >
            <a href="https://www.blueprint.beauty">
              <BBLogo />
            </a>
          </Box>
          {showSignUp ? (
            <SignUp
              toggleForm={() => setShowSignUp((oldVal) => !oldVal)}
              signIn={signIn}
            />
          ) : showResetPassword ? (
            <ResetPassword toggleForm={() => setShowResetPassword(false)} />
          ) : (
            <SignIn
              toggleForm={() => setShowSignUp((oldVal) => !oldVal)}
              goToResetPassword={() => setShowResetPassword(true)}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
