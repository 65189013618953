import React from 'react';

import Typography from 'components/atoms/Typography/Typography';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import { MoonList } from 'assets/iconsList';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function Ingredients(props) {
  const isDesktop = useCheckScreenSize().isDesktop;

  return (
    <div style={{ width: '100%' }}>
      {props.data &&
        props.data.map((item, ix) => (
          <GridContainer
            direction="row"
            alignItems="start"
            key={ix}
            style={{ width: '100%', marginBottom: props.addMargin && '20px' }}
          >
            <MoonList
              height={9}
              width={9}
              style={{ marginTop: '5px', marginRight: '16px' }}
            />

            <GridItem xs>
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Lato-Regular',
                  fontSize: isDesktop ? '16px' : '14px',
                  lineHeight: isDesktop ? '20px' : '18px',
                  textAlign: 'left',
                  color: '#342f38',
                }}
              >
                {item}
              </Typography>
            </GridItem>
          </GridContainer>
        ))}
    </div>
  );
}
