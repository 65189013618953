export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const DB_CONNECTION = 'Username-Password-Authentication';
export const {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  PUBLIC_URL,
} = process.env;

export const getCrypto = () => {
  //ie 11.x uses msCrypto
  return window.crypto || window.msCrypto;
};

export const getCryptoSubtle = () => {
  const crypto = getCrypto();
  //safari 10.x uses webkitSubtle
  return crypto.subtle || crypto.webkitSubtle;
};

export const bufferToBase64UrlEncoded = (input) => {
  const ie11SafeInput = new Uint8Array(input);
  return urlEncodeB64(
    window.btoa(String.fromCharCode(...Array.from(ie11SafeInput)))
  );
};

export const urlEncodeB64 = (input) => {
  const b64Chars = { '+': '-', '/': '_', '=': '' };
  return input.replace(/[+/=]/g, (m) => b64Chars[m]);
};

export const sha256 = async (s) => {
  const digestOp = getCryptoSubtle().digest(
    { name: 'SHA-256' },
    new TextEncoder().encode(s)
  );

  // msCrypto (IE11) uses the old spec, which is not Promise based
  // https://msdn.microsoft.com/en-us/expression/dn904640(v=vs.71)
  // Instead of returning a promise, it returns a CryptoOperation
  // with a result property in it.
  // As a result, the various events need to be handled in the event that we're
  // working in IE11 (hence the msCrypto check). These events just call resolve
  // or reject depending on their intention.
  if (window.msCrypto) {
    return new Promise((res, rej) => {
      digestOp.oncomplete = (e) => {
        res(e.target.result);
      };

      digestOp.onerror = (e) => {
        rej(e.error);
      };

      digestOp.onabort = () => {
        rej('The digest operation was aborted');
      };
    });
  }

  return await digestOp;
};

export const getRedirectUriForSignin = () => {
  return window.location.origin;
};

export const resetHash = () => {
  window.history.replaceState(
    {},
    document.title,
    window.location.href.split('#')[0]
  );
};

export const isCookieError = (val) => {
  const error = val.toLowerCase();
  return (
    error.includes('unknown or invalid login ticket') ||
    error.includes('unable to configure verification page')
  );
};
