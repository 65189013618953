import React from 'react';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';

import ContentCard from 'components/molecules/Common/ContentCard/ContentCard';
import ProductCard from 'components/molecules/Common/ProductCard/ProductCard';
import Swiper from 'components/atoms/Swiper/Swiper';
import './productSwiperStyles.css';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function ProductSwiper(props) {
  const { title, subtitle, items, color } = props;
  const isDesktop = useCheckScreenSize().isDesktop;

  const params = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: (index, className) => {
        if (props.pagination) {
          let color = props.paginationColor ? props.paginationColor : '#fff';
          return (
            '<span class="' +
            className +
            '" style="background-color: ' +
            color +
            ';"></span>'
          );
        } else {
          return '<span></span>';
        }
      },
    },
  };

  return (
    <ContentCard color={props.color} fullWidth={!isDesktop}>
      <GridContainer
        direction="column"
        style={{
          padding: '10px',
          maxWidth: '500px',
          margin: '0 auto 20px auto',
        }}
      >
        <GridItem>
          <Typography
            variant="body1"
            style={{ textTransform: 'uppercase', textAlign: 'center' }}
          >
            {title}
          </Typography>
        </GridItem>
        <GridItem>
          <Typography
            variant="body2"
            style={{
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            {subtitle}
          </Typography>
        </GridItem>
      </GridContainer>

      <Swiper swiperParams={params} activeSlide={isDesktop ? '1' : '0'}>
        {items &&
          items.map((item, ix) => (
            <div
              key={ix}
              style={{
                backgroundColor: color,
              }}
            >
              <ProductCard
                color="#f7ede7"
                title={item.name}
                type={item.category.name}
                image={item.image}
                tags={item.tags}
                onProductClick={(title) => {
                  props.onProductClick && props.onProductClick(item, ix);
                }}
              />
            </div>
          ))}
      </Swiper>
    </ContentCard>
  );
}
