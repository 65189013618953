import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { traitDetailsStyles } from './traitDetailsStyles';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import InfoCard from 'components/molecules/Common/InfoCard/InfoCard';
import ContentCard from 'components/molecules/Common/ContentCard/ContentCard';
import DetailsHeader from 'components/molecules/Header/DetailsHeader';
import Swiper from 'components/atoms/Swiper/Swiper';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function TraitDetails(props) {
  const { data } = props;
  const { t } = useTranslation();
  const classes = traitDetailsStyles(props);
  const [showMore, setShowMore] = useState(false);
  const [cardColor, setCardColor] = useState();
  const isDesktop = useCheckScreenSize().isDesktop;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data.trait.connotation === 'good') {
      if (data.percentile > 95) {
        setCardColor('#4e860d');
      } else if (data.percentile > 46) {
        setCardColor('#71a73b');
      } else {
        setCardColor('#9ac677');
      }
    } else {
      setCardColor('#e29234');
    }
  });

  const renderRiskCards = () => {
    return (
      <GridContainer
        spacing={1}
        style={{
          minHeight: isDesktop ? '100px' : '85px',
          color: 'white',
        }}
      >
        <GridItem xs={8}>
          <InfoCard
            color={cardColor}
            style={{
              height: '100%',
              padding: isDesktop ? '16px 20px' : '8px 10px',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography
                variant="caption"
                style={{
                  fontFamily: 'Museo-500',
                  fontSize: isDesktop ? '12px' : '11px',
                  lineHeight: isDesktop ? '14px' : '13px',
                }}
              >
                {data.trait.connotation === 'good'
                  ? t('needs.details.strength')
                  : t('needs.details.risk')}
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontFamily: 'MuseoSans-900',
                  fontSize: isDesktop ? '24px' : '21px',
                  lineHeight: isDesktop ? '24px' : '21px',
                }}
              >
                {data.level_label}
              </Typography>
            </Box>
          </InfoCard>
        </GridItem>
        <GridItem xs={4}>
          <InfoCard
            color={cardColor}
            style={{
              height: '100%',
              padding: isDesktop ? '16px 20px' : '8px 10px',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: '100%' }}
            >
              <Typography
                variant="caption"
                style={{
                  fontFamily: 'MuseoSans-700',
                  fontSize: isDesktop ? '11px' : '10px',
                  lineHeight: isDesktop ? '13px' : '12px',
                }}
              >
                {t('needs.details.higherThan')}
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontFamily: 'MuseoSans-900',
                  fontSize: isDesktop ? '36px' : '30px',
                  lineHeight: isDesktop ? '43px' : '36px',
                }}
              >
                {data.percentile}%
              </Typography>
              <Typography
                variant="caption"
                style={{
                  fontFamily: 'MuseoSans-700',
                  fontSize: isDesktop ? '11px' : '10px',
                  lineHeight: isDesktop ? '13px' : '12px',
                }}
              >
                {t('needs.details.ofPopulation')}
              </Typography>
            </Box>
          </InfoCard>
        </GridItem>
      </GridContainer>
    );
  };

  const renderDataInfo = (item) => {
    return (
      <InfoCard color="#ede4de">
        <Typography
          style={{
            fontFamily: 'MuseoSans-500',
            fontSize: '14px',
            lineHeight: '17px',
            padding: '10px',
          }}
        >
          {item}
        </Typography>
      </InfoCard>
    );
  };

  const renderRecommendations = () => {
    return (
      <ContentCard color="#f2ede9">
        <GridContainer spacing={2}>
          <GridItem xs={12}>
            <Typography
              variant="h4"
              style={{
                textAlign: 'center',
                fontFamily: 'MuseoSans-500',
                fontSize: isDesktop ? '19px' : '12px',
                lineHeight: isDesktop ? '23px' : '14px',
                marginBottom: '22px',
                color: '#070707',
              }}
            >
              {t('needs.details.recommend')}
            </Typography>
          </GridItem>
          <GridItem xs={12} md={6} style={{ padding: !isDesktop && '0 20px' }}>
            <Typography
              variant="h3"
              style={{
                textAlign: 'center',
                fontFamily: 'MuseoSans-700',
                fontSize: '18px',
                lineHeight: '22px',
                marginBottom: '11px',
                color: '#2a343f',
              }}
            >
              {t('needs.details.prevention')}
            </Typography>
            {data.prevention.map((item) => renderDataInfo(item))}
          </GridItem>
          <GridItem xs={12} md={6} style={{ padding: !isDesktop && '0 20px' }}>
            <Typography
              variant="h3"
              style={{
                textAlign: 'center',
                fontFamily: 'MuseoSans-700',
                fontSize: '18px',
                lineHeight: '22px',
                marginTop: !isDesktop && '20px',
                marginBottom: '11px',
                color: '#2a343f',
              }}
            >
              {t('needs.details.treatment')}
            </Typography>
            {data.treatment.map((item) => renderDataInfo(item))}
          </GridItem>
        </GridContainer>
      </ContentCard>
    );
  };

  const renderSkinProductImages = (ingredients) => {
    return (
      <Swiper activeSlide={isDesktop ? '1' : '0'}>
        {ingredients.map((ingredient, ix) => (
          <Box
            key={ix}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={'swiper-slide rich-products-slide'}
          >
            <img
              className={classes.skinProductImage}
              alt={ingredient.code}
              key={ingredient.code}
              src={
                ingredient.image
                  ? ingredient.image
                  : 'https://via.placeholder.com/98x98'
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = 'https://via.placeholder.com/98x98';
              }}
            />
            <Typography variant="body1" className={classes.skinProductText}>
              {ingredient.name}
            </Typography>
          </Box>
        ))}
      </Swiper>
    );
  };

  const renderEffects = () => {
    return (
      <Box display="flex" flexWrap="wrap" style={{ padding: '0 20px' }}>
        {data.trait.properties.map((property) => (
          <Typography variant="body2" className={classes.effectsText}>
            {`#${property.name}`}
          </Typography>
        ))}
      </Box>
    );
  };

  const renderSkinProductsAndEffects = () => {
    return (
      <ContentCard color="#fff" fullWidth={!isDesktop}>
        <GridContainer
          direction="row"
          alignItems="flex-start"
          style={{ padding: '0 10px' }}
          spacing={2}
        >
          {data.trait.key_ingredients && data.trait.key_ingredients.length > 0 && (
            <GridItem
              xs={12}
              md={6}
              style={{ marginBottom: !isDesktop && '30px' }}
            >
              <Typography
                variant="h3"
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontFamily: 'MuseoSans-500',
                  fontSize: '12px',
                  lineHeight: '14px',
                  color: '#21153c',
                  marginBottom: '15px',
                }}
              >
                {t('needs.details.skinProducts')}
              </Typography>

              {renderSkinProductImages(data.trait.key_ingredients)}
            </GridItem>
          )}
          {data.trait.properties && data.trait.properties.length !== 0 && (
            <GridItem xs={12} md={6}>
              <Typography
                variant="h3"
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontFamily: 'MuseoSans-500',
                  fontSize: '12px',
                  lineHeight: '14px',
                  color: '#21153c',
                  marginBottom: '15px',
                }}
              >
                {t('needs.details.effectOnHealth')}
              </Typography>

              {renderEffects()}

              <Box
                style={{
                  margin: '0px 15px 20px 15px',
                  marginTop: isDesktop ? '50px' : '30px',
                }}
              >
                <Typography
                  className={classes.seeAll}
                  variant="body1"
                  style={{
                    cursor: 'pointer',
                    fontSize: '12px',
                    textDecoration: 'underline',
                    marginBottom: '15px',
                  }}
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore
                    ? t('needs.details.showLess')
                    : t('needs.details.showMore')}
                </Typography>
                {showMore && (
                  <Typography variant="body1">
                    {data.trait.description_scientific}
                  </Typography>
                )}
              </Box>
            </GridItem>
          )}
        </GridContainer>
      </ContentCard>
    );
  };

  return (
    <GridContainer>
      <DetailsHeader
        title={data.trait.name}
        disableLeftButton={props.hidePreviousButton}
        onLeftClick={() => {
          props.onPreviousClick && props.onPreviousClick();
        }}
        disableRightButton={props.hideNextButton}
        onRightClick={() => {
          props.onNextClick && props.onNextClick();
        }}
      />

      <GridContainer style={{ maxWidth: '1024px', margin: 'auto' }} spacing={2}>
        <GridItem xs={isDesktop ? 6 : 12}>{renderRiskCards()}</GridItem>

        <GridItem xs={isDesktop ? 6 : 12}>
          <Typography
            variant="body2"
            style={{
              fontSize: isDesktop ? '16px' : '14px',
              lineHeight: isDesktop ? '20px' : '18px',
              color: '#342f38',
            }}
          >
            {data.trait.description}
          </Typography>
        </GridItem>
      </GridContainer>

      <GridItem xs={12} style={{ marginTop: '100px', marginBottom: '20px' }}>
        {renderRecommendations()}
      </GridItem>

      <GridItem xs={12}>{renderSkinProductsAndEffects()}</GridItem>
    </GridContainer>
  );
}
