import React from 'react';
import { useTranslation } from 'react-i18next';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
import Question from 'components/organisms/Inputs/Question/Question';

import IllustratedSlider from 'components/molecules/Inputs/IllustratedSlider/IllustratedSlider';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import {
  lifestyleSliderSmokeIcon1,
  lifestyleSliderSmokeIcon2,
  lifestyleSliderSmokeIcon3,
  lifestyleSliderSmokeIcon4,
  lifestyleSliderSmokeIcon5,
} from 'assets/iconsList';

export default function LifestyleCigarettesPage(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const action = useActions();

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <GridItem
        style={{
          maxWidth: '700px',
          marginBottom: '20px',
        }}
      >
        <Question
          title={t('onboarding.lifestyleSection.step2.question1.title')}
          subtitle={t('onboarding.lifestyleSection.step2.question1.subtitle')}
        >
          <IllustratedSlider
            id="illustrated-slider-sleep"
            illustrations={[
              {
                illustration: lifestyleSliderSmokeIcon1,
                height: 85,
                text: t('onboarding.lifestyleSection.step2.question1.slide1'),
              },
              {
                illustration: lifestyleSliderSmokeIcon2,
                height: 85,
                text: t('onboarding.lifestyleSection.step2.question1.slide2'),
              },
              {
                illustration: lifestyleSliderSmokeIcon3,
                height: 85,
                text: t('onboarding.lifestyleSection.step2.question1.slide3'),
              },
              {
                illustration: lifestyleSliderSmokeIcon4,
                height: 85,
                text: t('onboarding.lifestyleSection.step2.question1.slide4'),
              },
              {
                illustration: lifestyleSliderSmokeIcon5,
                height: 85,
                text: t('onboarding.lifestyleSection.step2.question1.slide5'),
              },
            ]}
            selectedIllustrationIndex={
              overmindState.blueprintProfile.profile.smoking_level
                ? overmindState.blueprintProfile.profile.smoking_level
                : 0
            }
            onValueChanged={(index) =>
              action.changeLifestyleSmokingLevel(index)
            }
          />
        </Question>
      </GridItem>
    </GridContainer>
  );
}
