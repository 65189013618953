import React from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import StressQuestion from 'components/organisms/Questions/Lifestyle/StressQuestion';

import {
  lifestyleSliderStressIcon1,
  lifestyleSliderStressIcon2,
  lifestyleSliderStressIcon3,
  lifestyleSliderStressIcon4,
  lifestyleSliderStressIcon5,
} from 'assets/iconsList';

import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

export default function LifestyleStressPage(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const action = useActions();

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <GridItem
        style={{
          maxWidth: '700px',
          marginBottom: '20px',
        }}
      >
        <StressQuestion
          title={t('onboarding.lifestyleSection.step1.question2.title')}
          subtitle={t('onboarding.lifestyleSection.step1.question2.subtitle')}
          data={[
            {
              illustration: lifestyleSliderStressIcon1,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question2.slide1'),
            },
            {
              illustration: lifestyleSliderStressIcon2,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question2.slide2'),
            },
            {
              illustration: lifestyleSliderStressIcon3,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question2.slide3'),
            },
            {
              illustration: lifestyleSliderStressIcon4,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question2.slide4'),
            },
            {
              illustration: lifestyleSliderStressIcon5,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question2.slide5'),
            },
          ]}
          selectedIllustrationIndex={
            overmindState.blueprintProfile.profile.stress_level
              ? overmindState.blueprintProfile.profile.stress_level
              : 0
          }
          onChange={(index) => action.changeLifestyleStressLevelIndex(index)}
        />
      </GridItem>
    </GridContainer>
  );
}
