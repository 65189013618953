import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Box from 'components/atoms/Layout/Box';
import RadioButtonGroup from 'components/organisms/Inputs/RadioButtonGroup/RadioButtonGroup';
import Typography from 'components/atoms/Typography/Typography';
import { usePageStyles } from 'components/pages/commonStyles';

export default function ResearchAgreement(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(props.selectedValue);

  const handleAgreementChange = (val, checked) => {
    setSelectedValue(val + '');
    props.onAgreementChange && props.onAgreementChange(val, true);
  };

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '31px', paddingRight: '30px' }}
    >
      <Box textAlign="center" mt={2} mb={2.5}>
        <Typography variant="h5">
          {t('onboarding.introSection.researchAgreement.heading')}
        </Typography>
      </Box>

      <Box textAlign="left">
        {t('onboarding.introSection.researchAgreement.agreementParagraphs', {
          returnObjects: true,
        }).map((paragraph, ix) => {
          return (
            <GridItem key={ix}>
              <Typography
                variant="body2"
                style={{
                  fontSize: '18px',
                  lineHeight: '22px',
                  color: '#262626',
                }}
                paragraph
              >
                {paragraph}
              </Typography>
            </GridItem>
          );
        })}
        <Box textAlign="left" mt={3.5} mb={10}>
          <RadioButtonGroup
            color="primary"
            spaceBetween="14px"
            rowNumber={1}
            onChange={(val, checked) => {
              handleAgreementChange(val, checked);
            }}
            items={[
              {
                label: t(
                  'onboarding.introSection.researchAgreement.contributeData'
                ),
                value: '1',
                checked: selectedValue === '1',
              },
              {
                label: t(
                  'onboarding.introSection.researchAgreement.doNotContributeData'
                ),
                value: '2',
                checked: selectedValue === '2',
              },
            ]}
          />
        </Box>
      </Box>
    </GridContainer>
  );
}
