import i18n from '../../i18n';

export function getEthnicities(userEthnicity) {
  return [
    {
      label: i18n.t(
        'onboarding.generalSection.step2.ethnicityOptions.nativeIndian'
      ),
      value: 1,
      valueForApi: 'american-indian-or-alaska-native',
      checked:
        userEthnicity === 1 ||
        userEthnicity === 'american-indian-or-alaska-native',
    },
    {
      label: i18n.t(
        'onboarding.generalSection.step2.ethnicityOptions.africanAmerican'
      ),
      value: 2,
      valueForApi: 'african-american',
      checked: userEthnicity === 2 || userEthnicity === 'african-american',
    },
    {
      label: i18n.t(
        'onboarding.generalSection.step2.ethnicityOptions.nativeHawaiian'
      ),
      value: 3,
      valueForApi: 'native-hawaiian-or-pacific-islander',
      checked:
        userEthnicity === 3 ||
        userEthnicity === 'native-hawaiian-or-pacific-islander',
    },
    {
      label: i18n.t('onboarding.generalSection.step2.ethnicityOptions.asian'),
      value: 4,
      valueForApi: 'asian',
      checked: userEthnicity === 4 || userEthnicity === 'asian',
    },
    {
      label: i18n.t('onboarding.generalSection.step2.ethnicityOptions.white'),
      value: 5,
      valueForApi: 'non-hispanic-white',
      checked: userEthnicity === 5 || userEthnicity === 'non-hispanic-white',
    },
    {
      label: i18n.t('onboarding.generalSection.step2.ethnicityOptions.other'),
      value: 6,
      valueForApi: 'other',
      checked: userEthnicity === 6 || userEthnicity === 'other',
    },
  ];
}

export function getHispanic() {
  return [
    {
      label: i18n.t(
        'onboarding.generalSection.step2.ethnicityOptions.hispanic'
      ),
      value: 7,
      valueForApi: 'hispanic',
    },
  ];
}
