import React from 'react';
import FileToUpload from './FileToUpload';

import { useActions, useOvermindState } from 'overmind/index';

/**
 * Actual questionnaire page asking user to provide kit number.
 * Basically renders FileToUpload component and manages its state.
 */
export default function FileToUploadPage() {
  const actions = useActions();
  const overmindState = useOvermindState();

  return (
    <FileToUpload
      showUploadStatus={overmindState.upload.showUploadStatus}
      uploadProgress={overmindState.upload.progress}
      fileProviders={overmindState.metaData.user_metadata.dnaProviders}
      uploadSuccessful={overmindState.upload.successful}
      onFileSelected={(file) => actions.uploadFile(file)}
    />
  );
}
