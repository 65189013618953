import React from 'react';
import ResearchAgreement from './ResearchAgreement';

import { useActions, useOvermindState } from 'overmind/index';

export default function ResearchAgreementPage(props) {
  const actions = useActions();
  const overmindState = useOvermindState();

  return (
    <ResearchAgreement
      onAgreementChange={(value, checked) => {
        actions.researchAgreementChecked(value);
      }}
      selectedValue={
        overmindState.metaData.user_metadata.research_agreement_BPB == null
          ? undefined
          : overmindState.metaData.user_metadata.research_agreement_BPB
          ? '1'
          : '2'
      }
    ></ResearchAgreement>
  );
}
