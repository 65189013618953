//add onboarding steps in order how it is defined in design
export const onboardingSteps = [
  'terms_of_service',
  'research_agreement_page',
  'general_intro',
  'name_page',
  'age_page',
  'biological_sex_page',
  'zip_code_page',
  // 'ethnicity_country_page',
  // 'bmi_page',
  'skin_intro',
  'skin_type',
  'skin_goals',
  'skin_eye_are_concerns',
  'food_intro',
  'dietary_preferences_page',
  'food_allergies_page',
  'lifestyle_intro',
  'lifestyle_sleep',
  'lifestyle_smoking',
  'lifestyle_stress',
  'lifestyle_alcohol',
  'lifestyle_hydration',
  // 'exercise_intro',
  // 'exercise_per_day',
  'environment_intro',
  'environment_where_do_you_live',
  'environment_sun_exposure',
  'environment_spf_use',
  // 'environment_space_with_air_conditioner',
  // 'environment_devices_with_screens',
  'ready_to_use_the_app_page',
  'our_genomics_intro',
  'has_dna_page',
  'dna_providers_page',
  'dna_explanation_page',
  'dna_file_upload_page',
  'kit_number_input_page',
  'kit_not_received_page',
  'onboarding_finished',
];

export const getStepIndex = (stepName) => {
  return onboardingSteps.indexOf(stepName);
};
