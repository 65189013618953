import React from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';

import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
import Question from 'components/organisms/Inputs/Question/Question';

import GridItem from 'components/atoms/Layout/Grid/GridItem';
import {
  AreaCountry,
  AreaSuburban,
  AreaUrban,
  ExposureExtended,
  ExposureMinimal,
  ExposureModerate,
  SPFDaily,
  SPFRarely,
  SPFSometimes,
  AirConDaily,
  AirConSometimes,
  AirConRarely,
  ScreensHigh,
  ScreensLow,
  ScreensMedium,
} from 'assets/iconsList';
import { renderSlider } from 'components/helpers/environmentHelper';

export default function EnvironmentPage(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const action = useActions();

  const { step } = props;

  const setValue = (value) => {
    if (step === 'step1') {
      action.setPlaceType(
        value === 0 ? 'urban' : value === 1 ? 'suburban' : 'country-side'
      );
    } else if (step === 'step2') {
      action.setSunExposureLevel(value);
    } else if (step === 'step3') {
      action.setSpfUsageLevel(value);
    } else if (step === 'step4') {
      action.setAirconUsageLevel(value);
    } else if (step === 'step5') {
      action.setBlueLightExposureLevel(value);
    }
  };

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <GridItem
        style={{
          maxWidth: '700px',
          marginBottom: '20px',
        }}
      >
        <Question title={t(`onboarding.environmentSection.${step}.title`)}>
          {step === 'step1' &&
            renderSlider(
              overmindState,
              [AreaUrban, AreaSuburban, AreaCountry],
              step,
              (index) => {
                setValue(index, step);
              }
            )}
          {step === 'step2' &&
            renderSlider(
              overmindState,
              [ExposureMinimal, ExposureModerate, ExposureExtended],
              step,
              (index) => {
                setValue(index, step);
              }
            )}
          {step === 'step3' &&
            renderSlider(
              overmindState,
              [SPFRarely, SPFSometimes, SPFDaily],
              step,
              (index) => {
                setValue(index, step);
              }
            )}
          {step === 'step4' &&
            renderSlider(
              overmindState,
              [AirConDaily, AirConSometimes, AirConRarely],
              step,
              (index) => {
                setValue(index, step);
              }
            )}
          {step === 'step5' &&
            renderSlider(
              overmindState,
              [ScreensHigh, ScreensLow, ScreensMedium],
              step,
              (index) => {
                setValue(index, step);
              }
            )}
        </Question>
      </GridItem>
    </GridContainer>
  );
}
