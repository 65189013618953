import { makeStyles } from '@material-ui/core';

export const useCalendarWeekStyles = makeStyles((theme) => ({
  main: (props) => ({
    marginBottom: '60px',
    padding: '10px',
    backgroundColor: props.color ? props.color : '#dcedeb',
  }),
  item: (props) => ({}),
  text: {
    padding: '16px 10px 0px 10px',
    marginBottom: '40px',
  },
  selectedDateText1: () => ({
    color: '#084658',
  }),
}));
