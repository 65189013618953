import { makeStyles } from '@material-ui/core';

export const useAppMenuStyles = makeStyles((theme) => ({
  root: (props) => ({
    height: props.isDesktop ? '50px' : '60px',
    position: 'fixed',
    top: props.isDesktop ? 0 : undefined,
    bottom: props.isDesktop ? undefined : 0,
    left: 0,
    width: '100%',
    zIndex: 9999,
    borderTop: '1px Solid #ececec',
  }),
  wrapper: (props) => ({
    display: 'flex',
    flexDirection: props.isDesktop ? 'row' : 'column',
    gap: props.isDesktop && '10px',
  }),
  labelRoot: (props) => ({
    color: theme.palette.text.tertiary,
    minWidth: '70px',
  }),
  label: {
    marginTop: '4px',
  },
  selected: (props) => ({
    color: props.isDesktop ? '#1b4658' : theme.palette.primary.main,
  }),
}));
