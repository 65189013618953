import React from 'react';
import PropTypes from 'prop-types';

import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from 'components/atoms/Inputs/Checkbox/Checkbox';
import Typography from 'components/atoms/Typography/Typography';

export default function CheckboxWithLabel(props) {
  const onCheckboxChange = (e) => {
    if (props.onCheckboxChange) {
      props.onCheckboxChange(e.target.value, e.target.checked);
    }
  };

  return (
    <MuiFormControlLabel
      control={
        <Checkbox
          {...props}
          value={props.value}
          checked={props.checked}
          onChange={(e) => onCheckboxChange(e)}
        />
      }
      label={
        <Typography
          style={{
            fontFamily: 'Lato-Bold',
            fontSize: '14px',
            lineHeight: '18px',
          }}
        >
          {props.label}
        </Typography>
      }
    />
  );
}

CheckboxWithLabel.propTypes = {
  /**
   * Should it be disabled?
   */
  disabled: PropTypes.bool,
  /**
   * Is checkbox checked.
   */
  checked: PropTypes.bool,
  /**
   * Value for checkbox
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Text added as label for checkbox
   */
  label: PropTypes.string,
  /**
   * Callback fired when the state is changed.
   */
  onChange: PropTypes.func,
};
