import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { useAppMenuStyles } from './appMenuStyles';
import { useHistory } from 'react-router-dom';

export default function AppMenu(props) {
  const history = useHistory();
  const classes = useAppMenuStyles(props);
  const [value, setValue] = React.useState(
    props.selectedValue != null ? props.selectedValue : 0
  );

  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    setY(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener('scroll', (e) => handleScroll(e));
    };
  }, [y]);

  const handleScroll = (e) => {
    const window = e.currentTarget;
    setY(window.scrollY);
  };

  const getBackgroundOpacity = () => {
    if (props.isDesktop) {
      return 1 / (100 / y) + 0.5;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    setValue(props.selectedValue);
  }, [props.selectedValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let qs = '';
    if (window.localStorage.getItem('qs') != null) {
      qs = window.localStorage.getItem('qs');
    }
    history.push(newValue + qs);
    window.scrollTo(0, 0);
    props.onSelected && props.onSelected(newValue);
  };

  return props.hideFooter ? (
    <></>
  ) : (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => handleChange(event, newValue)}
      showLabels
      className={classes.root}
      style={{
        backgroundColor: `rgba(255, 255, 255, ${getBackgroundOpacity()})`,
      }}
    >
      {props.menuOptions.map((menuOption, ix) => {
        return (
          <BottomNavigationAction
            classes={{
              root: classes.labelRoot,
              wrapper: classes.wrapper,
              label: classes.label,
            }}
            {...menuOption}
            style={{
              color:
                menuOption.value === value
                  ? props.isDesktop
                    ? '#1b4658'
                    : '#f9b093'
                  : '#5e6073',
              borderBottom: menuOption.value === value && '1px solid #1b4658',
            }}
            key={ix}
          />
        );
      })}
    </BottomNavigation>
  );
}

AppMenu.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      showLabel: PropTypes.bool,
      value: PropTypes.number,
    })
  ),
  onSelected: PropTypes.func,
  selectedValue: PropTypes.number,
};
