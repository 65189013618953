import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';
import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import HomeIntro from 'components/organisms/Home/HomeIntro';
import HomeRecipes from 'components/organisms/Home/HomeRecipes';
import PersonalizedTips from 'components/organisms/Home/PersonalizedTips';
import ProductSwiper from 'components/organisms/ProductSwiper/ProductSwiper';
import EnvironmentalImpact from 'components/organisms/Home/EnvironmentalImpact';
import AddDNASection from 'components/organisms/Home/AddDNASection';
import DnaAssessmentSection from 'components/organisms/Home/DnaAssessmentSection';
import Box from 'components/atoms/Layout/Box';
import RecipeDetails from 'components/organisms/RecipeDetails/RecipeDetails';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import {
  getAdviceForAirQuality,
  getWeatherIllustration,
} from 'components/helpers/environmentHelper';
import ProductDetails from 'components/organisms/ProductDetails/ProductDetails';

import {
  uv1,
  uv2,
  uv3,
  uv4,
  uv5,
  aqiFair,
  aqiGood,
  aqiModerate,
  aqiPoor,
  aqiVeryPoor,
} from 'assets/imagesList';

import {
  getTodaysHours,
  getIndexOfCurrentDayInWeek,
  getDate,
  formatDate,
  getIndexOfSelectedDayInWeek,
} from 'components/helpers/dateHelper';
import { getProductsForDay } from 'components/helpers/productsHelper';
import { getRecipe } from 'components/helpers/planHelper';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';
import VisibilitySensor from 'react-visibility-sensor';

const MEAL_TYPE_BREAKFAST = 'breakfast';
const MEAL_TYPE_LUNCH = 'lunch';
const MEAL_TYPE_DINNER = 'dinner';
const MEAL_TYPE_SNACK = 'snack';

export default function Home(props) {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const HOME_MODE = 'home';
  const RECIPE_DETAILS_MODE = 'recipeDetails';
  const PRODUCT_DETAILS_MODE = 'productDetails';
  const isDesktop = useCheckScreenSize().isDesktop;

  const [meals, setMeals] = useState({
    [MEAL_TYPE_BREAKFAST]: { recipe: '' },
    [MEAL_TYPE_LUNCH]: { recipe: '' },
    [MEAL_TYPE_DINNER]: { recipe: '' },
    [MEAL_TYPE_SNACK]: { recipe: '' },
  });
  const [mealsToShow, setMealsToShow] = useState([]);
  const [currentHour, setCurrentHour] = useState(null);
  const [homeIntroInfo, setHomeIntroInfo] = useState({ title: '', text: '' });
  const [weatherToday, setWeatherToday] = useState();
  const [uvIndexText, setUvIndexText] = useState('');
  const [spf, setSpf] = useState('');
  const [weatherAdvice, setWeatherAdvice] = useState([]);
  const [uvIndexAdvice, setUvIndexAdvice] = useState([]);
  const [airPollutionAdvice, setAirPollutionAdvice] = useState([]);
  const [weatherDescription, setWeatherDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState(getDate(new Date()));
  const [hideSelectAction, setHideSelectAction] = useState(true);
  const [allRecipesInSelectedDate, setAllRecipesInSelectedDate] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [displayMode, setDisplayMode] = useState(HOME_MODE);
  const [uvIllustration, setUvIllustration] = useState(null);
  const [weatherIllustration, setWeatherIllustration] = useState(null);
  const [aqiIllustration, setAqiIllustration] = useState(null);
  const [aqiIndexText, setAqiIndexText] = useState('');
  const [weatherHumidity, setWeatherHumidity] = useState(null);
  const [displayUploadDNA, setDisplayUploadDNA] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [traitCheckIntervalId, setTraitCheckIntervalId] = useState(null);
  const [reportCheckIntervalId, setReportCheckIntervalId] = useState(null);
  const [sentEventAboutVisibility, setSentEventAboutVisibility] =
    useState(false);

  useEffect(() => {
    if (overmindState.isMealPlanLoaded && currentHour != null) {
      let mealsToShow = [];
      if (window.innerWidth < 800) {
        if (currentHour < 12) {
          mealsToShow.push(MEAL_TYPE_BREAKFAST, MEAL_TYPE_LUNCH);
        } else if (currentHour >= 12 && currentHour < 18) {
          mealsToShow.push(MEAL_TYPE_LUNCH, MEAL_TYPE_DINNER);
        } else if (currentHour >= 18) {
          mealsToShow.push(MEAL_TYPE_DINNER, MEAL_TYPE_SNACK);
        }
      } else {
        mealsToShow.push(
          MEAL_TYPE_BREAKFAST,
          MEAL_TYPE_LUNCH,
          MEAL_TYPE_DINNER,
          MEAL_TYPE_SNACK
        );
      }
      setMealsToShow(mealsToShow);

      setMeals({
        [MEAL_TYPE_BREAKFAST]: { recipe: findRecipe(MEAL_TYPE_BREAKFAST) },
        [MEAL_TYPE_LUNCH]: { recipe: findRecipe(MEAL_TYPE_LUNCH) },
        [MEAL_TYPE_DINNER]: { recipe: findRecipe(MEAL_TYPE_DINNER) },
        [MEAL_TYPE_SNACK]: { recipe: findRecipe(MEAL_TYPE_SNACK) },
      });
    }
  }, [
    overmindState.areRecipeActionsLoaded,
    overmindState.isMealPlanLoaded,
    JSON.stringify(overmindState.mealPlan),
    currentHour,
    actions,
  ]);

  useEffect(() => {
    if (
      overmindState.isMealPlanLoaded === false &&
      overmindState.isMealPlanFetching !== true
    ) {
      actions.fetchMealPlan();
    }
    setCurrentHour(getTodaysHours());
  }, [overmindState.isMealPlanLoaded, actions]);

  useEffect(() => {
    getIntroText();
  }, [
    overmindState.genotype,
    overmindState.isGenotypeFetched,
    overmindState.errors.errorGettingGenotype,
  ]);

  useEffect(() => {
    if (!overmindState.isWeatherFetched) {
      actions.fetchWeather();
    } else {
      const today = formatDate(new Date(), 'YYYY-MM-DD');
      overmindState.weather.forEach((item) => {
        if (item.dt === today) {
          setWeatherData(item);
        }
      });
    }
  }, [overmindState.weather, actions]);

  useEffect(() => {
    if (
      overmindState.isGenotypeFetched === false &&
      !overmindState.genotype.source_details
    ) {
      setDisplayUploadDNA(true);
    } else {
      setDisplayUploadDNA(false);
      actions.fetchTraitAssessments();
      if (
        overmindState.traits?.length !== 0 &&
        !overmindState.report?.download_url
      ) {
        actions.getReport();
      }
    }
  }, [actions, overmindState.isGenotypeFetched, overmindState.genotype]);

  useEffect(() => {
    if (!overmindState.traits?.length === 0 && traitCheckIntervalId === null) {
      const id = setInterval(() => {
        actions.fetchTraitAssessments();
      }, 6000);
      setTraitCheckIntervalId(id);
    } else clearInterval(traitCheckIntervalId);
  }, [actions, overmindState.traits]);

  useEffect(() => {
    if (
      overmindState.traits?.length !== 0 &&
      !overmindState.report?.download_url &&
      reportCheckIntervalId === null
    ) {
      const id = setInterval(() => {
        actions.getReport();
      }, 6000);
      setReportCheckIntervalId(id);
    } else clearInterval(reportCheckIntervalId);
  }, [actions, overmindState.traits, overmindState.report]);

  const findRecipe = (mealCode) => {
    return getRecipe(
      overmindState.mealPlan,
      mealCode,
      getIndexOfCurrentDayInWeek(getDate()),
      false
    );
  };

  const handleRecipeSelected = (recipeId, mealType) => {
    let selectedDay = getIndexOfSelectedDayInWeek(getDate(), selectedDate);
    setHideSelectAction(true);

    const date = Object.keys(overmindState.mealPlan)[selectedDay];
    setAllRecipesInSelectedDate(overmindState.mealPlan[date].meals);

    setSelectedRecipe(
      overmindState.mealPlan[date].meals.filter(
        (m) => m.meal === mealType && m.recipe.id === recipeId
      )[0]
    );
    actions.fetchRecipeDetails(recipeId).then(() => {
      setDisplayMode(RECIPE_DETAILS_MODE);
    });
  };

  const getIntroText = () => {
    if (
      overmindState.errors.errorGettingGenotype ===
      '404: Genotype not defined for the profile'
    ) {
      if (!overmindState.metaData.user_metadata.seenWelcomeMessage) {
        actions.setUserSeenWelcomeMessage(true);
        setHomeIntroInfo({
          title: t('home.blueprintBuildStageTitle'),
          text: t('home.blueprintBuildStage'),
        });
      } else {
        setHomeIntroInfo({
          title: t('home.blueprintBuildStageTitle2'),
          text: t('home.blueprintBuildStage2'),
        });
      }

      return;
    }

    if (
      overmindState.genotype.status === 'trait-scores-available' ||
      overmindState.genotype.status === 'genotype-data-valid'
    ) {
      if (!overmindState.metaData.user_metadata.seenCongratulationsMessage) {
        actions.setUserSeenCongratulationsMessage(true);
        setHomeIntroInfo({
          title: '',
          text: t('home.introMessageGenotypeTrue'),
        });
      }
    }

    if (!overmindState.regime.loading && overmindState.regime.data == null) {
      actions.fetchRegime();
    }
  };

  const getProductsRecommendedToday = () => {
    return getProductsForDay(new Date(), overmindState.regime);
  };

  const setWeatherData = (weatherDataForToday) => {
    setWeatherToday(weatherDataForToday);
    const uvi = parseInt(weatherDataForToday.uvi);
    if (
      weatherDataForToday &&
      weatherDataForToday.weather_desc &&
      weatherDataForToday.weather_desc.length > 0
    ) {
      weatherDataForToday.weather_desc.forEach((desc, ix) => {
        const weatherCode = desc.main.toLowerCase().replace(' ', '');
        let translationKey =
          'home.environmentalImpact.advices.weather.' + weatherCode;
        const advice = t(translationKey);
        setWeatherAdvice(advice);
        translationKey =
          'home.environmentalImpact.advices.weatherDescription.' + weatherCode;
        const weatherDesc = t(translationKey);
        setWeatherDescription(weatherDesc);
        setWeatherIllustration(getWeatherIllustration(weatherCode));
      });
    }

    switch (weatherDataForToday.aqi) {
      case 1:
        setAqiIllustration(aqiGood);
        setAqiIndexText(t('home.environmentalImpact.aqi.0'));
        break;
      case 2:
        setAqiIllustration(aqiFair);
        setAqiIndexText(t('home.environmentalImpact.aqi.1'));
        break;
      case 3:
        setAqiIllustration(aqiModerate);
        setAqiIndexText(t('home.environmentalImpact.aqi.2'));
        break;
      case 4:
        setAqiIllustration(aqiPoor);
        setAqiIndexText(t('home.environmentalImpact.aqi.3'));
        break;
      case 5:
        setAqiIllustration(aqiVeryPoor);
        setAqiIndexText(t('home.environmentalImpact.aqi.4'));
        break;
      default:
        setAqiIllustration(null);
        setAqiIndexText('');
        break;
    }

    switch (true) {
      case uvi < 3:
        setUvIndexText(t('home.environmentalImpact.uvIndexSeverity.low'));
        setSpf(t('home.environmentalImpact.spf.20'));
        setUvIllustration(uv1);
        setUvIndexAdvice(t('home.environmentalImpact.advices.uvi.low'));
        break;
      case uvi < 6:
        setUvIndexText(t('home.environmentalImpact.uvIndexSeverity.moderate'));
        setSpf(t('home.environmentalImpact.spf.30+'));
        setUvIllustration(uv2);
        setUvIndexAdvice(t('home.environmentalImpact.advices.uvi.moderate'));
        break;
      case uvi < 8:
        setUvIndexText(t('home.environmentalImpact.uvIndexSeverity.high'));
        setSpf(t('home.environmentalImpact.spf.30+'));
        setUvIllustration(uv3);
        setUvIndexAdvice(t('home.environmentalImpact.advices.uvi.high'));
        break;
      case uvi <= 10:
        setUvIndexText(t('home.environmentalImpact.uvIndexSeverity.veryHigh'));
        setSpf(t('home.environmentalImpact.spf.50'));
        setUvIllustration(uv4);
        setUvIndexAdvice(t('home.environmentalImpact.advices.uvi.veryhigh'));
        break;
      case uvi > 10:
        setUvIndexText(t('home.environmentalImpact.uvIndexSeverity.extreme'));
        setSpf(t('home.environmentalImpact.spf.50'));
        setUvIllustration(uv5);
        setUvIndexAdvice(t('home.environmentalImpact.advices.uvi.extreme'));
        break;
      default:
        setUvIndexText('');
        setSpf('');
        break;
    }

    setAirPollutionAdvice(getAdviceForAirQuality(weatherDataForToday.aqi));
    setWeatherHumidity(weatherDataForToday.humidity);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setDisplayMode(PRODUCT_DETAILS_MODE);
  };

  return (
    <ThemeProvider theme={assessmentTheme}>
      {displayMode === HOME_MODE && (
        <GridContainer
          direction="row"
          style={{
            paddingTop: isDesktop ? '50px' : '0px',
            backgroundColor: '#dcedeb',
          }}
        >
          <GridItem xs={12}>
            <HomeIntro
              firstName={overmindState.metaData.user_metadata.firstName}
              title={homeIntroInfo.title}
              text={homeIntroInfo.text}
              image={null}
            />
          </GridItem>
          {/*displayUploadDNA && overmindState.isLoadingData === false && (
            <GridItem xs={12} style={{ margin: '10px 0' }}>
              <AddDNASection parent={'home'} />
            </GridItem>
          )*/}
          {
            /*overmindState.isGenotypeFetched &&
            overmindState.genotype.source_details != null &&*/ <DnaAssessmentSection
              onSummaryClick={() =>
                props.onSwitchMenuRequested &&
                props.onSwitchMenuRequested('genetics')
              }
            />
          }
          {overmindState &&
            !overmindState.regime.loading &&
            overmindState.regime.data && (
              <GridItem xs={12} style={{ margin: '30px 0' }}>
                <ProductSwiper
                  title={t('home.regimen.title')}
                  subtitle={t('home.regimen.description')}
                  items={getProductsRecommendedToday()}
                  color="#f8f4f2"
                  pagination
                  paginationColor="#084658"
                  onProductClick={(product) => {
                    handleProductSelect(product);
                  }}
                />
              </GridItem>
            )}
          {weatherToday && (
            <GridItem xs={12}>
              <EnvironmentalImpact
                title={t('home.environmentalImpact.title')}
                weatherText={weatherToday.weather_desc[0].description}
                weatherDescription={weatherDescription}
                weatherTemperature={parseInt(weatherToday.temp_max)}
                weatherIllustration={weatherIllustration}
                uvIndexValue={parseInt(weatherToday.uvi)}
                uvIndexText={uvIndexText}
                uvIndexIllustration={uvIllustration}
                uvIndexSunProtectFactor={spf}
                airPollutionValue={weatherToday.aqi}
                airPollutionText={aqiIndexText}
                airPollutionIllustration={aqiIllustration}
                recommendation={null} // no text from API
                weatherAdvice={weatherAdvice}
                uvIndexAdvice={uvIndexAdvice}
                humidity={weatherHumidity}
                airPollutionAdvice={airPollutionAdvice}
                onConfirm={() =>
                  props.onSwitchMenuRequested &&
                  props.onSwitchMenuRequested('regimen')
                }
              />
            </GridItem>
          )}
          <GridItem xs={12}>
            <HomeRecipes
              isLoaded={overmindState.isMealPlanLoaded}
              title={t('home.recipes.title')}
              subtitle={t('home.recipes.subtitle')}
              description={t('home.recipes.description')}
              meals={meals}
              mealsToShow={mealsToShow}
              handleRecipeSelected={(recipeId, mealType) =>
                handleRecipeSelected(recipeId, mealType)
              }
              onButtonClick={() =>
                props.onSwitchMenuRequested &&
                props.onSwitchMenuRequested('nutrition')
              }
            />
          </GridItem>
          {overmindState?.initialReport?.know_tip?.title && (
            <GridItem xs={12}>
              <PersonalizedTips
                subtitle={t('home.personalizedTips.title')}
                title={overmindState.initialReport.know_tip.title}
                image={overmindState.initialReport.know_tip.image}
                text={overmindState.initialReport.know_tip.text}
                reference={overmindState.initialReport.know_tip.reference}
                backgroundColor="#fff"
              />
            </GridItem>
          )}
          {overmindState.initialReport?.science_fact_tip?.text && (
            <GridItem xs={12}>
              <VisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible && !sentEventAboutVisibility) {
                    trackEvent('ElementInViewport', 'Home Bottom');
                    setSentEventAboutVisibility(true);
                  }
                }}
              >
                <div
                  style={{
                    marginBottom: '60px',
                  }}
                >
                  <PersonalizedTips
                    reverse
                    subtitle={t('home.scienceFact.title')}
                    title={overmindState.initialReport.science_fact_tip.title}
                    image={overmindState.initialReport.science_fact_tip.image}
                    text={overmindState.initialReport.science_fact_tip.text}
                    reference={
                      overmindState.initialReport.science_fact_tip.reference
                    }
                    backgroundColor="#e8dfd8"
                  />
                </div>
              </VisibilitySensor>
            </GridItem>
          )}
        </GridContainer>
      )}

      {displayMode === RECIPE_DETAILS_MODE && selectedRecipe && (
        <Box
          style={{
            marginBottom: '70px',
          }}
        >
          <ReoccurringHeader
            title={t('mealPlan.recipeTitle')}
            goBack={() => setDisplayMode(HOME_MODE)}
            showBack={displayMode === RECIPE_DETAILS_MODE}
            addMargin={false}
            whiteText={displayMode === RECIPE_DETAILS_MODE}
          />
          <RecipeDetails
            r={selectedRecipe}
            recipe={selectedRecipe.recipe}
            meal={selectedRecipe.meal}
            numberOfServings={selectedRecipe.numOfServings}
            changeRecipe={(recipeId, mealType, selectedDay) =>
              handleRecipeSelected(recipeId, mealType, selectedDay)
            }
            dailyRecipes={allRecipesInSelectedDate}
            dateIndex={getIndexOfSelectedDayInWeek(getDate(), selectedDate)}
            parentComponent="meal_plan"
            hideSelect={hideSelectAction}
            onSwap={() => {
              setDisplayMode(HOME_MODE);
            }}
            disableNavigation={true}
          />
        </Box>
      )}

      {displayMode === PRODUCT_DETAILS_MODE && (
        <Box>
          <ReoccurringHeader
            title={t('regimen.title')}
            goBack={() => setDisplayMode(HOME_MODE)}
            showBack={true}
            addMargin={false}
            whiteText={false}
          />
          <ProductDetails product={selectedProduct} />
        </Box>
      )}
    </ThemeProvider>
  );
}
