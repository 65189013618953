import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from 'components/atoms/Inputs/Buttons/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  button: {
    color: '#97d3c5',
  },
}));

export default function UploadButton(props) {
  const classes = useStyles();

  const handleFileSelect = ({ target }) => {
    props.onFileSelected(target.files[0]);
  };

  return (
    <div className={classes.root}>
      <input
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleFileSelect}
        disabled={props.disabled ? props.disabled : false}
      />
      <label htmlFor="contained-button-file">
        <Button
          component="span"
          variant={props.variant}
          className={classes.button}
          disabled={props.disabled ? props.disabled : false}
          onClick={() => props.onClick && props.onClick()}
        >
          {props.children}
        </Button>
      </label>
    </div>
  );
}

UploadButton.defaultProps = {
  variant: 'contained',
};

UploadButton.propTypes = {
  onFileSelected: PropTypes.func.isRequired,
  children: PropTypes.node,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
};
