import React, { useState, useEffect } from 'react';
import TextInput from 'components/atoms/Inputs/TextInput/TextInput';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import { useResetPasswordStyles, inputProps } from './resetPasswordStyles';
import { useTranslation } from 'react-i18next';
import {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  PUBLIC_URL,
  DB_CONNECTION,
  EMAIL_REGEX,
} from 'components/helpers/auth0Helper';
import Button from 'components/atoms/Inputs/Buttons/Button';
import * as auth0 from 'auth0-js';
import { WhiteRightArrow } from 'assets/iconsList';
import LandingModal from 'components/molecules/LandingModal/LandingModal';

export default function ResetPassword(props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningContent, setWarningContent] = useState(null);

  const resetPasswordStyles = useResetPasswordStyles(emailInvalid);

  const [webAuth] = useState(
    new auth0.WebAuth({
      domain: REACT_APP_AUTH0_DOMAIN || 'auth.lifenome.com',
      clientID: REACT_APP_AUTH0_CLIENT_ID || 'tSw6gdmNrsPDUalcY3aKOfsY2fI4M9eS',
      redirectUri: `${window.location.origin}${PUBLIC_URL}`,
      responseType: 'code token',
    })
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetPassword = () => {
    webAuth.changePassword(
      {
        connection: DB_CONNECTION,
        email: email,
      },
      function (err, resp) {
        if (err) {
          setResetLinkSent(false);
        } else {
          setResetLinkSent(true);
        }
      }
    );
  };

  const handleResetPassword = () => {
    let invalid = false;
    if (EMAIL_REGEX.test(email) === false) {
      invalid = true;
      setEmailInvalid(invalid);
    }
    if (!invalid) {
      resetPassword();
    } else {
      setWarningContent(
        <span>
          {t('onboarding.introSection.landing.wrongEmail')}
          <br></br>
        </span>
      );
      setShowWarning(true);
    }
  };

  const renderResendLinkView = () => {
    return (
      <>
        <Typography className={resetPasswordStyles.title}>
          {t('onboarding.introSection.landing.checkEmail')}
        </Typography>

        <Typography className={resetPasswordStyles.subtitle}>
          {t('onboarding.introSection.landing.linkSent')}
        </Typography>

        <Button
          className={resetPasswordStyles.confirmButton}
          style={{ margin: 0, marginTop: 20 }}
          variant="contained"
          onClick={resetPassword}
        >
          <Typography className={resetPasswordStyles.buttonText}>
            {t('onboarding.introSection.landing.resendLinkButtonText')}
          </Typography>
        </Button>
        <Typography
          className={resetPasswordStyles.signInText}
          style={{ marginTop: '25px' }}
        >
          {t('onboarding.introSection.landing.resendLinkText')}
        </Typography>
      </>
    );
  };

  const renderResetView = () => {
    return (
      <>
        <Typography className={resetPasswordStyles.title}>
          {t('onboarding.introSection.landing.resetPasswordTitle')}
        </Typography>

        <Typography
          className={resetPasswordStyles.subtitle}
          style={{ marginBottom: '30px' }}
        >
          {t('onboarding.introSection.landing.resetPasswordSubtitle')}
        </Typography>
        <TextInput
          onChange={(e) => {
            setEmailInvalid(false);
            setEmail(e.target.value);
          }}
          className={resetPasswordStyles.emailContainer}
          style={{
            marginTop: '25px',
            width: '100%',
          }}
          placeholder="Your email"
          inputProps={{ style: inputProps }}
          color="primary"
        />

        <Box marginTop="13.5">
          <Button
            className={resetPasswordStyles.confirmButton}
            variant="contained"
            onClick={handleResetPassword}
          >
            <Typography className={resetPasswordStyles.buttonText}>
              {t(
                'onboarding.introSection.landing.resetPasswordButtonText'
              ).toUpperCase()}
            </Typography>
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Box style={{ marginLeft: 23, marginRight: 23 }}>
      {!resetLinkSent ? renderResetView() : renderResendLinkView()}
      <Typography
        className={resetPasswordStyles.signInText}
        style={{ marginTop: '64px' }}
      >
        {t('onboarding.introSection.landing.signInInstead')}
      </Typography>
      <Box
        className={resetPasswordStyles.signInContainer}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => props.toggleForm && props.toggleForm()}
      >
        <Typography className={resetPasswordStyles.signInText}>
          {t('onboarding.introSection.landing.signIn')}
        </Typography>
        <WhiteRightArrow style={{ height: '25px', paddingRight: '15px' }} />
      </Box>

      <Typography className={resetPasswordStyles.statement}>
        {t('onboarding.introSection.landing.statement')}{' '}
        <a
          className={resetPasswordStyles.statement}
          style={{ textDecoration: 'underline' }}
          href="https://www.lifenome.com/terms-of-service/"
          target="_blank"
          rel="noreferrer"
        >
          {t('onboarding.introSection.landing.tos')}{' '}
        </a>
        {' & '}
        <a
          className={resetPasswordStyles.statement}
          style={{ textDecoration: 'underline' }}
          href="https://www.lifenome.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          {t('onboarding.introSection.landing.privacyPolicy')}
        </a>
      </Typography>
      <LandingModal
        content={warningContent}
        opened={showWarning}
        close={() => setShowWarning(false)}
      />
    </Box>
  );
}
