import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Button from 'components/atoms/Inputs/Buttons/Button';
import { useOvermindState } from '../../../overmind';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useFooterStyles, useButtonStyles } from './footerStyles';

export default function Footer(props) {
  const classes = useFooterStyles(props);
  const buttonClasses = useButtonStyles(props);
  const overmindState = useOvermindState();

  return (
    <>
      {!props.hideFooter && (
        <GridContainer
          direction="row"
          alignItems="center"
          classes={{ ...classes }}
        >
          {props.showLeftButton && (
            <GridItem>
              <Button
                onClick={() => {
                  props.onBackClick();
                }}
              >
                {props.leftButtonContent ? (
                  props.leftButtonContent
                ) : (
                  <>
                    <NavigateBeforeIcon /> Back
                  </>
                )}
              </Button>
            </GridItem>
          )}
          {props.hideRightButton !== true && (
            <GridItem
              className={
                props.centerRightButton
                  ? buttonClasses.center
                  : buttonClasses.rightSide
              }
            >
              <Button
                disabled={!overmindState.onboardingForwardEnabled}
                variant="contained"
                onClick={() => props.onNextClick()}
                className={buttonClasses.root}
              >
                {props.rightButtonContent ? (
                  props.rightButtonContent
                ) : (
                  <>
                    {props.text ? props.text : 'Next'} <NavigateNextIcon />
                  </>
                )}
              </Button>
            </GridItem>
          )}
        </GridContainer>
      )}
    </>
  );
}

Footer.propTypes = {
  hideFooter: PropTypes.bool,
  hideLeftButton: PropTypes.bool,
  hideRightButton: PropTypes.bool,
  leftButtonContent: PropTypes.element,
  rightButtonContent: PropTypes.element,
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
};
