import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/atoms/Layout/Box';
import Typography from 'components/atoms/Typography/Typography';
import CalendarItem from 'components/molecules/Calendar/CalendarItem';

import { useCalendarWeekStyles } from './calendarWeekStyles';
import { getDate, getCurrentWeekFromDate } from 'components/helpers/dateHelper';
import { useOvermindState, useActions } from 'overmind/index';

export default function CalendarWeek(props) {
  const overmindState = useOvermindState();
  const actions = useActions();
  const classes = useCalendarWeekStyles(props);
  const firstDay = getDate(getDate());
  const [selected, setSelected] = useState(
    getCurrentWeekFromDate(firstDay)[props.dateIndex]
  );
  const [data, setData] = useState();

  useEffect(() => {
    if (
      Object.keys(overmindState.blueprintProfile.profile).length === 0 &&
      overmindState.blueprintProfile.loading == null
    ) {
      actions.setBlueprintProfileLoading(true);
      actions.fetchBlueprintProfile();
    }

    let data = getWeekDaysData();
    setData(data);
  }, []);

  const getWeekDaysData = () => {
    let data = [];
    getCurrentWeekFromDate(firstDay).forEach((day, ix) => {
      data.push({
        selected: ix === props.dateIndex,
        date: day,
      });
    });

    return data;
  };

  const handleClick = (item, index) => {
    let newData = [...data];

    newData.forEach((i, ix) => {
      i.selected = false;
      if (ix === index) {
        i.selected = !i.selected;
      }
    });

    setData(newData);
    setSelected(item.date);
    props.onClick && props.onClick(item.date, index);

    return newData;
  };

  const renderSelectedDayInfo = (info) => {
    return (
      <Typography
        variant="body1"
        style={{ fontSize: '12px', fontWeight: 'normal' }}
      >
        {info}
      </Typography>
    );
  };

  return (
    <Box className={classes.main}>
      <Box
        style={{
          maxWidth: '670px',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
        marginBottom="10px"
      >
        {renderSelectedDayInfo(selected.format('dddd, MMMM DD, YYYY'))}
      </Box>

      <Box className={classes.dates} display="flex" justifyContent="center">
        {data &&
          data.map((item, ix) => (
            <Box className={classes.item}>
              <CalendarItem
                date={item.date}
                selected={props.type !== 'week' && item.selected}
                isDesktop={props.isDesktop}
                onClick={() => handleClick(item, ix)}
                type={props.type}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
}

CalendarWeek.propTypes = {
  selected: PropTypes.bool,
  type: PropTypes.oneOf(['day', 'week']),
};
