import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnvironmentalImpactStyles } from './HomeStyles';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';
import InfoCard from 'components/molecules/Common/InfoCard/InfoCard';
import ReactCardFlip from 'react-card-flip';
import ContentCard from 'components/molecules/Common/ContentCard/ContentCard';

export default function EnvironmentalImpact(props) {
  const {
    title,
    weatherText,
    weatherTemperature,
    weatherIllustration,
    uvIndexValue,
    uvIndexText,
    uvIndexIllustration,
    airPollutionValue,
    airPollutionText,
    airPollutionIllustration,
    recommendation,
    uvIndexAdvice,
    airPollutionAdvice,
    weatherAdvice,
    humidity,
  } = props;
  const { t } = useTranslation();
  const classes = useEnvironmentalImpactStyles();
  const [weatherFlipped, setWeatherFlipped] = useState(false);
  const [uvFlipped, setUvFlipped] = useState(false);
  const [airPollutionFlipped, setAirPollutionFlipped] = useState(false);
  const isDesktop = useCheckScreenSize().isDesktop;

  const renderWeatherCard = (title, humidity, infoText, temp, image) => {
    return (
      <InfoCard preventClick={true} style={{ cursor: 'pointer' }}>
        <GridContainer
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.card}
        >
          <GridItem
            xs={humidity ? 4 : 7}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: '16px',
                color: '#2a343f',
                fontWeight: 900,
              }}
            >
              {`${title[0].toUpperCase()}${title.slice(1)}`}
            </Typography>

            <Typography
              variant="h4"
              style={{
                fontSize: '20px',
                lineHeight: '22px',
                fontWeight: '900',
              }}
            >
              {`${infoText[0].toUpperCase()}${infoText.slice(1)}`}
            </Typography>
          </GridItem>
          {humidity && (
            <GridItem
              xs={3}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h4"
                style={{
                  fontSize: '21px',
                  lineHeight: '20px',
                  fontWeight: '700',
                }}
              >
                {humidity}
                {'%'}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: '10px',
                  lineHeight: '11px',
                  fontWeight: '300',
                  color: '#342f38',
                }}
              >
                {t('home.environmentalImpact.humidity')}
              </Typography>
            </GridItem>
          )}

          <GridItem
            xs={4}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <img
              src={image ? image : 'https://via.placeholder.com/70x70'}
              alt={`weather-icon`}
              height={'70px'}
              width={'70px'}
            />
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Typography
                variant="h4"
                style={{
                  fontSize: '50px',
                  lineHeight: '40px',
                  fontWeight: '700',
                }}
              >
                {temp}
              </Typography>
              <Typography variant="body2">&deg;{'F'}</Typography>
            </Box>
            <Typography
              variant="body2"
              style={{
                fontSize: '10px',
                lineHeight: '11px',
                fontWeight: '300',
                color: '#342f38',
              }}
            >
              {t('home.environmentalImpact.temperature')}
            </Typography>
          </GridItem>
        </GridContainer>
      </InfoCard>
    );
  };

  const renderEnvInfoCard = (title, number, image, smallText, optionalText) => {
    return (
      <InfoCard preventClick={true} style={{ cursor: 'pointer' }}>
        <GridContainer className={classes.card}>
          <GridItem xs={12}>
            <Typography
              variant="body1"
              style={{ fontSize: '14px', color: '#2a343f' }}
            >
              {title}
            </Typography>
          </GridItem>
          <GridItem xs={12} style={{ marginTop: 'auto' }}>
            <GridContainer
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <GridItem xs={6}>
                <Typography
                  variant="h4"
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: '900',
                    color: '#342f38',
                  }}
                >
                  {smallText}
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    fontSize: '45px',
                    lineHeight: '45px',
                    fontWeight: '700',
                  }}
                >
                  {number}
                </Typography>
                {optionalText && (
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: '8px',
                      lineHeight: '10px',
                      color: '#342f38',
                    }}
                  >
                    {optionalText}
                  </Typography>
                )}
              </GridItem>
              <GridItem
                xs={6}
                style={{
                  paddingLeft: '10px',
                }}
              >
                <img
                  src={image ? image : 'https://via.placeholder.com/50x60'}
                  alt="uv_index illustration"
                  width={50}
                  style={{
                    width: '100%',
                    maxHeight: '100px',
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </InfoCard>
    );
  };

  const renderWideCard = (title, text) => {
    return (
      <InfoCard preventClick={true} style={{ cursor: 'pointer' }}>
        <GridContainer className={classes.card}>
          <GridItem xs={12}>
            <Typography
              variant="body1"
              style={{ fontSize: '14px', color: '#2a343f' }}
            >
              {title}
            </Typography>
          </GridItem>
          <GridItem xs={12} style={{ marginTop: 'auto' }}>
            <GridContainer
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <GridItem>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: '16px',
                    lineHeight: '18px',
                    fontWeight: '300',
                    color: '#342f38',
                  }}
                >
                  {text}
                </Typography>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </InfoCard>
    );
  };

  const handleFlip = (type) => {
    if (type === 'uvIndex') {
      setUvFlipped(!uvFlipped);
    } else if (type === 'airPollution') {
      setAirPollutionFlipped(!airPollutionFlipped);
    } else {
      setWeatherFlipped(!weatherFlipped);
    }
  };

  return (
    <ContentCard color="#fff">
      <GridContainer direction="column">
        {title && (
          <GridItem
            xs={12}
            style={{
              margin: '15px auto',
              textAlign: 'center',
            }}
          >
            <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
              {title}
            </Typography>
          </GridItem>
        )}
        {recommendation && (
          <GridItem xs={12}>
            <Typography variant="h5" className={classes.subtitle}>
              {recommendation}
            </Typography>
          </GridItem>
        )}
      </GridContainer>

      <GridContainer className={classes.container} spacing={isDesktop ? 1 : 0}>
        <GridItem xs={12} md={4} onClick={() => handleFlip('weather')}>
          <ReactCardFlip isFlipped={weatherFlipped}>
            {renderWeatherCard(
              t('home.environmentalImpact.weather'),
              humidity,
              weatherText,
              weatherTemperature,
              weatherIllustration
            )}

            {renderWideCard(
              t('home.environmentalImpact.weather'),
              weatherAdvice
            )}
          </ReactCardFlip>
        </GridItem>
        <GridItem
          xs={uvFlipped ? 12 : 6}
          md={4}
          onClick={() => handleFlip('uvIndex')}
          style={{
            display: !isDesktop && airPollutionFlipped ? 'none' : 'block',
            paddingRight: !isDesktop && '4px',
          }}
        >
          <ReactCardFlip isFlipped={uvFlipped}>
            {renderEnvInfoCard(
              t('home.environmentalImpact.sunRadiation'),
              uvIndexValue,
              uvIndexIllustration,
              uvIndexText,
              t('home.environmentalImpact.uvIndex')
            )}

            {renderWideCard(
              t('home.environmentalImpact.uvIndex'),
              uvIndexAdvice
            )}
          </ReactCardFlip>
        </GridItem>
        <GridItem
          xs={airPollutionFlipped ? 12 : 6}
          md={4}
          onClick={() => handleFlip('airPollution')}
          style={{
            display: !isDesktop && uvFlipped ? 'none' : 'block',
            paddingLeft: !isDesktop && '4px',
          }}
        >
          <ReactCardFlip isFlipped={airPollutionFlipped}>
            {renderEnvInfoCard(
              t('home.environmentalImpact.airPollution'),
              airPollutionValue,
              airPollutionIllustration,
              airPollutionText,
              t('home.environmentalImpact.airQualityIndex')
            )}

            {renderWideCard(
              t('home.environmentalImpact.airPollution'),
              airPollutionAdvice
            )}
          </ReactCardFlip>
        </GridItem>
        <GridItem
          xs={12}
          style={{
            textAlign: 'center',
            marginTop: '10px',
            marginBottom: '40px',
          }}
        >
          <Button
            onClick={() => {
              props.onConfirm && props.onConfirm();
            }}
            variant="contained"
            style={{
              height: '60px',
              width: '250px',
              backgroundColor: '#084658',
              color: '#fff',
            }}
          >
            <Typography variant="body1">
              {t('home.environmentalImpact.checkRegimenButton')}
            </Typography>
          </Button>
        </GridItem>
      </GridContainer>
    </ContentCard>
  );
}
