import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import KitNumberInputQuestion from 'components/organisms/Questions/DNA/KitNumberInputQuestion';

import { usePageStyles } from 'components/pages/commonStyles';

/**
 * Questionnaire page asking user to provide DNA kit number.
 * Exists for presentation in StoryBook. Actual page implemented as KitNumberInputPage component.
 */
export default function KitNumberInput(props) {
  const classes = usePageStyles(props);

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem style={{ marginLeft: '12px', marginRight: '12px' }}>
        <KitNumberInputQuestion
          title={props.title}
          kitNumber={props.kitNumber}
          submitSuccessful={props.submitSuccessful}
          showSubmitStatus={props.showSubmitStatus}
          onKitNumberSubmit={(kitNumber) => {
            if (props.onKitNumberSubmit) {
              props.onKitNumberSubmit(kitNumber);
            }
          }}
          error={props.error}
        />
      </GridItem>
    </GridContainer>
  );
}
