import React from 'react';

import { useProfileTilesStyles } from './profileTilesStyles';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export default function InfoContainer(props) {
  const classes = useProfileTilesStyles();

  return (
    <GridContainer
      onClick={props.onTileClick && (() => props.onTileClick())}
      style={{ cursor: 'pointer' }}
    >
      <Box className={classes.tile}>
        <GridItem>
          <Typography className={classes.tileTitle}>{props.title}</Typography>
        </GridItem>
        {props.icon ? (
          <GridContainer
            direction="row"
            style={{
              marginTop: '-10px',
            }}
            justify="space-between"
            alignItems="flex-end"
          >
            <GridItem
              xs={5}
              style={{
                textAlign: 'left',
              }}
            >
              <Typography className={classes.tileInfo} color={props.color}>
                {props.value ? props.value : '-'}
              </Typography>
            </GridItem>
            <GridItem
              xs={5}
              style={{
                textAlign: 'right',
                margin: '-30px 0 0 -10px',
              }}
            >
              <img
                src={props.icon}
                height={props.iconHeight ? props.iconHeight : '80px'}
                width={props.iconWidth ? props.iconWidth : '80px'}
                alt={`${props.title}-icon`}
              />
            </GridItem>
            <GridItem xs={1} style={{ textAlign: 'right' }}>
              <NavigateNextIcon color={props.color} />
            </GridItem>
          </GridContainer>
        ) : Array.isArray(props.value) ? (
          <GridContainer justify="space-between" alignItems="flex-end">
            <GridItem xs={10}>
              {props.tallContainer ? (
                <GridContainer direction="row">
                  {props.value &&
                    props.value.length > 0 &&
                    props.value.map((item) => <GridItem>{item}</GridItem>)}
                  {props.value == null || (props.value.length === 0 && '-')}
                </GridContainer>
              ) : (
                <Typography className={classes.tileInfo} color={props.color}>
                  {props.value}
                </Typography>
              )}
            </GridItem>
            <GridItem xs={1} style={{ textAlign: 'right' }}>
              <NavigateNextIcon color={props.color} />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="flex-end"
          >
            <GridItem xs={8}>
              <Typography className={classes.tileInfo} color={props.color}>
                {props.value ? props.value : '-'}
              </Typography>
            </GridItem>
            <GridItem xs={1} style={{ textAlign: 'right' }}>
              <NavigateNextIcon color={props.color} />
            </GridItem>
          </GridContainer>
        )}
      </Box>
    </GridContainer>
  );
}

InfoContainer.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
};
