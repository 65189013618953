import React from 'react';

import { useProfileTilesStyles } from './infoCardStyles';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';

export default function InfoCard(props) {
  const { title, info, icon, onClick, children } = props;
  const classes = useProfileTilesStyles(props);

  return (
    <GridContainer
      className={classes.tile}
      style={props.style}
      onClick={onClick && (() => onClick())}
    >
      {(title || info) && (
        <GridItem xs={9}>
          <Typography variant="h4" className={classes.tileTitle}>
            {title}
          </Typography>
          <Typography
            variant="body1"
            style={{ color: props.infoColor }}
            className={classes.tileInfo}
          >
            {info}
          </Typography>
        </GridItem>
      )}

      {icon && (
        <GridItem xs={3}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            className={classes.tileIcon}
          >
            {icon}
          </Box>
        </GridItem>
      )}

      {children}
    </GridContainer>
  );
}

InfoCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
};
