import { makeStyles } from '@material-ui/core';

export const useLandingPageStyles = makeStyles((theme) => ({
  title: (prop) => ({
    fontFamily: 'MuseoSans',
    fontSize: '50px',
    fontWeight: '300',
    lineHeight: '0.8',
    color: '#fff',
    // width: '220px',
    maxWidth: '300px',
    textAlign: 'center',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    userSelect: 'none',
  }),
  subtitle: (prop) => ({
    fontFamily: 'MuseoSans',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '1.13',
    color: '#fff',
    // width: '220px',
    textAlign: 'center',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    userSelect: 'none',
  }),
}));
