import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useActions, useOvermindState } from 'overmind/index';
import { ROUTE_ASSESSMENT, ROUTE_REGISTER_KIT } from 'routes';
import { useTranslation } from 'react-i18next';

import { intro4, LogoBlue } from 'assets/imagesList';

import { ThemeProvider } from '@material-ui/core/styles';
import {
  dnaTheme,
  generalTheme,
  lifestyleTheme,
  nutritionTheme,
  environmentTheme,
  introTheme,
} from 'themes';

import Footer from 'components/molecules/Footer/Footer';
import Header from 'components/molecules/Header/Header';
import Slider from 'components/molecules/Slider/Slider';

import ResearchAgreementPage from './Intro/ResearchAgreement/ResearchAgreementPage';
import TermsOfServicePage from './Intro/TermsOfService/TermsOfServicePage';

import SectionIntro from 'components/molecules/SectionIntro/SectionIntro';
import AgePage from './General/Age/AgePage';
import ZipCodePage from './General/ZipCode/ZipCodePage';
import BiologicalSexPage from './General/BiologicalSex/BiologicalSexPage';
import NamePage from './General/Name/NamePage';

import ExercisePerDayPage from './Exercise/ExercisePerDayPage';

import LifestyleSleepPage from './Lifestyle/LifestyleSleepPage';
import LifestyleStressPage from './Lifestyle/LifestyleStressPage';

import DietaryPreferencesPage from './Food/DietaryPreferences/DietaryPreferencesPage';
import FoodAllergiesPage from './Food/FoodAllergies/FoodAllergiesPage';

import ProgressIndicator from 'components/organisms/ProgressIndicator/ProgressIndicator';
import {
  getProgressItems,
  getSectionByStepId,
  getFirstStepInSection,
  isIntroStep,
} from 'components/helpers/progressIndicatorHelper';
import { getStepIndex } from 'components/helpers/onboardingHelper';

import Box from 'components/atoms/Layout/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

import LifestyleCigarettesPage from './Lifestyle/LifestyleCigarettesPage';
import LifestyleAlcoholPage from './Lifestyle/LifestyleAlcoholPage';
import LifestyleWaterPage from './Lifestyle/LifestyleWaterPage';
import PastWeekConsumptionPage from './Food/PastWeekConsumption/PastWeekConsumptionPage';
import EnvironmentIntroPage from './Environment/EnvironmentIntroPage';
import EnvironmentPage from './Environment/EnvironmentPage';
import SkinIntroPage from './Skin/SkinIntroPage';
import SkinPage from './Skin/SkinPage';

export default function Onboarding() {
  const actions = useActions();
  const overmindState = useOvermindState();

  const isIntroPage = () => {
    return (
      overmindState.progressStep === getStepIndex('general_intro') ||
      overmindState.progressStep === getStepIndex('lifestyle_intro') ||
      overmindState.progressStep === getStepIndex('food_intro') ||
      overmindState.progressStep === getStepIndex('environment_intro') ||
      overmindState.progressStep === getStepIndex('skin_intro') ||
      overmindState.progressStep === getStepIndex('onboarding_finished') ||
      overmindState.progressStep === getStepIndex('ready_to_use_the_app_page')
    );
  };

  return (
    <>
      <Box
        style={{
          backgroundImage: isIntroPage()
            ? 'radial-gradient(ellipse 80% 450px, #9CAFAD, #40635F)'
            : '',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <HeaderWrapper />

        {window.innerWidth > 800 ? (
          <GridContainer
            direction="column"
            justify="center"
            alignItems="center"
          >
            <GridItem
              style={{
                maxWidth: '1200px',
              }}
            >
              <ProgressIndicatorWrapper />
            </GridItem>
            <GridItem
              style={{
                maxWidth: '1200px',
              }}
            >
              <SliderWrapper />
            </GridItem>
          </GridContainer>
        ) : (
          <>
            <ProgressIndicatorWrapper />
            <SliderWrapper />
          </>
        )}
      </Box>
      <Footer
        onBackClick={() => {
          actions.goBack();
        }}
        onNextClick={() => {
          actions.goNext();
        }}
        buttonBackgroundColor="#97d3c5"
        transparent={true}
      />
    </>
  );
}

export const HeaderWrapper = () => {
  const overmindState = useOvermindState();

  return (
    <Header
      hideHeader={overmindState.hideHeader}
      adjustMargin={isIntroStep(overmindState.progressStep)}
      hideLeftButton={overmindState.progressStep <= 1}
      logo={
        overmindState.progressStep !== getStepIndex('terms_of_service') ||
        overmindState.progressStep !== getStepIndex('research_agreement_page')
          ? LogoBlue
          : undefined
      }
    />
  );
};

export function ProgressIndicatorWrapper() {
  const overmindState = useOvermindState();
  const actions = useActions();

  const renderIndicator = (progressStep) => {
    return !isIntroStep(progressStep) ? (
      <ProgressIndicator
        items={getProgressItems()}
        selectedSlideIx={getSectionByStepId(progressStep).id}
        lastVisitedSectionId={overmindState.lastSectionVisited}
        uid="navigation-swiper"
        slideClassName="q-nav-swiper-slide"
        onProgressStepClick={(sectionId, lastVisitedSectionId) => {
          actions.goToStep(getFirstStepInSection(sectionId));
        }}
      />
    ) : null;
  };

  return renderIndicator(overmindState.progressStep);
}

export function SliderWrapper() {
  const overmindState = useOvermindState();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderStep = (step) => {
    switch (step) {
      case getStepIndex('terms_of_service'):
        return (
          <ThemeProvider theme={introTheme}>
            <TermsOfServicePage />
          </ThemeProvider>
        );
      case getStepIndex('research_agreement_page'):
        return (
          <ThemeProvider theme={introTheme}>
            <ResearchAgreementPage />
          </ThemeProvider>
        );
      case getStepIndex('general_intro'):
      case getStepIndex('name_page'):
      case getStepIndex('age_page'):
      case getStepIndex('biological_sex_page'):
      case getStepIndex('zip_code_page'):
        // case getStepIndex('ethnicity_country_page'):
        // case getStepIndex('bmi_page'):
        return (
          <ThemeProvider theme={generalTheme}>
            {renderGeneralSectionStep(step)}
          </ThemeProvider>
        );
      // case getStepIndex('exercise_intro'):
      // case getStepIndex('exercise_per_day'):
      //   return (
      //     <ThemeProvider theme={exerciseTheme}>
      //       {renderExerciseSectionStep(step)}
      //     </ThemeProvider>
      //   );
      case getStepIndex('lifestyle_intro'):
      case getStepIndex('lifestyle_sleep'):
      case getStepIndex('lifestyle_smoking'):
      case getStepIndex('lifestyle_stress'):
      case getStepIndex('lifestyle_alcohol'):
      case getStepIndex('lifestyle_hydration'):
        return (
          <ThemeProvider theme={lifestyleTheme}>
            {renderLifestyleSectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('food_intro'):
      case getStepIndex('dietary_preferences_page'):
      case getStepIndex('food_allergies_page'):
      case getStepIndex('food_consumption'):
        return (
          <ThemeProvider theme={nutritionTheme}>
            {renderNutritionSectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('environment_intro'):
      case getStepIndex('environment_where_do_you_live'):
      case getStepIndex('environment_sun_exposure'):
      case getStepIndex('environment_spf_use'):
        // case getStepIndex('environment_space_with_air_conditioner'):
        // case getStepIndex('environment_devices_with_screens'):
        return (
          <ThemeProvider theme={environmentTheme}>
            {renderEnvironmentSectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('skin_intro'):
      case getStepIndex('skin_type'):
      case getStepIndex('skin_goals'):
      case getStepIndex('skin_eye_are_concerns'):
        return (
          <ThemeProvider theme={nutritionTheme}>
            {renderSkinSectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('ready_to_use_the_app_page'):
        return (
          <ThemeProvider theme={dnaTheme}>
            <SectionIntro title={t('onboarding.readyToUseTheApp')} />
          </ThemeProvider>
        );
      case getStepIndex('onboarding_finished'):
        return (
          <ThemeProvider theme={dnaTheme}>
            <SectionIntro
              title={t('onboarding.finished', {
                name: overmindState.metaData.user_metadata.firstName,
              })}
            />
          </ThemeProvider>
        );
      default:
        return <></>;
    }
  };

  const renderGeneralSectionStep = (step) => {
    switch (step) {
      case getStepIndex('general_intro'):
        return (
          <SectionIntro
            image={intro4}
            subTitle={t('onboarding.generalSection.intro')}
            bold
          />
        );
      case getStepIndex('name_page'):
        return <NamePage />;
      case getStepIndex('age_page'):
        return <AgePage />;
      case getStepIndex('biological_sex_page'):
        return <BiologicalSexPage />;
      case getStepIndex('zip_code_page'):
      //   return <ZipCodePage />;
      // case getStepIndex('ethnicity_country_page'):
      //   return <EthnicityCountryPage />;
      // case getStepIndex('bmi_page'):
      default:
        return <ZipCodePage />;
    }
  };

  const renderExerciseSectionStep = (step) => {
    switch (step) {
      case getStepIndex('exercise_intro'):
        return <SectionIntro title={t('onboarding.exerciseSection.intro')} />;
      case getStepIndex('exercise_per_day'):
      default:
        return <ExercisePerDayPage />;
    }
  };

  const renderLifestyleSectionStep = (step) => {
    switch (step) {
      case getStepIndex('lifestyle_intro'):
        return <SectionIntro title={t('onboarding.lifestyleSection.intro')} />;
      case getStepIndex('lifestyle_sleep'):
        return <LifestyleSleepPage />;
      case getStepIndex('lifestyle_smoking'):
        return <LifestyleCigarettesPage />;
      case getStepIndex('lifestyle_stress'):
        return <LifestyleStressPage />;
      case getStepIndex('lifestyle_alcohol'):
        return <LifestyleAlcoholPage />;
      case getStepIndex('lifestyle_hydration'):
      default:
        return <LifestyleWaterPage />;
    }
  };

  const renderNutritionSectionStep = (step) => {
    switch (step) {
      case getStepIndex('food_intro'):
        return <SectionIntro title={t('onboarding.foodSection.intro')} />;
      case getStepIndex('dietary_preferences_page'):
        return <DietaryPreferencesPage />;
      case getStepIndex('food_allergies_page'):
        return <FoodAllergiesPage />;
      case getStepIndex('food_consumption'):
        return <PastWeekConsumptionPage />;
      default:
        return <SectionIntro title={t('onboarding.foodSection.intro')} />;
    }
  };

  const renderEnvironmentSectionStep = (step) => {
    switch (step) {
      case getStepIndex('environment_intro'):
        return (
          <SectionIntro title={t('onboarding.environmentSection.intro')} />
        );
      case getStepIndex('environment_where_do_you_live'):
        return <EnvironmentPage step="step1" />;
      case getStepIndex('environment_sun_exposure'):
        return <EnvironmentPage step="step2" />;
      case getStepIndex('environment_spf_use'):
        return <EnvironmentPage step="step3" />;
      // case getStepIndex('environment_space_with_air_conditioner'):
      //   return <EnvironmentPage step="step4" />;
      // case getStepIndex('environment_devices_with_screens'):
      //   return <EnvironmentPage step="step5" />;
      default:
        return <EnvironmentIntroPage />;
    }
  };

  const renderSkinSectionStep = (step) => {
    switch (step) {
      case getStepIndex('skin_intro'):
        return <SectionIntro title={t('onboarding.skinSection.intro')} />;
      case getStepIndex('skin_type'):
        return <SkinPage step="step1" isRadio={true} />;
      case getStepIndex('skin_goals'):
        return <SkinPage step="step2" isRadio={false} />;
      case getStepIndex('skin_eye_are_concerns'):
        return <SkinPage step="step3" isRadio={false} />;
      default:
        return <SkinIntroPage />;
    }
  };

  let qs = '';
  if (window.localStorage.getItem('qs') != null) {
    qs = window.localStorage.getItem('qs');
  }

  return overmindState.progressStep >= 1000 ? (
    <Redirect to={ROUTE_ASSESSMENT + qs} />
  ) : overmindState.progressStep > getStepIndex('ready_to_use_the_app_page') &&
    overmindState.metaData.user_metadata.completed_kitregistration_BPB !==
      true ? (
    <Redirect to={ROUTE_REGISTER_KIT + qs} />
  ) : window.innerWidth < 800 ? (
    <Slider
      isForward={overmindState.forwardNavigation}
      index={overmindState.progressStep}
    >
      {renderStep(overmindState.progressStep)}
    </Slider>
  ) : (
    <>{renderStep(overmindState.progressStep)}</>
  );
}
