import {
  swiperGeneralActiveIcon,
  swiperGeneralInactiveIcon,
  swiperExerciseActiveIcon,
  swiperExerciseInactiveIcon,
  swiperFoodActiveIcon,
  swiperFoodInactiveIcon,
  swiperLifestyleActiveIcon,
  swiperLifestyleInactiveIcon,
  swiperEnvironmentIcon,
  swiperEnvironmentActiveIcon,
  swiperSkinIcon,
  swiperSkinActiveIcon,
} from 'assets/iconsList';
import { getStepIndex } from './onboardingHelper';

export const getProgressItems = () => [
  {
    id: 1,
    label: 'GENERAL',
    hideLeftDots: true,
    selectedIcon: swiperGeneralActiveIcon,
    notSelectedIcon: swiperGeneralInactiveIcon,
    introStepIds: [getStepIndex('general_intro')],
    stepIds: [
      getStepIndex('name_page'),
      getStepIndex('age_page'),
      getStepIndex('biological_sex_page'),
      getStepIndex('zip_code_page'),
      getStepIndex('ethnicity_country_page'),
      getStepIndex('bmi_page'),
    ],
  },
  {
    id: 2,
    label: 'LIFESTYLE',
    selectedIcon: swiperLifestyleActiveIcon,
    notSelectedIcon: swiperLifestyleInactiveIcon,
    introStepIds: [getStepIndex('lifestyle_intro')],
    stepIds: [
      getStepIndex('lifestyle_sleep'),
      getStepIndex('lifestyle_smoking'),
      getStepIndex('lifestyle_stress'),
      getStepIndex('lifestyle_alcohol'),
      getStepIndex('lifestyle_hydration'),
    ],
  },
  {
    id: 3,
    label: 'EXERCISE',
    selectedIcon: swiperExerciseActiveIcon,
    notSelectedIcon: swiperExerciseInactiveIcon,
    introStepIds: [getStepIndex('exercise_intro')],
    stepIds: [getStepIndex('exercise_per_day')],
  },
  {
    id: 4,
    label: 'FOOD',
    hideRightDots: false,
    selectedIcon: swiperFoodActiveIcon,
    notSelectedIcon: swiperFoodInactiveIcon,
    introStepIds: [getStepIndex('food_intro')],
    stepIds: [
      getStepIndex('dietary_preferences_page'),
      getStepIndex('food_allergies_page'),
    ],
  },
  {
    id: 5,
    label: 'SKIN',
    hideRightDots: false,
    selectedIcon: swiperSkinActiveIcon,
    notSelectedIcon: swiperSkinIcon,
    introStepIds: [getStepIndex('skin_intro')],
    stepIds: [
      getStepIndex('skin_type'),
      getStepIndex('skin_goals'),
      getStepIndex('skin_eye_are_concerns'),
    ],
  },
  {
    id: 6,
    label: 'ENVIRONMENT',
    hideRightDots: true,
    selectedIcon: swiperEnvironmentActiveIcon,
    notSelectedIcon: swiperEnvironmentIcon,
    introStepIds: [getStepIndex('environment_intro')],
    stepIds: [
      getStepIndex('environment_where_do_you_live'),
      getStepIndex('environment_sun_exposure'),
      getStepIndex('environment_spf_use'),
      getStepIndex('environment_space_with_air_conditioner'),
      getStepIndex('environment_devices_with_screens'),
    ],
  },
];

export const getSectionByStepId = (stepId) => {
  const progressItems = getProgressItems();
  return progressItems.find((i) => i.stepIds.includes(stepId));
};

export const getSectionBySectionId = (sectionId) => {
  const progressItems = getProgressItems();
  return progressItems.find((i) => i.id === sectionId);
};

export const getFirstStepInSection = (sectionId) => {
  return getSectionBySectionId(sectionId).stepIds[0];
};

export const isIntroStep = (step) => {
  const progressItems = getProgressItems();
  const firstSectionIntroId = progressItems[0].introStepIds[0];
  const lastSectionStepIds = progressItems[progressItems.length - 1].stepIds;

  return (
    step < firstSectionIntroId ||
    step > lastSectionStepIds[lastSectionStepIds.length - 1] ||
    progressItems.find((i) => i.introStepIds.includes(step))
  );
};
