import { makeStyles } from '@material-ui/core';

export const useRecipeSummaryStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.16)',
  },
  rootVertical: {
    borderRadius: '10px',
  },
  text: {
    marginLeft: '10px',
    marginRight: '10px',
    maxWidth: '150px',
  },
  textVertical: {},
  bookmark: (props) => ({
    marginBottom: '-20px',
    marginRight: '15px',
  }),
  popoverContentContainer: {
    padding: 24,
  },
  popoverContent: {
    margin: theme.spacing(0.5),
  },
  recipeInfo: {
    justifyContent: 'left',
  },
  servings: {
    right: '95px',
    bottom: '10px',
    color: '#636771',
  },
  checkIcon: {
    right: '20px',
    bottom: '8px',
  },
}));
