import React, { useEffect, useState } from 'react';
import TermsOfService from './TermsOfService';

import { useActions, useOvermindState } from 'overmind/index';

export default function TermsOfServicePage() {
  const actions = useActions();
  const overmindState = useOvermindState();

  const getTerms = () => {
    return (
      overmindState.metaData &&
      overmindState.metaData.user_metadata &&
      overmindState.metaData.user_metadata.terms_BPB
    );
  };

  const [termsChecked, setTermsChecked] = useState(getTerms());

  useEffect(() => {
    if (
      (overmindState.metaData == null ||
        Object.keys(overmindState.metaData.user_metadata).length === 0) &&
      overmindState.loadingUserMetadata == null
    ) {
      actions.fetchMetadata();
    } else {
      setTermsChecked(getTerms());
      actions.setEnableForward(getTerms());
    }
  }, [actions, overmindState.loadingUserMetadata]);
  return (
    <TermsOfService
      onTermsChange={(value, checked) => {
        actions.termsChecked(checked);
      }}
      checked={termsChecked == null ? getTerms() : termsChecked}
    />
  );
}
