import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import IntroPageContent from './IntroPageContent';
import { useTranslation } from 'react-i18next';
import { intro1, intro2, intro3, intro4 } from 'assets/imagesList';
import Box from 'components/atoms/Layout/Box/Box';
import Footer from 'components/molecules/Footer/Footer';
import { useOvermindState, useActions } from 'overmind/index';
import { useHistory } from 'react-router-dom';
import { ROUTE_ONBOARDING } from 'routes';

export default function IntroPageSlider() {
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const actions = useActions();
  const history = useHistory();
  let introTexts = t('onboarding.introSection.landing.introTexts', {
    returnObjects: true,
  });
  let titleTexts = t('onboarding.introSection.landing.titleTexts', {
    returnObjects: true,
  });
  const [lastStep, setLastStep] = useState(introTexts.length - 1);
  const [lastStepCheck, setLastStepCheck] = useState(false);
  const introImages = [intro1, intro2, intro3, intro4];
  const slider = useRef(null);

  const nextClick = (e) => {
    if (e === lastStep) {
      setLastStepCheck(true);
    } else {
      setLastStepCheck(false);
    }
  };

  var settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    customPaging: function (i) {
      return (
        <span
          style={{
            height: '10px',
            width: '10px',
            color: 'white',
            backgroundColor:
              slider?.current?.innerSlider?.state?.currentSlide === i
                ? '#FFFFFF'
                : '#999999',
            borderRadius: '50%',
            display: 'inline-block',
          }}
        ></span>
      );
    },
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: nextClick,
  };

  const goToNextOnboardingPage = () => {
    if (
      overmindState.metaData &&
      overmindState.metaData.user_metadata &&
      overmindState.metaData.user_metadata.terms_BPB
    ) {
      actions.enableForwardButton();
    } else {
      actions.disableForwardButton();
    }
    let qs = '';
    if (window.localStorage.getItem('qs') != null) {
      qs = window.localStorage.getItem('qs');
    }
    history.push(ROUTE_ONBOARDING + qs);
  };

  const checkButtonFunction = () => {
    if (!lastStepCheck) {
      slider.current.slickNext();
    } else {
      goToNextOnboardingPage();
    }
  };

  return (
    <Box style={{ maxHeight: '100%' }}>
      <Slider ref={slider} {...settings}>
        <div>
          <IntroPageContent
            titleText={
              overmindState.metaData?.user_metadata?.firstName
                ? titleTexts[0] +
                  ' ' +
                  overmindState.metaData.user_metadata.firstName
                : titleTexts[0]
            }
            introText={introTexts[0]}
            introImage={introImages[0]}
            buttonText={'Skip intro '}
            skipButtonFunction={goToNextOnboardingPage}
          />
        </div>
        <div>
          <IntroPageContent
            titleText={titleTexts[1]}
            introText={introTexts[1]}
            introImage={introImages[1]}
            buttonText={'Skip intro '}
            skipButtonFunction={goToNextOnboardingPage}
          />
        </div>
        <div>
          <IntroPageContent
            titleText={titleTexts[2]}
            introText={introTexts[2]}
            introImage={introImages[2]}
            buttonText={'Skip intro '}
            skipButtonFunction={goToNextOnboardingPage}
          />
        </div>
        <div>
          <IntroPageContent
            titleText={titleTexts[3]}
            introText={introTexts[3]}
            introImage={introImages[3]}
          />
        </div>
      </Slider>
      <Footer
        onNextClick={checkButtonFunction}
        hideLeftButton
        backgroundColor="rgba(255, 255, 255, 0)"
        buttonBackgroundColor="#97d3c5"
        isIntro={true}
      />
    </Box>
  );
}
