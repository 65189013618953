import React from 'react';

import { useProfileTilesStyles } from 'components/molecules/Common/InfoContainer/profileTilesStyles';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';

export default function ProfileSummaryItem(props) {
  const classes = useProfileTilesStyles();

  return (
    <GridContainer direction="column" style={{ marginBottom: '10px' }}>
      <GridItem>
        <Typography variant="body1">{props.title}</Typography>
      </GridItem>
      <GridItem>
        <Typography
          variant="body1"
          className={classes.tileInfo}
          style={{ marginTop: '10px', marginLeft: '0' }}
          color={props.color}
        >
          {props.value ? props.value : '-'}
        </Typography>
      </GridItem>
    </GridContainer>
  );
}
