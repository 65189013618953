import i18n from 'i18n';
import {
  SkinCombo,
  SkinDry,
  SkinNormal,
  SkinOily,
  SkinSensitive,
  SkinGoalBoostRadiance,
  SkinGoalDiminishWrinkles,
  SkinGoalEvenTexture,
  SkinGoalImproveHydration,
  SkinGoalReduceBlemishes,
  SkinGoalReduceDarkSpots,
  SkinGoalReduceRedness,
  SkinGoalTargetSkinAging,
  EyesCrowsFeet,
  EyesDarkCircles,
  EyesDry,
  EyesPuffiness,
  EyesSaggingEyelids,
  EyesTired,
} from 'assets/iconsList';

export function getInitialSkinTypes() {
  const step = 'step1';
  return [
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer1`),
      id: 'step1-a1',
      icon: SkinNormal,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer2`),
      id: 'step1-a2',
      icon: SkinDry,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer3`),
      id: 'step1-a3',
      icon: SkinOily,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer4`),
      id: 'step1-a4',
      icon: SkinCombo,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer5`),
      id: 'step1-a5',
      icon: SkinSensitive,
      checked: false,
    },
  ];
}

export function getInitialSkinGoals() {
  const step = 'step2';
  return [
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer1`),
      id: 'step2-a1',
      icon: SkinGoalReduceDarkSpots,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer2`),
      id: 'step2-a2',
      icon: SkinGoalTargetSkinAging,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer3`),
      id: 'step2-a3',
      icon: SkinGoalBoostRadiance,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer4`),
      id: 'step2-a4',
      icon: SkinGoalImproveHydration,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer5`),
      id: 'step2-a5',
      icon: SkinGoalReduceRedness,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer6`),
      id: 'step2-a6',
      icon: SkinGoalEvenTexture,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer7`),
      id: 'step2-a7',
      icon: SkinGoalReduceBlemishes,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer8`),
      id: 'step2-a8',
      icon: SkinGoalDiminishWrinkles,
      checked: false,
    },
  ];
}

export function getInitialEyeAreaConcerns() {
  const step = 'step3';
  return [
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer1`),
      id: 'step3-a1',
      icon: EyesDarkCircles,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer2`),
      id: 'step3-a2',
      icon: EyesPuffiness,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer3`),
      id: 'step3-a3',
      icon: EyesSaggingEyelids,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer4`),
      id: 'step3-a4',
      icon: EyesDry,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer5`),
      id: 'step3-a5',
      icon: EyesCrowsFeet,
      checked: false,
    },
    {
      uid: '',
      label: i18n.t(`onboarding.skinSection.${step}.answer6`),
      id: 'step3-a6',
      icon: EyesTired,
      checked: false,
    },
  ];
}

export function getItemsForStep(step, blueprintProfile) {
  if (step === 'step1') {
    const itemsToSet = getInitialSkinTypes();
    itemsToSet.forEach((i) => {
      if (
        i.id === 'step1-a1' &&
        blueprintProfile.profile.skin_type === 'normal'
      ) {
        i.checked = true;
      } else if (
        i.id === 'step1-a2' &&
        blueprintProfile.profile.skin_type === 'dry'
      ) {
        i.checked = true;
      } else if (
        i.id === 'step1-a3' &&
        blueprintProfile.profile.skin_type === 'oily'
      ) {
        i.checked = true;
      } else if (
        i.id === 'step1-a4' &&
        blueprintProfile.profile.skin_type === 'combination'
      ) {
        i.checked = true;
      } else if (
        i.id === 'step1-a5' &&
        blueprintProfile.profile.skin_type === 'sensitive'
      ) {
        i.checked = true;
      }
    });
    return itemsToSet;
  } else if (step === 'step2') {
    const itemsToSet = getInitialSkinGoals();
    itemsToSet.forEach((i) => {
      if (
        i.id === 'step2-a1' &&
        blueprintProfile.profile.goal_reduce_dark_spots
      ) {
        i.checked = true;
      } else if (
        i.id === 'step2-a2' &&
        blueprintProfile.profile.goal_target_skin_aging
      ) {
        i.checked = true;
      } else if (
        i.id === 'step2-a3' &&
        blueprintProfile.profile.goal_boost_radiance
      ) {
        i.checked = true;
      } else if (
        i.id === 'step2-a4' &&
        blueprintProfile.profile.goal_improve_hydration
      ) {
        i.checked = true;
      } else if (
        i.id === 'step2-a5' &&
        blueprintProfile.profile.goal_reduce_redness
      ) {
        i.checked = true;
      } else if (
        i.id === 'step2-a5' &&
        blueprintProfile.profile.goal_even_texture
      ) {
        i.checked = true;
      } else if (
        i.id === 'step2-a6' &&
        blueprintProfile.profile.goal_reduce_blemishes
      ) {
        i.checked = true;
      } else if (
        i.id === 'step2-a7' &&
        blueprintProfile.profile.goal_diminish_wrinkles
      ) {
        i.checked = true;
      }
    });
    return itemsToSet;
  } else if (step === 'step3') {
    const itemsToSet = getInitialEyeAreaConcerns();
    itemsToSet.forEach((i) => {
      if (
        i.id === 'step3-a1' &&
        blueprintProfile.profile.eye_concern_dark_circles
      ) {
        i.checked = true;
      } else if (
        i.id === 'step3-a2' &&
        blueprintProfile.profile.eye_concern_puffiness
      ) {
        i.checked = true;
      } else if (
        i.id === 'step3-a3' &&
        blueprintProfile.profile.eye_concern_sagging_eyelids
      ) {
        i.checked = true;
      } else if (
        i.id === 'step3-a4' &&
        blueprintProfile.profile.eye_concern_dry_skin
      ) {
        i.checked = true;
      } else if (
        i.id === 'step3-a5' &&
        blueprintProfile.profile.eye_concern_fine_lines
      ) {
        i.checked = true;
      } else if (
        i.id === 'step3-a5' &&
        blueprintProfile.profile.eye_concern_fatigue
      ) {
        i.checked = true;
      }
    });
    return itemsToSet;
  }
  return;
}
