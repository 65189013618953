import React from 'react';
import PropTypes from 'prop-types';
import MuiGrid from '@material-ui/core/Grid';

export default function GridItem(props) {
  const { children, ...rest } = props;
  return (
    <MuiGrid item {...rest}>
      {children}
    </MuiGrid>
  );
}

GridItem.propTypes = {
  children: PropTypes.node,
  isPageItemContainer: PropTypes.bool,
};
