import React from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Meal from 'components/organisms/Meal/Meal';
import ContentCard from 'components/molecules/Common/ContentCard/ContentCard';
import Button from 'components/atoms/Inputs/Buttons/Button';

export default function HomeRecipes(props) {
  const { isLoaded, title, subtitle, description, meals, mealsToShow } = props;
  const { t } = useTranslation();

  return (
    <ContentCard color="#dcedeb">
      {title && (
        <GridItem
          xs={12}
          style={{
            margin: '20px auto',
            textAlign: 'center',
          }}
        >
          <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
            {title}
          </Typography>
        </GridItem>
      )}
      {subtitle && (
        <GridItem
          xs={12}
          style={{
            margin: '0 20px 17px 20px',
          }}
        >
          <Typography
            variant="h3"
            style={{
              textAlign: 'center',
              fontFamily: 'MuseoSans-500',
              fontSize: '25px',
              lineHeight: '25px',
            }}
          >
            {subtitle}
          </Typography>
        </GridItem>
      )}
      {description && (
        <GridItem
          xs={12}
          style={{
            maxWidth: '540px',
            paddingLeft: '20px',
            paddingRight: '20px',
            margin: 'auto',
            marginBottom: '40px',
          }}
        >
          <Typography
            variant="body1"
            style={{ fontFamily: 'MuseoSans-300', color: '#342f38' }}
          >
            {description}
          </Typography>
        </GridItem>
      )}

      {isLoaded && meals && (
        <GridItem xs={12}>
          <GridContainer
            style={{
              maxWidth: '1200px',
              margin: 'auto',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            {isLoaded &&
              meals &&
              Object.keys(meals).map((key, ix) => {
                return (
                  mealsToShow.includes(key) && (
                    <GridItem xs={6} md={12 / mealsToShow.length}>
                      <Meal
                        recipe={meals[key].recipe}
                        onClicked={() =>
                          props.handleRecipeSelected &&
                          props.handleRecipeSelected(
                            meals[key].recipe.id,
                            key,
                            meals[key].recipe.numberOfServings
                          )
                        }
                        largeImage={true}
                        parentComponent={'home'}
                        hideBookmarks={props.hideBookmarks}
                        isPremium={props.isPremium}
                      />
                    </GridItem>
                  )
                );
              })}
          </GridContainer>
        </GridItem>
      )}
      <GridItem
        xs={12}
        style={{
          marginTop: '40px',
          textAlign: 'center',
        }}
      >
        <Button
          onClick={() => {
            props.onButtonClick && props.onButtonClick();
          }}
          variant="contained"
          style={{
            height: '60px',
            width: '250px',
            backgroundColor: '#084658',
            color: '#fff',
          }}
        >
          <Typography variant="body1">
            {t('home.recipes.buttonText')}
          </Typography>
        </Button>
      </GridItem>
    </ContentCard>
  );
}
