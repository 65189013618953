import React from 'react';
import PropTypes from 'prop-types';

import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

import Radio from 'components/atoms/Inputs/Radio/Radio';
import Typography from 'components/atoms/Typography/Typography';

export default function RadioWithLabel(props) {
  const onRadioChange = (e) => {
    props.onChange && props.onChange(e.target.value, e.target.checked);
  };

  return (
    <MuiFormControlLabel
      control={
        <Radio
          {...props}
          value={props.value}
          checked={props.checked}
          onChange={(e) => onRadioChange(e)}
          size={props.size}
          color={props.color}
          colorUnchecked={props.colorUnchecked}
        />
      }
      label={
        <Typography
          style={{
            fontFamily: 'Lato-Bold',
            fontSize: '14px',
            lineHeight: '18px',
          }}
        >
          {props.label}
        </Typography>
      }
    />
  );
}

RadioWithLabel.propTypes = {
  /**
   * Should it be disabled?
   */
  disabled: PropTypes.bool,
  /**
   * Is checkbox checked.
   */
  checked: PropTypes.bool,
  /**
   * Value for checkbox
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Text added as label for checkbox
   */
  label: PropTypes.string,
  /**
   * Callback fired when the state is changed.
   */
  onChange: PropTypes.func,
};
