import React from 'react';

import { useProfileTilesStyles } from '../InfoContainer/profileTilesStyles';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import SvgIcon from 'components/atoms/SvgIcon/SvgIcon';

export default function ImageWithText(props) {
  const classes = useProfileTilesStyles();

  return (
    <GridContainer
      direction="column"
      style={{
        width: '100px',
        marginTop: '15px',
      }}
    >
      <GridItem xs={12} style={{ margin: '0 auto' }}>
        <SvgIcon height={50} width={50} icon={props.icon} />
      </GridItem>
      <GridItem xs={12} style={{ height: '10px', margin: '0 auto' }}>
        <Typography
          className={classes.tileInfo}
          color={props.color}
          style={{ marginTop: '5px' }}
          variant="body1"
        >
          {props.text}
        </Typography>
      </GridItem>
    </GridContainer>
  );
}

ImageWithText.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  color: PropTypes.string,
};
