import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';
import { useActions } from 'overmind/index';

import Question from 'components/organisms/Inputs/Question/Question';
import Typography from 'components/atoms/Typography/Typography';

const useStyles = makeStyles((theme) => ({
  step: {
    textAlign: 'center',
    fontFamily: 'MuseoSans-900',
    fontSize: '30px',
    lineHeight: '36px',
    color: '#97d3c5',
    marginBottom: '22px',
    marginTop: '0px',
  },
  text: {
    fontFamily: 'MuseoSans-300',
    fontSize: '18px',
    lineHeight: '22px',
    marginBottom: '33px',
  },
}));

export default function DnaUploadExplanationQuestion(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const actions = useActions();

  const getSelectedProviderData = (id) => {
    switch (id) {
      case '1':
        return {
          label: '23andMe',
          account_name: '23andMe',
          step2: 'onboarding.dnaSection.step3.23andMeStep2',
          step3: 'onboarding.dnaSection.step3.23andMeStep3',
        };
      case '2':
        return {
          label: 'AncestryDNA',
          account_name: 'AncestryDNA',
          step2: 'onboarding.dnaSection.step3.AncestryDNAStep2',
          step3: 'onboarding.dnaSection.step3.AncestryDNAStep3',
        };
      case '3':
        return {
          label: 'MyHeritage',
          account_name: 'MyHeritage',
          step2: 'onboarding.dnaSection.step3.MyHeritageStep2',
          step3: 'onboarding.dnaSection.step3.MyHeritageStep3',
        };
      case '4':
        return {
          label: 'FamilyTreeDNA',
          account_name: 'Family Tree DNA FTDNA account',
          step2: 'onboarding.dnaSection.step3.FamilyTreeDNAStep2',
          step3: 'onboarding.dnaSection.step3.FamilyTreeDNAStep3',
        };
      default:
        break;
    }
  };

  return (
    <Question
      title={t('onboarding.dnaSection.step3.title')}
      subtitle={t('onboarding.dnaSection.step3.subtitle')}
      divider={false}
    >
      <Typography class={classes.step}>
        <Trans i18nKey="onboarding.dnaSection.step3.heading1" />
      </Typography>
      <Typography class={classes.text}>
        <Trans
          i18nKey="onboarding.dnaSection.step3.textBody1"
          values={{
            selected_provider: getSelectedProviderData(props.selectedProvider)
              .label,
            account_name: getSelectedProviderData(props.selectedProvider)
              .account_name,
          }}
          components={{ bold: <strong /> }}
        />
      </Typography>

      <Typography class={classes.step}>
        <Trans i18nKey="onboarding.dnaSection.step3.heading2" />
      </Typography>
      <Typography class={classes.text}>
        <Trans
          i18nKey={getSelectedProviderData(props.selectedProvider).step2}
          components={{ bold: <strong /> }}
        />
      </Typography>

      <Typography class={classes.step}>
        <Trans i18nKey="onboarding.dnaSection.step3.heading3" />
      </Typography>
      <Typography class={classes.text}>
        <Trans
          i18nKey={getSelectedProviderData(props.selectedProvider).step3}
          components={{ bold: <strong /> }}
        />
      </Typography>

      <Typography class={classes.step}>
        <Trans i18nKey="onboarding.dnaSection.step3.heading4" />
      </Typography>
      <Typography class={classes.text}>
        {t('onboarding.dnaSection.step3.textBody4')}{' '}
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            actions.goNext();
          }}
        >
          {t('onboarding.dnaSection.step3.upload')}
        </span>
        {'.'}
      </Typography>
    </Question>
  );
}

DnaUploadExplanationQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onQuestionAnswered: PropTypes.func,
  selectedProviders: PropTypes.arrayOf(PropTypes.string),
};
