import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import InfoContainer from 'components/molecules/Common/InfoContainer/InfoContainer';
import ImageWithText from 'components/molecules/Common/ImageWithText/ImageWithText';
import { ThemeProvider } from '@material-ui/core/styles';

import { skinTheme } from 'themes';

export default function SkinSection(props) {
  const handleOpenPopup = (component) => {
    props.onTileClick && props.onTileClick(component);
  };

  return (
    <ThemeProvider theme={skinTheme}>
      <GridContainer>
        <GridContainer
          direction="row"
          style={{
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          <GridItem
            style={{
              width: '50%',
              textAlign: 'left',
            }}
          >
            <Typography
              variant="h4"
              style={{
                textTransform: 'uppercase',
                fontSize: '15px',
              }}
            >
              {'SKIN'}
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <InfoContainer
            title={'Skin type'}
            value={
              props.skinTypes &&
              props.skinTypes.map((skinType, i) => (
                <ImageWithText
                  icon={props.skinTypesIcons[i]}
                  text={skinType}
                  color="primary"
                />
              ))
            }
            color="primary"
            tallContainer={true}
            onTileClick={() => handleOpenPopup('skinType')}
          />
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <InfoContainer
            title={'Skin goals'}
            value={
              props.skinGoals &&
              props.skinGoals.map((skinGoal, i) => (
                <ImageWithText
                  icon={props.skinGoalsIcons[i]}
                  text={skinGoal}
                  color="primary"
                />
              ))
            }
            color="primary"
            tallContainer={true}
            onTileClick={() => handleOpenPopup('skinGoal')}
          />
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <InfoContainer
            title={'Eye concerns'}
            value={
              props.eyeAreaConcerns &&
              props.eyeAreaConcerns.map((eyeAreaConcern, i) => (
                <ImageWithText
                  icon={props.eyeAreaConcernsIcons[i]}
                  text={eyeAreaConcern}
                  color="primary"
                />
              ))
            }
            color="primary"
            tallContainer={true}
            onTileClick={() => handleOpenPopup('eyeAreaConcern')}
          />
        </GridContainer>
      </GridContainer>
    </ThemeProvider>
  );
}
