import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AddGroceryList } from 'assets/iconsList';

import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Accordion from 'components/atoms/Navigation/Accordion/Accordion';
import AccordionSummary from 'components/atoms/Navigation/Accordion/AccordionSummary';
import AccordionDetails from 'components/atoms/Navigation/Accordion/AccordionDetails';
import ChipList from 'components/molecules/ChipList/ChipList';
import ListContent from 'components/molecules/Content/Accordion/ListContent';
import Instructions from 'components/molecules/Content/Accordion/Instructions';
import DetailsHeader from 'components/molecules/Header/DetailsHeader';

import {
  getFoodAllergiesData,
  getNutritionData,
} from 'components/helpers/nutritionHelper';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function RecipeDetails(props) {
  const { t } = useTranslation();
  const isDesktop = useCheckScreenSize().isDesktop;
  const overmindState = useOvermindState();
  const [openedPanels, setOpenedPanels] = useState(
    window.innerWidth > 800 ? ['ingredients', 'instructions'] : []
  );
  const [recipe, setRecipe] = useState(props.recipe);
  const [meal, setMeal] = useState(props.meal);
  const [inUserShoppingList, setInUserShoppingList] = useState(
    overmindState.recipeDetails &&
      overmindState.recipeDetails.inUserShoppingList
  );
  const actions = useActions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    actions.fetchRecipeDetails(recipe.id);
  }, [
    overmindState.recipeDetails &&
      overmindState.recipeDetails.inUserShoppingList === undefined,
  ]);

  useEffect(() => {
    overmindState.recipeDetails &&
      setInUserShoppingList(overmindState.recipeDetails.inUserShoppingList);
  }, [overmindState.recipeDetails]);

  const findNewRecipe = (meal) => {
    for (let i = 0; i < props.dailyRecipes.length; i++) {
      if (props.dailyRecipes[i].meal === meal) {
        actions
          .fetchRecipeDetails(props.dailyRecipes[i].recipe.id)
          .then(
            setInUserShoppingList(
              overmindState.recipeDetails.inUserShoppingList
            )
          );
        setRecipe(props.dailyRecipes[i].recipe);
        setMeal(props.dailyRecipes[i].meal);
        return;
      }
    }
  };

  const handleChangeRecipeClick = (navigate) => {
    switch (meal) {
      case 'breakfast':
        return findNewRecipe('lunch');
      case 'lunch':
        return findNewRecipe(navigate === 'previous' ? 'breakfast' : 'dinner');
      case 'dinner':
        return findNewRecipe(navigate === 'previous' ? 'lunch' : 'snack');
      case 'snack':
        return findNewRecipe('dinner');
      default:
        break;
    }
  };

  const hasReasons = () => {
    return recipe && recipe.reason && recipe.reason.length > 0;
  };

  const getReasons = () => {
    let reasons = [];
    recipe.reason.forEach((reason) => {
      let code = reason.code;
      if (code && code.length > 0) {
        if (reason.type === 'nutrient') {
          code = code + ' Need';
        } else if (reason.type === 'preference') {
          const allergies = getFoodAllergiesData().filter(
            (a) => a.uid === code
          );
          if (allergies && allergies.length === 1) {
            code = allergies[0].label;
            code = code + ' Free';
          } else {
            const nutrition = getNutritionData().filter((a) => a.uid === code);
            if (nutrition && nutrition.length === 1) {
              code = nutrition[0].label;
            }
          }
        }
        code = code.charAt(0).toUpperCase() + code.substr(1).toLowerCase();
        reasons.push(code);
      }
    });
    return reasons;
  };

  const getInstructions = () => {
    if (recipe && recipe.instructions && recipe.instructions.length > 0) {
      return recipe.instructions;
    } else if (
      recipe &&
      recipe.source &&
      recipe.source.recipeUrl &&
      recipe.source.displayName &&
      recipe.source.recipeUrl.length > 0 &&
      recipe.source.displayName.length > 0
    ) {
      return recipe.source;
    } else {
      return null;
    }
  };

  const calculateCalories = () => {
    let calories = 0;
    try {
      if (recipe.caloriesPerServing.protein) {
        calories += recipe.caloriesPerServing.protein;
      }
      if (recipe.caloriesPerServing.protein) {
        calories += recipe.caloriesPerServing.carbs;
      }
      if (recipe.caloriesPerServing.fat) {
        calories += recipe.caloriesPerServing.fat;
      }
    } catch {}
    return calories;
  };

  const handleOpenedPanels = (panel) => {
    let panels = [...openedPanels];

    const index = panels.indexOf(panel);
    if (index > -1) {
      panels.splice(index, 1);
    } else {
      panels.push(panel);
    }
    setOpenedPanels(panels);
  };

  const renderInfoText = (number, text) => {
    return (
      <>
        <Typography
          variant="body1"
          style={{
            fontFamily: 'Museo-700',
            fontSize: isDesktop ? '36px' : '30px',
            lineHeight: isDesktop ? '36px' : '30px',
          }}
        >
          {number}
        </Typography>
        <Typography
          variant="caption"
          style={{
            fontFamily: 'MuseoSans-700',
            fontSize: isDesktop ? '8px' : '7px',
            lineHeight: isDesktop ? '10px' : '9px',
          }}
        >
          {text}
        </Typography>
      </>
    );
  };

  const renderServingsInfoText = (title, caption, number) => {
    return (
      <GridContainer
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: '10px' }}
      >
        <GridItem>
          <Typography
            variant="h5"
            style={{
              fontFamily: 'MuseoSans-700',
              fontSize: isDesktop ? '20px' : '16px',
              lineHeight: isDesktop ? '30px' : '20px',
              color: '#121114',
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: 'MuseoSans-300',
              fontSize: isDesktop ? '12px' : '10px',
              lineHeight: isDesktop ? '20px' : '15px',
              color: '#342f38',
            }}
          >
            {caption}
          </Typography>
        </GridItem>
        <GridItem style={{ textAlign: 'center' }}>
          <Typography
            variant="h5"
            style={{
              fontFamily: 'MuseoSans-500',
              fontSize: isDesktop ? '30px' : '30px',
              lineHeight: isDesktop ? '40px' : '40px',
              color: '#121114',
            }}
          >
            {number}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: 'MuseoSans-300',
              fontSize: isDesktop ? '7px' : '7px',
              lineHeight: isDesktop ? '9px' : '9px',
              color: '#342f38',
            }}
          >
            {number > 1
              ? t('recipeDetails.servings').toUpperCase()
              : t('recipeDetails.serving').toUpperCase()}
          </Typography>
        </GridItem>
      </GridContainer>
    );
  };

  const renderAccordion = (id, title, children) => {
    return (
      <Accordion
        square
        expanded={openedPanels.includes(id)}
        onChange={() => handleOpenedPanels(id)}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="body1"
            style={{
              fontSize: isDesktop ? '20px' : '14px',
              lineHeight: isDesktop ? '24px' : '17px',
              textTransform: 'uppercase',
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  };

  const renderRecipeButton = (icon, label, handleClick) => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight: '35px',
          minWidth: '150px',
          padding: '5px',
          borderRadius: '50px',
          cursor: 'pointer',
          color: '#28052e',
          backgroundColor: '#ebdad3',
        }}
        onClick={handleClick}
      >
        <Box>{icon}</Box>
        <Typography variant="body1" textSize="11px" lineHeight="13px">
          {label}
        </Typography>
      </Box>
    );
  };

  const renderRecipeButtons = () => {
    let shoppingListLabel;
    let shoppingListAction;
    if (inUserShoppingList) {
      shoppingListLabel = t('recipeDetails.removeFromGroceryList');
      shoppingListAction = () => {
        setInUserShoppingList(false);
        actions
          .removeFromShoppingList(recipe.id)
          .then(actions.fetchRecipeDetails(recipe.id));
      };
    } else {
      shoppingListLabel = t('recipeDetails.addToGroceryList');
      shoppingListAction = () => {
        setInUserShoppingList(true);
        actions
          .addToShoppingList({
            databaseId: recipe.databaseId,
            id: recipe.id,
          })
          .then(actions.fetchRecipeDetails(recipe.id));
      };
    }

    return (
      <GridContainer
        direction="row"
        justify="space-evenly"
        alignItems="center"
        style={{ marginTop: '15px' }}
      >
        <GridItem
          xs={
            props.parentComponent && props.parentComponent !== 'profile' ? 6 : 4
          }
        >
          {renderRecipeButton(
            <AddGroceryList height={20} />,
            shoppingListLabel,
            shoppingListAction
          )}
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <>
      {/* First Section Start */}
      <GridContainer
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        style={{
          height: isDesktop ? '650px' : '500px',
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.47)) , url(${
            recipe.mainImage ? recipe.mainImage : ''
          })`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          textAlign: 'center',
          color: 'white',
        }}
      >
        <GridItem style={{ width: '100%', marginTop: '50px' }}>
          <DetailsHeader
            title={recipe.name}
            onLeftClick={() =>
              meal !== 'breakfast' && handleChangeRecipeClick('previous')
            }
            disableLeftButton={
              props.disableNavigation
                ? props.disableNavigation
                : meal === 'breakfast'
            }
            onRightClick={() =>
              meal !== 'snack' && handleChangeRecipeClick('next')
            }
            disableRightButton={
              props.disableNavigation
                ? props.disableNavigation
                : meal === 'snack'
            }
          />
          <Typography
            variant="subtitle2"
            style={{ textTransform: 'uppercase', fontWeight: 900 }}
          >
            {meal}
          </Typography>
        </GridItem>

        <GridItem style={{ width: isDesktop ? '450px' : '100%' }}>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              paddingLeft: '40px',
              paddingRight: '40px',
              paddingBottom: isDesktop ? '350px' : '60px',
              textAlign: 'center',
              textTransform: 'uppercase',
              color: 'white',
            }}
          >
            {recipe.ingredients && (
              <GridItem>
                {renderInfoText(
                  recipe.ingredients.length,
                  t('recipeDetails.ingredients')
                )}
              </GridItem>
            )}
            {recipe.totalTime && (
              <GridItem>
                {renderInfoText(
                  recipe.totalTime.substring(
                    0,
                    recipe.totalTime.lastIndexOf(' ')
                  ),
                  t('recipeDetails.minutes')
                )}
              </GridItem>
            )}
            {calculateCalories() > 0 && (
              <GridItem>
                {renderInfoText(
                  Math.round(calculateCalories()),
                  t('recipeDetails.servingInfo')
                )}
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
      </GridContainer>
      {/* First Section End */}

      <Box
        style={{
          maxWidth: '1024px',
          minHeight: isDesktop ? '350px' : '450px',
          margin: 'auto',
          marginTop: isDesktop ? '-340px' : '-50px',
          borderRadius: '50px 50px 0 0',
          backgroundColor: 'white',
        }}
      >
        <GridContainer
          direction="row"
          style={{
            padding: '40px 20px 0 20px',
          }}
          spacing={isDesktop && 2}
        >
          <GridItem xs={isDesktop ? 6 : 12}>
            {renderServingsInfoText(
              t('recipeDetails.recipe'),
              t('recipeDetails.recipeYield'),
              recipe.numberOfServings
            )}
          </GridItem>
          <GridItem xs={isDesktop ? 6 : 12}>
            {renderServingsInfoText(
              t('recipeDetails.recommended'),
              t('recipeDetails.recipeYouShould'),
              props.numberOfServings
            )}
          </GridItem>
          <GridItem xs={isDesktop ? 6 : 12}>
            {renderAccordion(
              'ingredients',
              t('recipeDetails.ingredients'),
              <ListContent data={recipe.ingredientLines} />
            )}
            {isDesktop && hasReasons() && (
              <>
                <Typography
                  variant="body1"
                  textAlign="left"
                  style={{ marginTop: '20px' }}
                >
                  {t('recipeDetails.recommendationReason')}
                </Typography>
                <Box style={{ marginTop: '30px' }}>
                  <ChipList chipsTexts={getReasons()} />
                </Box>
              </>
            )}
          </GridItem>
          {getInstructions() != null && (
            <GridItem xs={isDesktop ? 6 : 12}>
              {renderAccordion(
                'instructions',
                t('recipeDetails.instructions'),
                <Instructions data={getInstructions()} addMargin />
              )}
            </GridItem>
          )}

          {!isDesktop && hasReasons() && (
            <GridItem xs={12}>
              <Typography
                variant="body1"
                textAlign="left"
                style={{ marginTop: '20px' }}
              >
                {t('recipeDetails.recommendationReason')}
              </Typography>
              <Box style={{ marginTop: '30px' }}>
                <ChipList chipsTexts={getReasons()} />
              </Box>
            </GridItem>
          )}

          {/* <GridItem xs={12}>{renderRecipeButtons()}</GridItem> */}
        </GridContainer>
      </Box>
    </>
  );
}
