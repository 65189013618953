import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Progress from 'components/molecules/Progress/Progress';
import UploadButton from 'components/molecules/UploadButton/UploadButton';
import Question from 'components/organisms/Inputs/Question/Question';
import { useTranslation } from 'react-i18next';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

import { UploadIcon } from 'assets/iconsList';

export default function FileToUploadQuestion(props) {
  const { t } = useTranslation();

  /**
   * Upload step indicates in which phase of the upload are we:
   * 0 = upload isn't initiated
   * 1 = file is being uploaded
   */
  const [uploadStep, setUploadStep] = useState(0);

  const onFileSelect = (e) => {
    if (props.onFileSelected) {
      props.onFileSelected(e);
      setUploadStep(1);
    }
  };

  return (
    <>
      {!props.showUploadStatus && uploadStep === 0 && (
        <Question
          title={t('onboarding.dnaSection.step4.title')}
          subtitle={t('onboarding.dnaSection.step4.subtitle')}
        >
          {/* upload control goes here together with instructions */}
          <GridContainer
            direction="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <GridItem>
              <Box
                pt={8}
                mr={{ xs: 2.5 }}
                justifyContent="center"
                display="flex"
              >
                <UploadIcon height={80} width={80} />
              </Box>
            </GridItem>
            <GridItem>
              <Box
                p={{ xs: 2.5, sm: 3, md: 4 }}
                mr={{ xs: 2.5 }}
                justifyContent="center"
                display="flex"
              >
                <UploadButton
                  variant="outlined"
                  onClick={() => {
                    trackEvent('Onboarding', 'DNA File Upload Click');
                  }}
                  onFileSelected={(e) => {
                    onFileSelect(e);
                  }}
                >
                  {t('onboarding.dnaSection.step4.buttonText')}
                </UploadButton>
              </Box>
            </GridItem>
            <GridItem>
              <Typography
                style={{
                  fontFamily: 'MuseoSans-300',
                  fontSize: '18px',
                  lineHeight: '22px',
                }}
              >
                {t('onboarding.dnaSection.step4.caption')}
              </Typography>
            </GridItem>
          </GridContainer>
        </Question>
      )}
      {!props.showUploadStatus && uploadStep === 1 && (
        <Question title={t('onboarding.dnaSection.fileUpload.title')}>
          <Box justifyContent="center" display="flex">
            {/* progress control goes here */}
            <Progress
              size={250}
              thickness={5}
              variant="determinate"
              valueVariant="h2"
              captionVariant="caption"
              value={props.uploadProgress ? props.uploadProgress : 0}
              caption="uploaded"
            />
          </Box>
        </Question>
      )}
      {props.showUploadStatus && (
        <Question
          title={
            props.uploadSuccessful
              ? t('onboarding.dnaSection.fileUpload.uploaded')
              : t('onboarding.dnaSection.fileUpload.uploadFailed')
          }
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {props.uploadSuccessful ? (
              <CheckCircleIcon color="primary" style={{ fontSize: '80px' }} />
            ) : (
              <ErrorIcon color="error" style={{ fontSize: '80px' }} />
            )}
          </Box>
        </Question>
      )}
    </>
  );
}

FileToUploadQuestion.propTypes = {
  /**
   * Indicates whether upload status needs to be displayed.
   */
  showUploadStatus: PropTypes.bool,

  /**
   * Indicates how much of the file is uploaded.
   */
  uploadProgress: PropTypes.number,

  /**
   * Callback when file to upload is selected.
   */
  onFileSelected: PropTypes.func,
};
