import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/atoms/Layout/Box/Box';

import Question from 'components/organisms/Inputs/Question/Question';
import ContainedRadioButtonGroup from 'components/organisms/Inputs/RadioButtonGroup/ContainedRadioButtonGroup';
import { Ancestry, AndMe, MyHeritage, FamilyTreeDna } from 'assets/imagesList';
import { useTranslation } from 'react-i18next';

export default function DnaProvidersQuestion(props) {
  const { t } = useTranslation();
  const selected =
    props.selectedProviders == null ? [] : props.selectedProviders;

  return (
    <Question
      title={t('onboarding.dnaSection.step2.title')}
      subtitle={t('onboarding.dnaSection.step2.subtitle')}
      divider={false}
    >
      <Box justifyContent="flex-start" display="flex" mt={2}>
        <ContainedRadioButtonGroup
          color="primary"
          rowNumber={1}
          items={[
            {
              label: '23andMe',
              value: '1',
              checked: selected.includes('1'),
              image: AndMe,
            },
            {
              label: 'AncestryDNA',
              value: '2',
              checked: selected.includes('2'),
              image: Ancestry,
            },
            {
              label: 'MyHeritage',
              value: '3',
              checked: selected.includes('3'),
              image: MyHeritage,
            },
            {
              label: 'FamilyTreeDNA',
              value: '4',
              checked: selected.includes('4'),
              image: FamilyTreeDna,
            },
          ]}
          onChange={(val) => {
            if (props.onQuestionAnswered) {
              props.onQuestionAnswered(val);
            }
          }}
        />
      </Box>
    </Question>
  );
}

DnaProvidersQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onQuestionAnswered: PropTypes.func,
  selectedProviders: PropTypes.arrayOf(PropTypes.string),
};
