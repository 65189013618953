import { makeStyles } from '@material-ui/core';

export const useProductCardStyles = makeStyles((theme) => ({
  card: (props) => ({
    minHeight: '230px',
    margin: '10px 15px 10px 15px',
    backgroundColor: props.color ? props.color : '#FFFFFF',
    borderRadius: '50px',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
    cursor: 'pointer',
    flexWrap: 'nowrap',
  }),
  textContainer: {
    padding: '20px',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: '20px',
    fontWeight: '900',
    textAlign: 'left',
    color: '#342f38',
  },
  text: {
    fontFamily: 'MuseoSans-700',
    fontSize: '14px',
    fontWeight: '300',
    textAlign: 'left',
    color: '#342f38',
  },
  minorText: {
    fontFamily: 'MuseoSans-300',
    fontSize: '16px',
    fontWeight: '300',
    textAlign: 'left',
    color: '#342f38',
  },
  imageContainer: (props) => ({
    width: '100%',
    borderRadius: '0px 50px 50px 0',
    backgroundImage: props.image
      ? `url(${props.image})`
      : "url('https://via.placeholder.com/120x230')",
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }),
}));
