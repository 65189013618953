import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { assessmentTraitsStyles } from './assessmentTraitsStyles';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import InfoCard from 'components/molecules/Common/InfoCard/InfoCard';
import ContentCard from 'components/molecules/Common/ContentCard/ContentCard';
import {
  getTraitIcon,
  getInfoColor,
} from 'components/helpers/assessmentTraitsHelper';
import Box from 'components/atoms/Layout/Box/Box';
import { BlackArrowRight } from 'assets/iconsList';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function AssessmentTraits(props) {
  const { title, traits, connotation } = props;
  const { t } = useTranslation();
  const classes = assessmentTraitsStyles(props);
  const [showMore, setShowMore] = useState(false);
  const isDesktop = useCheckScreenSize().isDesktop;

  const getTraitsToShow = () => {
    let traitsToShow = [];

    traits.forEach((item) => {
      if (item.trait.trait.connotation === connotation) {
        traitsToShow.push(item);
      }
    });

    if (!showMore) {
      return traitsToShow.slice(0, 4);
    } else {
      return traitsToShow;
    }
  };

  const hasMoreTraitsToShow = () => {
    let traitsToShow = [];

    traits.forEach((item) => {
      if (item.trait.trait.connotation === connotation) {
        traitsToShow.push(item);
      }
    });
    return traitsToShow.length > 4;
  };

  return (
    <ContentCard color={props.color}>
      <GridItem
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Typography className={classes.title} variant="body1">
          {title}
        </Typography>
      </GridItem>
      <GridContainer
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingBottom: '50px',
        }}
      >
        {getTraitsToShow().map((item) => (
          <GridItem
            xs={12}
            md={4}
            style={{ paddingLeft: '5px', paddingRight: '5px' }}
          >
            <InfoCard
              title={item.trait.trait.name}
              info={item.trait.level_label}
              infoColor={getInfoColor(item.trait)}
              icon={getTraitIcon(item.trait)}
              height={isDesktop && '150px'}
              onClick={
                props.onCardClick && (() => props.onCardClick(item.index))
              }
            />
          </GridItem>
        ))}

        {hasMoreTraitsToShow() && (
          <GridItem
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: isDesktop ? 'flex-end' : 'center',
            }}
          >
            <Typography
              className={classes.seeAll}
              variant="subtitle1"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? (
                t('needs.showLess')
              ) : (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography>{t('needs.showMore')}</Typography>

                  <BlackArrowRight style={{ marginLeft: 10, height: '10px' }} />
                </Box>
              )}
            </Typography>
          </GridItem>
        )}
      </GridContainer>
    </ContentCard>
  );
}
