import React, { useState, useEffect } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import ProfileSummaryItem from 'components/organisms/Profile/ProfileSummaryItem';
import ProfileCard from 'components/molecules/Common/ProfileCard/ProfileCard';
import LinkBox from 'components/molecules/Navigation/LinkBox/LinkBox';
import { ThemeProvider } from '@material-ui/core/styles';

import { useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import { profileGeneralTheme } from 'themes';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import { useActions } from 'overmind/index';
import ModalEditWindow from './ModalEditWindow';
import { useAuth0 } from '@auth0/auth0-react';

export default function BasicProfile(props) {
  const actions = useActions();
  const overmindState = useOvermindState();
  const { t } = useTranslation();
  const [kitNumber, setKitNumber] = useState(null);
  const [openedModal, setOpenedModal] = useState(false);
  const [hideSaveInModal, setHideSaveInModal] = useState(false);
  const { user } = useAuth0();
  const [userEmail, setUserEmail] = useState(
    user != null ? user.email : overmindState.email
  );

  useEffect(() => {
    if (overmindState.isGenotypeFetched) {
      setKitNumber(
        overmindState.genotype && overmindState.genotype.source_details
          ? overmindState.genotype.source_details.gxg_kit_number
          : null
      );
    }
  }, [overmindState.genotype, overmindState.isGenotypeFetched]);

  const renderLinkBox = (title, onClick) => {
    return <LinkBox title={title} onClick={onClick} />;
  };

  return (
    <>
      <ThemeProvider theme={profileGeneralTheme}>
        <GridContainer
          style={{
            marginBottom: '20px',
            paddingLeft: '23px',
            paddingRight: '23px',
          }}
        >
          <GridContainer
            direction="row"
            style={{
              marginTop: '40px',
              marginBottom: '20px',
            }}
          >
            <GridItem
              xs={6}
              style={{
                textAlign: 'left',
              }}
            ></GridItem>
            <GridItem
              xs={6}
              style={{
                textAlign: 'right',
              }}
              onClick={() => props.changeProfileView('profile_details')}
            >
              <Typography
                variant="body2"
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '11px',
                  fontWeight: 'normal',
                }}
              >
                {t('myProfile.editYourProfileDetails')}
              </Typography>
            </GridItem>
          </GridContainer>

          <GridContainer direction="column" style={{ textAlign: 'left' }}>
            <GridItem>
              <ProfileSummaryItem
                title={t('myProfile.name')}
                value={props.name}
                color="primary"
              />
            </GridItem>
            {userEmail && (
              <GridItem>
                <ProfileSummaryItem
                  title={'Email'}
                  value={userEmail}
                  color="primary"
                />
              </GridItem>
            )}
          </GridContainer>

          <GridContainer direction="row">
            {overmindState.report && overmindState.report.download_url && (
              <ProfileCard
                onClicked={() => {
                  trackEvent('PDF', 'Downloaded', { location: 'UserProfile' });
                  window.open(overmindState.report.download_url, '_blank');
                }}
                title={t('myProfile.dnaAssessment')}
                infoText={t('myProfile.dnaAssessmentInfo')}
                actionText={t('myProfile.downloadPdf')}
                showClickIndicator={true}
              />
            )}
          </GridContainer>
          <GridContainer direction="row">
            {((overmindState.isGenotypeFetched &&
              overmindState.genotype.source !== 'file-upload') ||
              Object.keys(overmindState.genotype).length === 0) && (
              <ProfileCard
                onClicked={() => {
                  if (kitNumber == null || kitNumber === '') {
                    actions.setComponentForModalWindow('dnaKitNumber');
                    setHideSaveInModal(false);
                    setOpenedModal(true);
                  }
                }}
                title={t('myProfile.kitCodeNumber')}
                infoText={
                  kitNumber != null && kitNumber !== ''
                    ? kitNumber
                    : t('myProfile.kitCodeNumberNotRegistered')
                }
                actionText={
                  kitNumber != null && kitNumber !== ''
                    ? ''
                    : t('myProfile.registerKitCodeNumber')
                }
                showClickIndicator={kitNumber == null || kitNumber === ''}
              />
            )}
            {((overmindState.isGenotypeFetched &&
              overmindState.genotype.source === 'file-upload') ||
              Object.keys(overmindState.genotype).length === 0) && (
              <ProfileCard
                onClicked={() => {
                  actions.setComponentForModalWindow('dnaFile');
                  actions.fileUploadInitialize();
                  setHideSaveInModal(true);
                  setOpenedModal(true);
                }}
                title={t('myProfile.uploadedFile')}
                infoText={
                  overmindState.genotype.source_details
                    ? overmindState.genotype.source_details.original_filename
                    : "You haven't uploaded your DNA file yet"
                }
                actionText={
                  overmindState.genotype.source_details &&
                  overmindState.genotype.source_details.original_filename
                    ? ''
                    : 'Upload'
                }
                showClickIndicator={
                  overmindState.genotype.source_details &&
                  overmindState.genotype.source_details.original_filename
                    ? false
                    : true
                }
              />
            )}
          </GridContainer>
        </GridContainer>
        {/*renderLinkBox('Grocery List', () =>
        props.changeProfileView('grocery_list')
            )*/}
      </ThemeProvider>
      <ModalEditWindow
        opened={openedModal}
        onClose={() => setOpenedModal(false)}
        hideSave={hideSaveInModal}
      />
    </>
  );
}
