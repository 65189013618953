import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/atoms/Layout/Box/Box';

import { useOvermindState, useActions } from 'overmind/index';
import Question from 'components/organisms/Inputs/Question/Question';

import SelectableIconGroup from 'components/organisms/Inputs/SelectableIconGroup/SelectableIconGroup';
import SvgIcon from 'components/atoms/SvgIcon/SvgIcon';
import {
  getInitialSkinGoals,
  getInitialSkinTypes,
  getInitialEyeAreaConcerns,
} from 'components/helpers/skinHelper';

export default function SkinQuestion(props) {
  const { step, hasSubtitle, isRadio } = props;
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const action = useActions();

  const getInitialItems = () => {
    if (step === 'step1') {
      return getInitialSkinTypes();
    } else if (step === 'step2') {
      return getInitialSkinGoals();
    } else if (step === 'step3') {
      return getInitialEyeAreaConcerns();
    }
  };
  const [items, setItems] = useState(undefined);

  useEffect(() => {
    const itemsToSet = getInitialItems();
    if (step === 'step1') {
      itemsToSet.forEach((i) => {
        if (
          i.id === 'step1-a1' &&
          overmindState.blueprintProfile.profile.skin_type === 'normal'
        ) {
          i.checked = true;
        } else if (
          i.id === 'step1-a2' &&
          overmindState.blueprintProfile.profile.skin_type === 'dry'
        ) {
          i.checked = true;
        } else if (
          i.id === 'step1-a3' &&
          overmindState.blueprintProfile.profile.skin_type === 'oily'
        ) {
          i.checked = true;
        } else if (
          i.id === 'step1-a4' &&
          overmindState.blueprintProfile.profile.skin_type === 'combination'
        ) {
          i.checked = true;
        } else if (
          i.id === 'step1-a5' &&
          overmindState.blueprintProfile.profile.skin_type === 'sensitive'
        ) {
          i.checked = true;
        }
      });
    } else if (step === 'step2') {
      itemsToSet.forEach((i) => {
        if (
          i.id === 'step2-a1' &&
          overmindState.blueprintProfile.profile.goal_reduce_dark_spots
        ) {
          i.checked = true;
        } else if (
          i.id === 'step2-a2' &&
          overmindState.blueprintProfile.profile.goal_target_skin_aging
        ) {
          i.checked = true;
        } else if (
          i.id === 'step2-a3' &&
          overmindState.blueprintProfile.profile.goal_boost_radiance
        ) {
          i.checked = true;
        } else if (
          i.id === 'step2-a4' &&
          overmindState.blueprintProfile.profile.goal_improve_hydration
        ) {
          i.checked = true;
        } else if (
          i.id === 'step2-a5' &&
          overmindState.blueprintProfile.profile.goal_reduce_redness
        ) {
          i.checked = true;
        } else if (
          i.id === 'step2-a5' &&
          overmindState.blueprintProfile.profile.goal_even_texture
        ) {
          i.checked = true;
        } else if (
          i.id === 'step2-a6' &&
          overmindState.blueprintProfile.profile.goal_reduce_blemishes
        ) {
          i.checked = true;
        } else if (
          i.id === 'step2-a7' &&
          overmindState.blueprintProfile.profile.goal_diminish_wrinkles
        ) {
          i.checked = true;
        }
      });
    } else if (step === 'step3') {
      itemsToSet.forEach((i) => {
        if (
          i.id === 'step3-a1' &&
          overmindState.blueprintProfile.profile.eye_concern_dark_circles
        ) {
          i.checked = true;
        } else if (
          i.id === 'step3-a2' &&
          overmindState.blueprintProfile.profile.eye_concern_puffiness
        ) {
          i.checked = true;
        } else if (
          i.id === 'step3-a3' &&
          overmindState.blueprintProfile.profile.eye_concern_sagging_eyelids
        ) {
          i.checked = true;
        } else if (
          i.id === 'step3-a4' &&
          overmindState.blueprintProfile.profile.eye_concern_dry_skin
        ) {
          i.checked = true;
        } else if (
          i.id === 'step3-a5' &&
          overmindState.blueprintProfile.profile.eye_concern_fine_lines
        ) {
          i.checked = true;
        } else if (
          i.id === 'step3-a5' &&
          overmindState.blueprintProfile.profile.eye_concern_fatigue
        ) {
          i.checked = true;
        }
      });
    }
    setItems(itemsToSet);
  }, [step]);

  return (
    <Question
      title={t(`onboarding.skinSection.${step}.title`)}
      subtitle={
        hasSubtitle ? t(`onboarding.skinSection.${step}.subtitle`) : null
      }
    >
      <Box justifyContent="center" display="flex">
        {items && (
          <SelectableIconGroup
            columnNumber={2}
            width={step === 'step2' ? '100%' : 240}
            isRadio={isRadio}
            name={'skin-question'}
            items={items.map((i) => {
              return {
                uid: i.uid,
                label: i.label,
                value: i.id,
                icon: (
                  <SvgIcon
                    height={70}
                    icon={i.icon}
                    style={{
                      marginTop: step === 'step2' ? '0px' : '-15px',
                    }}
                  />
                ),
                checkedIcon: (
                  <SvgIcon
                    height={70}
                    icon={i.icon}
                    style={{
                      marginTop: step === 'step2' ? '0px' : '-15px',
                    }}
                  />
                ),
                labelFontSize: 14,
                checked: i.checked,
              };
            })}
            onChange={(selectedValues) => {
              let selectedItems = [];
              items.forEach((item) => {
                item.checked = selectedValues.includes(item.id);
                selectedItems.push(item);
              });
              setItems(selectedItems);

              if (step === 'step1') {
                const selectedItemId = selectedValues;
                const selectedItemValue =
                  selectedItemId === 'step1-a1'
                    ? 'normal'
                    : selectedItemId === 'step1-a2'
                    ? 'dry'
                    : selectedItemId === 'step1-a3'
                    ? 'oily'
                    : selectedItemId === 'step1-a4'
                    ? 'combination'
                    : 'sensitive';
                action.setSkinType(selectedItemValue);
              } else if (step === 'step2') {
                const concerns = {
                  goal_reduce_dark_spots: selectedValues.includes('step2-a1'),
                  goal_improve_hydration: selectedValues.includes('step2-a4'),
                  goal_boost_radiance: selectedValues.includes('step2-a3'),
                  goal_target_skin_aging: selectedValues.includes('step2-a2'),
                  goal_diminish_wrinkles: selectedValues.includes('step2-a8'),
                  goal_even_texture: selectedValues.includes('step2-a6'),
                  goal_reduce_blemishes: selectedValues.includes('step2-a7'),
                  goal_reduce_redness: selectedValues.includes('step2-a5'),
                };
                action.setSkinConcerns(concerns);
              } else if (step === 'step3') {
                const concerns = {
                  eye_concern_fine_lines: selectedValues.includes('step3-a5'),
                  eye_concern_puffiness: selectedValues.includes('step3-a2'),
                  eye_concern_dark_circles: selectedValues.includes('step3-a1'),
                  eye_concern_fatigue: selectedValues.includes('step3-a6'),
                  eye_concern_dry_skin: selectedValues.includes('step3-a4'),
                  eye_concern_sagging_eyelids:
                    selectedValues.includes('step3-a3'),
                };
                action.setEyeConcerns(concerns);
              }
            }}
            wideVersion={step === 'step2'}
            rowSpacing={10}
            moveTextHigher={true}
          />
        )}
      </Box>
    </Question>
  );
}
