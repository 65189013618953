import { createTheme } from '@material-ui/core/styles';
const theme = createTheme();

// ------------------------------------------------------------------------
// Basic colour values defined. Names are defined to follow names used in Zeplin.
// ------------------------------------------------------------------------

const COOL_BLUE = '#33b0ce';
const FEDORA_GRAY = '#5b575c';
const PINKISH_TAN = '#7949b9';
const UGLY_PURPLE = '#aa4793';
const SOFT_PURPLE = '#bf67c3';
const EXERCISE_GREEN = '#add429';
const SECOND_EXERCISE_GREEN = '#64c5ab';
const SOFT_BLUE = '#6a8be5';
const NUTRITION_YELLOW = '#e5b515';
const SKIN_PINK = '#a64daa';
const ENVIRONMENT_GREEN = '#7EAC20';
const REDDISH = '#e8716d';
const BLACK = '#000000';
const WHITE = '#fff';
const GRAY = '#808080';
const UNSATURATED_RED = '#d18282';
const YELLOWISH_ORANGE = '#f6a409';
const SLATE_GRAY_TWO = '#636771';
const GREENISH_GREY = '#8ace84';
const TEXT_GRAY = '#262626';
const DARK_BLUE = '#084658';

// ------------------------------------------------------------------------
// Basic colours exposed to use across app and in components.
// ------------------------------------------------------------------------

export const APP_PRIMARY = COOL_BLUE;
export const APP_SECONDARY = FEDORA_GRAY;
export const APP_TEXT_PRIMARY = TEXT_GRAY;
export const APP_TEXT_SECONDARY = COOL_BLUE;
export const APP_TEXT_HINT = UNSATURATED_RED;

export const APP_SECTION_DNA = COOL_BLUE;

const commonProperties = {
  palette: {
    primary: {
      main: APP_PRIMARY,
      contrastText: WHITE,
    },
    secondary: {
      main: APP_SECONDARY,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: APP_TEXT_PRIMARY,
      secondary: APP_TEXT_SECONDARY,
    },
  },
  typography: {
    h1: {
      fontFamily: 'Museo-900',
      fontSize: '38px',
      lineHeight: '40px',
    },
    h2: {
      fontFamily: 'Museo-900',
      fontSize: '28px',
      fontWeight: 900,
      lineHeight: '40px',
    },
    h3: {
      fontFamily: 'MuseoSans-900',
      fontSize: '24px',
      fontWeight: 900,
      lineHeight: 1.09,
    },
    h4: {
      fontFamily: 'MuseoSans-700',
      fontSize: '25px',
      fontWeight: 700,
      lineHeight: '25px',
    },
    h5: {
      fontFamily: 'MuseoSans-500',
      fontSize: '20px',
      lineHeight: '22px',
    },
    h6: {
      fontFamily: 'Museo',
      fontSize: '16px',
      lineHeight: 1.09,
    },
    body1: {
      fontFamily: 'MuseoSans-500',
      fontSize: '12px',
      lineHeight: '14px',
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
    body2: {
      fontFamily: 'MuseoSans-300',
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: '18px',
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        lineHeight: '20px',
      },
    },
    subtitle1: {
      fontFamily: 'MuseoSans-500',
      fontSize: '12px',
      lineHeight: '14px',
      color: GRAY,
    },
    caption: {
      fontFamily: 'MuseoSans-500',
      fontSize: '10px',
      lineHeight: '11px',
    },
  },
  overrides: {
    MuiBottomNavigationAction: {
      label: {
        fontFamily: 'Museo',
        fontWeight: 800,
        fontSize: '10px',
        lineHeight: '10px',
      },
    },
  },
};

export const commonTheme = createTheme({
  ...commonProperties,
});

/**
 * Theme for app intro section.
 */
export const introTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: '#cecaca',
      contrastText: WHITE,
    },
    text: {
      primary: PINKISH_TAN,
      secondary: PINKISH_TAN,
    },
  },
});

/**
 * Theme for general section.
 */
export const generalTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: UGLY_PURPLE,
      contrastText: WHITE,
    },
    text: {
      primary: UGLY_PURPLE,
      secondary: UGLY_PURPLE,
    },
  },
});

/**
 * Theme for pregnancy section.
 */
export const pregnancyTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: SOFT_PURPLE,
      contrastText: WHITE,
    },
    text: {
      primary: SOFT_PURPLE,
      secondary: SOFT_PURPLE,
    },
  },
});

/**
 * Theme for exercise section.
 */
export const exerciseTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: EXERCISE_GREEN,
      contrastText: WHITE,
    },
    text: {
      primary: EXERCISE_GREEN,
      secondary: EXERCISE_GREEN,
    },
  },
});

/**
 * Theme for lifestyle section.
 */
export const lifestyleTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: SOFT_BLUE,
      contrastText: WHITE,
    },
    text: {
      primary: SOFT_BLUE,
      secondary: SOFT_BLUE,
    },
    secondary: {
      main: SECOND_EXERCISE_GREEN,
      contrastText: WHITE,
    },
  },
});

/**
 * Theme for nutrition section.
 */
export const nutritionTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: NUTRITION_YELLOW,
      contrastText: WHITE,
    },
    text: {
      primary: NUTRITION_YELLOW,
      secondary: NUTRITION_YELLOW,
    },
  },
});

/**
 * Theme for skin section.
 */
export const skinTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: SKIN_PINK,
      contrastText: WHITE,
    },
    text: {
      primary: SKIN_PINK,
      secondary: SKIN_PINK,
    },
  },
});

/**
 * Theme for environment section.
 */
export const environmentTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: ENVIRONMENT_GREEN,
      contrastText: WHITE,
    },
    text: {
      primary: ENVIRONMENT_GREEN,
      secondary: ENVIRONMENT_GREEN,
    },
  },
});

/**
 * Theme for app DNA section.
 */
export const dnaTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: APP_SECTION_DNA,
      contrastText: WHITE,
    },
    text: {
      primary: APP_SECTION_DNA,
      secondary: APP_SECTION_DNA,
    },
  },
});

/**
 * Theme for app assessment section.
 */
export const assessmentTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: REDDISH,
      contrastText: WHITE,
      ctaBackground: 'linear-gradient(to left, #e8af44, #e86e6e)',
      weeklyCheckinModal: 'linear-gradient(to bottom, #e8af44, #e86e6e)',
    },
    secondary: {
      main: YELLOWISH_ORANGE,
      contrastText: WHITE,
    },
    text: {
      primary: BLACK,
      secondary: WHITE,
      tertiary: SLATE_GRAY_TWO,
    },
    icon: {
      primary: GREENISH_GREY,
    },
  },
});

/**
 * Theme for Profile General section.
 */
export const profileGeneralTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: APP_TEXT_HINT,
      contrastText: WHITE,
    },
  },
});

/**
 * Theme for Weekly checkin and Monitoring section.
 */
export const monitoringTheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: WHITE,
      contrastText: BLACK,
    },
    text: {
      primary: WHITE,
      secondary: WHITE,
    },
    secondary: {
      main: WHITE,
      contrastText: BLACK,
    },
  },
});

/**
 * Theme for Regimen section.
 */
export const regimenTheme = createTheme({
  ...commonProperties,
});

/**
 * Theme for upload DNA section.
 */
export const uploadDNATheme = createTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: REDDISH,
      contrastText: WHITE,
    },
    text: {
      primary: BLACK,
    },
    secondary: {
      main: DARK_BLUE,
      contrastText: WHITE,
    },
  },
});
