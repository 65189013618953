export const state = {
  signInRequired: false,
  introPageIndex: 0,
  email: null,
  onboardingForwardEnabled: true,
  progressStep: 0,
  errors: {},
  hasFetchedMetaData: null,
  metaData: {
    user_metadata: {},
  },
  profile: {},
  blueprintProfile: {
    loading: null,
    profile: {},
  },
  profileLifestyle: {},
  profileNutrition: {},
  traits: [],
  kitNumberSubmit: {
    submitSuccessful: false,
    showSubmitStatus: false,
  },
  upload: {
    progress: 0,
    showUploadStatus: false,
    successful: false,
  },
  isMealPlanLoaded: false,
  genotype: {},
  isGenotypeFetched: false,
  isGenotypeFetching: false,
  weather: {},
  isWeatherFetched: false,
  regime: {
    loading: false,
    data: undefined,
  },
  componentForModalWindow: undefined,
};
