import React from 'react';

import Box from 'components/atoms/Layout/Box/Box';
import Question from 'components/organisms/Inputs/Question/Question';

import TextInput from 'components/atoms/Inputs/TextInput/TextInput';
import { Typography } from '@material-ui/core';

export default function ZipCodeQuestion(props) {
  const handleChange = (value) => {
    props.onChange && props.onChange(value);
  };

  return (
    <Question
      title={props.title}
      divider={props.divider !== null ? props.divider : true}
    >
      <Box justifyContent="center" display="flex" mt={2}>
        <TextInput
          value={props.zipCode}
          color={'primary'}
          onChange={(e) => handleChange(e.target.value)}
          inputProps={{
            style: {
              fontSize: '18px',
              width: '300px',
              color: 'black',
            },
          }}
        />
      </Box>
      {props.zipCodeIsInvalid && (
        <Box justifyContent="center" display="flex" mt={2}>
          <Typography variant="body1" style={{ color: 'red' }}>
            {props.zipCodeInvalidMessage}
          </Typography>
        </Box>
      )}
    </Question>
  );
}
