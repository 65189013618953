import React from 'react';

import { AlertIcon, CloseAlertIcon } from 'assets/iconsList';
import { useLandingModalStyles } from './landingModalStyles';

import Modal from 'components/atoms/Modal/Modal';
import Box from 'components/atoms/Layout/Box/Box';
import Button from 'components/atoms/Inputs/Buttons/Button';
import Typography from 'components/atoms/Typography/Typography';

export default function LandingModal(props) {
  const { opened, close, content } = props;
  const styles = useLandingModalStyles();
  return (
    <Modal opened={opened} close={() => close && close()}>
      <Box className={styles.container}>
        <Box className={styles.closeAlertIcon} onClick={() => close && close()}>
          <CloseAlertIcon width={10} height={10} />
        </Box>
        <Box className={styles.alertIcon}>
          <AlertIcon width={36} height={32} />
        </Box>
        <Box className={styles.contentContainer}>
          <Typography className={styles.content}>{content}</Typography>
        </Box>
        {props.onClick && props.buttonText && (
          <Box className={styles.contentContainer}>
            <Button
              onClick={() => props.onClick()}
              component="span"
              variant="contained"
              style={{
                margin: '20px auto',
                width: '80%',
                backgroundColor: '#084658',
              }}
            >
              {props.buttonText}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
