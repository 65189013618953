import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

const isLocalhost = () => {
  const location = window.location;
  return location.host.indexOf('localhost') !== -1;
};

const getGoogleAnalyticsId = () => {
  if (isLocalhost()) {
    return null;
  }

  return process.env.REACT_APP_GA_ID;
};

const getGoogleAdsId = () => {
  if (isLocalhost()) {
    return null;
  }

  return process.env.REACT_APP_GADS_ID;
};

const getGoogleAdsSendToId = () => {
  if (isLocalhost()) {
    return null;
  }

  return process.env.REACT_APP_GADS_SENDTO_ID;
};

/**
 * Initializes tracking.
 */
export const initializeTracking = () => {
  const gaId = getGoogleAnalyticsId();

  if (gaId != null) {
    ReactGA.initialize(gaId);
  }

  const pixelId = getFacebookPixelId();
  if (pixelId != null) {
    ReactPixel.init(pixelId, null, {
      autoConfig: true,
      debug: false,
    });
  }
};

const getFacebookPixelId = () => {
  if (isLocalhost()) {
    // no FB Pixel id for localhost
    return null;
  }

  return process.env.REACT_APP_FBP_ID;
};

/**
 * Tracks visit to the page.
 * @param {string} pagePath Path of the tracked page.
 */
export const trackPageVisit = (pagePath) => {
  if (isLocalhost()) {
    return;
  }

  ReactGA.pageview(pagePath);
};

export const trackEvent = (category, action, data) => {
  if (isLocalhost()) {
    return;
  }

  trackGoogleEvent(category, action);

  // request is: when the user finishes onboarding, we are counting that as conversion
  /*if (category === 'Onboarding' && action === 'Finished') {
    trackGoogleAdsConversionEvent();
  }*/
  // request is: when the user uploads a DNA file, we are counting that also as conversion
  if (category === 'DNA File' && action === 'Uploaded') {
    trackGoogleAdsConversionEvent();
    trackFbPixelEvent('SubmitApplication');
  }
};

export const trackFbPixelEvent = (event) => {
  ReactPixel.track(event);
};

export const trackGoogleAdsConversionEvent = () => {
  const adsId = getGoogleAdsId();
  const sendToId = getGoogleAdsSendToId();
  if (!adsId || !sendToId) {
    return;
  }

  window.gtag('config', adsId);
  window.gtag('event', 'conversion', { send_to: sendToId });
};

/**
 * Tracks Google events specified with category and action.
 * @param {string} category Event's category
 * @param {string} action Event's action
 */
const trackGoogleEvent = (category, action) => {
  ReactGA.event({
    category: category,
    action: action,
  });
};
