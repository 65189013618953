import i18n from '../../i18n';

export const getGoalsString = (userGoals) => {
  let goals = [];
  if (userGoals.goal_boost_radiance === true) {
    goals.push(i18n.t('mealPlan.goals.boostRadiance'));
  }
  if (userGoals.goal_diminish_wrinkles === true) {
    goals.push(i18n.t('mealPlan.goals.diminishWrinkles'));
  }
  if (userGoals.goal_even_texture === true) {
    goals.push(i18n.t('mealPlan.goals.evenTexture'));
  }
  if (userGoals.goal_improve_hydration === true) {
    goals.push(i18n.t('mealPlan.goals.improveHydration'));
  }
  if (userGoals.goal_reduce_blemishes === true) {
    goals.push(i18n.t('mealPlan.goals.reduceBlemishes'));
  }
  if (userGoals.goal_reduce_dark_spots === true) {
    goals.push(i18n.t('mealPlan.goals.reduceDarkSpots'));
  }
  if (userGoals.goal_reduce_redness === true) {
    goals.push(i18n.t('mealPlan.goals.reduceRedness'));
  }
  if (userGoals.goal_target_skin_aging === true) {
    goals.push(i18n.t('mealPlan.goals.targetSkinAging'));
  }

  return goals;
};

export const getGoalsText = (userGoals) => {
  let goalText =
    userGoals.length === 1
      ? userGoals[0]
      : userGoals.length === 2
      ? userGoals.join(' & ')
      : userGoals.length === 0
      ? ''
      : userGoals.slice(0, -1).join(', ') + ' & ' + userGoals.slice(-1);

  return goalText;
};
