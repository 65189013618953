import { makeStyles } from '@material-ui/core';

export const useProfileTilesStyles = makeStyles(() => ({
  root: {},
  tile: (props) => ({
    height: props.height && props.height,
    padding: '10px',
    marginBottom: '10px',
    border: `1px solid ${props.color ? props.color : '#EEEEEE'}`,
    borderRadius: '20px',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.10)',
    backgroundColor: props.color ? props.color : '#FFFFFF',
    cursor: props.preventClick ? 'auto' : 'pointer',
  }),
  tileTitle: {
    fontFamily: 'Museo-700',
    fontSize: '20px',
    marginTop: '25px',
    marginLeft: '20px',
    marginBottom: '5px',
  },
  tileInfo: {
    fontFamily: 'MuseoSans-700',
    marginLeft: '20px',
    marginBottom: '25px',
    fontWeight: 'bold',
  },
  tileIcon: {
    height: '100%',
    marginRight: '20px',
    marginLeft: '10px',
  },
}));
