import React from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';

import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
import Question from 'components/organisms/Inputs/Question/Question';

import IllustratedSlider from 'components/molecules/Inputs/IllustratedSlider/IllustratedSlider';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import {
  lifestyleSliderWaterIcon1,
  lifestyleSliderWaterIcon2,
  lifestyleSliderWaterIcon3,
  lifestyleSliderWaterIcon4,
  lifestyleSliderWaterIcon5,
} from 'assets/iconsList';

export default function LifestyleWaterPage(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const action = useActions();

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <GridItem
        style={{
          maxWidth: '700px',
          marginBottom: '20px',
        }}
      >
        <Question
          title={t('onboarding.lifestyleSection.step3.question1.title')}
          subtitle={t('onboarding.lifestyleSection.step3.question1.subTitle')}
        >
          <IllustratedSlider
            id="illustrated-slider-sleep"
            illustrations={[
              {
                illustration: lifestyleSliderWaterIcon1,
                height: 100,
                text: t('onboarding.lifestyleSection.step3.question1.slide1'),
              },
              {
                illustration: lifestyleSliderWaterIcon2,
                height: 100,
                text: t('onboarding.lifestyleSection.step3.question1.slide2'),
              },
              {
                illustration: lifestyleSliderWaterIcon3,
                height: 100,
                text: t('onboarding.lifestyleSection.step3.question1.slide3'),
              },
              {
                illustration: lifestyleSliderWaterIcon4,
                height: 100,
                text: t('onboarding.lifestyleSection.step3.question1.slide4'),
              },
              {
                illustration: lifestyleSliderWaterIcon5,
                height: 100,
                text: t('onboarding.lifestyleSection.step3.question1.slide5'),
              },
            ]}
            selectedIllustrationIndex={
              overmindState.blueprintProfile.profile.water_level
                ? overmindState.blueprintProfile.profile.water_level
                : 0
            }
            onValueChanged={(index) =>
              action.changeLifestyleHydrationLevel(index)
            }
          />
        </Question>
      </GridItem>
    </GridContainer>
  );
}
