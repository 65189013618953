import {
  StarGreen,
  StarOrange,
  StarRed,
  StarYellow,
  StartIcon,
} from 'assets/iconsList';

export const getTraitIcon = (trait) => {
  return <StartIcon color={getInfoColor(trait)} />;
};

export const getInfoColor = (trait) => {
  if (trait.level_label === 'Elevated') {
    return '#db6043';
  } else if (trait.level_label === 'Slightly Elevated') {
    return '#e29234';
  } else if (trait.level_label === 'Low') {
    return '#e5af1d';
  } else if (trait.level_label === 'Typical') {
    return '#9ac677';
  } else if (trait.level_label === 'Slightly Advantaged') {
    return '#71a73b';
  } else if (trait.level_label === 'Advantaged') {
    return '#4e860d';
  } else {
    return '#070707';
  }
};

export const sortTraits = (traits) => {
  if (!traits) {
    return;
  }

  let elevated = [];
  let slightlyElevated = [];
  let rest = [];
  let sortedTraits = [];

  traits.forEach((trait) => {
    if (trait.level_label.toLowerCase() === 'elevated') {
      elevated.push(trait);
    } else if (trait.level_label.toLowerCase() === 'slightly elevated') {
      slightlyElevated.push(trait);
    } else {
      rest.push(trait);
    }
  });

  [...elevated, ...slightlyElevated, ...rest].forEach((trait, index) => {
    sortedTraits.push({ trait: { ...trait }, index: index });
  });

  return sortedTraits;
};

export const getHardCodedTraits = () => {
  return [
    {
      trait: {
        trait: {
          code: 'collagen-breakdown',
          name: 'Collagen Breakdown',
          connotation: 'risky',
        },
        level: 2,
        level_label: 'Elevated',
      },
      index: 1,
    },
    {
      trait: {
        trait: {
          code: 'dryness',
          name: 'Dryness',
          connotation: 'risky',
        },
        level: 2,
        level_label: 'Elevated',
      },
      index: 2,
    },
    {
      trait: {
        trait: {
          code: 'dermal-sensitivity',
          name: 'Dermal Sensitivity',
          connotation: 'risky',
        },
        level: 1,
        level_label: 'Slightly Elevated',
      },
      index: 3,
    },
    {
      trait: {
        trait: {
          code: 'skin-glycation',
          name: 'Skin Glycation',
          connotation: 'risky',
        },
        level: 1,
        level_label: 'Slightly Elevated',
      },
      index: 4,
    },
    {
      trait: {
        trait: {
          code: 'youthfulness',
          name: 'Youthfulness',
          connotation: 'good',
        },
        level: 2,
        level_label: 'Advantaged',
      },
      index: 5,
    },
  ];
};
