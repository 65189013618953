import { makeStyles } from '@material-ui/core';

export const useSignUpStyles = makeStyles({
  title: {
    marginTop: '55px',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#fff',
    fontFamily: 'MuseoSans-900',
    textAlign: 'center',
    userSelect: 'none',
  },
  subTitle: {
    marginTop: '15px',
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '20px',
    height: '59px',
    fontFamily: 'MuseoSans-700',
    color: '#fff',
    userSelect: 'none',
  },
  emailContainer: {
    display: 'flex',
    height: '48px',
    userSelect: 'none',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid white',
      },
      '&:hover fieldset': {
        border: '2px solid white',
      },
      '&:not(:hover) fieldset': {
        border: '2px solid white',
      },
    },
  },
  passwordContainer: {
    display: 'flex',
    height: '48px',
    userSelect: 'none',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid white',
      },
      '&:hover fieldset': {
        border: '2px solid white',
      },
      '&:not(:hover) fieldset': {
        border: '2px solid white',
      },
    },
  },
  confirmPasswordContainer: {
    display: 'flex',
    height: '48px',
    userSelect: 'none',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid white',
      },
      '&:hover fieldset': {
        border: '2px solid white',
      },
      '&:not(:hover) fieldset': {
        border: '2px solid white',
      },
    },
  },
  confirmButton: {
    height: '54.7px',
    width: '100%',
    marginTop: '26.6px',
    marginBottom: ' 18px',
    backgroundColor: '#97d3c5',
    '&:disabled': {
      color: 'white',
    },
    '&:active': {
      color: 'white',
      backgroundColor: '#97d3c5',
    },
    '&:click': {
      color: 'white',
      backgroundColor: '#97d3c5',
    },
    '&:focus': {
      color: 'white',
      backgroundColor: '#97d3c5',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#97d3c5',
    },
  },
  buttonText: {
    fontSize: '20px',
    lineHeight: '24px',
    fontFamily: 'MuseoSans',
  },
  noAccountText: {
    fontFamily: 'MuseoSans-500',
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#fff',
    userSelect: 'none',
  },
  signInContainer: {
    display: 'flex',
    height: '42px',
    marginTop: '25px',
    borderTop: '2px solid #fff',
    borderBottom: '2px solid #fff',
    cursor: 'pointer',
  },
  signInText: {
    fontFamily: 'MuseoSans-500',
    fontSize: '16px',
    paddingLeft: '5.5px',
    lineHeight: 1.5,
    color: '#fff',
    userSelect: 'none',
  },
  statement: {
    marginTop: '17.5px',
    paddingBottom: '20px',
    fontFamily: 'MuseoSans',
    fontSize: '11px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: '-0.17px',
    textAlign: 'center',
    color: '#fff',
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  learnMoreText: {
    width: '234px',
    height: '19px',
    marginTop: '41.7px',
    paddingBottom: '40px',
    fontFamily: 'MuseoSans',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#fff',
    userSelect: 'none',
  },
  pageLink: {
    color: 'white',
    textDecoration: 'underlined',
    '&:visited': {
      color: 'white',
    },
    '&:active': {
      color: 'white',
    },
  },
});

export const inputProps = {
  fontFamily: 'MuseoSans',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#ffffff',
  textAlign: 'center',
};
