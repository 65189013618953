import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';

export default function MealPlanProblemsDialog(props) {
  const { openDialog, title, confirmText } = props;

  return (
    <MuiDialog
      open={openDialog}
      onClose={() => {
        props.onClose && props.onClose();
      }}
    >
      <Box style={{ margin: '20px', textAlign: 'center' }}>
        <Typography>{title}</Typography>

        <GridContainer style={{ marginTop: 8 }}>
          <GridItem xs={12}>
            <Button
              onClick={() => props.onConfirm && props.onConfirm()}
              variant="contained"
            >
              <Typography textSize={12}>{confirmText}</Typography>
            </Button>
          </GridItem>
        </GridContainer>
      </Box>
    </MuiDialog>
  );
}
