import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useOvermindState } from 'overmind/index';
import { trackPageVisit } from 'components/helpers/userTrackingEventsHelper';

import {
  ROUTE_LANDING_PAGE,
  ROUTE_INTRO,
  ROUTE_ONBOARDING,
  ROUTE_REGISTER_KIT,
  ROUTE_ASSESSMENT,
  EMAIL_VERIFICATION,
} from 'routes';

import LandingPage from 'components/pages/Onboarding/LandingPage/LandingPage';
import IntroPage from 'components/pages/Onboarding/Intro/IntroPage/IntroPage';
import Onboarding from 'components/pages/Onboarding/Onboarding';
import KitRegistrationPage from 'components/pages/DnaTemp/KitRegistrationPage';
import Assessment from 'components/pages/Assessment/Assessment';
import { onboardingSteps } from 'components/helpers/onboardingHelper';

export default function App() {
  const overmindState = useOvermindState();

  function usePageViews() {
    let location = useLocation();

    useEffect(() => {
      if (location.pathname === '/onboarding') {
        trackPageVisit(
          `${location.pathname}/${onboardingSteps[overmindState.progressStep]}`
        );
      } else {
        trackPageVisit(location.pathname);
      }
    }, [overmindState.progressStep, location]);
  }

  usePageViews();

  return (
    <Switch>
      <Route exact path={ROUTE_LANDING_PAGE} component={LandingPage} />
      <Route exact path={ROUTE_INTRO} component={IntroPage} />
      <Route exact path={ROUTE_ONBOARDING} component={Onboarding} />
      <Route exact path={ROUTE_REGISTER_KIT} component={KitRegistrationPage} />
      <Route path={ROUTE_ASSESSMENT} component={Assessment} />
      <Route exact path={EMAIL_VERIFICATION} component={LandingPage} />
    </Switch>
  );
}
