import React from 'react';
import PropTypes from 'prop-types';

import { useNavigationHeaderStyles } from './headerStyles';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';

import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
} from '@material-ui/icons';

export default function NavigationHeader(props) {
  const classes = useNavigationHeaderStyles(props);
  const isDesktop = useCheckScreenSize().isDesktop;
  const {
    hideHeader,
    title,
    heading,
    showBack,
    goBack,
    showNext,
    goNext,
    addMargin,
  } = props;

  return hideHeader ? (
    <></>
  ) : (
    <>
      <GridContainer
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
        style={{ marginTop: isDesktop ? '50px' : '0px' }}
      >
        <GridItem xs={1}>
          {goBack && showBack && (
            <NavigateBeforeIcon
              onClick={() => goBack()}
              className={classes.button}
            />
          )}
        </GridItem>
        <GridItem xs={10}>
          {title && (
            <Typography
              variant="h5"
              style={{
                fontFamily: isDesktop ? 'MuseoSans-900' : 'MuseoSans-500',
                fontSize: isDesktop ? '24px' : '18px',
                lineHeight: '22px',
              }}
            >
              {title}
            </Typography>
          )}
          {heading && (
            <Typography
              variant="h3"
              textAlign="center"
              style={{
                fontFamily: 'MuseoSans-700',
                fontSize: isDesktop ? '34px' : '20px',
                lineHeight: '22px',
              }}
            >
              {heading}
            </Typography>
          )}
        </GridItem>
        <GridItem xs={1}>
          {goNext && showNext && (
            <NavigateNextIcon
              onClick={() => goNext()}
              className={classes.button}
            />
          )}
        </GridItem>
      </GridContainer>
      {addMargin && <div className={classes.headerMargin} />}
    </>
  );
}

NavigationHeader.propTypes = {
  hideHeader: PropTypes.bool,
  title: PropTypes.string,
  heading: PropTypes.string,
  goBack: PropTypes.func,
  showBack: PropTypes.bool,
  goNext: PropTypes.func,
  showNext: PropTypes.bool,
  addMargin: PropTypes.bool,
};
