export const minWeightLbs = 80;
export const maxWeightLbs = 350;
export const minWeightKg = 40;
export const maxWeightKg = 160;
export const minHeightFt = 4;
export const maxHeightFt = 7;
export const minHeightIn = 0;
export const maxHeightIn = 11;
export const minHeightCm = 120;
export const maxHeightCm = 230;
export const minAge = 18;
export const maxAge = 99;
