import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import InfoContainer from 'components/molecules/Common/InfoContainer/InfoContainer';
import ImageWithText from 'components/molecules/Common/ImageWithText/ImageWithText';
import { ThemeProvider } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { nutritionTheme } from 'themes';

export default function NutritionSection(props) {
  const { t } = useTranslation();

  const handleOpenPopup = (component) => {
    props.onTileClick && props.onTileClick(component);
  };

  return (
    <ThemeProvider theme={nutritionTheme}>
      <GridContainer>
        <GridContainer
          direction="row"
          style={{
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          <GridItem
            style={{
              width: '50%',
              textAlign: 'left',
            }}
          >
            <Typography
              variant="h4"
              style={{
                textTransform: 'uppercase',
                fontSize: '15px',
              }}
            >
              {t('myProfile.subtitleNutrition')}
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <InfoContainer
            title={t('myProfile.dietaryPreferences')}
            value={
              props.dietaryPreferences &&
              props.dietaryPreferences.map((dietaryPreference, i) => (
                <ImageWithText
                  icon={props.dietaryPreferencesIcons[i]}
                  text={dietaryPreference}
                  color="primary"
                />
              ))
            }
            color="primary"
            tallContainer={true}
            onTileClick={() => handleOpenPopup('dietaryPreferences')}
          />
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <GridItem>
            <InfoContainer
              title={t('myProfile.allergiesAndRestrictions')}
              value={
                props.allergiesRestrictions &&
                props.allergiesRestrictions.map((alergyRestriction, i) => (
                  <ImageWithText
                    icon={props.allergiesRestrictionsIcons[i]}
                    text={alergyRestriction}
                    color="primary"
                  />
                ))
              }
              color="primary"
              tallContainer={true}
              onTileClick={() => handleOpenPopup('allergiesRestrictions')}
            />
          </GridItem>
        </GridContainer>
      </GridContainer>
    </ThemeProvider>
  );
}
