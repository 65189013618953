import { makeStyles } from '@material-ui/core';

export const traitDetailsStyles = makeStyles((theme) => ({
  root: {},
  content: {
    margin: '10px 15px 20px 15px',
  },
  seeAll: {
    fontSize: '12px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  effectsText: {
    fontFamily: 'Museo-700',
    fontSize: '18px',
    marginRight: '20px',
    marginBottom: '10px',
    color: '#28052e',
  },
  skinProductImage: {
    height: '85px',
    width: 'auto',
    margin: '10px',
    borderRadius: '50%',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  skinProductText: {
    fontFamily: 'MuseoSans-700',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
  },
}));
