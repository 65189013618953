import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import './productDetailsStyles.css';

import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Accordion from 'components/atoms/Navigation/Accordion/Accordion';
import AccordionSummary from 'components/atoms/Navigation/Accordion/AccordionSummary';
import AccordionDetails from 'components/atoms/Navigation/Accordion/AccordionDetails';
import ChipList from 'components/molecules/ChipList/ChipList';
import HorizontalSwiper from 'components/molecules/HorizontalSwiper/HorizontalSwiper';
import ContentCard from 'components/molecules/Common/ContentCard/ContentCard';
import Button from 'components/atoms/Inputs/Buttons/Button';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function ProductDetails(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const [openedPanels, setOpenedPanels] = useState(
    window.innerWidth > 800 ? ['description'] : []
  );
  const { product } = props;
  const isDesktop = useCheckScreenSize().isDesktop;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenedPanels = (panel) => {
    let panels = [...openedPanels];

    const index = panels.indexOf(panel);
    if (index > -1) {
      panels.splice(index, 1);
    } else {
      panels.push(panel);
    }
    setOpenedPanels(panels);
  };

  const handleProductChange = (isNext) => {
    props.onChange && props.onChange(isNext);
  };

  const renderInfoText = (image, text) => {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box style={{ margin: '0px 20px 8px 20px', minWidth: '40px' }}>
          {image && (
            <img alt="infoImage" style={{ borderRadius: '15px' }} src={image} />
          )}
        </Box>
        <Typography
          variant="caption"
          style={{
            fontFamily: 'MuseoSans-700',
            fontSize: isDesktop ? '13px' : '10px',
            lineHeight: isDesktop ? '15px' : '12px',
            marginLeft: '4px',
            marginRight: '4px',
            textAlign: 'center',
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  const renderOrderedList = (list) => {
    return (
      <Typography
        variant="body1"
        style={{
          fontFamily: isDesktop ? 'MuseoSans-700' : 'MuseoSans-500',
          fontSize: '18px',
          lineHeight: '22px',
        }}
      >
        <ol className={isDesktop && 'product-list'}>
          {list.map((item) => (
            <li>{item}</li>
          ))}
        </ol>
      </Typography>
    );
  };

  const renderSwiper = (list) => {
    return (
      <HorizontalSwiper uid="navigation-swiper">
        {list.map((item, index) => (
          <React.Fragment key={item.code}>
            {(list.length == null || index + 1 <= list.length) && (
              <GridContainer
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  width: '120px',
                  marginTop: '20px',
                  backgroundColor: 'inherit',
                }}
                className={'swiper-slide q-nav-swiper-slide'}
              >
                <GridItem>
                  {
                    <img
                      src={
                        item.image
                          ? item.image
                          : 'https://via.placeholder.com/80x80'
                      }
                      alt={item.code}
                      height={'98px'}
                      width={'98px'}
                      style={{
                        borderRadius: '50%',
                      }}
                    />
                  }
                </GridItem>
                <Typography
                  variant="body1"
                  style={{ fontSize: '14px', marginTop: '10px' }}
                >
                  {item.name}
                </Typography>
              </GridContainer>
            )}
          </React.Fragment>
        ))}
      </HorizontalSwiper>
    );
  };

  const renderAccordion = (id, title, children, smallFont, color) => {
    return (
      <Accordion
        square
        expanded={openedPanels.includes(id)}
        onChange={() => handleOpenedPanels(id)}
        style={{
          width: '100%',
          backgroundColor: 'inherit',
          border: 'none',
          color: color ? color : isDesktop ? '#084658' : '#342f38',
        }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ border: 'none', maxHeight: '50px', minHeight: '24px' }}
          expandIcon={openedPanels.includes(id) ? <RemoveIcon /> : <AddIcon />}
        >
          <Typography
            variant="body1"
            style={{
              fontFamily: 'MuseoSans-500',
              fontSize: isDesktop ? (smallFont ? '15px' : '20px') : '12px',
              lineHeight: isDesktop ? (smallFont ? '18px' : '24px') : '14px',
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            style={{
              fontFamily: 'MuseoSans-300',
              fontSize: isDesktop ? '16px' : '14px',
              lineHeight: isDesktop ? '20px' : '18px',
            }}
          >
            {children}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderChangeProductButton = (isNext) => {
    return (
      <Box
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: '60px',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          left: !isNext && 0,
          right: isNext && 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() => handleProductChange(isNext)}
      >
        {isNext ? (
          <ArrowForwardIcon style={{ fill: '#fff' }} />
        ) : (
          <ArrowBackIcon style={{ fill: '#fff' }} />
        )}
      </Box>
    );
  };

  const formatText = (text) => {
    return (
      <>
        {text.split('\n').map((paragraph, key) => {
          return <p key={key}>{paragraph}</p>;
        })}
      </>
    );
  };

  return (
    <>
      {isDesktop && props.enablePrevious && renderChangeProductButton(false)}
      {isDesktop && props.enableNext && renderChangeProductButton(true)}

      {!isDesktop && (
        <GridContainer
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
          style={{
            height: product && product.image ? '500px' : '80px',
            backgroundImage:
              product && product.image
                ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.47)) , url(${product.image})`
                : 'none',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            textAlign: 'center',
            color: 'white',
            paddingBottom: '10px',
          }}
        >
          <GridItem
            style={{
              width: '100%',
              paddingLeft: '40px',
              paddingRight: '40px',
              paddingBottom: '70px',
              textAlign: 'center',
              textTransform: 'uppercase',
              color: 'white',
            }}
          >
            {product.tags && product.tags.length > 0 && (
              <HorizontalSwiper uid="tags-swiper">
                {product.tags.map((tag, ix) =>
                  renderInfoText(tag.image, tag.name)
                )}
              </HorizontalSwiper>
            )}
          </GridItem>
        </GridContainer>
      )}

      {isDesktop && (
        <div
          style={{
            paddingTop: '100px',
            paddingBottom: '100px',
            backgroundColor: '#dcedeb',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h3"
            style={{
              fontFamily: 'MuseoSans-700',
              fontSize: isDesktop ? '34px' : '24px',
              lineHeight: isDesktop ? '34px' : '24px',
            }}
          >
            {product.name}
          </Typography>
          <Typography
            variant="h5"
            style={{
              fontFamily: 'MuseoSans-300',
              fontSize: '18px',
              lineHeight: '22px',
              marginTop: '14px',
            }}
          >
            {product.subtitle}
          </Typography>
        </div>
      )}

      <ContentCard color={isDesktop ? '#fff' : '#e8dfd8'} fullWidth>
        <GridContainer
          style={{
            minHeight: isDesktop
              ? '500px'
              : product && product.image
              ? 'auto'
              : '100vh',
            padding: '0 16px',
            paddingTop: !isDesktop && '40px',
            paddingBottom: isDesktop && '40px',
            color: isDesktop ? '#084658' : '#100e12',
          }}
        >
          {isDesktop && (
            <GridItem
              xs={12}
              md={6}
              style={{
                backgroundImage:
                  product && product.image ? `url(${product.image})` : 'none',
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '450px',
              }}
            ></GridItem>
          )}
          <GridItem
            xs={12}
            md={6}
            style={{
              marginTop: isDesktop
                ? '0px'
                : product && product.image
                ? '-50px'
                : '-30px',
              maxWidth: '500px',
            }}
          >
            {!isDesktop && (
              <>
                <Typography variant="h3" style={{ textAlign: 'center' }}>
                  {product.name}
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: 'center',
                    marginBottom: '20px',
                    lineHeight: '1.22',
                    marginTop: '14px',
                  }}
                >
                  {product.subtitle}
                </Typography>
              </>
            )}

            {/* Commented until we get images for tags */}
            {/* <Box style={{ marginBottom: '40px' }}>
              {isDesktop && product.tags && product.tags.length > 0 && (
                <HorizontalSwiper uid="tags-swiper">
                  {product.tags.map((tag, ix) =>
                    renderInfoText(tag.image, tag.name)
                  )}
                </HorizontalSwiper>
              )}
            </Box> */}

            <Typography
              variant="body1"
              style={{
                fontFamily: 'MuseoSans-500',
                fontSize: isDesktop ? '21px' : '12px',
                lineHeight: isDesktop ? '25px' : '14px',
              }}
            >
              {t('regimen.productDetails.howToUse')}
            </Typography>
            {renderOrderedList(product.directions)}

            <Box
              style={{
                display: 'flex',
                flexDirection: isDesktop ? 'column-reverse' : 'column',
              }}
            >
              {product.caution_text &&
                renderAccordion(
                  'caution',
                  t('regimen.productDetails.caution'),
                  formatText(product.caution_text)
                )}
              {product.description &&
                renderAccordion(
                  'description',
                  t('regimen.productDetails.description'),
                  formatText(product.description)
                )}
            </Box>
          </GridItem>
        </GridContainer>
      </ContentCard>

      <ContentCard color={isDesktop ? '#dcedeb' : '#f2ece9'} fullWidth>
        <GridContainer
          style={{
            maxWidth: '1024px',
            margin: 'auto',
            padding: '0 16px',
          }}
          spacing={isDesktop ? 2 : 0}
        >
          {isDesktop && (
            <GridItem xs={12}>
              <Typography
                variant="body1"
                style={{
                  fontSize: '20px',
                  lineHeight: '24px',
                  color: '#084658',
                }}
              >
                {t('regimen.productDetails.ingredients')}
              </Typography>
            </GridItem>
          )}

          <GridItem xs={12} md={6}>
            {product.active_ingredients.length > 0 && (
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: isDesktop ? '15px' : '12px',
                    lineHeight: isDesktop ? '18px' : '14px',
                    marginTop: '15px',
                  }}
                >
                  {t('regimen.productDetails.keyIngredients')}
                </Typography>
                {renderSwiper(product.active_ingredients)}
              </Box>
            )}
          </GridItem>
          <GridItem xs={12} md={6}>
            {product.ingredients &&
              renderAccordion(
                'ingredients',
                t('regimen.productDetails.ingredientsList'),
                product.ingredients,
                true,
                '#21153c'
              )}
          </GridItem>
          <GridItem xs={12} md={6} style={{ marginTop: '20px' }}>
            {product.recommended_for != null &&
              product.recommended_for.length > 0 && (
                <Box>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: isDesktop ? '15px' : '12px',
                      lineHeight: isDesktop ? '18px' : '14px',
                      marginBottom: '20px',
                      color: isDesktop ? '#084658' : '#100e12',
                    }}
                  >
                    {t('regimen.productDetails.recommendedBecause')}
                  </Typography>
                  <ChipList chipsTexts={product.recommended_for} />
                </Box>
              )}
          </GridItem>
          {isDesktop && (
            <GridItem
              xs={12}
              md={6}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                component="span"
                variant="contained"
                style={{ backgroundColor: '#084658', padding: '15px 20px' }}
                onClick={() => {
                  let qs = '';
                  if (window.localStorage.getItem('qs') != null) {
                    qs = window.localStorage.getItem('qs');
                  }
                  history.push(`/assessment/genetics` + qs);
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '15px',
                    lineHeight: '18px',
                  }}
                >
                  {t('home.dnaAssessmentReport.summaryButtonText')}
                </Typography>
              </Button>
            </GridItem>
          )}
        </GridContainer>
      </ContentCard>
    </>
  );
}
