import React from 'react';
import DnaProviders from './DnaProviders';

import { useActions, useOvermindState } from 'overmind/index';

/**
 * Actual questionnaire page asking user about DNA providers to which user paid for DNA analysis.
 * Basically renders DnaProviders component and manages its state.
 */
export default function DnaProvidersPage() {
  const actions = useActions();
  const overmindState = useOvermindState();

  return (
    <DnaProviders
      onQuestionAnswered={(value) => {
        actions.providersAnswered(
          value.filter((v) => v.checked).map((v) => v.value)
        );
      }}
      selectedProviders={overmindState.metaData?.user_metadata?.dnaProviders}
    />
  );
}
