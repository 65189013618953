import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from 'components/atoms/Layout/Box';
import Slider from 'components/atoms/Inputs/Slider/Slider';
import SvgIcon from 'components/atoms/SvgIcon/SvgIcon';
import Typography from 'components/atoms/Typography/Typography';

export default function IllustratedSlider(props) {
  const selectedIx = props.selectedIllustrationIndex;

  const handleValueChange = (value) => {
    props.onValueChanged && props.onValueChanged(value);
  };

  return (
    <Box style={{ maxWidth: '700px' }}>
      {selectedIx != null && (
        <>
          <Box
            justifyContent="center"
            display="flex"
            style={{ marginBottom: '14px' }}
          >
            {props.illustrations && props.hideIllustration !== true ? (
              <SvgIcon
                id={`${props.id}-icon`}
                height={
                  props.illustrations.length > 1
                    ? props.illustrations[selectedIx].height
                    : null
                }
                width={props.illustrations[selectedIx].width}
                icon={props.illustrations[selectedIx].illustration}
              />
            ) : null}
          </Box>
          <Box
            justifyContent="center"
            display="flex"
            style={{ marginBottom: '7px' }}
          >
            {props.illustrations ? (
              <Typography
                id={`${props.id}-text`}
                style={{
                  fontFamily: 'Lato-Regular',
                  fontSize: '22px',
                  textAlign: 'center',
                }}
              >
                {props.illustrations[selectedIx] &&
                  props.illustrations[selectedIx].text}
              </Typography>
            ) : null}
          </Box>
          <Box justifyContent="center" display="flex">
            <div
              style={{
                width: props.width
                  ? props.width
                  : window.innerWidth > 800
                  ? '580px'
                  : '300px',
              }}
            >
              <Slider
                id={`${props.id}-slider`}
                valueLabelDisplay={'off'}
                onValueChanged={(value) => handleValueChange(value)}
                selectedIndex={selectedIx}
                theme={props.theme}
                step={props.step ? props.step : undefined}
                valueFactor={props.valueFactor ? props.valueFactor : undefined}
                marksValues={props.values ? props.values : undefined}
                min={props.min}
                max={props.max}
                marks={props.marks}
              />
            </div>
          </Box>
        </>
      )}
    </Box>
  );
}

IllustratedSlider.propTypes = {
  uid: PropTypes.string.isRequired,
  selectedIllustrationIndex: PropTypes.number.isRequired,
  illustrations: PropTypes.arrayOf(
    PropTypes.shape({
      illustration: PropTypes.object,
      text: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    })
  ).isRequired,
};
