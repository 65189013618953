import React from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';

import {
  lifestyleSliderAlcoholIcon1,
  lifestyleSliderAlcoholIcon2,
  lifestyleSliderAlcoholIcon3,
  lifestyleSliderAlcoholIcon4,
  lifestyleSliderAlcoholIcon5,
} from 'assets/iconsList';

import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
import Question from 'components/organisms/Inputs/Question/Question';

import IllustratedSlider from 'components/molecules/Inputs/IllustratedSlider/IllustratedSlider';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

export default function LifestyleAlcoholPage(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const action = useActions();

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <GridItem
        style={{
          maxWidth: '700px',
          marginBottom: '20px',
        }}
      >
        <Question
          title={t('onboarding.lifestyleSection.step2.question2.title')}
          subtitle={t('onboarding.lifestyleSection.step2.question2.subtitle')}
          divider={false}
        >
          <IllustratedSlider
            id="illustrated-slider-stress"
            illustrations={[
              {
                illustration: lifestyleSliderAlcoholIcon1,
                height: 85,
                text: t('onboarding.lifestyleSection.step2.question2.slide1'),
              },
              {
                illustration: lifestyleSliderAlcoholIcon2,
                height: 85,
                text: t('onboarding.lifestyleSection.step2.question2.slide2'),
              },
              {
                illustration: lifestyleSliderAlcoholIcon3,
                height: 85,
                text: t('onboarding.lifestyleSection.step2.question2.slide3'),
              },
              {
                illustration: lifestyleSliderAlcoholIcon4,
                height: 85,
                text: t('onboarding.lifestyleSection.step2.question2.slide4'),
              },
              {
                illustration: lifestyleSliderAlcoholIcon5,
                height: 85,
                text: t('onboarding.lifestyleSection.step2.question2.slide5'),
              },
            ]}
            selectedIllustrationIndex={
              overmindState.blueprintProfile.profile.alcohol_level
                ? overmindState.blueprintProfile.profile.alcohol_level
                : 0
            }
            onValueChanged={(index) =>
              action.changeLifestyleAlcoholLevel(index)
            }
          />
        </Question>
      </GridItem>
    </GridContainer>
  );
}
