import React, { useEffect } from 'react';
import Box from 'components/atoms/Layout/Box/Box';
import { useIntroPageStyles } from './introPageStyles';
import IntroPageSlider from './IntroPageSlider';

import { ThemeProvider } from '@material-ui/core/styles';
import { introTheme } from 'themes';
import moment from 'moment';
import {
  trackEvent,
  trackFbPixelEvent,
} from 'components/helpers/userTrackingEventsHelper';
import { useOvermindState } from 'overmind/index';
import { useActions } from 'overmind/index';

export default function IntroPage() {
  const overmindState = useOvermindState();
  const actions = useActions();
  useIntroPageStyles();

  async function saveFirstSigningDate() {
    let currentDate = moment().format('YYYY-MM-DD');
    await actions.saveFirstSigningDate(currentDate);
    trackEvent('SignUp', 'Completed');
    trackFbPixelEvent('Lead');
  }

  useEffect(() => {
    if (
      !overmindState.metaData.user_metadata.blueprint_beauty_first_signing_date
    ) {
      saveFirstSigningDate();

      actions.fetchUserEmail();
    }
  }, []);

  return (
    <Box
      style={{
        backgroundImage: 'radial-gradient(ellipse 80% 450px, #9CAFAD, #40635F)',
        height: '100%',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <ThemeProvider theme={introTheme}>
        <Box flexDirection="column" alignItems="center">
          <IntroPageSlider />
        </Box>
      </ThemeProvider>
    </Box>
  );
}
