import React from 'react';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import HasDnaQuestion from 'components/organisms/Questions/DNA/HasDnaQuestion';

import { usePageStyles } from 'components/pages/commonStyles';
import { useTranslation } from 'react-i18next';

/**
 * Questionnaire page asking user if user has DNA.
 * Exists for presentation in StoryBook. Actual page implemented as HasDnaPage component.
 */

export default function HasDna(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();

  return (
    <GridContainer
      direction="column"
      className={classes.container}
      style={{ padding: '30px 12px' }}
    >
      <GridItem>
        <HasDnaQuestion
          title={t('onboarding.dnaSection.step1.title')}
          subtitle={t('onboarding.dnaSection.step1.subtitle')}
          selectedQuestion={props.selectedWantsToUseValue}
          selectedDnaOption={props.selectedDnaOptionValue}
          onQuestionAnswered={(val) => {
            if (props.onWantsToUseDna) {
              props.onWantsToUseDna(val);
            }
          }}
          onDnaOptionQuestionAnswered={(val) => {
            if (props.onDnaOption) {
              props.onDnaOption(val);
            }
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
