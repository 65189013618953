import { getStepIndex } from 'components/helpers/onboardingHelper';
import { lbsToKg } from 'components/helpers/converterHelper';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

const { REACT_APP_PDF_REPORT_CODE } = process.env;

export const initializeErrorsState = ({ state, effects, actions }) => {
  state.errors.errorGettingUserMetadata = '';
  state.errors.errorGettingProfile = '';
  state.errors.errorGettingProfilePregnancy = '';
  state.errors.errorGettingNutritionPreferences = '';
  state.errors.errorGettingAssessment = '';
  state.errors.errorGettingRecipeActions = '';
  state.errors.errorGettingWeeklyCheckin = '';
  state.errors.errorGettingWeather = '';
  state.errors.errorSettingWeeklyCheckin = '';
  state.errors.errorDeletingGenotype = '';
  state.errors.errorDeletingProfile = '';
  state.errors.errorDeletingUserMetadata = '';
  state.errors.errorDeletingRecipeActions = '';
  state.errors.errorSavingUserMetadata = '';
  state.errors.errorSavingProfile = '';
  state.errors.errorSavingProfilePregnancy = '';
  state.errors.errorSavingNutritionPreferences = '';
  state.errors.errorSavingGenotype = '';
  state.errors.errorSavingRecipeActions = '';
  state.errors.errorFetchingSubscriptionStatus = '';
  state.errors.errorSettingSubscriptionStatus = '';
  state.errors.errorSavingProfileLifestyle = '';
  state.errors.errorGettingRegime = '';
  state.errors.errorSavingBlueprintProfile = '';
};

export const onInitializeOvermindState = async (
  { state, effects, actions },
  options
) => {
  actions.initializeErrorsState();
  await effects.api
    .getProfile()
    .then((response) => {
      state.profile = response;
      return effects.apiNutrition.getNutritionPreferences();
    })
    .then((response) => {
      state.profileNutrition = response;
    })
    .catch(() => {
      state.isLoadingData = false;
    });
  await effects.apiGenotype
    .getGenotype()
    .then((response) => {
      actions.setGenotype(response);
      actions.setErrorGettingGenotype('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingGenotype(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });

  if (options && options.fetchEmail) {
    await effects.apiAuthentication
      .getUserInfo()
      .then((response) => {
        if (response.email) {
          actions.setUserEmail(response.email);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          state.signInRequired = true;
        }
      });
  }

  await effects.api.getCurrentUserMetadata().then((response) => {
    state.metaData = response;
    state.hasFetchedMetaData = true;
  });
  state.isLoadingData = false;
};

export const initializeAssessmentData = async ({ state, effects, actions }) => {
  actions.fetchMealPlan();
  actions.fetchInitialReport();
};

export const setErrorGettingGenotype = ({ state, effects, actions }, value) => {
  state.errors.errorGettingGenotype = value;
};

export const setErrorGettingMealPlan = ({ state, effects, actions }, value) => {
  state.errors.errorGettingMealPlan = value;
};

export const setErrorGettingWeather = ({ state, effects, actions }, value) => {
  state.errors.errorGettingWeather = value;
};

// Assessment api calls
export const fetchMealPlan = async ({ state, effects, actions }) => {
  if (state.isMealPlanFetching) {
    return;
  }
  state.isMealPlanLoaded = false;
  state.isMealPlanFetching = true;
  state.mealPlan = {};
  state.similarRecipes = [];

  await effects.apiAssessment
    .getMealPlan()
    .then((response) => {
      state.isMealPlanFetching = false;
      state.mealPlan = response;
      state.isMealPlanLoaded = true;
      actions.setErrorGettingMealPlan('');
    })
    .catch((err) => {
      state.isMealPlanFetching = false;
      if (err.response) {
        actions.setErrorGettingMealPlan(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchInitialReport = async ({ state, effects, actions }) => {
  await effects.apiAssessment
    .getInitialReport()
    .then((response) => {
      state.initialReport = response;
      state.isInitialReportLoaded = true;
    })
    .catch(() => {
      state.initialReport = null;
      state.isInitialReportLoaded = true;
    });
};

export const fetchSimilarRecipe = async (
  { state, effects, actions },
  value
) => {
  await effects.apiAssessment
    .getSimilarRecipes(value.recipeId, value.servingSize)
    .then((response) => {
      state.similarRecipes[value.recipeId] = response;
    })
    .catch(() => {});
};

//end of Assessment Api calls

export const goNextIntroIndex = ({ state }) => {
  state.forwardNavigation = true;
  state.introPageIndex = state.introPageIndex + 1;
};

export const disableForwardButton = ({ state, effects, actions }) => {
  state.onboardingForwardEnabled = false;
};

export const enableForwardButton = ({ state, effects, actions }) => {
  state.onboardingForwardEnabled = true;
};

export const setEnableForward = ({ state, effects, actions }, value) => {
  state.onboardingForwardEnabled = value;
};

export const termsChecked = ({ state }, checked) => {
  state.metaData.user_metadata.terms_BPB = checked;
  state.onboardingForwardEnabled = checked;
};

export const researchAgreementChecked = ({ state }, value) => {
  state.metaData.user_metadata.research_agreement_BPB = value === '1';
  state.onboardingForwardEnabled = true;
};

export const nameChanged = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.firstName = value;
  state.profile.body_type = 'average';
};

export const ageChanged = ({ state }, value) => {
  state.profile.year_of_birth = value;
  state.profile.body_type = 'average';
};

export const genderChanged = ({ state }, value) => {
  state.profile.gender = value;
  state.onboardingForwardEnabled = true;
};

export const countryEthnicitySubmitted = ({ state }, value) => {
  state.metaData.user_metadata.country = value.country;
  state.blueprintProfile.profile.ethnicity = value.ethnicity;
  state.onboardingForwardEnabled = value.enableForward;
};

export const BMISubmitted = ({ state }, value) => {
  state.profile.weight =
    value.selectedWeightMetric === 1 ? lbsToKg(value.weight) : value.weight;
  state.profile.height = value.height;

  state.metaData.user_metadata.selectedWeightMetric =
    value.selectedWeightMetric;
  state.metaData.user_metadata.selectedHeightMetric =
    value.selectedHeightMetric;

  if (value.selectedWeightMetric === 1) {
    state.metaData.user_metadata.weightLbs = value.weight;
  } else {
    state.metaData.user_metadata.weightLbs = null;
  }

  if (value.selectedHeightMetric === 1) {
    state.metaData.user_metadata.heightFt = value.heightFt;
    state.metaData.user_metadata.heightIn = value.heightIn;
  }

  if (value.selectedHeightMetric === 2) {
    state.metaData.user_metadata.heightFt = null;
    state.metaData.user_metadata.heightIn = null;
  }

  state.onboardingForwardEnabled = value.enableForward;
};

export const changeExerciseLevel = ({ state }, index) => {
  state.blueprintProfile.profile.exercise_level = index;
};

export const changeLifestyleStressLevelIndex = ({ state }, index) => {
  state.blueprintProfile.profile.stress_level = index;
};

export const changeLifestyleSleepLevel = ({ state }, index) => {
  state.blueprintProfile.profile.sleep_level = index;
};

export const changeLifestyleSmokingLevel = ({ state }, index) => {
  state.blueprintProfile.profile.smoking_level = index;
};

export const changeLifestyleAlcoholLevel = ({ state }, index) => {
  state.blueprintProfile.profile.alcohol_level = index;
};

export const changeLifestyleHydrationLevel = ({ state }, index) => {
  state.blueprintProfile.profile.water_level = index;
};

export const changeLifestyleVegetablesLevel = ({ state }, index) => {
  state.profileLifestyle.vegetables = index;
};

export const checkNutritionPreferencesAnswered = ({
  state,
  effects,
  actions,
}) => {
  if (state.metaData.user_metadata.nutrition_no_preference != null) {
    return true;
  }
};

export const checkNutritionAllergiesAnswered = (
  { state, effects, actions },
  value
) => {
  let nutritionAllergies = [];
  if (value != null) {
    Object.keys(value).forEach((nutritionValue) => {
      if (value[nutritionValue] === true) {
        nutritionAllergies.push(value[nutritionValue]);
      }
    });
  } else if (state.profileNutrition != null) {
    if (
      state.profileNutrition.dairy_free === true ||
      state.profileNutrition.egg_free === true ||
      state.profileNutrition.gluten_free === true ||
      state.profileNutrition.nut_free === true ||
      state.profileNutrition.shellfish_free === true ||
      state.profileNutrition.soy_free === true ||
      state.profileNutrition.sugar_free === true ||
      state.profileNutrition.peanut_free === true ||
      state.profileNutrition.sesame_free === true
    ) {
      nutritionAllergies.push('true');
    }
  }

  if (state.metaData.user_metadata.nutrition_no_allergies != null) {
    if (state.metaData.user_metadata.nutrition_no_allergies === true) {
      return true;
    } else if (
      state.metaData.user_metadata.nutrition_no_allergies === false &&
      nutritionAllergies.length > 0
    ) {
      return true;
    }
  } else if (nutritionAllergies.length > 0) {
    return true;
  }
};

export const nutritionPreferencesAnswered = (
  { state, effects, actions },
  value
) => {
  if (value.no_preference != null) {
    if (value.no_preference) {
      state.metaData.user_metadata.nutrition_no_preference = true;
    } else {
      state.metaData.user_metadata.nutrition_no_preference = false;
    }
  }
  state.profileNutrition = { ...state.profileNutrition, ...value };
  state.onboardingForwardEnabled = actions.checkNutritionPreferencesAnswered();
};

export const nutritionAllergiesAnswered = (
  { state, effects, actions },
  value
) => {
  if (value.no_allergies != null) {
    if (value.no_allergies) {
      state.metaData.user_metadata.nutrition_no_allergies = true;
    } else {
      state.metaData.user_metadata.nutrition_no_allergies = false;
    }
  }
  state.profileNutrition = { ...state.profileNutrition, ...value };
  state.onboardingForwardEnabled =
    actions.checkNutritionAllergiesAnswered(value);
};

export const goToStep = ({ state, effects, actions }, value) => {
  state.progressStep < value
    ? (state.forwardNavigation = true)
    : (state.forwardNavigation = false);
  state.progressStep = value;
};

export const goBack = ({ state, effects, actions }) => {
  state.forwardNavigation = false;
  state.onboardingForwardEnabled = true;

  if (state.progressStep === getStepIndex('onboarding_finished')) {
    state.progressStep = state.progressStep - 4;
  } else if (state.progressStep === getStepIndex('kit_number_input_page')) {
    state.progressStep = state.progressStep - 4;
  } else if (state.progressStep === getStepIndex('kit_not_received_page')) {
    state.progressStep = state.progressStep - 5;
  } else {
    state.progressStep = state.progressStep - 1;
  }

  actions.initializeErrorsState();

  if (state.progressStep < 0) {
    state.progressStep = 0;
  }
};

export const goNext = async ({ state, effects, actions }) => {
  let step = 1;

  state.forwardNavigation = true;
  state.onboardingForwardEnabled = false;

  if (state.progressStep === getStepIndex('landing_page')) {
    // submit intro screen seen
    state.metaData.user_metadata.seen_intro_9moons = true;

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    if (state.metaData.user_metadata.terms_BPB === true) {
      state.onboardingForwardEnabled = true;
    }
  } else if (state.progressStep === getStepIndex('terms_of_service')) {
    trackEvent('Onboarding', 'Terms Accepted');
    // submit ToS accepted
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    if (state.metaData.user_metadata.research_agreement_BPB != null) {
      state.onboardingForwardEnabled = true;
    }
  } else if (state.progressStep === getStepIndex('research_agreement_page')) {
    trackEvent('Onboarding', 'Research Agreement Passed');
    // submit Research agreement answer
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = true;
  } else if (state.progressStep === getStepIndex('general_intro')) {
    trackEvent('Onboarding', 'General Intro Passed');
    // submit general splash screen seen
    state.metaData.user_metadata.seen_intro_general = true;
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
  } else if (state.progressStep === getStepIndex('name_page')) {
    trackEvent('Onboarding', 'Name Passed');
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = state.profile.year_of_birth != null;
    state.lastSectionVisited = 1;

    actions.saveEmail({
      email_address: state.email,
      first_name: state.metaData.user_metadata.firstName,
      tags: [
        {
          name: 'NO DNA',
          status: 'active',
        },
      ],
    });
  } else if (state.progressStep === getStepIndex('age_page')) {
    trackEvent('Onboarding', 'Age Passed');
    effects.api
      .putProfile(state.profile)
      .then(() => {
        actions.setErrorSavingProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = state.profile.gender != null;
    state.lastSectionVisited = 1;
  } else if (state.progressStep === getStepIndex('biological_sex_page')) {
    trackEvent('Onboarding', 'BioSex Passed');
    effects.api
      .putProfile(state.profile)
      .then(() => {
        actions.setErrorSavingProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled =
      state.blueprintProfile.profile.zip_code != null &&
      state.blueprintProfile.profile.zip_code !== '';
    state.lastSectionVisited = 1;
  } else if (state.progressStep === getStepIndex('zip_code_page')) {
    trackEvent('Onboarding', 'ZIP Code Passed');
    actions.setErrorSavingBlueprintProfile('');
    await effects.apiBlueprintProfile
      .putBlueprintProfile(state.blueprintProfile.profile)
      .then(() => {
        actions.setErrorSavingBlueprintProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingBlueprintProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else {
          actions.setErrorSavingBlueprintProfile('error');
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 1;
  } else if (state.progressStep === getStepIndex('ethnicity_country_page')) {
    trackEvent('Onboarding', 'Ethnicity and Country Passed');
    effects.apiBlueprintProfile
      .putBlueprintProfile(state.blueprintProfile.profile)
      .then(() => {
        actions.setErrorSavingBlueprintProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingBlueprintProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else {
          actions.setErrorSavingBlueprintProfile('error');
        }
      });
    state.lastSectionVisited = 1;
  } else if (state.progressStep === getStepIndex('bmi_page')) {
    trackEvent('Onboarding', 'BMI Passed');
    // submit weight and height screen seen and submit weight and height
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    effects.api
      .putProfile(state.profile)
      .then(() => {
        actions.setErrorSavingProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.lastSectionVisited = 1;
    state.onboardingForwardEnabled = true;
  } else if (state.progressStep === getStepIndex('exercise_intro')) {
    trackEvent('Onboarding', 'Exercise Intro Passed');
    state.onboardingForwardEnabled = true;
  } else if (state.progressStep === getStepIndex('exercise_per_day')) {
    trackEvent('Onboarding', 'Exercise Daily Passed');
    // submit exercise per day
    if (state.blueprintProfile.profile.exercise_level == null) {
      state.blueprintProfile.profile.exercise_level = 0;
    }
    effects.apiBlueprintProfile
      .putBlueprintProfile(state.blueprintProfile.profile)
      .then(() => {
        actions.setErrorSavingBlueprintProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingBlueprintProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else {
          actions.setErrorSavingBlueprintProfile('error');
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 3;
  } else if (state.progressStep === getStepIndex('lifestyle_intro')) {
    trackEvent('Onboarding', 'Lifestyle Intro Passed');
    state.onboardingForwardEnabled = true;
  } else if (state.progressStep === getStepIndex('lifestyle_sleep')) {
    // submit sleep and stress
    if (state.blueprintProfile.profile.sleep_level == null) {
      state.blueprintProfile.profile.sleep_level = 0;
    }

    effects.apiBlueprintProfile
      .putBlueprintProfile(state.blueprintProfile.profile)
      .then(() => {
        actions.setErrorSavingBlueprintProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingBlueprintProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else {
          actions.setErrorSavingBlueprintProfile('error');
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 4;
  } else if (state.progressStep === getStepIndex('lifestyle_stress')) {
    trackEvent('Onboarding', 'Lifestyle Stress Passed');
    if (state.blueprintProfile.profile.stress_level == null) {
      state.blueprintProfile.profile.stress_level = 0;
    }

    effects.apiBlueprintProfile
      .putBlueprintProfile(state.blueprintProfile.profile)
      .then(() => {
        actions.setErrorSavingBlueprintProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingBlueprintProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else {
          actions.setErrorSavingBlueprintProfile('error');
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 4;
  } else if (state.progressStep === getStepIndex('lifestyle_smoking')) {
    trackEvent('Onboarding', 'Lifestyle Smoking Passed');
    if (state.blueprintProfile.profile.smoking_level == null) {
      state.blueprintProfile.profile.smoking_level = 0;
    }

    effects.apiBlueprintProfile
      .putBlueprintProfile(state.blueprintProfile.profile)
      .then(() => {
        actions.setErrorSavingBlueprintProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingBlueprintProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else {
          actions.setErrorSavingBlueprintProfile('error');
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 4;
  } else if (state.progressStep === getStepIndex('lifestyle_alcohol')) {
    trackEvent('Onboarding', 'Lifestyle Alcohol Passed');
    if (state.blueprintProfile.profile.alcohol_level == null) {
      state.blueprintProfile.profile.alcohol_level = 0;
    }

    effects.apiBlueprintProfile
      .putBlueprintProfile(state.blueprintProfile.profile)
      .then(() => {
        actions.setErrorSavingBlueprintProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingBlueprintProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else {
          actions.setErrorSavingBlueprintProfile('error');
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 4;
  } else if (state.progressStep === getStepIndex('lifestyle_hydration')) {
    trackEvent('Onboarding', 'Lifestyle Hydration Passed');
    if (state.blueprintProfile.profile.water_level == null) {
      state.blueprintProfile.profile.water_level = 0;
    }

    effects.apiBlueprintProfile
      .putBlueprintProfile(state.blueprintProfile.profile)
      .then(() => {
        actions.setErrorSavingBlueprintProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingBlueprintProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else {
          actions.setErrorSavingBlueprintProfile('error');
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 4;
  } else if (state.progressStep === getStepIndex('food_intro')) {
    trackEvent('Onboarding', 'Food Intro Passed');
    state.metaData.user_metadata.seen_intro_food = true;
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled =
      actions.checkNutritionPreferencesAnswered();
  } else if (state.progressStep === getStepIndex('dietary_preferences_page')) {
    trackEvent('Onboarding', 'Food Preferences Passed');
    // submit nutrition preferences
    effects.apiNutrition
      .patchNutritionPreferences(state.profileNutrition)
      .then(() => {
        actions.setErrorSavingNutritionPreferences('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingNutritionPreferences(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = actions.checkNutritionAllergiesAnswered();
    state.lastSectionVisited = 5;
  } else if (state.progressStep === getStepIndex('food_allergies_page')) {
    trackEvent('Onboarding', 'Food Allergies Passed');
    // submit food allergies
    effects.apiNutrition
      .patchNutritionPreferences(state.profileNutrition)
      .then(() => {
        actions.setErrorSavingNutritionPreferences('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });

    /*if (
      state.genotype &&
      state.genotype.status != null &&
      state.genotype.status !== 'genotype-data-invalid' &&
      state.genotype.status !== 'datasource-invalid'
    ) {
      effects.api
        .putCurrentUserMetadata(state.metaData)
        .then(() => {
          actions.setErrorSavingUserMetadata('');
        })
        .catch((err) => {
          if (err.response) {
            actions.setErrorSavingUserMetadata(
              `${err.response.status}: ${err.response.data.detail}`
            );
          }
        });
      state.previouslyHadDna = true;
    } else if (state.metaData.user_metadata.app_ready_to_use_page_seen) {
      step = 2;

      state.metaData.user_metadata.wantsToUseDna != null
        ? (state.onboardingForwardEnabled = true)
        : (state.onboardingForwardEnabled = false);
    }*/

    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 5;
  } else if (state.progressStep === getStepIndex('food_consumption')) {
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 5;
  } else if (
    state.progressStep === getStepIndex('environment_intro') ||
    state.progressStep === getStepIndex('environment_where_do_you_live') ||
    state.progressStep === getStepIndex('environment_sun_exposure') ||
    state.progressStep === getStepIndex('environment_spf_use') ||
    state.progressStep ===
      getStepIndex('environment_space_with_air_conditioner') ||
    state.progressStep === getStepIndex('environment_devices_with_screens')
  ) {
    if (state.progressStep === getStepIndex('environment_intro')) {
      trackEvent('Onboarding', 'Environment Intro Passed');
    } else if (
      state.progressStep === getStepIndex('environment_where_do_you_live')
    ) {
      trackEvent('Onboarding', 'Environment Where Do You Live Passed');
    } else if (
      state.progressStep === getStepIndex('environment_sun_exposure')
    ) {
      trackEvent('Onboarding', 'Environment Sun Exposure Passed');
    } else if (state.progressStep === getStepIndex('environment_spf_use')) {
      trackEvent('Onboarding', 'Environment SPF Passed');
    } else if (
      state.progressStep ===
      getStepIndex('environment_space_with_air_conditioner')
    ) {
      trackEvent('Onboarding', 'Environment Air Condition Passed');
    } else if (
      state.progressStep === getStepIndex('environment_devices_with_screens')
    ) {
      trackEvent('Onboarding', 'Environment Devices With Screen Passed');
    }

    effects.apiBlueprintProfile
      .putBlueprintProfile(state.blueprintProfile.profile)
      .then(() => {
        actions.setErrorSavingBlueprintProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingBlueprintProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else {
          actions.setErrorSavingBlueprintProfile('error');
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 7;
    if (
      state.metaData.user_metadata.completed_onboarding_BPB &&
      state.progressStep === getStepIndex('environment_devices_with_screens')
    ) {
      step = 1000;
    }
  } else if (state.progressStep === getStepIndex('skin_intro')) {
    trackEvent('Onboarding', 'Skin Intro Passed');
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 6;
  } else if (
    state.progressStep === getStepIndex('skin_type') ||
    state.progressStep === getStepIndex('skin_goals') ||
    state.progressStep === getStepIndex('skin_eye_are_concerns')
  ) {
    if (state.progressStep === getStepIndex('skin_type')) {
      trackEvent('Onboarding', 'Skin Type Passed');
    } else if (state.progressStep === getStepIndex('skin_goals')) {
      trackEvent('Onboarding', 'Skin Goals Passed');
    } else if (state.progressStep === getStepIndex('skin_eye_are_concerns')) {
      trackEvent('Onboarding', 'Skin Eye Area Concerns Passed');
    }

    effects.apiBlueprintProfile
      .putBlueprintProfile(state.blueprintProfile.profile)
      .then(() => {
        actions.setErrorSavingBlueprintProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingBlueprintProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else {
          actions.setErrorSavingBlueprintProfile('error');
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 6;
  } else if (state.progressStep === getStepIndex('ready_to_use_the_app_page')) {
    state.metaData.user_metadata.app_ready_to_use_page_seen = true;
    state.metaData.user_metadata.completed_onboarding_BPB = true;
    if (state.metaData.user_metadata.dnaKitReceived != null) {
      step = 1000;
    }

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = true;
  } else if (state.progressStep === getStepIndex('our_genomics_intro')) {
    trackEvent('Onboarding', 'DNA Intro Passed');
    state.hideBackButton = false;

    state.metaData.user_metadata.wantsToUseDna != null
      ? (state.onboardingForwardEnabled = true)
      : (state.onboardingForwardEnabled = false);
  } else if (state.progressStep === getStepIndex('has_dna_page')) {
    trackEvent('Onboarding', 'Has DNA Passed');
    if (state.metaData.user_metadata.dnaUsageOption !== null) {
      if (state.metaData.user_metadata.dnaUsageOption === '1') {
        trackEvent('Onboarding', 'Has DNA File');
      } else if (state.metaData.user_metadata.dnaUsageOption === '2') {
        trackEvent('Onboarding', 'Has DNA Kit');
      }
    }

    if (state.metaData.user_metadata.dnaUsageOption === '2') {
      step = 4;
    }

    if (state.metaData.user_metadata.wantsToUseDna === '2') {
      step = 5;
      state.onboardingForwardEnabled = true;
    }

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
  } else if (state.progressStep === getStepIndex('dna_providers_page')) {
    if (state.metaData.user_metadata.dnaProviders) {
      state.onboardingForwardEnabled = true;
    } else {
      state.onboardingForwardEnabled = false;
    }

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
  } else if (state.progressStep === getStepIndex('dna_explanation_page')) {
    if (state.metaData.user_metadata.dnaProviders) {
      state.onboardingForwardEnabled = true;
    } else {
      state.onboardingForwardEnabled = false;
    }
  } else if (state.progressStep === getStepIndex('dna_file_upload_page')) {
    trackEvent('Onboarding', 'DNA File Upload Passed');
    state.hideBackButton = true;
    state.metaData.user_metadata.completed_kitregistration_BPB = true;

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    step = 3;
    state.onboardingForwardEnabled = true;
    // window.location = '/assessment';
  } else if (state.progressStep === getStepIndex('kit_number_input_page')) {
    trackEvent('Onboarding', 'DNA Kit Input Passed');
    state.hideBackButton = true;
    actions.dnaKitReceived(true);
    state.metaData.user_metadata.completed_kitregistration_BPB = true;

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    step = 2;
    state.onboardingForwardEnabled = true;
    // window.location = '/assessment';
  } else if (state.progressStep === getStepIndex('kit_not_received_page')) {
    trackEvent('Onboarding', 'No DNA File Passed');
    actions.dnaKitReceived(false);
    state.metaData.user_metadata.completed_kitregistration_BPB = true;

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });

    let qs = '';
    if (window.localStorage.getItem('qs') != null) {
      qs = window.localStorage.getItem('qs');
    }

    if (state.metaData.user_metadata.terms_BPB) {
      window.location = '/assessment' + qs;
    } else {
      window.location = '/onboarding' + qs;
    }
    return;
  } else if (state.progressStep === getStepIndex('onboarding_finished')) {
    if (state.metaData.user_metadata.app_ready_to_use_page_seen !== true) {
      state.onboardingForwardEnabled = false;
      state.progressStep = 0;
      return;
    }

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
        let qs = '';
        if (window.localStorage.getItem('qs') != null) {
          qs = window.localStorage.getItem('qs');
        }

        window.location = '/assessment' + qs;
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
  }

  if (
    !state.errors.errorSavingUserMetadata &&
    !state.errors.errorSavingProfile &&
    !state.errors.errorSavingGenotype &&
    !state.errors.errorSavingNutritionPreferences &&
    !state.errors.errorSavingProfileLifestyle &&
    !state.errors.errorSavingBlueprintProfile
  ) {
    state.progressStep = state.progressStep + step;
  }
};

export const setUserSeenWelcomeMessage = (
  { state, effects, actions },
  value
) => {
  state.metaData.user_metadata.seenWelcomeMessage = value;
  effects.api
    .putCurrentUserMetadata(state.metaData)
    .then(() => {
      actions.setErrorSavingUserMetadata('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSavingUserMetadata(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const setUserSeenCongratulationsMessage = (
  { state, effects, actions },
  value
) => {
  state.metaData.user_metadata.seenCongratulationsMessage = value;
  effects.api
    .putCurrentUserMetadata(state.metaData)
    .then(() => {
      actions.setErrorSavingUserMetadata('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSavingUserMetadata(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const setErrorSavingUserMetadata = (
  { state, effects, actions },
  value
) => {
  state.errors.errorSavingUserMetadata = value;
};
export const setErrorSavingProfile = ({ state, effects, actions }, value) => {
  state.errors.errorSavingProfile = value;
};
export const setErrorSavingBlueprintProfile = ({ state }, value) => {
  state.errors.errorSavingBlueprintProfile = value;
};
export const setErrorSavingGenotype = ({ state, effects, actions }, value) => {
  state.errors.errorSavingGenotype = value;
};
export const setErrorSavingNutritionPreferences = (
  { state, effects, actions },
  value
) => {
  state.errors.errorSavingNutritionPreferences = value;
};
export const setErrorSavingProfileLifestyle = (
  { state, effects, actions },
  value
) => {
  state.errors.errorSavingProfileLifestyle = value;
};
export const setErrorDeletingGenotype = (
  { state, effects, actions },
  value
) => {
  state.errors.errorDeletingGenotype = value;
};
export const setErrorDeletingProfile = ({ state, effects, actions }, value) => {
  state.errors.errorDeletingProfile = value;
};
export const setErrorDeletingUserMetadata = (
  { state, effects, actions },
  value
) => {
  state.errors.errorDeletingUserMetadata = value;
};

export const fetchProfile = async ({ state, effects, actions }) => {
  await effects.api
    .getProfile()
    .then((response) => {
      state.profile = response;
      actions.setErrorGettingProfile('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingProfile(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const wantsToUseDnaAnswered = ({ state, effects, actions }, value) => {
  if (value === '2') {
    state.metaData.user_metadata.dnaUsageOption = null;
  }
  state.metaData.user_metadata.wantsToUseDna = value;
  state.onboardingForwardEnabled =
    value === '2'
      ? true
      : !state.metaData.user_metadata.dnaUsageOption
      ? false
      : true;
};

export const dnaOptionAnswered = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.dnaUsageOption = value;
  state.onboardingForwardEnabled = true;
};

export const providersAnswered = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.dnaProviders = value;
  if (value.length === 0) {
    state.onboardingForwardEnabled = false;
  } else {
    state.onboardingForwardEnabled = true;
  }
};

export const dnaKitReceived = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.dnaKitReceived = value;
};

export const kitNumberSubmitCompleted = ({ state, effects, actions }) => {
  state.kitNumberSubmit.submitSuccessful = true;
  state.kitNumberSubmit.showSubmitStatus = true;
  state.onboardingForwardEnabled = true;
};

export const kitNumberSubmitFailed = ({ state, effects, actions }) => {
  state.kitNumberSubmit.submitSuccessful = false;
  state.kitNumberSubmit.showSubmitStatus = true;
  state.onboardingForwardEnabled = false;
  state.hideBackButton = false;
};

export const submitKitNumber = ({ state, effects, actions }, kitNumber) => {
  const params = {
    gxgimport: {
      gxg_kit_number: kitNumber,
    },
  };

  state.hideBackButton = true;

  effects.apiGenotype
    .deleteGenotype()
    .then(() => {
      actions.setErrorDeletingGenotype('');
      return effects.apiGenotype.postGenotype(params);
    })
    .then(() => {
      actions.setErrorSavingGenotype('');
      return effects.apiGenotype.getGenotype();
    })
    .then((genotype) => {
      actions.setGenotype(genotype);
      actions.setErrorSavingGenotype('');
      if (genotype.status != null) {
        actions.kitNumberSubmitCompleted();
        actions.fetchRegime();
        actions.fetchMealPlan();
        actions.fetchWeather();
      } else {
        actions.kitNumberSubmitFailed();
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.config.method === 'delete') {
          actions.setErrorDeletingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else if (err.response.config.method === 'post') {
          actions.setErrorSavingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else if (err.response.config.method === 'get') {
          actions.setErrorGettingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      }
    });
};

export const setGenotype = ({ state, effects, actions }, value) => {
  state.genotype = value;
  state.isGenotypeFetched = true;
};

export const fetchTraitAssessments = async ({ state, effects, actions }) => {
  await effects.apiProfileAssessment
    .getListTraitAssessment()
    .then((response) => {
      state.traits = response.traits;
    })
    .catch((error) => {
      // ToDo: handle error
    });
};

export const periodicallyCheckForPdfReport = async ({
  state,
  effects,
  actions,
}) => {
  actions.getReport();
  return setInterval(() => {
    if (!state.report?.download_url) {
      actions.getReport();
    }
  }, 6000);
};

export const periodicallyCheckForDnaTraits = async ({
  state,
  effects,
  actions,
}) => {
  actions.fetchTraitAssessments();
  return setInterval(() => {
    if (
      (!state.traits || state.traits.length === 0) &&
      !state.report?.download_url
    ) {
      actions.fetchTraitAssessments();
    }
  }, 6000);
};

export const fetchWeather = async ({ state, effects, actions }) => {
  await effects.apiWeather
    .getWeather()
    .then((response) => {
      state.isWeatherFetched = true;
      state.weather = response.daily;
      actions.setErrorGettingWeather('');
    })
    .catch((err) => {
      state.isWeatherFetched = false;
      actions.setErrorGettingWeather('error');
    });
};

export const generateReport = async ({ state, effects, actions }) => {
  await effects.apiReport
    .generateReport({
      report_code: REACT_APP_PDF_REPORT_CODE,
      language: 'en',
    })
    .then((response) => {
      if (
        response &&
        (Object.keys(response).length !== null ||
          response.find((r) => r.report_code === REACT_APP_PDF_REPORT_CODE))
      ) {
        state.report = response;
      }
    });
};

export const getReport = async ({ state, effects, actions }) => {
  await effects.apiReport
    .getReport()
    .then((response) => {
      if (
        state.genotype &&
        state.genotype.status != null &&
        response &&
        (Object.keys(response).length === 0 ||
          response.find((r) => r.report_code === REACT_APP_PDF_REPORT_CODE) ==
            null)
      ) {
        actions.generateReport();
      } else {
        state.report = response.find(
          (r) => r.report_code === REACT_APP_PDF_REPORT_CODE
        );
      }
    })
    .catch(() => {});
};

//Recipe actions
export const fetchRecipeDetails = async (
  { state, effects, actions },
  recipeId
) => {
  await effects.apiRecipeActions
    .getRecipeDetails(recipeId)
    .then((response) => {
      state.recipeDetails = response;
    })
    .catch(() => {});
};

export const addToShoppingList = async ({ state, effects, actions }, ids) => {
  const params = {
    recipe_db_id: ids.databaseId,
    action: 'add',
  };

  await effects.apiRecipeActions.postShoppingList(params).then(() => {
    actions.fetchRecipeDetails(ids.id);
  });
};

export const removeFromShoppingList = async (
  { state, effects, actions },
  recipeId
) => {
  const params = {
    recipe_id: recipeId,
    action: 'remove',
  };

  await effects.apiRecipeActions.postShoppingList(params).then(() => {
    actions.fetchRecipeDetails(recipeId);
  });
};

export const fetchShoppingList = async (
  { state, effects, actions },
  viewType
) => {
  await effects.apiRecipeActions
    .getShoppingList(viewType)
    .then((response) => {
      state.shoppingList = response;
    })
    .catch(() => {});
};

export const toggleShoppingListItem = async (
  { state, effects, actions },
  value
) => {
  state.shoppingList.forEach((item) => {
    if (item.id === value.itemId) {
      item.items[value.index].isDone = !item.items[value.index].isDone;
    }
  });
};

export const updateToShoppingListItem = async (
  { state, effects, actions },
  data
) => {
  const params = {
    item_id: data.value.databaseId,
    is_aggregate: data.displayMode === 'aisles',
  };

  await effects.apiRecipeActions
    .postShoppingListItem(params)
    .then((response) => {
      actions.toggleShoppingListItem(data.value);
    });
};

export const deleteCheckedShoppingListItems = async (
  { state, effects, actions },
  viewType
) => {
  await effects.apiRecipeActions
    .deleteShoppingListItems()
    .then((response) => {
      actions.fetchShoppingList(viewType);
    })
    .catch((err) => {});
};

export const editRecipeActions = async ({ state, effects, actions }, value) => {
  const recipeAction = value;
  const date = Object.keys(state.mealPlan);
  switch (recipeAction.code) {
    case 'favorite':
      const recipe_db_id = { recipe_db_id: recipeAction.recipe_db_id };
      await effects.apiRecipeFavorites
        .postRecipeFavorites(recipe_db_id)
        .then(() => {
          let found = false;
          date.forEach((day) => {
            state.mealPlan[day].meals.forEach((meal) => {
              if (recipeAction.recipe_id === meal.recipe.id) {
                meal.recipe.isUserFavorite = !meal.recipe.isUserFavorite;
                actions.setRecipeDetails(meal.recipe);
                actions.setRecipeMealType(meal);
                found = true;
                return;
              }
            });
          });
          if (found === false && state.similarRecipes) {
            for (let ix1 in state.similarRecipes) {
              for (let ix2 in state.similarRecipes[ix1]) {
                if (
                  state.similarRecipes[ix1][ix2].id === recipeAction.recipe_id
                ) {
                  state.similarRecipes[ix1][ix2].isUserFavorite =
                    !state.similarRecipes[ix1][ix2].isUserFavorite;
                }
              }
            }
          }
          return effects.apiRecipeActions.getFavoriteRecipes();
        })
        .then((response) => {
          state.favoriteRecipes = response;
        })
        .catch((err) => {
          if (err.response) {
            actions.setErrorSavingRecipeActions(
              `${err.response.status}: ${err.response.data.detail}`
            );
          }
        });
      break;
    case 'save':
      const recipe_id = { recipe_id: recipeAction.recipe_id };
      await effects.apiRecipeSaved
        .postRecipeSaved(recipe_id)
        .then(() => {
          let found = false;
          date.forEach((day) => {
            state.mealPlan[day].meals.forEach((meal) => {
              if (recipeAction.recipe_id === meal.recipe.id) {
                meal.recipe.isSaved = !meal.recipe.isSaved;
                actions.setRecipeDetails(meal.recipe);
                actions.setRecipeMealType(meal);
                found = true;
                return;
              }
            });
          });
          if (found === false && state.similarRecipes) {
            for (let ix1 in state.similarRecipes) {
              for (let ix2 in state.similarRecipes[ix1]) {
                if (
                  state.similarRecipes[ix1][ix2].id === recipeAction.recipe_id
                ) {
                  state.similarRecipes[ix1][ix2].isSaved =
                    !state.similarRecipes[ix1][ix2].isSaved;
                }
              }
            }
          }
          return effects.apiRecipeActions.getSavedForLaterRecipes();
        })
        .then((response) => {
          state.savedForLaterRecipes = response;
        })
        .catch((err) => {
          if (err.response) {
            actions.setErrorSavingRecipeActions(
              `${err.response.status}: ${err.response.data.detail}`
            );
          }
        });
      break;
    case 'log':
      const data = {
        recipe_id: recipeAction.recipe_id,
        meal_datetime: recipeAction.meal_datetime,
        num_of_servings: recipeAction.num_of_servings,
        meal_type: recipeAction.meal_type,
        meal_id: recipeAction.meal_id,
      };
      await effects.apiRecipeLogged
        .postRecipeLogged(data)
        .then((response) => {
          date.forEach((day) => {
            state.mealPlan[day].meals.forEach((meal) => {
              if (recipeAction.recipe_id === meal.recipe.id) {
                meal.recipe.isLogged = !meal.recipe.isLogged;
                actions.setRecipeDetails(meal.recipe);
                actions.setRecipeMealType(meal);
                return;
              }
            });
          });
        })
        .catch((err) => {
          if (err.response) {
            actions.setErrorSavingRecipeActions(
              `${err.response.status}: ${err.response.data.detail}`
            );
          }
        });
      break;
    default:
      return null;
  }
};

//End of recipe actions

export const fetchMetadata = async ({ state, effects }) => {
  await effects.api
    .getCurrentUserMetadata()
    .then((response) => {
      state.metaData = response;
      state.hasFetchedMetaData = true;
    })
    .catch(() => {
      state.hasFetchedMetaData = false;
    });
};

export const fetchBlueprintProfile = async ({ state, effects }) => {
  await effects.apiBlueprintProfile
    .getBlueprintProfile()
    .then((response) => {
      state.blueprintProfile.profile = response;
      state.blueprintProfile.loading = false;
    })
    .catch(() => {
      state.blueprintProfile.loading = false;
    });
};

export const updateBlueprintProfile = async ({ state, effects, actions }) => {
  effects.apiBlueprintProfile
    .putBlueprintProfile(state.blueprintProfile.profile)
    .then((response) => {
      actions.setErrorSavingBlueprintProfile('');
    })
    .catch((err) =>
      actions.setErrorSavingBlueprintProfile(
        `${err.response.status}: ${err.response.data.detail}`
      )
    );
};

export const setBlueprintProfileLoading = async ({ state }, value) => {
  state.blueprintProfile.loading = value;
};

export const setZipCode = async ({ state }, newZipCode) => {
  state.blueprintProfile.profile.zip_code = newZipCode;
};

export const setSkinType = async ({ state }, skinType) => {
  state.blueprintProfile.profile.skin_type = skinType;
};

export const setSkinConcerns = async ({ state }, concerns) => {
  /* concerns = {
    goal_reduce_dark_spots: true/false,
    goal_improve_hydration: true/false,
    goal_boost_radiance: true/false,
    goal_target_skin_aging: true/false,
    goal_diminish_wrinkles: true/false,
    goal_even_texture: true/false,
    goal_reduce_blemishes: true/false,
    goal_reduce_redness: true/false,
  };*/
  state.blueprintProfile.profile.goal_reduce_dark_spots =
    concerns.goal_reduce_dark_spots;
  state.blueprintProfile.profile.goal_improve_hydration =
    concerns.goal_improve_hydration;
  state.blueprintProfile.profile.goal_boost_radiance =
    concerns.goal_boost_radiance;
  state.blueprintProfile.profile.goal_target_skin_aging =
    concerns.goal_target_skin_aging;
  state.blueprintProfile.profile.goal_diminish_wrinkles =
    concerns.goal_diminish_wrinkles;
  state.blueprintProfile.profile.goal_even_texture = concerns.goal_even_texture;
  state.blueprintProfile.profile.goal_reduce_blemishes =
    concerns.goal_reduce_blemishes;
  state.blueprintProfile.profile.goal_reduce_redness =
    concerns.goal_reduce_redness;
};

export const setEyeConcerns = async ({ state }, concerns) => {
  /*concerns = {
    eye_concern_fine_lines: true/false,
    eye_concern_puffiness: true/false,
    eye_concern_dark_circles: true/false,
    eye_concern_fatigue: true/false,
    eye_concern_dry_skin: true/false,
    eye_concern_sagging_eyelids: true/false,
  };*/
  state.blueprintProfile.profile.eye_concern_fine_lines =
    concerns.eye_concern_fine_lines;
  state.blueprintProfile.profile.eye_concern_puffiness =
    concerns.eye_concern_puffiness;
  state.blueprintProfile.profile.eye_concern_dark_circles =
    concerns.eye_concern_dark_circles;
  state.blueprintProfile.profile.eye_concern_fatigue =
    concerns.eye_concern_fatigue;
  state.blueprintProfile.profile.eye_concern_dry_skin =
    concerns.eye_concern_dry_skin;
  state.blueprintProfile.profile.eye_concern_sagging_eyelids =
    concerns.eye_concern_sagging_eyelids;
};

export const setPlaceType = async ({ state }, placeType) => {
  state.blueprintProfile.profile.place_type = placeType;
};

export const setSunExposureLevel = async ({ state }, exposureLevel) => {
  state.blueprintProfile.profile.sun_exposure_level = exposureLevel;
};

export const setSpfUsageLevel = async ({ state }, spfUsageLevel) => {
  state.blueprintProfile.profile.spf_usage_level = spfUsageLevel;
};

export const setAirconUsageLevel = async ({ state }, airconUsageLevel) => {
  state.blueprintProfile.profile.aircon_usage_level = airconUsageLevel;
};

export const setBlueLightExposureLevel = async (
  { state },
  bluelightExposureLevel
) => {
  state.blueprintProfile.profile.blue_light_exposure_level =
    bluelightExposureLevel;
};

export const setRegime = ({ state }, response, error) => {
  state.errorGettingRegime = error;
  state.regime.data = response;
  state.regime.loading = false;
};

export const fetchRegime = async ({ state, effects, actions }) => {
  state.regime.loading = true;
  effects.apiRegime
    .getRegime()
    .then((response) => {
      actions.setRegime(response, null);
    })
    .catch(() => {
      actions.setRegime(null, 'error');
    });
};

export const fileUploadProgressChanged = (
  { state, effects, actions },
  value
) => {
  state.upload.progress = value;
};

export const fileUploadInitialize = ({ state, effects, actions }) => {
  state.upload.showUploadStatus = false;
  state.upload.progress = 0;
  state.upload.successful = null;
};

export const fileUploadCompleted = ({ state, effects, actions }) => {
  state.upload.showUploadStatus = true;
  state.upload.progress = 100;
  state.upload.successful = true;
  state.hideBackButton = true;
  state.onboardingForwardEnabled = true;
  state.isGenotypeFetched = true;
  trackEvent('DNA File', 'Uploaded');

  const emailSubmitBody = {
    email_address: state.email,
    first_name: state.metaData.user_metadata.firstName,
    tags: [
      {
        name: 'NO DNA',
        status: 'inactive',
      },
      {
        name: 'DNA File',
        status: 'active',
      },
    ],
  };
  if (!emailSubmitBody.email) {
    effects.apiAuthentication.getUserInfo().then((response) => {
      if (response.email) {
        actions.setUserEmail(response.email);
        emailSubmitBody.email_address = response.email;
        actions.updateEmail(emailSubmitBody);
      }
    });
  } else {
    actions.updateEmail(emailSubmitBody);
  }

  actions.fetchRegime();
  actions.fetchMealPlan();
  actions.fetchWeather();
  actions.fetchProfile();
  actions.fetchMetadata();
  let timer = setTimeout(async () => {
    try {
      actions.fetchTraitAssessments();
      actions.getReport();
    } catch (e) {
      console.log(e);
    }
  }, 6000);
  return () => clearTimeout(timer);
};

export const fileUploadFailed = ({ state, effects, actions }) => {
  state.upload.showUploadStatus = true;
  state.upload.progress = 100;
  state.upload.successful = false;
};

export const uploadFile = ({ state, effects, actions }, file) => {
  const params = {
    fileupload: {
      content_type: 'application/octet-stream',
      method: 'PUT',
      original_filename: file.name,
    },
  };

  state.hideBackButton = true;

  effects.apiGenotype
    .deleteGenotype()
    .then(() => {
      actions.setErrorDeletingGenotype('');
      return effects.apiGenotype.postGenotype(params);
    })
    .then(() => {
      actions.setErrorSavingGenotype('');
      return effects.apiGenotype.getGenotype();
    })
    .then((genotype) => {
      actions.setErrorSavingGenotype('');
      const uploadUrl = genotype.source_details.upload_url;

      const xhr = new XMLHttpRequest();
      xhr.open('PUT', uploadUrl, true);

      xhr.upload.onprogress = (ev) => {
        actions.fileUploadProgressChanged((ev.loaded * 100) / ev.total);
      };

      xhr.onload = () => {
        const status = xhr.status;
        if (status === 200) {
          actions.setGenotype(genotype);
          actions.fileUploadCompleted();
        }
      };

      xhr.onerror = (err) => {
        actions.fileUploadFailed();
      };
      xhr.setRequestHeader('Content-Type', 'application/octet-stream');
      xhr.send(file);
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.config.method === 'delete') {
          actions.setErrorDeletingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else if (err.response.config.method === 'post') {
          actions.setErrorSavingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else if (err.response.config.method === 'get') {
          actions.setErrorGettingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      }
    });
};

export const setComponentForModalWindow = (
  { state, effects, actions },
  value
) => {
  state.componentForModalWindow = value;
};

export const deleteUserData = async ({ state, effects, actions }) => {
  await effects.apiGenotype
    .deleteGenotype()
    .then(() => {
      actions.setErrorDeletingGenotype('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorDeletingGenotype(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
  await effects.api
    .deleteProfile()
    .then(() => {
      actions.setErrorDeletingProfile('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorDeletingProfile(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
  await effects.api
    .deleteCurrentUserMetadata()
    .then(() => {
      actions.setErrorDeletingUserMetadata('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorDeletingUserMetadata(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const changeZipCodeSaved = ({ state }, value) => {
  state.metaData.user_metadata.zipCodeSaved = value;
};

export const saveChanges = ({ state, effects, actions }, value) => {
  if (value === 'nutrition') {
    effects.apiNutrition
      .patchNutritionPreferences(state.profileNutrition)
      .then(() => {
        actions.setErrorSavingNutritionPreferences('');
        actions.fetchMealPlan();
        actions.fetchNutrientsReport();
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingNutritionPreferences(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
  } else if (
    value === 'skin' ||
    value === 'general' ||
    value === 'environment'
  ) {
    effects.apiBlueprintProfile
      .patchBlueprintProfile(state.blueprintProfile.profile)
      .then((response) => {
        actions.changeZipCodeSaved(true);
        actions.setErrorSavingBlueprintProfile('');
        actions.fetchBlueprintProfile();
        actions.fetchRegime();
        actions.fetchMealPlan();
        actions.fetchWeather();
      })
      .catch((err) => {
        actions.changeZipCodeSaved(false);
        actions.setErrorSavingBlueprintProfile(
          `${err.response.status}: ${err.response.data.detail}`
        );
      });
  }
};

export const getAuthencationTokensAndMetaData = async (
  { effects, state, actions },
  data
) => {
  state.hasFetchedMetaData = false;
  effects.apiAuthentication.checkAuthorizationCode(data).then((response) => {
    if (response) {
      window.localStorage.setItem('access_token', response.access_token);
      actions.onInitializeOvermindState({ fetchEmail: true });
    }
  });
};

export const saveFirstSigningDate = async (
  { state, effects, actions },
  signingDate
) => {
  state.metaData.user_metadata.blueprint_beauty_first_signing_date =
    signingDate;
  effects.api
    .putCurrentUserMetadata(state.metaData)
    .then(() => {
      actions.setErrorSavingUserMetadata('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSavingUserMetadata(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const setUserEmail = async ({ state }, email) => {
  state.email = email;
};

export const fetchUserEmail = async ({ effects, actions }) => {
  await effects.apiAuthentication.getUserInfo().then((response) => {
    if (response.email) {
      actions.setUserEmail(response.email);
    }
  });
};

export const saveEmail = async ({ state, effects }, value) => {
  await effects.apiSaveEmail
    .postEmail(value)
    .then(() => {
      state.savedEmailToMailchimp = true;
    })
    .catch(() => {});
};

export const updateEmail = async ({ state, effects }, value) => {
  await effects.apiUpdateEmail
    .postEmail(value)
    .then(() => {})
    .catch(() => {});
};
