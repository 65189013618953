import React from 'react';

import Typography from 'components/atoms/Typography/Typography';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import ListContent from 'components/molecules/Content/Accordion/ListContent';

export default function Instructions(props) {
  return (
    <GridContainer>
      <GridItem xs={12}>
        {props.data && props.data.recipeUrl != null && (
          <GridItem xs={12} style={{ margin: '20px 0' }}>
            <Typography
              variant="body1"
              style={{ textAlign: 'left', margin: '0 5%' }}
            >
              <a href={props.data.recipeUrl} target="_blank" rel="noreferrer">
                {props.data.displayName}
              </a>
            </Typography>
          </GridItem>
        )}
        {props.data.recipeUrl == null && props.data.length > 0 && (
          <ListContent data={props.data} />
        )}
      </GridItem>
    </GridContainer>
  );
}
