import { formatDate } from 'components/helpers/dateHelper';

export const getProductsForDay = (day, regime) => {
  const today = formatDate(day, 'YYYY-MM-DD');
  if (regime == null || regime.data == null || regime.data.days == null) {
    return [];
  }

  const allRecommendationsForToday = regime.data.days.filter(
    (day) => day.ts === today
  );
  if (
    allRecommendationsForToday == null ||
    allRecommendationsForToday.length === 0
  ) {
    return [];
  }

  const recommendationForToday = allRecommendationsForToday[0];
  if (
    recommendationForToday.routine == null ||
    recommendationForToday.routine.length === 0
  ) {
    return [];
  }

  const productsCategories = {};
  ['morning', 'afternoon', 'evening'].forEach((period) => {
    recommendationForToday.routine[period].forEach((productCategory) => {
      productsCategories[productCategory] = true;
    });
  });

  const products = [];
  Object.keys(productsCategories).forEach((category) => {
    if (regime.data.products[category] == null) {
      return;
    }

    regime.data.products[category].forEach((product) => {
      products.push(product);
    });
  });

  return products;
};
