import React from 'react';

import SelectableIconGroup from 'components/organisms/Inputs/SelectableIconGroup/SelectableIconGroup';
import Question from 'components/organisms/Inputs/Question/Question';

import Box from 'components/atoms/Layout/Box/Box';

export default function BiologicalSexQuestion(props) {
  return (
    <Question title={props.title} caption={props.caption}>
      <Box justifyContent="center" display="flex">
        <SelectableIconGroup
          columnNumber={2}
          width={240}
          isRadio={true}
          rowSpacing={8}
          name={'biologicalsex'}
          items={
            props.items &&
            props.items.map((i) => {
              return {
                uid: i.uid,
                label: i.label,
                value: i.id,
                icon: i.icon,
                checkedIcon: i.icon,
                labelFontSize: 14,
                checked: i.checked,
              };
            })
          }
          onChange={(selectedValues) => {
            props.onAnswered && props.onAnswered(selectedValues);
          }}
        />
      </Box>
    </Question>
  );
}
