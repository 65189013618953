import React from 'react';
import { useProductCardStyles } from './productCardStyles';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import Typography from 'components/atoms/Typography/Typography';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

export default function ProductCard(props) {
  const { title, type, children, tags, ...rest } = props;
  const classes = useProductCardStyles(props);

  return (
    <GridContainer
      className={classes.card}
      onClick={() => {
        props.onProductClick && props.onProductClick(title);
      }}
      {...rest}
    >
      <GridItem xs={props.image ? 8 : 12}>
        <GridContainer
          className={classes.textContainer}
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <GridItem>
            {type && (
              <Typography variant="subtitle1" style={{ color: '#28052e' }}>
                {type}
              </Typography>
            )}
          </GridItem>
          <GridItem>
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
          </GridItem>
          <GridItem
            style={{
              marginBottom: '10px',
            }}
          >
            {tags &&
              tags.map((item) => {
                return (
                  <Typography variant="body2" className={classes.minorText}>
                    {item.name}
                  </Typography>
                );
              })}
          </GridItem>
        </GridContainer>
      </GridItem>
      {props.image && (
        <GridItem xs={4} className={classes.imageContainer}></GridItem>
      )}
    </GridContainer>
  );
}
