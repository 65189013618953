import { makeStyles } from '@material-ui/core';

export const useFooterStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: props.isIntro ? 'absolute' : 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    paddingTop: 15,
    paddingLeft: 17,
    paddingRight: 17,
    paddingBottom: 14,
    backgroundColor: props.transparent
      ? ''
      : props.backgroundColor
      ? props.backgroundColor
      : 'rgba(255, 255, 255, 0.7)',
  }),
}));

export const useButtonStyles = makeStyles(() => ({
  root: (props) => ({
    backgroundColor: props.buttonBackgroundColor
      ? props.buttonBackgroundColor
      : '#7949b9 !important',
    color: `rgba(255, 255, 255) !important`,
    '&:disabled': {
      color: `rgba(255, 255, 255, 0.28) !important`,
      backgroundColor: props.buttonBackgroundColor
        ? props.buttonBackgroundColor
        : 'rgba(121, 73, 185, 0.12) !important',
    },
    '&:hover': {
      color: `rgba(255, 255, 255) !important`,
      backgroundColor: props.buttonBackgroundColor
        ? props.buttonBackgroundColor
        : 'rgba(121, 73, 185, 0.12) !important',
    },
  }),
  rightSide: {
    marginLeft: 'auto',
  },
  center: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
