import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { regimenTheme } from 'themes';
import { useOvermindState, useActions } from 'overmind/index';
import { useTranslation } from 'react-i18next';

import Box from 'components/atoms/Layout/Box/Box';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import CalendarWeek from 'components/organisms/Calendar/CalendarWeek';
import ProductSwiper from 'components/organisms/ProductSwiper/ProductSwiper';
import ProductDetails from 'components/organisms/ProductDetails/ProductDetails';

import {
  getIndexOfSelectedDayInWeek,
  getDate,
  addDaysWithoutFormatting,
  isSameDay,
} from 'components/helpers/dateHelper';
import Typography from 'components/atoms/Typography/Typography';
import { getGoalsString, getGoalsText } from 'components/helpers/goalsHelper';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function Regimen() {
  const { t } = useTranslation();
  const isDesktop = useCheckScreenSize().isDesktop;

  const DAILY_MODE = 'daily';
  const DETAILS_MODE = 'productDetails';
  let routineSections = ['morning', 'afternoon', 'evening'];

  const overmindState = useOvermindState();
  const actions = useActions();

  const [selectedDate, setSelectedDate] = useState(getDate(new Date()));
  const [displayMode, setDisplayMode] = useState(DAILY_MODE);
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [selectedProductIx, setSelectedProductIx] = useState(undefined);
  const [selectedProductGroup, setSelectedProductGroup] = useState(undefined);
  const [enableNext, setEnableNext] = useState(true);
  const [enablePrevious, setEnablePrevious] = useState(false);
  const [routines, setRoutines] = useState({});
  const [goals, setGoals] = useState([]);
  const [goalsText, setGoalsText] = useState('');

  useEffect(() => {
    if (!overmindState.regime.loading && overmindState.regime.data == null) {
      actions.fetchRegime();
    } else if (overmindState.regime && overmindState.regime.data) {
      let routines = getFormattedRoutines();
      setRoutines(routines);
    }
  }, [
    actions,
    overmindState.regime.loading,
    overmindState.regime,
    selectedDate,
  ]);

  const getGoals = () => {
    let userGoals = getGoalsString(overmindState.blueprintProfile.profile);
    let goalText = getGoalsText(userGoals);
    if (goalText) {
      setGoalsText(t('mealPlan.goals.basetext') + ' ' + goalText);
    } else {
      setGoalsText('');
    }

    return userGoals;
  };

  useEffect(() => {
    if (
      goals.length === 0 &&
      overmindState.blueprintProfile &&
      overmindState.blueprintProfile.profile
    ) {
      let goals = getGoals();
      setGoals(goals);
    }
  }, [overmindState.blueprintProfile.profile]);

  const getFormattedRoutines = () => {
    let regimenRoutines = {};
    routineSections.forEach((section) => {
      regimenRoutines[section] = [];
    });

    overmindState.regime.data.days.forEach((day) => {
      if (isSameDay(selectedDate, getDate(day.ts, 'YYYY-MM-DD'))) {
        routineSections.forEach((section) => {
          let items = [];
          day.routine[section].forEach((item) => {
            if (overmindState.regime.data.products[item]) {
              items.push(overmindState.regime.data.products[item][0]);
            }
          });
          regimenRoutines[section] = items;
        });
      }
    });

    return regimenRoutines;
  };

  const handleDateClick = (day, dayIndex) => {
    setSelectedDate(addDaysWithoutFormatting(getDate(), dayIndex));
  };

  const handleProductChange = (changeIx) => {
    let products = getRoutineProducts(selectedProductGroup);

    if (
      products.length - 1 >= selectedProductIx + changeIx &&
      selectedProductIx + changeIx >= 0
    ) {
      handleProductSelect(
        products[selectedProductIx + changeIx],
        selectedProductIx + changeIx,
        selectedProductGroup
      );
    }
  };

  const handleProductSelect = (product, ix, group) => {
    setSelectedProduct(product);
    setSelectedProductIx(ix);
    setSelectedProductGroup(group);
    setDisplayMode(DETAILS_MODE);
    setDetailsNavigation(ix, group);
  };

  const setDetailsNavigation = (ix, group) => {
    let products = getRoutineProducts(group);

    if (products.length === 0) {
      setEnableNext(false);
      setEnablePrevious(false);
    } else if (products.length - 1 === ix) {
      setEnableNext(false);
      setEnablePrevious(true);
    } else if (ix === 0) {
      setEnableNext(true);
      setEnablePrevious(false);
    } else {
      setEnableNext(true);
      setEnablePrevious(true);
    }
  };

  const getRoutineProducts = (group) => {
    let products = [];

    switch (group) {
      case 'morning':
        products = routines.morning;
        break;
      case 'afternoon':
        products = routines.afternoon;
        break;
      case 'evening':
        products = routines.evening;
        break;
      default:
        break;
    }

    return products;
  };

  return (
    <ThemeProvider theme={regimenTheme}>
      <Box
        style={{
          backgroundColor: '#dcedeb',
          marginBottom: '50px',
        }}
      >
        <ReoccurringHeader
          title={`${overmindState.metaData.user_metadata.firstName}'s ${t(
            'regimen.title'
          )}`}
          goBack={() => setDisplayMode(DAILY_MODE)}
          showBack={displayMode === DETAILS_MODE}
          addMargin={displayMode === DAILY_MODE}
        />

        {displayMode === DAILY_MODE && (
          <>
            <Box
              style={{
                maxWidth: '670px',
                margin: 'auto',
                textAlign: 'center',
                padding: '0 15px',
                paddingTop: isDesktop ? '50px' : '0px',
                marginBottom: '30px',
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: '20px',
                  lineHeight: '24px',
                  color: '#105e69',
                  marginBottom: '15px',
                }}
              >
                {t('regimen.stage.1')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#342f38',
                }}
              >
                {overmindState?.regime?.data &&
                overmindState.regime.data.default_regime === false
                  ? t('regimen.introText') + ' '
                  : t('regimen.introTextDefault')}
                {overmindState?.regime?.data &&
                  overmindState.regime.data.default_regime === false && (
                    <b>{goalsText ? goalsText : ''}</b>
                  )}
              </Typography>
            </Box>
            <CalendarWeek
              dateIndex={getIndexOfSelectedDayInWeek(getDate(), selectedDate)}
              type={'day'}
              color="#edf5f4"
              isDesktop={isDesktop}
              onClick={handleDateClick}
            />

            <Typography
              variant="subtitle1"
              style={{ textAlign: 'center', color: '#28052e' }}
            >
              {t('regimen.yourMorningRoutine')}
            </Typography>
            <ProductSwiper
              color="#dcedeb"
              items={routines.morning}
              pagination
              onProductClick={(product, ix) => {
                handleProductSelect(product, ix, 'morning');
              }}
            />

            <Typography
              variant="subtitle1"
              style={{ textAlign: 'center', color: '#28052e' }}
            >
              {t('regimen.yourAfternoonRoutine')}
            </Typography>
            <ProductSwiper
              color="#dcedeb"
              items={routines.afternoon}
              pagination
              onProductClick={(product, ix) => {
                handleProductSelect(product, ix, 'afternoon');
              }}
            />

            <Typography
              variant="subtitle1"
              style={{ textAlign: 'center', color: '#28052e' }}
            >
              {t('regimen.yourEveningRoutine')}
            </Typography>
            <ProductSwiper
              color="#dcedeb"
              items={routines.evening}
              pagination
              onProductClick={(product, ix) => {
                handleProductSelect(product, ix, 'evening');
              }}
            />
          </>
        )}

        {displayMode === DETAILS_MODE && (
          <Box>
            <ProductDetails
              product={selectedProduct}
              enableNext={enableNext}
              enablePrevious={enablePrevious}
              onChange={(isNext) => {
                handleProductChange(isNext ? 1 : -1);
              }}
            />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
