import React, { useState, useEffect } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';

import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';

import BasicProfile from 'components/organisms/Profile/BasicProfile';
import ProfileDetails from 'components/organisms/Profile/ProfileDetails';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function Profile() {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const [name, setName] = useState(
    overmindState.metaData.user_metadata.firstName
      ? overmindState.metaData.user_metadata.firstName
      : null
  );
  const [displayMode, setDisplayMode] = useState('profile');
  const [traitCheckIntervalId, setTraitCheckIntervalId] = useState(null);
  const [reportCheckIntervalId, setReportCheckIntervalId] = useState(null);
  const isDesktop = useCheckScreenSize().isDesktop;

  useEffect(() => {
    if (
      overmindState.isGenotypeFetched &&
      overmindState.genotype?.source_details
    ) {
      actions.fetchTraitAssessments();
      if (
        overmindState.traits?.length !== 0 &&
        !overmindState.report?.download_url
      ) {
        actions.getReport();
      }
    }
  }, [actions, overmindState.isGenotypeFetched, overmindState.genotype]);

  useEffect(() => {
    if (!overmindState.traits?.length === 0 && traitCheckIntervalId === null) {
      const id = setInterval(() => {
        actions.fetchTraitAssessments();
      }, 6000);
      setTraitCheckIntervalId(id);
    } else {
      if (overmindState.traits?.length !== 0) {
        clearInterval(traitCheckIntervalId);
      }
    }
  }, [actions, overmindState.traits]);

  useEffect(() => {
    if (
      overmindState.traits?.length !== 0 &&
      !overmindState.report?.download_url &&
      reportCheckIntervalId === null
    ) {
      // If the traits are there, but report isn't, we will immediately fetch the report and we will start
      // the periodical check for the report. If immediate fetch won't get the report, we expect from
      // periodical checks to get it.
      actions.getReport();
      const id = setInterval(() => {
        if (!overmindState.report?.download_url) {
          actions.getReport();
        }
      }, 6000);
      setReportCheckIntervalId(id);
    } else {
      if (overmindState.report?.download_url) {
        clearInterval(reportCheckIntervalId);
      }
    }
  }, [actions, overmindState.traits, overmindState.report]);

  useEffect(() => {
    if (
      overmindState.blueprintProfile.profile == null ||
      Object.keys(overmindState.blueprintProfile.profile).length === 0
    ) {
      actions.fetchBlueprintProfile();
    }
  }, []);

  const downloadClicked = () => {
    trackEvent('PDF', 'Downloaded', { location: 'UserProfile' });
    window.open(overmindState.report.download_url, '_blank');
  };

  return (
    <ThemeProvider theme={assessmentTheme}>
      <ReoccurringHeader
        title={t('myProfile.title')}
        goBack={() => setDisplayMode('profile')}
        showBack={displayMode !== 'profile'}
        addMargin={false}
        whiteText={false}
      />
      <GridContainer
        justify="center"
        alignItems="center"
        style={{ paddingTop: isDesktop ? '50px' : '0px' }}
      >
        <GridItem>
          <GridContainer direction="column" style={{}}>
            <GridItem
              style={{
                marginBottom: '120px',
                textAlign: 'center',
                width: '100%',
                maxWidth: '1200px',
              }}
            >
              {displayMode === 'profile' && (
                <BasicProfile
                  changeProfileView={(mode) => setDisplayMode(mode)}
                  downloadClicked={downloadClicked}
                  name={name}
                />
              )}
              {displayMode === 'profile_details' && (
                <Typography
                  variant="h1"
                  style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                  }}
                >
                  <ProfileDetails />
                </Typography>
              )}
            </GridItem>
            <GridItem>
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="body2"
                  style={{ fontSize: '10px', fontWeight: 'normal' }}
                >
                  {' '}
                  Blueprint Beauty app v.{process.env.REACT_APP_VERSION} &copy;
                  LifeNome Inc.
                </Typography>
              </Box>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
}
