import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useActions, useOvermindState } from 'overmind/index';
import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';

import Box from 'components/atoms/Layout/Box';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import CalendarWeek from 'components/organisms/Calendar/CalendarWeek';
import DailyPlan from 'components/organisms/DailyPlan/DailyPlan';
import RecipeDetails from 'components/organisms/RecipeDetails/RecipeDetails';
import MealPlanProblemsDialog from 'components/organisms/ModalWindow/MealPlanProblemsDialog';
import Typography from 'components/atoms/Typography/Typography';

import {
  getIndexOfSelectedDayInWeek,
  getDate,
  addDaysWithoutFormatting,
} from 'components/helpers/dateHelper';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

import { getRecipe, getMeal } from 'components/helpers/planHelper';

export default function Planner() {
  const DAILY_MODE = 'daily';
  const DETAILS_MODE = 'recipeDetails';
  const isDesktop = useCheckScreenSize().isDesktop;

  const overmindState = useOvermindState();
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [allRecipesInSelectedDate, setAllRecipesInSelectedDate] = useState([]);
  const [hideSelectAction, setHideSelectAction] = useState(true);
  const [selectedDate, setSelectedDate] = useState(getDate(new Date()));
  const [showMealPlanDialog, setShowMealPlanDialog] = useState(false);
  const [displayMode, setDisplayMode] = useState(DAILY_MODE);
  const actions = useActions();
  const { t } = useTranslation();

  useEffect(() => {
    if (overmindState.isMealPlanLoaded === false) {
      actions.fetchMealPlan();
    }
  }, [overmindState.isMealPlanLoaded, actions]);

  useEffect(() => {
    if (
      overmindState.errors.errorGettingMealPlan &&
      overmindState.errors.errorGettingMealPlan !== ''
    ) {
      setShowMealPlanDialog(true);
    } else {
      setShowMealPlanDialog(false);
    }
  }, [overmindState.errors.errorGettingMealPlan, actions]);

  useEffect(() => {
    if (
      overmindState.areRecipeActionsLoaded === false &&
      overmindState.isMealPlanLoaded === true
    ) {
      actions.fetchRecipeActions();
    }
  }, [
    overmindState.areRecipeActionsLoaded,
    overmindState.isMealPlanLoaded,
    actions,
  ]);

  useEffect(() => {
    if (selectedDate != null) {
      fetchSimilarRecipesForAllMeals(selectedDate);
    }
  }, [selectedDate, overmindState.mealPlan]);

  const fetchSimilarRecipesForAllMeals = (selectedDate) => {
    let day = getIndexOfSelectedDayInWeek(getDate(), selectedDate);
    fetchSimilarRecipesForMeal('breakfast', day);
    fetchSimilarRecipesForMeal('lunch', day);
    fetchSimilarRecipesForMeal('dinner', day);
    fetchSimilarRecipesForMeal('snack', day);
  };

  const fetchSimilarRecipesForMeal = (mealType, day) => {
    const recipe = getRecipe(overmindState.mealPlan, mealType, day, true);
    const meal = getMeal(overmindState.mealPlan, mealType, day);

    if (recipe && meal && overmindState.similarRecipes[recipe.id] == null) {
      actions.fetchSimilarRecipe({
        recipeId: recipe.id,
        servingSize: meal.numOfServings,
      });
    }
  };

  const handleRecipeSelected = (recipeId, mealType) => {
    let selectedDay = getIndexOfSelectedDayInWeek(getDate(), selectedDate);
    setHideSelectAction(true);

    const date = Object.keys(overmindState.mealPlan)[selectedDay];
    setAllRecipesInSelectedDate(overmindState.mealPlan[date].meals);

    setSelectedRecipe(
      overmindState.mealPlan[date].meals.filter(
        (m) => m.meal === mealType && m.recipe.id === recipeId
      )[0]
    );
    actions.fetchRecipeDetails(recipeId).then(() => {
      setDisplayMode(DETAILS_MODE);
    });
  };

  const handleSimilarRecipeSelected = (recipeId, mainRecipeId, mealType) => {
    setHideSelectAction(false);
    if (selectedDate == null) {
      return;
    }
    let selectedDay = getIndexOfSelectedDayInWeek(getDate(), selectedDate);
    const date = Object.keys(overmindState.mealPlan)[selectedDay];
    const similarRecipe = overmindState.similarRecipes[mainRecipeId].find(
      (recipe) => recipe.id === recipeId
    );
    const selectedSimilarRecipe = {
      recipe: similarRecipe,
      calories: null,
      id: similarRecipe.id,
      meal: mealType,
      numOfServings: 1,
    };
    let meals = overmindState.mealPlan[date].meals;
    if (mealType === 'breakfast') {
      setAllRecipesInSelectedDate([
        selectedSimilarRecipe,
        meals[1],
        meals[2],
        meals[3],
      ]);
    } else if (mealType === 'lunch') {
      setAllRecipesInSelectedDate([
        meals[0],
        selectedSimilarRecipe,
        meals[2],
        meals[3],
      ]);
    } else if (mealType === 'snack') {
      setAllRecipesInSelectedDate([
        meals[0],
        meals[1],
        meals[2],
        selectedSimilarRecipe,
      ]);
    } else if (mealType === 'dinner') {
      setAllRecipesInSelectedDate([
        meals[0],
        meals[1],
        selectedSimilarRecipe,
        meals[3],
      ]);
    }
    setSelectedRecipe(selectedSimilarRecipe);
    setDisplayMode(DETAILS_MODE);
  };

  const handleDateClick = (day, dayIndex) => {
    setSelectedDate(addDaysWithoutFormatting(getDate(), dayIndex));
    fetchSimilarRecipesForAllMeals(selectedDate);
  };

  return (
    <ThemeProvider theme={assessmentTheme}>
      <Box
        style={{
          backgroundColor: isDesktop ? '#dcedeb' : '#fff',
        }}
      >
        <ReoccurringHeader
          title={
            displayMode === DAILY_MODE
              ? t('mealPlan.title')
              : t('mealPlan.recipeTitle')
          }
          goBack={() => setDisplayMode(DAILY_MODE)}
          showBack={displayMode === DETAILS_MODE}
          addMargin={displayMode === DAILY_MODE}
          whiteText={displayMode === DETAILS_MODE}
        />
      </Box>

      {displayMode === DAILY_MODE && (
        <>
          <Box
            style={{
              backgroundColor: isDesktop ? '#dcedeb' : '#fff',
              paddingTop: isDesktop ? '50px' : '0px',
            }}
          >
            <Box
              style={{
                maxWidth: '670px',
                margin: 'auto',
                textAlign: 'center',
                padding: '0 15px',
                marginBottom: '30px',
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'MuseoSans-300',
                  fontSize: isDesktop ? '18px' : '14px',
                  lineHeight: '18px',
                  color: '#221d22',
                  marginBottom: '15px',
                }}
              >
                {t('mealPlan.subtitle')}
              </Typography>
            </Box>

            <CalendarWeek
              dateIndex={getIndexOfSelectedDayInWeek(getDate(), selectedDate)}
              type={'day'}
              isDesktop={isDesktop}
              color={isDesktop ? '#edf5f4' : '#fff'}
              onClick={handleDateClick}
            />
          </Box>
          <Box
            style={{
              maxWidth: '1024px',
              margin: 'auto',
              marginBottom: '80px',
              paddingLeft: '23px',
              paddingRight: '23px',
            }}
          >
            <DailyPlan
              dateIndex={getIndexOfSelectedDayInWeek(getDate(), selectedDate)}
              onRecipeSelected={(recipeId, mealType) =>
                handleRecipeSelected(recipeId, mealType)
              }
              onSimilarRecipeSelected={(recipeId, mainRecipeId, mealType) =>
                handleSimilarRecipeSelected(recipeId, mainRecipeId, mealType)
              }
            />
          </Box>
        </>
      )}

      {displayMode === DETAILS_MODE && selectedRecipe && (
        <Box
          style={{
            marginBottom: '70px',
          }}
        >
          <RecipeDetails
            r={selectedRecipe}
            recipe={selectedRecipe.recipe}
            meal={selectedRecipe.meal}
            numberOfServings={selectedRecipe.numOfServings}
            changeRecipe={(recipeId, mealType, selectedDay) =>
              handleRecipeSelected(recipeId, mealType, selectedDay)
            }
            dailyRecipes={allRecipesInSelectedDate}
            dateIndex={getIndexOfSelectedDayInWeek(getDate(), selectedDate)}
            parentComponent="meal_plan"
            hideSelect={hideSelectAction}
            onSwap={() => {
              setDisplayMode(DAILY_MODE);
            }}
          />
        </Box>
      )}

      <MealPlanProblemsDialog
        openDialog={showMealPlanDialog}
        title={t('mealPlan.cantGenerateMessage')}
        confirmText={t('common.answerOk')}
        onClose={() => setShowMealPlanDialog(false)}
        //onConfirm={() => history.push(ROUTE_PROFILE)}
      />
    </ThemeProvider>
  );
}
Planner.whyDidYouRender = true;
