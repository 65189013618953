import Typography from 'components/atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useActions } from 'overmind/index';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

export default function KitNotReceivedPage() {
  const { t } = useTranslation();
  const actions = useActions();

  useEffect(() => {
    // we need to send Onboarding Finished when user answers that she doesn't have the KIT, nor DNA file
    trackEvent('Onboarding', 'Finished');
  }, [actions]);

  return (
    <Typography
      variant={'h3'}
      textAlign="center"
      textSize="18px"
      style={{ marginLeft: '30px', marginRight: '30px', marginTop: '60px' }}
    >
      {t('onboarding.dnaSection.kitNotReceived')}
    </Typography>
  );
}
