import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import './horizontalSwiperStyles.css';

import ProgressIndicatorItem from 'components/molecules/ProgressIndicator/ProgressIndicatorItem';
import Box from 'components/atoms/Layout/Box';

export default function ProgressIndicator(props) {
  const [selectedSlideIx, setSelectedSlideIx] = useState(
    props.selectedSlideIx ? props.selectedSlideIx : 0
  );

  useEffect(() => {
    let swiper = new Swiper('#' + props.uid, {
      slidesPerView: 'auto',
      freeMode: true,
      allowTouchMove: true,
    });

    // if selected slide is defined, slide to it
    if (selectedSlideIx) {
      if (props.firstStep) {
        swiper.slideTo(selectedSlideIx - 1);
      } else {
        swiper.slideTo(selectedSlideIx - 1, 0, false);
      }
    }

    setSelectedSlideIx(props.selectedSlideIx);
  }, [props.selectedSlideIx]);

  const handleStepClick = (sectionId) => {
    if (sectionId <= props.lastVisitedSectionId) {
      setSelectedSlideIx(sectionId);
      props.onProgressStepClick &&
        props.onProgressStepClick(sectionId, selectedSlideIx);
    }
  };

  return (
    <Box justifyContent="center" display="flex" mt={'24px'}>
      <div
        className={
          props.centered
            ? 'swiper-container-navigation-centered'
            : 'swiper-container'
        }
        id={props.uid}
      >
        <div className="swiper-wrapper">
          {props.items &&
            props.items.map((item, index) => (
              <div
                className={'swiper-slide ' + props.slideClassName}
                key={index}
              >
                <ProgressIndicatorItem
                  selected={item.id === selectedSlideIx}
                  selectedIcon={item.selectedIcon}
                  notSelectedIcon={item.notSelectedIcon}
                  hideLeftDots={item.hideLeftDots}
                  hideRightDots={item.hideRightDots}
                  label={item.label}
                  onClick={() => handleStepClick(item.id)}
                />
              </div>
            ))}
        </div>
      </div>
    </Box>
  );
}

ProgressIndicator.propTypes = {
  uid: PropTypes.string,
  selectedSlideIx: PropTypes.number,
  slideClassName: PropTypes.string,
  items: PropTypes.arrayOf({
    id: PropTypes.number.isRequired,
    label: PropTypes.string,
    hideLeftDots: PropTypes.bool,
    hideRightDots: PropTypes.bool,
    selectedIcon: PropTypes.object,
    notSelectedIcon: PropTypes.object,
  }),
};
