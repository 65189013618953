import React, { useState, useEffect } from 'react';
import HasDna from './HasDna';

import { useActions, useOvermindState } from 'overmind/index';

/**
 * Actual questionnaire page asking user if user has DNA.
 * Basically renders HasDna component and manages its state.
 */
export default function HasDnaPage() {
  const actions = useActions();
  const overmindState = useOvermindState();
  const [selectedWantsToUse, setSelectedWantsToUse] = useState(
    overmindState.metaData.user_metadata.wantsToUseDna == null
      ? undefined
      : overmindState.metaData.user_metadata.wantsToUseDna
  );
  const [selectedDnaOption, setSelectedDnaOption] = useState(
    overmindState.metaData.user_metadata.dnaUsageOption == null
      ? undefined
      : overmindState.metaData.user_metadata.dnaUsageOption
  );

  useEffect(() => {
    setSelectedWantsToUse(overmindState.metaData.user_metadata.wantsToUseDna);
    setSelectedDnaOption(overmindState.metaData.user_metadata.dnaUsageOption);
  }, [
    overmindState.metaData.user_metadata.wantsToUseDna,
    overmindState.metaData.user_metadata.dnaUsageOption,
  ]);

  return (
    <HasDna
      onWantsToUseDna={(value) => {
        actions.wantsToUseDnaAnswered(value);
      }}
      onDnaOption={(value) => {
        actions.dnaOptionAnswered(value);
      }}
      selectedWantsToUseValue={selectedWantsToUse}
      selectedDnaOptionValue={selectedDnaOption}
    ></HasDna>
  );
}
