import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import FoodAllergiesQuestion from 'components/organisms/Questions/Food/FoodAllergiesQuestion';

import {
  getFoodAllergiesData,
  getFoodAllergiesObject,
} from 'components/helpers/nutritionHelper';
import { useActions, useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import { usePageStyles } from 'components/pages/commonStyles';

export default function FoodAllergiesPage(props) {
  const classes = usePageStyles(props);
  const actions = useActions();
  const overmindState = useOvermindState();
  const { t } = useTranslation();

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <FoodAllergiesQuestion
        title={t('onboarding.foodSection.step2.title')}
        caption={t('onboarding.foodSection.step2.subtitle')}
        items={getFoodAllergiesData(overmindState.profileNutrition)}
        onAnswered={(values) => {
          const obj = getFoodAllergiesObject(values);
          actions.nutritionAllergiesAnswered(obj);
        }}
      />
    </GridContainer>
  );
}
