import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePersonalizedTipsStyles } from './HomeStyles';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import ExpendableText from 'components/organisms/ExpandableText/ExpandableText';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function PersonalizedTips(props) {
  const classes = usePersonalizedTipsStyles();
  const { t } = useTranslation();
  const { text, title, subtitle, image, category } = props;

  const isDesktop = useCheckScreenSize().isDesktop;

  const renderForDesktop = () => {
    return (
      <>
        {image && (
          <GridItem
            md={6}
            style={{
              padding: isDesktop && props.reverse ? '0px' : '16px',
              paddingLeft: props.reverse ? '16px' : '0px',
            }}
          >
            <img
              src={image}
              alt="personalized_tip_image"
              style={{
                maxHeight: '375px',
              }}
            />
          </GridItem>
        )}

        <GridItem md={image ? 6 : 12}>
          <GridContainer
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            style={{ height: '100%' }}
          >
            {subtitle && (
              <GridItem>
                <Typography variant="body1" className={classes.subtitle}>
                  {subtitle}
                </Typography>
              </GridItem>
            )}
            {title && (
              <GridItem>
                <Typography variant="h4" className={classes.title}>
                  {title}
                </Typography>
              </GridItem>
            )}

            <div style={{ paddingBottom: '10px' }}>
              <ExpendableText
                text={text}
                shortWordCount={50}
                textShowMore={t('common.showMore')}
              />
            </div>
          </GridContainer>
        </GridItem>
      </>
    );
  };

  const renderForMobile = () => {
    return (
      <>
        {subtitle && (
          <GridItem xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: 'center' }}
              className={classes.subtitle}
            >
              {subtitle}
            </Typography>
          </GridItem>
        )}
        {title && (
          <GridItem xs={12}>
            <Typography
              variant="h4"
              style={{ textAlign: 'center' }}
              className={classes.title}
            >
              {title}
            </Typography>
          </GridItem>
        )}
        {image && (
          <GridItem
            xs={12}
            style={{
              marginBottom: '20px',
            }}
          >
            <img
              src={image}
              alt="personalized_tip_image"
              style={{
                maxWidth: '100%',
                display: 'block',
              }}
            />
          </GridItem>
        )}

        <div style={{ paddingBottom: '10px' }}>
          <ExpendableText
            text={text}
            shortWordCount={50}
            textShowMore={t('common.showMore')}
            showMoreAlign="right"
          />
        </div>
      </>
    );
  };

  return (
    <div style={{ backgroundColor: props.backgroundColor }}>
      <GridContainer
        direction={props.reverse ? 'row-reverse' : 'row'}
        className={
          category === 'Nutrition'
            ? classes.nutritionTipComponent
            : classes.component
        }
        style={{
          padding: isDesktop && props.reverse ? '0px' : '20px',
          maxWidth: '1024px',
          minHeight: '300px',
          margin: 'auto',
          ...props.style,
        }}
        spacing={isDesktop ? 4 : 0}
      >
        {isDesktop ? renderForDesktop() : renderForMobile()}
      </GridContainer>
    </div>
  );
}
