import React from 'react';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import { useProgressStyles } from './progressStyles';
import { getVariantValues } from 'components/helpers/propTypesHelpers';

/**
 * Progress component based on https://material-ui.com/api/circular-progress/
 * @param {} props All possible props can be found in docs https://material-ui.com/api/circular-progress/
 */
export default function Progress(props) {
  const classes = useProgressStyles();

  return (
    <Box position="relative" display="inline-flex">
      <MuiCircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {props.variant !== 'indeterminate' && (
          <Typography
            variant={props.valueVariant}
            color="primary"
          >{`${Math.round(props.value)}%`}</Typography>
        )}
        <Typography variant={props.captionVariant} className={classes.caption}>
          {props.caption}
        </Typography>
      </Box>
    </Box>
  );
}

Progress.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant. Value between 0 and 100.
   */
  value: PropTypes.number,
  caption: PropTypes.string,
  variant: PropTypes.oneOf(['determinate', 'indeterminate', 'static']),
  size: PropTypes.number.isRequired,
  disableShrink: PropTypes.bool,
  valueVariant: PropTypes.oneOf(getVariantValues()),
  captionVariant: PropTypes.oneOf(getVariantValues()),
};
