import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RecipeSummary from 'components/molecules/RecipeSummary/RecipeSummary';
import Typography from 'components/atoms/Typography/Typography';

import { getRecipeProps } from 'components/helpers/propTypesHelpers';
import { useOvermindState, useActions } from 'overmind/index';

export default function Meal(props) {
  const overmindState = useOvermindState();
  const [bookmarksActionsLoaded, setBookmarksActionsLoaded] = useState(false);
  const actions = useActions();

  const handleBookmarkChange = (data, code) => {
    let mealData = null;
    if (code === 'favorite') {
      mealData = { ...data, code: code };
    } else if (code === 'save') {
      mealData = { recipe_id: data, code: code };
    } else if (code === 'log') {
      mealData = { ...data, code: code };
    }
    actions.editRecipeActions(mealData);
  };

  useEffect(() => {
    if (
      overmindState.areRecipeActionsLoaded === true &&
      overmindState.isMealPlanLoaded === true &&
      props.recipe
    ) {
      let favorites = [];
      overmindState.recipeFavorites.forEach((recipeAction) => {
        if (recipeAction.recipe_id === props.recipe.id) {
          favorites.push(recipeAction);
        }
      });
      setBookmarksActionsLoaded(true);
    }
  }, [
    overmindState.areRecipeActionsLoaded,
    overmindState.isMealPlanLoaded,
    actions,
  ]);

  return (
    <>
      {props.name && (
        <Typography
          variant={'body1'}
          style={{ textTransform: 'uppercase', fontSize: '10px' }}
        >
          {props.name}
        </Typography>
      )}
      {props.recipe && (
        <RecipeSummary
          onClick={() =>
            props.onClicked && props.recipe && props.onClicked(props.recipe.id)
          }
          onBookmarkChange={handleBookmarkChange}
          isHorizontal={
            props.isHorizontal != null
              ? props.isHorizontal
              : overmindState.showPlan === 'daily'
              ? true
              : false
          }
          uid={props.recipe.id}
          bookmarksActionsLoaded={bookmarksActionsLoaded}
          parentComponent={props.parentComponent}
          meal={props.name}
          {...props.recipe}
          largeImage={props.largeImage ? props.largeImage : false}
          hideBookmarks={props.hideBookmarks}
          isPremium={props.isPremium}
        />
      )}
    </>
  );
}

Meal.propTypes = {
  name: PropTypes.string,
  recipe: getRecipeProps(),
  onClicked: PropTypes.func,
};
