import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Box from 'components/atoms/Layout/Box/Box';

export default function ContainedRadioButtonGroup(props) {
  const { items, rowNumber } = props;

  const handleChange = (item) => {
    item.checked = !item.checked;
    props.onChange && props.onChange([item]);
  };

  return (
    <GridContainer direction={props.direction ? props.direction : 'row'}>
      {items &&
        items.map((item, ix) => (
          <GridItem
            xs={12 / rowNumber}
            key={ix}
            style={{
              marginBottom: props.spaceBetween ? props.spaceBetween : 0,
              textAlign: 'center',
            }}
          >
            <Box
              style={{
                maxWidth: '300px',
                margin: '0 auto 15px auto',
                border: `${item.checked ? '3' : '1'}px solid #97d3c5`,
                borderRadius: '50px',
                padding: '10px',
                cursor: 'pointer',
              }}
              onClick={() => handleChange(item)}
            >
              {item.image ? (
                <img src={item.image} alt={item.label} height="40px" />
              ) : (
                item.label
              )}
            </Box>
          </GridItem>
        ))}
    </GridContainer>
  );
}

ContainedRadioButtonGroup.propTypes = {
  color: PropTypes.string,
  rowNumber: PropTypes.number,
  spaceBetween: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
