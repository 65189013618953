import React from 'react';
import ContainerWithBackgroundImg from 'components/molecules/Common/ContainerWithBackgroundImage/ContainerWithBackgroundImg';
import { backgroundSkin } from 'assets/imagesList';
import Typography from 'components/atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'components/atoms/Layout/Box/Box';

export default function SkinIntroPage(props) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box>
        <ContainerWithBackgroundImg
          image={backgroundSkin}
          maxWidth={'957px'}
          maxHeight={'700px'}
          height={'95vh'}
          width={'100vw'}
          marginTop={'0vh'}
        >
          <Box
            color="#ffffff"
            maxWidth="298px"
            position="relative"
            pr={{ xs: 2 }}
            pl={{ xs: 4 }}
            left={{ sm: '27%' }}
            top={{ sm: '25%', xs: '35%' }}
          >
            <Typography variant="h1" textSize={35}>
              {t('onboarding.skinSection.intro')}
            </Typography>
          </Box>
        </ContainerWithBackgroundImg>
      </Box>
    </React.Fragment>
  );
}
