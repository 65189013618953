import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from 'components/atoms/Inputs/Buttons/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import DietaryPreferencesQuestion from 'components/organisms/Questions/Food/DietaryPreferencesQuestion';
import FoodAllergiesQuestion from 'components/organisms/Questions/Food/FoodAllergiesQuestion';
import KitNumberInputQuestion from 'components/organisms/Questions/DNA/KitNumberInputQuestion';
import ZipCodeQuestion from 'components/organisms/Questions/General/ZipCodeQuestion';
import Question from 'components/organisms/Inputs/Question/Question';

import { useActions, useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import { renderSlider } from 'components/helpers/environmentHelper';

import {
  getNutritionData,
  getNutritionObject,
  getFoodAllergiesData,
  getFoodAllergiesObject,
} from 'components/helpers/nutritionHelper';
import {
  AreaCountry,
  AreaSuburban,
  AreaUrban,
  ExposureExtended,
  ExposureMinimal,
  ExposureModerate,
  SPFDaily,
  SPFRarely,
  SPFSometimes,
  AirConDaily,
  AirConSometimes,
  AirConRarely,
  ScreensHigh,
  ScreensLow,
  ScreensMedium,
} from 'assets/iconsList';
import { getEthnicities } from 'components/helpers/ethnicityHelper';
import { minAge, maxAge } from 'components/helpers/unitValueHelper';
import FileToUploadQuestion from '../Questions/DNA/FileToUploadQuestion';
import SkinQuestion from 'components/pages/Onboarding/Skin/SkinQuestion';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
const useStyles = (theme) => ({
  root: {
    margin: 0,
    width: '95%',
  },
  paper: {
    margin: 0,
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let today = new Date();
let ethnicities = [];

export default function ModalEditWindow(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const actions = useActions();
  const overmindState = useOvermindState();
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [open, setOpen] = useState(props.opened ? props.opened : false);
  const [dnaKitNumber, setDnaKitNumber] = useState(null);

  const [userEthnicity, setUserEthnicity] = useState(
    overmindState.metaData.user_metadata &&
      overmindState.metaData.user_metadata.selectedEthnicity
      ? overmindState.metaData.user_metadata.selectedEthnicity
      : []
  );
  const [age, setAge] = useState(
    overmindState.profile.year_of_birth != null
      ? today.getFullYear() - overmindState.profile.year_of_birth
      : null
  );

  const [dietaryPreferences, setDietaryPreferences] = useState(
    overmindState.profileNutrition ? overmindState.profileNutrition : null
  );

  const [zipCode, setZipCode] = useState(
    overmindState.blueprintProfile
      ? overmindState.blueprintProfile.profile.zip_code
      : null
  );

  const setValue = (value, step) => {
    if (step === 'step1') {
      actions.setPlaceType(
        value === 0 ? 'urban' : value === 1 ? 'suburban' : 'country-side'
      );
    } else if (step === 'step2') {
      actions.setSunExposureLevel(value);
    } else if (step === 'step3') {
      actions.setSpfUsageLevel(value);
    } else if (step === 'step4') {
      actions.setAirconUsageLevel(value);
    } else if (step === 'step5') {
      actions.setBlueLightExposureLevel(value);
    }
  };

  useEffect(() => {
    if (overmindState.componentForModalWindow === 'age') {
      setSaveDisabled(!(age >= minAge && age <= maxAge));
    } else {
      setSaveDisabled(false);
    }
    setOpen(props.opened);
  }, [props.opened]);

  useEffect(() => {
    if (ethnicities.length === 0) {
      ethnicities = getEthnicities(userEthnicity);
    }
  }, [userEthnicity]);

  useEffect(() => {
    if (overmindState.metaData.user_metadata.zipCodeSaved === true) {
      actions.changeZipCodeSaved(false);
      props.onClose && props.onClose();
    }
  }, [overmindState.metaData.user_metadata.zipCodeSaved]);

  function onDietaryPreferencesChange(obj) {
    setDietaryPreferences({ ...overmindState.profileNutrition, ...obj });
  }

  function resetDataAndClose() {
    setDietaryPreferences(
      overmindState.profileNutrition ? overmindState.profileNutrition : null
    );
    props.onClose && props.onClose();
  }

  function updateAndClose() {
    if (overmindState.componentForModalWindow === 'zipCode') {
      actions.setZipCode(zipCode);
      actions.saveChanges('general');
    } else {
      if (
        overmindState.componentForModalWindow === 'dietaryPreferences' ||
        overmindState.componentForModalWindow === 'allergiesRestrictions'
      ) {
        actions.nutritionPreferencesAnswered(dietaryPreferences);
        actions.saveChanges('nutrition');
      }
      if (
        overmindState.componentForModalWindow === 'skinType' ||
        overmindState.componentForModalWindow === 'skinGoal' ||
        overmindState.componentForModalWindow === 'eyeAreaConcern'
      ) {
        actions.saveChanges('skin');
      } else if (overmindState.componentForModalWindow === 'dnaKitNumber') {
        if (dnaKitNumber != null) {
          actions.submitKitNumber(dnaKitNumber);
        }
      }
      if (
        overmindState.componentForModalWindow === 'environmentArea' ||
        overmindState.componentForModalWindow === 'environmentExposure' ||
        overmindState.componentForModalWindow === 'environmentSpf' ||
        overmindState.componentForModalWindow === 'environmentAirCon' ||
        overmindState.componentForModalWindow === 'environmentScreens'
      ) {
        actions.saveChanges('environment');
      }

      props.onClose && props.onClose();
    }
  }

  return (
    <div
      style={{
        width: '100%',
        margin: '0px',
        maxWidth: '400px',
      }}
    >
      <Dialog
        onClose={() => props.onClose && props.onClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{
          width: '100%',
          marginTop: '40px',
          maxHeight: '80vh',
        }}
        fullWidth={true}
        scroll="paper"
      >
        <DialogContent
          style={{
            margin: '0',
            padding: '60px 20px',
          }}
        >
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'dietaryPreferences' ? (
            <DietaryPreferencesQuestion
              title={t('onboarding.foodSection.step1.title')}
              caption={t('onboarding.foodSection.step1.subtitle')}
              items={getNutritionData(dietaryPreferences)}
              onAnswered={(values) => {
                const obj = getNutritionObject(values);
                onDietaryPreferencesChange(obj);
              }}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'allergiesRestrictions' ? (
            <FoodAllergiesQuestion
              title={t('onboarding.foodSection.step2.title')}
              caption={t('onboarding.foodSection.step2.subtitle')}
              items={getFoodAllergiesData(dietaryPreferences)}
              onAnswered={(values) => {
                const obj = getFoodAllergiesObject(values);
                onDietaryPreferencesChange(obj);
              }}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'skinType' ? (
            <SkinQuestion step={'step1'} isRadio={true} />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'skinGoal' ? (
            <SkinQuestion step={'step2'} isRadio={false} />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'eyeAreaConcern' ? (
            <SkinQuestion step={'step3'} isRadio={false} />
          ) : null}
          {overmindState.componentForModalWindow &&
            overmindState.componentForModalWindow === 'dnaKitNumber' && (
              <KitNumberInputQuestion
                onChange={(value) => setDnaKitNumber(value)}
                hideButton={true}
              />
            )}
          {overmindState.componentForModalWindow &&
            overmindState.componentForModalWindow === 'dnaFile' && (
              <FileToUploadQuestion
                fileProviders={
                  overmindState.metaData.user_metadata.dnaProviders
                }
                showUploadStatus={overmindState.upload.showUploadStatus}
                uploadProgress={overmindState.upload.progress}
                uploadSuccessful={overmindState.upload.successful}
                onFileSelected={(file) => {
                  actions.uploadFile(file);
                }}
              />
            )}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'zipCode' ? (
            <ZipCodeQuestion
              title={t('onboarding.generalSection.zipCodeStep.title')}
              zipCode={zipCode}
              onChange={(newZipCode) => {
                setZipCode(newZipCode);
              }}
              zipCodeIsInvalid={
                overmindState.errors.errorSavingBlueprintProfile != null &&
                overmindState.errors.errorSavingBlueprintProfile !== ''
              }
              zipCodeInvalidMessage={t(
                'onboarding.generalSection.zipCodeStep.invalidMessage'
              )}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'environmentArea' ? (
            <GridItem
              style={{
                maxWidth: '95%',
                marginBottom: '20px',
              }}
            >
              <Question title={t(`onboarding.environmentSection.step1.title`)}>
                {renderSlider(
                  overmindState,
                  [AreaUrban, AreaSuburban, AreaCountry],
                  'step1',
                  (index) => {
                    setValue(index, 'step1');
                  }
                )}
              </Question>
            </GridItem>
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'environmentExposure' ? (
            <GridItem
              style={{
                maxWidth: '95%',
                marginBottom: '20px',
              }}
            >
              <Question title={t(`onboarding.environmentSection.step2.title`)}>
                {renderSlider(
                  overmindState,
                  [ExposureMinimal, ExposureModerate, ExposureExtended],
                  'step2',
                  (index) => {
                    setValue(index, 'step2');
                  }
                )}
              </Question>
            </GridItem>
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'environmentSpf' ? (
            <GridItem
              style={{
                maxWidth: '95%',
                marginBottom: '20px',
              }}
            >
              <Question title={t(`onboarding.environmentSection.step3.title`)}>
                {renderSlider(
                  overmindState,
                  [SPFDaily, SPFRarely, SPFSometimes],
                  'step3',
                  (index) => {
                    setValue(index, 'step3');
                  }
                )}
              </Question>
            </GridItem>
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'environmentAirCon' ? (
            <GridItem
              style={{
                maxWidth: '95%',
                marginBottom: '20px',
              }}
            >
              <Question title={t(`onboarding.environmentSection.step4.title`)}>
                {renderSlider(
                  overmindState,
                  [AirConDaily, AirConSometimes, AirConRarely],
                  'step4',
                  (index) => {
                    setValue(index, 'step4');
                  }
                )}
              </Question>
            </GridItem>
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'environmentScreens' ? (
            <GridItem
              style={{
                maxWidth: '95%',
                marginBottom: '20px',
              }}
            >
              <Question title={t(`onboarding.environmentSection.step5.title`)}>
                {renderSlider(
                  overmindState,
                  [ScreensHigh, ScreensLow, ScreensMedium],
                  'step5',
                  (index) => {
                    setValue(index, 'step5');
                  }
                )}
              </Question>
            </GridItem>
          ) : null}
        </DialogContent>
        <DialogActions
          style={{
            margin: '0 auto',
            paddingBottom: '40px',
          }}
        >
          <Button
            onClick={resetDataAndClose}
            color={
              overmindState.componentForModalWindow === 'dnaFile'
                ? 'primary'
                : 'default'
            }
            variant="contained"
          >
            {t('common.answerClose')}
          </Button>
          {!props.hideSave && (
            <Button
              onClick={() => updateAndClose()}
              color="primary"
              variant="contained"
              disabled={saveDisabled}
            >
              {t('common.answerSave')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
