import { makeStyles } from '@material-ui/core';

export const useContentCardStyles = makeStyles((theme) => ({
  card: (props) => ({
    width: '100%',
    padding: '40px 0px 80px 0px',
    marginTop: '-70px',
    backgroundColor: props.color ? props.color : '#f8f8f8',
    borderRadius: '50px 50px 0 0',
  }),
  addDnaCard: (props) => ({
    padding: '40px 0px 50px 0px',
    margin: '-50px 10px',
    backgroundColor: '#f7f0ed',
    borderRadius: '20px 20px 20px 20px',
  }),
  title: {
    textAlign: 'center',
  },
}));
