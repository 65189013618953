import { makeStyles } from '@material-ui/core';

const borderRadius = '50px';

export const useButtonStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    padding: '5px 20px',
    borderRadius: borderRadius,
    minWidth: '113px',
  },
}));
