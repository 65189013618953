import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import FileToUploadQuestion from 'components/organisms/Questions/DNA/FileToUploadQuestion';

import { usePageStyles } from 'components/pages/commonStyles';

/**
 * Questionnaire page asking user to upload DNA file.
 * Exists for presentation in StoryBook. Actual page implemented as FileUploadPage component.
 */
export default function FileToUpload(props) {
  const classes = usePageStyles(props);

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem style={{ marginLeft: '12px', marginRight: '12px' }}>
        <FileToUploadQuestion
          fileProviders={props.fileProviders}
          showUploadStatus={props.showUploadStatus}
          uploadProgress={props.uploadProgress}
          uploadSuccessful={props.uploadSuccessful}
          onFileSelected={(file) => {
            if (props.onFileSelected) {
              props.onFileSelected(file);
            }
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
