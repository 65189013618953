import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';

import AppMenu from 'components/molecules/AppMenu/AppMenu';
import Home from 'components/pages/Assessment/Home';
import Needs from 'components/pages/Assessment/Needs';
import Profile from 'components/pages/Assessment/Profile';
import Regimen from 'components/pages/Assessment/Regimen';
import Planner from 'components/pages/Assessment/Planner';

import { getMenuOptions } from 'components/helpers/footerNavigationHelper';

import { useActions, useOvermindState } from 'overmind/index';
import { useHistory } from 'react-router-dom';

export default function Assessment() {
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    window?.location?.pathname
  );
  const actions = useActions();
  const overmindState = useOvermindState();
  const history = useHistory();

  const hasUserMetaData = () => {
    return (
      overmindState.metaData != null &&
      overmindState.metaData.user_metadata != null &&
      Object.keys(overmindState.metaData.user_metadata).length !== 0
    );
  };

  useEffect(() => {
    actions.initializeAssessmentData();
    if (!hasUserMetaData()) {
      actions.onInitializeOvermindState();
    }
  }, []);

  useEffect(() => {
    setSelectedMenuItem(window.location.pathname);
  }, [window.location.pathname]);

  const routes = [
    {
      path: '/assessment',
      exact: true,
      main: () => (
        <Home
          onSwitchMenuRequested={(section) => {
            let qs = '';
            if (window.localStorage.getItem('qs') != null) {
              qs = window.localStorage.getItem('qs');
            }
            history.push(`/assessment/${section}` + qs);
            window.scrollTo(0, 0);
          }}
        />
      ),
    },
    {
      path: '/assessment/regimen',
      exact: true,
      main: () => <Regimen />,
    },
    {
      path: '/assessment/nutrition',
      exact: true,
      main: () => <Planner />,
    },
    {
      path: '/assessment/genetics',
      exact: true,
      main: () => <Needs />,
    },
    {
      path: '/assessment/profile',
      exact: true,
      main: () => <Profile />,
    },
  ];

  return (
    <ThemeProvider theme={assessmentTheme}>
      {hasUserMetaData() && (
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={<route.main />}
            />
          ))}
        </Switch>
      )}

      <AppMenu
        selectedValue={selectedMenuItem}
        menuOptions={getMenuOptions(assessmentTheme, selectedMenuItem)}
        isDesktop={window.innerWidth > 800}
      />
    </ThemeProvider>
  );
}
