import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './i18n.js';
import './index.css';
import { createOvermind } from 'overmind';
import { Provider } from 'overmind-react';
import { config } from './overmind';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { initializeTracking } from 'components/helpers/userTrackingEventsHelper';

initializeTracking();

const overmind = createOvermind(config);

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  PUBLIC_URL,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

ReactDOM.render(
  <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN || 'auth.lifenome.com'}
    clientId={REACT_APP_AUTH0_CLIENT_ID || 'tSw6gdmNrsPDUalcY3aKOfsY2fI4M9eS'}
    redirectUri={`${window.location.origin}${PUBLIC_URL}`}
    audience={REACT_APP_AUTH0_AUDIENCE}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <React.StrictMode>
      <Provider value={overmind}>
        <Router basename={PUBLIC_URL}>
          <App />
        </Router>
      </Provider>
    </React.StrictMode>
  </Auth0Provider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
