import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';

import {} from 'assets/iconsList';

import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
import Question from 'components/organisms/Inputs/Question/Question';

import IllustratedSlider from 'components/molecules/Inputs/IllustratedSlider/IllustratedSlider';

export default function PastWeekConsumptionPage(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const action = useActions();

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <Box
        mt={2}
        px={2}
        style={{
          maxWidth: '1024px',
        }}
      >
        <Question title={t('onboarding.foodSection.step3.question1.title')}>
          <IllustratedSlider
            id="illustrated-slider-vegetables"
            illustrations={[
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question1.slide1'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question1.slide2'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question1.slide3'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question1.slide4'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question1.slide5'),
              },
            ]}
            selectedIllustrationIndex={
              overmindState.profileLifestyle.vegetables
                ? overmindState.profileLifestyle.vegetables
                : 0
            }
            onValueChanged={(index) =>
              action.changeLifestyleVegetablesLevel(index)
            }
          />
        </Question>

        <Question title={t('onboarding.foodSection.step3.question2.title')}>
          <IllustratedSlider
            id="illustrated-slider-fruits"
            illustrations={[
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question2.slide1'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question2.slide2'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question2.slide3'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question2.slide4'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question2.slide5'),
              },
            ]}
            selectedIllustrationIndex={
              overmindState.profileLifestyle.fruits
                ? overmindState.profileLifestyle.fruits
                : 0
            }
            onValueChanged={(index) => {}}
          />
        </Question>

        <Question title={t('onboarding.foodSection.step3.question3.title')}>
          <IllustratedSlider
            id="illustrated-slider-wholeGrains"
            illustrations={[
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question3.slide1'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question3.slide2'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question3.slide3'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question3.slide4'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question3.slide5'),
              },
            ]}
            selectedIllustrationIndex={
              overmindState.profileLifestyle.wholeGrains
                ? overmindState.profileLifestyle.wholeGrains
                : 0
            }
            onValueChanged={(index) => {}}
          />
        </Question>

        <Question title={t('onboarding.foodSection.step3.question4.title')}>
          <IllustratedSlider
            id="illustrated-slider-wholeGrains"
            illustrations={[
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question4.slide1'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question4.slide2'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question4.slide3'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question4.slide4'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question4.slide5'),
              },
            ]}
            selectedIllustrationIndex={
              overmindState.profileLifestyle.beansLegumes
                ? overmindState.profileLifestyle.beansLegumes
                : 0
            }
            onValueChanged={(index) => {}}
          />
        </Question>

        <Question title={t('onboarding.foodSection.step3.question4.title')}>
          <IllustratedSlider
            id="illustrated-slider-wholeGrains"
            illustrations={[
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question4.slide1'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question4.slide2'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question4.slide3'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question4.slide4'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question4.slide5'),
              },
            ]}
            selectedIllustrationIndex={
              overmindState.profileLifestyle.dairy
                ? overmindState.profileLifestyle.dairy
                : 0
            }
            onValueChanged={(index) => {}}
          />
        </Question>

        <Question title={t('onboarding.foodSection.step3.question5.title')}>
          <IllustratedSlider
            id="illustrated-slider-wholeGrains"
            illustrations={[
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question5.slide1'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question5.slide2'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question5.slide3'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question5.slide4'),
              },
              {
                illustration: null,
                height: 85,
                text: t('onboarding.foodSection.step3.question5.slide5'),
              },
            ]}
            selectedIllustrationIndex={
              overmindState.profileLifestyle.meatFishProteins
                ? overmindState.profileLifestyle.meatFishProteins
                : 0
            }
            onValueChanged={(index) => {}}
          />
        </Question>
      </Box>
    </GridContainer>
  );
}
