import React from 'react';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import { useHomeStyles } from './HomeStyles';

export default function HomeIntro(props) {
  const classes = useHomeStyles();

  return (
    <GridContainer className={classes.homeIntroComponent}>
      {props.firstName && (
        <GridItem
          xs={12}
          style={{
            marginBottom: '20px',
          }}
        >
          <Typography
            variant="h5"
            style={{ fontFamily: 'Museo-500', fontSize: 18, color: '#342f38' }}
          >
            Hello {props.firstName}!
          </Typography>
        </GridItem>
      )}

      {props.image && (
        <GridItem
          xs={12}
          style={{
            paddingBottom: '30px',
          }}
        >
          <img
            src={props.image}
            height={'128px'}
            width={'128px'}
            alt={`${props.text}-icon`}
          />
        </GridItem>
      )}

      {props.title && (
        <GridItem
          xs={12}
          style={{
            paddingBottom: '15px',
          }}
        >
          <Typography
            style={{ fontWeight: 900, color: '#342f38' }}
            variant="h3"
          >
            {props.title}
          </Typography>
        </GridItem>
      )}

      {props.text && (
        <GridItem
          xs={12}
          style={{
            paddingBottom: '40px',
            maxWidth: '670px',
            margin: 'auto',
          }}
        >
          <Typography variant="body1">{props.text}</Typography>
        </GridItem>
      )}
    </GridContainer>
  );
}
