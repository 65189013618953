/**
 * axiosClient is responsible for axios configuration and instance creating.
 * It is also interface for all axios methods.
 */
import axios from 'axios';
const { REACT_APP_API_URL } = process.env;

// Instance containing config for all requests
// Unavailable from outside
const axiosInstance = axios.create({
  //TODO: this should be done only once after login and token fetch, include token, and keep instance somewhere
  baseURL: `${REACT_APP_API_URL}/`,
});

const getToken = () => window.localStorage.getItem('access_token');
/**
 * Returns information about
 */
const getAxiosConfig = () => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return token ? config : undefined;
};

const handleErrors = (error, preventRedirect) => {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 403) {
      window.localStorage.removeItem('access_token');
      if (preventRedirect !== true && window.location.pathname !== '/') {
        window.location = '/?auth=true';
      } else {
        throw error;
      }
    } else {
      throw error;
    }
  } else {
    throw error;
  }
};

const axiosGET = async (url) => {
  let result = await axiosInstance
    .get(url, getAxiosConfig())
    .catch((error) => handleErrors(error));
  return result;
};

const axiosPUT = async (url, data) => {
  let result = await axiosInstance.put(url, data, getAxiosConfig());
  return result;
};

const axiosPOST = (url, data) => {
  return axiosInstance.post(url, data, getAxiosConfig());
};

const axiosAnonymousPOST = (url, data) => {
  return axiosInstance.post(url, data);
};

const axiosAnonymousPUT = (url, data) => {
  return axiosInstance.put(url, data);
};

const axiosPATCH = (url, data) => {
  return axiosInstance.patch(url, data, getAxiosConfig());
};

const axiosDELETE = async (url) => {
  let apiRes = null;
  try {
    apiRes = await axiosInstance.delete(url, getAxiosConfig());
  } catch (err) {
    console.error('Error response:');
    console.error(err.response.data); // ***
    console.error(err.response.status); // ***
    console.error(err.response.headers); // ***
  } finally {
    console.log(apiRes);
  }
  return apiRes;
};

export {
  axiosGET,
  axiosPUT,
  axiosPOST,
  axiosPATCH,
  axiosDELETE,
  axiosAnonymousPOST,
  axiosAnonymousPUT,
};
