import React, { useState, useEffect } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import ShowMoreButton from 'components/molecules/Common/ShowMoreButton/ShowMoreButton';

import { truncateText } from 'components/helpers/textHelper';

export default function ExpendableText(props) {
  const { text, shortWordCount, textShowMore, textShowLess } = props;
  const [textToDisplay, setTextToDisplay] = useState(
    truncateText(text, shortWordCount)
  );
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setTextToDisplay(truncateText(text, shortWordCount));
  }, [text]);

  const handleButtonClick = (showMore) => {
    if (showMore) {
      setTextToDisplay(text);
    } else {
      setTextToDisplay(truncateText(text, shortWordCount));
    }
    setShowMore(showMore);
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Typography variant="body1">
          {textToDisplay.split('\n').map((paragraph, key) => {
            return <p key={key}>{paragraph}</p>;
          })}
        </Typography>
      </GridItem>

      {text && textToDisplay && (
        <GridItem
          xs={12}
          style={{
            marginTop: '12px',
            textAlign: props.showMoreAlign ? props.showMoreAlign : 'left',
          }}
        >
          <ShowMoreButton
            showMoreValue={showMore}
            textShowLess={textShowLess}
            textShowMore={textShowMore}
            onClick={() => handleButtonClick(!showMore)}
          />
        </GridItem>
      )}
    </GridContainer>
  );
}
