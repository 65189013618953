import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/atoms/Layout/Box';
import Typography from 'components/atoms/Typography/Typography';

export default function GeneralIntro(props) {
  const { t } = useTranslation();

  const headerHeight = 130;
  const footerHeight = 65;

  return (
    <Box
      color="#ffffff"
      style={{
        height: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {props.image && (
        <Box style={{ height: '300px' }}>
          <img src={props.image} alt="intro_illustration" width={340} />
        </Box>
      )}
      {props.title && (
        <Typography
          variant="h1"
          style={{
            maxWidth: '312px',
            fontFamily: props.bold ? 'MuseoSans-900' : 'MuseoSans-700',
            fontSize: '35px',
            lineHeight: '40px',
          }}
        >
          {props.title}
        </Typography>
      )}
      {props.subTitle && (
        <Typography
          variant="h1"
          style={{
            maxWidth: '312px',
            paddingTop: '20px',
            fontFamily: props.bold ? 'MuseoSans-900' : 'MuseoSans-700',
            fontSize: '25px',
            lineHeight: '32px',
          }}
        >
          {props.subTitle}
        </Typography>
      )}
      {props.text && (
        <Typography
          variant="h1"
          style={{
            maxWidth: '312px',
            paddingTop: '33px',
            fontFamily: 'MuseoSans-700',
            fontSize: '20px',
            lineHeight: '22px',
          }}
        >
          {props.text}
        </Typography>
      )}
    </Box>
  );
}
