import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';
import AssessmentTraits from 'components/organisms/AssessmentTraits/AssessmentTraits';
import TraitDetails from 'components/organisms/AssessmentTraits/TraitDetails';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import AddDNASection from 'components/organisms/Home/AddDNASection';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

import { useActions, useOvermindState } from 'overmind/index';
import {
  sortTraits,
  getHardCodedTraits,
} from 'components/helpers/assessmentTraitsHelper';
import BarLoader from 'react-spinners/BarLoader';
import Box from 'components/atoms/Layout/Box/Box';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function Needs() {
  const { t } = useTranslation();
  const TRAITS_MODE = 'traits';
  const DETAILS_MODE = 'details';
  const isDesktop = useCheckScreenSize().isDesktop;

  const overmindState = useOvermindState();
  const actions = useActions();
  const [displayMode, setDisplayMode] = useState(TRAITS_MODE);
  const [traits, setTraits] = useState([]);
  const [fakedTraits, setFakedTraits] = useState([]);
  const [selectedTraitIndex, setSelectedTraitIndex] = useState(undefined);
  const [traitCheckIntervalId, setTraitCheckIntervalId] = useState(null);
  const [reportCheckIntervalId, setReportCheckIntervalId] = useState(null);

  useEffect(() => {
    if (
      overmindState.isGenotypeFetched &&
      overmindState.genotype?.source_details
    ) {
      actions.fetchTraitAssessments();
      if (
        overmindState.traits?.length !== 0 &&
        !overmindState.report?.download_url
      ) {
        actions.getReport();
      }
    }
  }, [actions, overmindState.isGenotypeFetched, overmindState.genotype]);

  useEffect(() => {
    if (!overmindState.traits?.length === 0 && traitCheckIntervalId === null) {
      const id = setInterval(() => {
        actions.fetchTraitAssessments();
      }, 6000);
      setTraitCheckIntervalId(id);
    } else {
      if (overmindState.traits?.length !== 0) {
        clearInterval(traitCheckIntervalId);
      }
    }
  }, [actions, overmindState.traits]);

  useEffect(() => {
    if (
      overmindState.traits?.length !== 0 &&
      !overmindState.report?.download_url &&
      reportCheckIntervalId == null
    ) {
      // If the traits are there, but report isn't, we will immediately fetch the report and we will start
      // the periodical check for the report. If immediate fetch won't get the report, we expect from
      // periodical checks to get it.
      actions.getReport();
      const id = setInterval(() => {
        if (!overmindState.report?.download_url) {
          actions.getReport();
        }
      }, 6000);
      setReportCheckIntervalId(id);
    } else {
      if (overmindState.report?.download_url) {
        clearInterval(reportCheckIntervalId);
      }
    }
  }, [actions, overmindState.traits, overmindState.report]);

  useEffect(() => {
    if (overmindState.traits == null || overmindState.traits.length === 0) {
      setFakedTraits(getHardCodedTraits());
    } else {
      let sortedTraits = sortTraits(overmindState.traits);
      setTraits(sortedTraits);
    }
  }, [overmindState.traits]);

  const handleDownload = () => {
    trackEvent('PDF', 'Downloaded', { location: 'Genetics' });
    window.open(overmindState.report.download_url, '_blank');
  };

  const getTraitByIndex = (index) => {
    if (!traits || traits.length <= 0) {
      return;
    }

    if (index < 0) {
      return traits[0].trait;
    } else if (index >= traits.length) {
      return traits[traits.length - 1].trait;
    } else {
      return traits[index].trait;
    }
  };

  const isReportAvailable = () => {
    return overmindState.report && overmindState.report.download_url;
  };

  const areTraitsAvailable = () => {
    return (
      overmindState.isGenotypeFetched &&
      overmindState.genotype.source_details != null &&
      overmindState.traits != null &&
      overmindState.traits.length !== 0
    );
  };

  const renderDummyDataWithUploadPopup = () => {
    return (
      <>
        <GridItem
          xs={12}
          style={{
            position: 'fixed',
            top: '250px',
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            style={{ maxWidth: '800px', opacity: 1 }}
          >
            <AddDNASection parent={'needs'} />
          </Box>
        </GridItem>
        <GridItem
          xs={12}
          style={{
            textAlign: 'center',
            padding: '20px',
            marginBottom: '50px',
          }}
        >
          <Typography variant="body1" style={{ fontSize: '14px' }}>
            {t('needs.subtitle')}
          </Typography>
        </GridItem>
        <GridItem
          xs={12}
          style={{
            opacity: 0.3,
            zIndex: -1,
          }}
        >
          <AssessmentTraits
            title={t('needs.geneticRisks')}
            traits={fakedTraits}
            connotation="risky"
            color="#fde8e3"
            onCardClick={() => {}}
          />
        </GridItem>
        <GridItem xs={12} style={{ opacity: 0.3, zIndex: -1 }}>
          <AssessmentTraits
            title={t('needs.genericStrengths')}
            traits={fakedTraits}
            connotation="good"
            color="#dfefde"
            onCardClick={() => {}}
          />
        </GridItem>
      </>
    );
  };

  return (
    <>
      <ReoccurringHeader
        title={`${overmindState.metaData.user_metadata.firstName}'s ${t(
          'needs.title'
        )}`}
        showBack={displayMode === DETAILS_MODE}
        goBack={() => setDisplayMode(TRAITS_MODE)}
        addMargin
      />

      <GridContainer
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          paddingTop: isDesktop && displayMode === TRAITS_MODE ? '50px' : '0px',
        }}
      >
        {((overmindState.genotype &&
          overmindState.genotype.error_details != null) ||
          (overmindState.isGenotypeFetched === false &&
            !overmindState.genotype.source_details)) &&
          renderDummyDataWithUploadPopup()}

        {overmindState.genotype.source_details != null &&
          overmindState.upload.successful &&
          overmindState.traits.length === 0 && (
            <GridItem xs={12} style={{ margin: '20px auto' }}>
              <BarLoader
                loading={true}
                color={'#000000'}
                css={{
                  display: 'block',
                  margin: '0 auto',
                }}
              />
            </GridItem>
          )}

        {displayMode === TRAITS_MODE && (
          <GridContainer
            style={{
              maxWidth: isReportAvailable() ? '1024px' : '700px',
              margin: 'auto',
              marginBottom: '30px',
              padding: '20px 20px 30px 20px',
            }}
          >
            {areTraitsAvailable() && (
              <GridItem
                xs={12}
                md={isReportAvailable() ? 8 : 12}
                style={{
                  textAlign: isDesktop ? 'left' : 'center',
                }}
              >
                <Typography variant="body1" style={{ fontSize: '14px' }}>
                  {t('needs.subtitle')}
                </Typography>
              </GridItem>
            )}
            {isReportAvailable() && (
              <GridItem
                xs={12}
                md={4}
                style={{
                  textAlign: 'center',
                }}
              >
                <Button
                  style={{
                    fontFamily: 'MuseoSans-500',
                    fontSize: '15px',
                    lineHeight: '18px',
                    backgroundColor: '#dcedeb',
                    color: '#342f38',
                    padding: '20px',
                  }}
                  onClick={handleDownload}
                >
                  {t('needs.downloadAssessment')}
                </Button>
              </GridItem>
            )}
          </GridContainer>
        )}

        {displayMode === TRAITS_MODE && areTraitsAvailable() && (
          <>
            <GridItem xs={12} style={{ marginTop: '50px' }}>
              <AssessmentTraits
                title={t('needs.geneticRisks')}
                traits={traits}
                connotation="risky"
                color="#fde8e3"
                onCardClick={(index) => {
                  setSelectedTraitIndex(index);
                  setDisplayMode(DETAILS_MODE);
                }}
              />
            </GridItem>

            <GridItem xs={12} style={{ marginTop: '-40px' }}>
              <AssessmentTraits
                title={t('needs.genericStrengths')}
                traits={traits}
                connotation="good"
                color="#dfefde"
                onCardClick={(index) => {
                  setSelectedTraitIndex(index);
                  setDisplayMode(DETAILS_MODE);
                }}
              />
            </GridItem>
          </>
        )}
        {displayMode === DETAILS_MODE && (
          <TraitDetails
            data={getTraitByIndex(selectedTraitIndex)}
            onGoBack={() => {
              setSelectedTraitIndex(undefined);
              setDisplayMode(TRAITS_MODE);
            }}
            hidePreviousButton={selectedTraitIndex <= 0}
            onPreviousClick={() => {
              setSelectedTraitIndex(selectedTraitIndex - 1);
            }}
            hideNextButton={selectedTraitIndex >= traits.length - 1}
            onNextClick={() => {
              setSelectedTraitIndex(selectedTraitIndex + 1);
            }}
          />
        )}
      </GridContainer>
    </>
  );
}
