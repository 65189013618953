import {
  axiosGET,
  axiosPOST,
  axiosDELETE,
  axiosPUT,
  axiosPATCH,
  axiosAnonymousPOST,
  axiosAnonymousPUT,
} from 'api/axiosClient';

const URL_USER_PROFILE = `profile/`;
const URL_USER_PROFILE_METADATA = 'user/';
const URL_USER_GENOTYPE = 'profile/genotype/';
const URL_USER_LIFESTYLE = 'profile/lifestyle/';
const URL_USER_NUTRITION = 'profile/nutrition/';
const URL_USER_REPORT = 'profile/reports/';
const URL_USER_ASSESSMENT = 'profile/assessments/rms/9moons/';
const URL_USER_MEAL_PLAN = 'profile/recommender/mealplan/?app_id=blueprint';
const URL_REPORT_INITIAL = 'apps/blueprint/report/tips/';
const URL_USER_DNA_REPORT = 'apps/blueprint/report/dna/';
const URL_USER_WEATHER = 'apps/blueprint/report/weather/';
const URL_USER_BLUEPRINT_PROFILE = 'apps/blueprint/details/';
const URL_USER_REGIME = 'apps/blueprint/report/regime/';
const URL_SAVE_EMAIL = '/apps/blueprint/mailchimp-lists/80819e6616/subscribe/';

//Shopping list
const URL_USER_SHOPPING_LIST = '/profile/recommender/mealplan/shopping-list/';
const URL_USER_SHOPPING_LIST_ITEMS =
  '/profile/recommender/mealplan/shopping-list/items/';
const URL_USER_SIMILAR_RECIPES =
  '/profile/recommender/mealplan/recipes/similar/';
const URL_USER_RECIPE_DETAILS =
  '/profile/recommender/mealplan/recipes/details/';

//Recipe
const URL_USER_RECIPE_ACTIONS = 'profile/recommender/mealplan/recipe-actions/';
const URL_USER_SAVED_FOR_LATER_RECIPES =
  '/profile/recommender/mealplan/recipes/saved/';
const URL_USER_FAVORITE_RECIPES =
  '/profile/recommender/mealplan/recipes/favorites/';
const URL_USER_RECIPE_LOGGED = 'profile/recommender/mealplan/recipes/logged/';
const URL_USER_RECIPE_FAVORITES =
  'profile/recommender/mealplan/recipes/favorites/';
const URL_USER_RECIPE_SAVED = 'profile/recommender/mealplan/recipes/saved/';

const { REACT_APP_AUTH0_DOMAIN } = process.env;

export const api = {
  getProfile: async () => {
    const response = await axiosGET(URL_USER_PROFILE);
    return response.data;
  },
  putProfile: async (data) => {
    const response = await axiosPUT(URL_USER_PROFILE, data);
    return response.data;
  },
  deleteProfile: async () => {
    const response = await axiosDELETE(URL_USER_PROFILE);
    return response.data;
  },
  getCurrentUserMetadata: async () => {
    const response = await axiosGET(URL_USER_PROFILE_METADATA);
    return response.data;
  },
  putCurrentUserMetadata: async (data) => {
    const response = await axiosPUT(URL_USER_PROFILE_METADATA, data);
    return response.data;
  },
  deleteCurrentUserMetadata: async () => {
    const response = await axiosDELETE(URL_USER_PROFILE_METADATA);
    return response.data;
  },
};

export const apiProfileLifestyle = {
  getProfileLifestyle: async () => {
    const response = await axiosGET(URL_USER_LIFESTYLE);
    return response.data;
  },
  putProfileLifestyle: async (data) => {
    const response = await axiosPUT(URL_USER_LIFESTYLE, data);
    return response.data;
  },
  patchProfileLifestyle: async (data) => {
    const response = await axiosPATCH(URL_USER_LIFESTYLE, data);
    return response.data;
  },
};

export const apiProfileAssessment = {
  getListTraitAssessment: async () => {
    const response = await axiosGET(URL_USER_DNA_REPORT);
    return response.data;
  },
};

export const apiWeather = {
  getWeather: async () => {
    const response = await axiosGET(URL_USER_WEATHER);
    return response.data;
  },
};

export const apiNutrition = {
  getNutritionPreferences: async () => {
    const response = await axiosGET(URL_USER_NUTRITION);
    return response.data;
  },
  putNutritionPreferences: async (data) => {
    const response = await axiosPUT(URL_USER_NUTRITION, data);
    return response.data;
  },
  patchNutritionPreferences: async (data) => {
    const response = await axiosPATCH(URL_USER_NUTRITION, data);
    return response.data;
  },
};

export const apiRecipeActions = {
  getRecipeActions: async () => {
    const response = await axiosGET(URL_USER_RECIPE_ACTIONS);
    return response.data;
  },
  postRecipeAction: async (data) => {
    const response = await axiosPOST(URL_USER_RECIPE_ACTIONS, data);
    return response.data;
  },
  removeRecipeAction: async (data) => {
    const urlExtension = data.recipe_id + '/' + data.action + '/';
    const response = await axiosDELETE(URL_USER_RECIPE_ACTIONS + urlExtension);
    return response.data;
  },
  getSimilarRecipes: async (recipeId, servingSize) => {
    const response = await axiosGET(
      `${URL_USER_SIMILAR_RECIPES}${recipeId}/${servingSize}/`
    );
    return response.data;
  },
  getFavoriteRecipes: async () => {
    const response = await axiosGET(URL_USER_FAVORITE_RECIPES);
    return response.data;
  },
  getSavedForLaterRecipes: async () => {
    const response = await axiosGET(URL_USER_SAVED_FOR_LATER_RECIPES);
    return response.data;
  },
  getRecipeDetails: async (recipeId) => {
    const response = await axiosGET(`${URL_USER_RECIPE_DETAILS}${recipeId}/`);
    return response.data;
  },
  postShoppingList: async (data) => {
    const response = await axiosPOST(URL_USER_SHOPPING_LIST, data);
    return response.data;
  },
  getShoppingList: async (type) => {
    let viewType = type ? `?view_type=${type}` : '';

    const response = await axiosGET(`${URL_USER_SHOPPING_LIST}${viewType}`);
    return response.data;
  },
  postShoppingListItem: async (data) => {
    const response = await axiosPOST(URL_USER_SHOPPING_LIST_ITEMS, data);
    return response.data;
  },
  deleteShoppingListItems: async () => {
    const response = await axiosDELETE(URL_USER_SHOPPING_LIST_ITEMS);
    return response.data;
  },
};

export const apiRecipeFavorites = {
  getRecipeFavorites: async () => {
    const response = await axiosGET(URL_USER_RECIPE_FAVORITES);
    return response.data;
  },
  postRecipeFavorites: async (data) => {
    const response = await axiosPOST(URL_USER_RECIPE_FAVORITES, data);
    return response.data;
  },
};

export const apiRecipeSaved = {
  getRecipeSaved: async () => {
    const response = await axiosGET(URL_USER_RECIPE_SAVED);
    return response.data;
  },
  postRecipeSaved: async (data) => {
    const response = await axiosPOST(URL_USER_RECIPE_SAVED, data);
    return response.data;
  },
};

export const apiRecipeLogged = {
  getRecipeLogged: async () => {
    const response = await axiosGET(URL_USER_RECIPE_LOGGED);
    return response.data;
  },
  postRecipeLogged: async (data) => {
    const response = await axiosPOST(URL_USER_RECIPE_LOGGED, data);
    return response.data;
  },
};

export const apiGenotype = {
  getGenotype: async () => {
    const response = await axiosGET(URL_USER_GENOTYPE);
    return response.data;
  },
  postGenotype: async (data) => {
    const response = await axiosPOST(URL_USER_GENOTYPE, data);
    return response.data;
  },
  deleteGenotype: async () => {
    const response = await axiosDELETE(URL_USER_GENOTYPE);
    if (response) {
      return response.data;
    } else {
      return null;
    }
  },
};

export const apiReport = {
  getReport: async () => {
    const response = await axiosGET(URL_USER_REPORT);
    return response.data;
  },
  generateReport: async (data) => {
    const response = await axiosPOST(URL_USER_REPORT, data);
    return response.data;
  },
};

export const apiAssessment = {
  getAssessment: async () => {
    const response = await axiosGET(URL_USER_ASSESSMENT);
    return response.data;
  },
  getMealPlan: async () => {
    const response = await axiosGET(URL_USER_MEAL_PLAN);
    return response.data;
  },
  getInitialReport: async () => {
    const response = await axiosGET(URL_REPORT_INITIAL);
    return response.data;
  },
  getSimilarRecipes: async (recipeId, servingSize) => {
    const response = await axiosGET(
      `${URL_USER_SIMILAR_RECIPES}${recipeId}/${servingSize}/`
    );
    return response.data;
  },
  getRecipeDetails: async (recipeId) => {
    const response = await axiosGET(`${URL_USER_RECIPE_DETAILS}${recipeId}/`);
    return response.data;
  },
};

export const apiBlueprintProfile = {
  getBlueprintProfile: async () => {
    const response = await axiosGET(URL_USER_BLUEPRINT_PROFILE);
    return response.data;
  },
  putBlueprintProfile: async (data) => {
    const response = await axiosPUT(URL_USER_BLUEPRINT_PROFILE, data);
    return response.data;
  },
  deleteBlueprintProfile: async () => {
    const response = await axiosDELETE(URL_USER_BLUEPRINT_PROFILE);
    return response.data;
  },
  patchBlueprintProfile: async (data) => {
    const response = await axiosPATCH(URL_USER_BLUEPRINT_PROFILE, data);
    return response.data;
  },
};

export const apiRegime = {
  getRegime: async () => {
    const response = await axiosGET(URL_USER_REGIME);
    return response.data;
  },
};

export const apiAuthentication = {
  checkAuthorizationCode: async (data) => {
    const response = await axiosAnonymousPOST(
      `https://${REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      data
    );
    return response.data;
  },
  getUserInfo: async () => {
    const response = await axiosGET(
      `https://${REACT_APP_AUTH0_DOMAIN}/userinfo`
    );
    return response.data;
  },
};

export const apiSaveEmail = {
  postEmail: async (data) => {
    const response = await axiosAnonymousPOST(URL_SAVE_EMAIL, data);
    return response.data;
  },
};

export const apiUpdateEmail = {
  postEmail: async (data) => {
    const response = await axiosAnonymousPUT(URL_SAVE_EMAIL, data);
    return response.data;
  },
};
