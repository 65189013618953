import { makeStyles } from '@material-ui/core';

export const useResetPasswordStyles = makeStyles((theme) => ({
  title: (prop) => ({
    marginTop: '55px',
    fontFamily: 'MuseoSans',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '0.8',
    color: '#fff',
    textAlign: 'center',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    userSelect: 'none',
  }),
  subtitle: (prop) => ({
    marginTop: '13px',
    fontFamily: 'MuseoSans',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#fff',
    textAlign: 'center',
    userSelect: 'none',
  }),
  emailContainer: {
    display: 'flex',
    height: '48px',
    userSelect: 'none',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid white',
      },
      '&:hover fieldset': {
        border: '2px solid white',
      },
      '&:not(:hover) fieldset': {
        border: '2px solid white',
      },
    },
  },
  confirmButton: {
    height: '54.7px',
    width: '100%',
    marginTop: '26.6px',
    marginBottom: ' 18px',
    backgroundColor: '#97d3c5',
    '&:disabled': {
      color: 'white',
    },
    '&:active': {
      color: 'white',
      backgroundColor: '#97d3c5',
    },
    '&:click': {
      color: 'white',
      backgroundColor: '#97d3c5',
    },
    '&:focus': {
      color: 'white',
      backgroundColor: '#97d3c5',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#97d3c5',
    },
  },
  buttonText: {
    fontSize: '20px',
    lineHeight: '24px',
    fontFamily: 'MuseoSans',
  },
  statement: {
    marginTop: '17.5px',
    paddingBottom: '20px',
    fontFamily: 'MuseoSans',
    fontSize: '11px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: '-0.17px',
    textAlign: 'center',
    color: '#fff',
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  signInContainer: {
    display: 'flex',
    height: '42px',
    marginTop: '33px',
    borderTop: '2px solid #fff',
    borderBottom: '2px solid #fff',
    cursor: 'pointer',
  },
  signInText: {
    fontFamily: 'MuseoSans',
    fontWeight: '500',
    fontSize: '16px',
    paddingLeft: '5.5px',
    lineHeight: 1.5,
    color: '#fff',
    userSelect: 'none',
  },
}));

export const inputProps = {
  fontFamily: 'MuseoSans',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#ffffff',
  textAlign: 'center',
  userSelect: 'none',
};
