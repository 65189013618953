import React from 'react';
import { useContentCardStyles } from './contentCardStyles';
import Typography from 'components/atoms/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';

export default function ContentCard(props) {
  const { title, children } = props;
  const classes = useContentCardStyles(props);

  return (
    <Box className={classes.card}>
      <Box>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      {props.fullWidth ? (
        children
      ) : (
        <Box style={{ maxWidth: '1024px', margin: 'auto' }}>{children}</Box>
      )}
    </Box>
  );
}
