import React from 'react';

import ContentCard from 'components/molecules/Common/ContentCard/ContentCard';
import AddDNA from 'components/molecules/Common/AddDNA/AddDNA';
import { useContentCardStyles } from '../../molecules/Common/ContentCard/contentCardStyles';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

export default function AddDNASection(props) {
  const classes = useContentCardStyles();
  return (
    <GridItem>
      {props.parent && props.parent === 'home' && (
        <ContentCard color="#f7f0ed">
          <AddDNA />
        </ContentCard>
      )}
      {props.parent && props.parent === 'needs' && (
        <GridItem className={classes.addDnaCard}>
          <AddDNA />
        </GridItem>
      )}
    </GridItem>
  );
}
