import * as React from 'react';

function StartIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size : '20'}
      height={props.size ? props.size : '20'}
      viewBox={`0 0 ${props.size ? props.size : '40'} ${
        props.size ? props.size : '40'
      }`}
    >
      <path
        id="Path_22950"
        data-name="Path 22950"
        d="M32.4,9.569a5.088,5.088,0,0,1,8.318,4.82l0,.025A5.088,5.088,0,0,0,44.05,20.19h0a5.088,5.088,0,0,1,0,9.62h0a5.088,5.088,0,0,0-3.337,5.776l0,.025a5.088,5.088,0,0,1-8.318,4.82l-.078-.067a5.088,5.088,0,0,0-6.645,0l-.078.067a5.088,5.088,0,0,1-8.318-4.82l0-.025A5.088,5.088,0,0,0,13.95,29.81h0a5.088,5.088,0,0,1,0-9.62h0a5.088,5.088,0,0,0,3.337-5.776l0-.025A5.088,5.088,0,0,1,25.6,9.569l.078.067a5.088,5.088,0,0,0,6.645,0Z"
        transform="translate(-10.52 -8.316)"
        fill={props.color}
      />
    </svg>
  );
}

export default StartIcon;
