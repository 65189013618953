import React from 'react';

import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';

import { usePageStyles } from 'components/pages/commonStyles';
import SkinQuestion from './SkinQuestion';

export default function SkinPage(props) {
  const classes = usePageStyles(props);

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{
        paddingLeft: '12px',
        paddingRight: '12px',
      }}
    >
      <Box
        my={2}
        px={2}
        style={{
          maxWidth: '1024px',
        }}
      >
        <SkinQuestion
          step={props.step}
          hasSubtitle={props.hasSubtitle}
          isRadio={props.isRadio}
        />
      </Box>
    </GridContainer>
  );
}
