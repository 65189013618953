import React, { useRef } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import './swiperStyles.css';

export default function ProductSwiper(props) {
  const { children, swiperParams } = props;

  const swiperRef = useRef(null);

  const params = {
    centeredSlides: true,
    activeSlideKey: props.activeSlide,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      480: {
        slidesPerView: 'auto',
      },
    },
    ...swiperParams,
  };

  return (
    <Swiper {...params} ref={swiperRef} rebuildOnUpdate={true}>
      {children}
    </Swiper>
  );
}
