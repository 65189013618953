import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import Box from 'components/atoms/Layout/Box/Box';
import TextInput from 'components/atoms/Inputs/TextInput/TextInput';
import Question from 'components/organisms/Inputs/Question/Question';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Button from 'components/atoms/Inputs/Buttons/Button';
import { dnaKitNumberStep0 } from 'assets/imagesList';

export default function KitNumberInputQuestion(props) {
  const [kitNumber, setKitNumber] = useState(
    props.kitNumber == null ? '' : props.kitNumber
  );
  const [submitting, setSubmitting] = useState(false);

  return props.showSubmitStatus ? (
    <Question title={props.title}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {props.submitSuccessful ? (
          <CheckCircleIcon color="primary" style={{ fontSize: '80px' }} />
        ) : (
          <ErrorIcon color="error" style={{ fontSize: '80px' }} />
        )}
      </Box>
    </Question>
  ) : (
    <Question title={props.title} divider={false}>
      <Box justifyContent="center" display="flex">
        <GridContainer direction="column">
          <GridItem>
            <Box
              pt={{ xs: 2.5, sm: 3, md: 4 }}
              justifyContent="center"
              display="flex"
            >
              <img
                src={dnaKitNumberStep0}
                alt="kit number illustration"
                width={200}
              />
            </Box>
          </GridItem>
          <GridItem>
            <Box
              p={{ xs: 2, sm: 3, md: 4 }}
              justifyContent="center"
              display="flex"
            >
              <TextInput
                value={kitNumber}
                fullwidth
                variant={'standard'}
                inputProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: '18px',
                  },
                }}
                onChange={(e) => {
                  setKitNumber(e.target.value);
                  if (props.hideButton) {
                    props.onChange && props.onChange(e.target.value);
                  }
                }}
              />
            </Box>
          </GridItem>
          {(props.hideButton == null || props.hideButton === false) && (
            <GridItem>
              <Box
                p={{ xs: 2, sm: 3, md: 4 }}
                justifyContent="center"
                display="flex"
              >
                <Button
                  component="span"
                  variant="contained"
                  disabled={
                    (props.kitNumber === '' || submitting) && !props.error
                  }
                  onClick={() => {
                    if (props.onKitNumberSubmit) {
                      setSubmitting(true);
                      props.onKitNumberSubmit(kitNumber);
                    }
                  }}
                >
                  {props.error
                    ? 'Try again'
                    : submitting
                    ? 'Processing...'
                    : 'Submit'}
                </Button>
              </Box>
            </GridItem>
          )}
        </GridContainer>
      </Box>
    </Question>
  );
}

KitNumberInputQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  onKitNumberSubmit: PropTypes.func,
  kitNumber: PropTypes.string,
  submitSuccessful: PropTypes.bool,
  showSubmitStatus: PropTypes.bool,
  error: PropTypes.string,
};
