import React from 'react';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';
import Box from 'components/atoms/Layout/Box/Box';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export default function IntroPageSlider(props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ marginTop: '30px', marginBottom: '50px' }}
    >
      <Box
        width="80%"
        style={{ maxWidth: '400px', marginTop: '50px', textAlign: 'center' }}
      >
        <Typography
          variant="body2"
          style={{
            color: 'white',
            fontSize: '20px',
            lineHeight: '24px',
          }}
        >
          {props.titleText}
        </Typography>
      </Box>
      <Box style={{ maxWidth: '400px', height: '350px' }}>
        <img src={props.introImage} alt="intro_illustration" width={340} />
      </Box>

      <Box width="80%" style={{ maxWidth: '400px' }}>
        <Typography
          variant="body2"
          style={{
            color: 'rgba(255, 255, 255)',
            fontSize: '20px',
            lineHeight: '24px',
          }}
        >
          {props.introText}
        </Typography>
      </Box>
      {props.skipButtonFunction && props.buttonText && (
        <Box>
          <Button
            variant="outlined"
            onClick={() => props.skipButtonFunction()}
            style={{
              position: 'relative',
              top: '30px',
              color: 'rgba(255, 255, 255)',
            }}
          >
            <>
              {props.buttonText} <NavigateNextIcon />
            </>
          </Button>
        </Box>
      )}
    </Box>
  );
}
