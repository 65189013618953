import React, { useEffect, useState } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Box from 'components/atoms/Layout/Box';
import Typography from 'components/atoms/Typography/Typography';
import Popover from 'components/atoms/Popover/Popover';

import { useRecipeSummaryStyles } from './recipeSummaryStyles';
import { getRecipeProps } from 'components/helpers/propTypesHelpers';
import { useOvermindState } from 'overmind/index';
import CheckIcon from '@material-ui/icons/Check';

import {
  BookmarkFavorite,
  BookmarkSaveForLater,
  BookmarkFavoriteEmpty,
  BookmarkSaveForLaterEmpty,
} from 'assets/iconsList';
import { formatDate } from 'components/helpers/dateHelper';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

export default function RecipeSummary(props) {
  const classes = useRecipeSummaryStyles();
  const overmindState = useOvermindState();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useCheckScreenSize().isDesktop;

  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isUserFavorite, setIsUserFavorite] = useState(props.isUserFavorite);
  const [isSaved, setIsSaved] = useState(props.isSaved);
  const { isHorizontal, largeImage, parentComponent } = props;

  const recipeActionFavorite = 'favorite';
  const recipeActionSave = 'save';
  const recipeActionLog = 'log';

  useEffect(() => {
    setIsUserFavorite(props.isUserFavorite);
    setIsSaved(props.isSaved);
  }, [props.isUserFavorite, props.isSaved]);

  const handleBookmarkClick = (e) => {
    setOpenPopover(!openPopover);
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverOptionClick = (code) => {
    handlePopoverClose();
    let data = null;
    if (code === recipeActionFavorite) {
      setIsUserFavorite(!isUserFavorite);
      data = { recipe_id: props.id, recipe_db_id: props.recipe_db_id };
    } else if (code === recipeActionSave) {
      setIsSaved(!isSaved);
      data = props.id;
    } else if (code === recipeActionLog) {
      data = {
        recipe_id: props.id,
        meal_datetime: formatDate(props.meal_datetime, 'YYYY-MM-DD hh:mm'),
        num_of_servings: props.numberOfServings,
        meal_type: props.meal && props.meal,
        meal_id: props.id,
      };
    }
    props.onBookmarkChange && props.onBookmarkChange(data, code);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenPopover(false);
  };

  const handleRecipeClick = () => {
    props.onClick && props.onClick();
  };

  const getNormalizedName = () => {
    let name = props.name;

    return name.normalize();
  };

  const renderPopover = () => {
    if (!props.bookmarksActionsLoaded) {
      return;
    }

    let data = [
      {
        icon: <BookmarkFavorite />,
        text: isUserFavorite
          ? t('recipeDetails.removeLikedMark')
          : t('recipeDetails.markLiked'),
        code: recipeActionFavorite,
      },
      {
        icon: <BookmarkSaveForLater />,
        text: isSaved
          ? t('recipeDetails.removeSavedMark')
          : t('recipeDetails.markSaved'),
        code: recipeActionSave,
      },
    ];

    if (parentComponent === 'recipe_list') {
      data.shift();
    } else if (parentComponent === 'recipe_list') {
      data = [];
    }

    return (
      <Popover
        id="recipe-summary-bookmark-popover"
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        <Box className={classes.popoverContentContainer}>
          {data.map((item, ix) => (
            <Box
              display="flex"
              flexDirection="row"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handlePopoverOptionClick(item.code);
              }}
              key={ix}
            >
              <Box className={classes.popoverContent}>{item.icon}</Box>
              <Box className={classes.popoverContent}>
                <Typography>{item.text}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Popover>
    );
  };

  const renderBookmarks = () => {
    return (
      <Box
        className={classes.bookmark}
        display="flex"
        justifyContent="flex-end"
      >
        <Box style={{ cursor: 'pointer', zIndex: 2 }} px={0.5}>
          {isUserFavorite ? (
            <BookmarkFavorite height={20} onClick={handleBookmarkClick} />
          ) : (
            <BookmarkFavoriteEmpty height={20} onClick={handleBookmarkClick} />
          )}
        </Box>

        <Box style={{ cursor: 'pointer', zIndex: 2 }} px={0.5}>
          {isSaved ? (
            <BookmarkSaveForLater height={20} onClick={handleBookmarkClick} />
          ) : (
            <BookmarkSaveForLaterEmpty
              height={20}
              onClick={handleBookmarkClick}
            />
          )}
        </Box>
      </Box>
    );
  };

  const getImageSize = () => {
    if (isHorizontal) {
      return largeImage ? 247 : 130;
    } else {
      return largeImage ? (isDesktop ? 223 : 140) : isDesktop ? 150 : 110;
    }
  };

  return (
    <div className={isHorizontal ? classes.root : classes.rootVertical}>
      {overmindState.areRecipeActionsLoaded &&
      (parentComponent === 'meal' || parentComponent === 'home') &&
      !props.hideBookmarks
        ? renderBookmarks()
        : null}
      {renderPopover()}

      <GridContainer
        direction="row"
        style={{
          height: isHorizontal
            ? getImageSize()
            : isDesktop
            ? parentComponent === 'home'
              ? '320px'
              : '245px'
            : '190px',
          width: isHorizontal ? 'auto' : getImageSize(),
          cursor: 'pointer',
        }}
        onClick={handleRecipeClick}
      >
        <GridItem
          xs={isHorizontal ? 6 : 12}
          style={{
            height: getImageSize(),
            backgroundImage: `url(${props.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '10px 0 10px  0',
          }}
        ></GridItem>
        <GridItem
          xs={isHorizontal ? 6 : 12}
          style={{
            height: isHorizontal
              ? '100%'
              : `calc(100% - ${getImageSize()}px + ${
                  !isHorizontal && largeImage ? 30 : 0
                }px)`,
            padding: isHorizontal
              ? '30px 20px 10px 20px'
              : isDesktop
              ? '15px 0'
              : '9px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            style={{
              fontFamily: 'MuseoSans-700',
              fontSize:
                isDesktop && largeImage
                  ? '24px'
                  : isDesktop && !isHorizontal
                  ? '16px'
                  : '14px',
              lineHeight:
                isDesktop && largeImage
                  ? '26px'
                  : isDesktop && !isHorizontal
                  ? '16px'
                  : '16px',
              cursor: 'pointer',
              textAlign: 'left',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: isHorizontal ? 6 : 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {getNormalizedName()}
          </Typography>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {props.numberOfServings && (
              <Typography
                variant="caption"
                className={classes.servings}
                style={{
                  fontFamily: 'MuseoSans-700',
                  fontSize:
                    isDesktop && isHorizontal
                      ? '14px'
                      : isDesktop && !isHorizontal
                      ? '12px'
                      : '10px',
                  lineHeight:
                    isDesktop && isHorizontal
                      ? '14px'
                      : isDesktop && !isHorizontal
                      ? '12px'
                      : '10px',
                }}
              >
                {props.numberOfServings}{' '}
                {props.numberOfServings > 1
                  ? t('recipeDetails.servings')
                  : t('recipeDetails.serving')}
              </Typography>
            )}
            {isHorizontal && (
              <CheckIcon
                className={classes.checkIcon}
                style={{
                  color: theme.palette.icon.primary,
                }}
              />
            )}
          </Box>
        </GridItem>
      </GridContainer>
    </div>
  );
}

RecipeSummary.propTypes = getRecipeProps();
