import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/atoms/Layout/Box/Box';
import DnaUploadExplanationQuestion from 'components/organisms/Questions/DNA/DnaUploadExplanationQuestion';
import { useOvermindState } from 'overmind/index';

export default function DnaUploadExplanationPage(props) {
  const overmindState = useOvermindState();

  return (
    <Box style={{ margin: '30px 20px' }}>
      <DnaUploadExplanationQuestion
        selectedProvider={
          overmindState.metaData?.user_metadata?.dnaProviders[0]
        }
      />
    </Box>
  );
}

DnaUploadExplanationPage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onQuestionAnswered: PropTypes.func,
  selectedProviders: PropTypes.arrayOf(PropTypes.string),
};
