import i18n from '../../i18n';
import IllustratedSlider from 'components/molecules/Inputs/IllustratedSlider/IllustratedSlider';
import { ThemeProvider } from '@material-ui/core/styles';
import { environmentTheme } from 'themes';

import {
  atmosphere,
  clear,
  clouds,
  drizzle,
  heavyrain,
  rain,
  snow,
  thunderstorms,
  wind,
  lightrain,
} from 'assets/imagesList';

export function getAdviceForAirQuality(aqiIx) {
  const aqi = parseInt(aqiIx);
  if (aqi === 1) {
    return i18n.t('home.environmentalImpact.advices.aqi.good');
  } else if (aqi === 2) {
    return i18n.t('home.environmentalImpact.advices.aqi.fair');
  } else if (aqi === 3) {
    return i18n.t('home.environmentalImpact.advices.aqi.moderate');
  } else if (aqi === 4) {
    return i18n.t('home.environmentalImpact.advices.aqi.poor');
  } else if (aqi === 5) {
    return i18n.t('home.environmentalImpact.advices.aqi.verypoor');
  } else {
    return '';
  }
}

export function getWeatherIllustration(weatherCode) {
  return weatherCode === 'clear'
    ? clear
    : weatherCode === 'atmosphere'
    ? atmosphere
    : weatherCode === 'clouds'
    ? clouds
    : weatherCode === 'drizzle'
    ? drizzle
    : weatherCode === 'rain'
    ? rain
    : weatherCode === 'heavyrain'
    ? heavyrain
    : weatherCode === 'snow'
    ? snow
    : weatherCode === 'thunderstorms'
    ? thunderstorms
    : weatherCode === 'wind'
    ? wind
    : weatherCode === 'lightrain'
    ? lightrain
    : null;
}

export function getSelectedEnvironmentValue(state, step) {
  if (step === 'step1') {
    const stateValue = state.blueprintProfile.profile.place_type;
    return stateValue === 'urban'
      ? 0
      : stateValue === 'suburban'
      ? 1
      : stateValue === 'country-side'
      ? 2
      : 0;
  } else if (step === 'step2') {
    return state.blueprintProfile.profile.sun_exposure_level
      ? state.blueprintProfile.profile.sun_exposure_level
      : 0;
  } else if (step === 'step3') {
    return state.blueprintProfile.profile.spf_usage_level
      ? state.blueprintProfile.profile.spf_usage_level
      : 0;
  } else if (step === 'step4') {
    return state.blueprintProfile.profile.aircon_usage_level
      ? state.blueprintProfile.profile.aircon_usage_level
      : 0;
  } else if (step === 'step5') {
    return state.blueprintProfile.profile.blue_light_exposure_level
      ? state.blueprintProfile.profile.blue_light_exposure_level
      : 0;
  } else {
    return 0;
  }
}

export function renderSlider(state, images, id, onChange) {
  return (
    <ThemeProvider theme={environmentTheme}>
      <IllustratedSlider
        id={`illustrated-slider-${id}`}
        illustrations={[
          {
            illustration: images && images[0],
            height: 100,
            text: i18n.t(`onboarding.environmentSection.${id}.answer1`),
          },
          {
            illustration: images && images[1],
            height: 100,
            text: i18n.t(`onboarding.environmentSection.${id}.answer2`),
          },
          {
            illustration: images && images[2],
            height: 100,
            text: i18n.t(`onboarding.environmentSection.${id}.answer3`),
          },
        ]}
        selectedIllustrationIndex={getSelectedEnvironmentValue(state, id)}
        onValueChanged={onChange}
        step={1}
        valueFactor={1}
        values={[0, 1, 2]}
        min={0}
        max={2}
      />
    </ThemeProvider>
  );
}
