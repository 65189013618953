import React, { useEffect } from 'react';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import AgeQuestion from 'components/organisms/Questions/General/AgeQuestion';
import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
let today = new Date();

export default function AgePage(props) {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const classes = usePageStyles(props);

  useEffect(() => {
    actions.setEnableForward(
      getForwardEnabled(calculateAge(overmindState.profile.year_of_birth))
    );
  });

  const getForwardEnabled = (age) => {
    return age >= 18 && age <= 99;
  };

  const calculateAge = (yearOfBirth) => {
    return yearOfBirth != null
      ? today.getFullYear() - yearOfBirth
      : props.year_of_birth
      ? today.getFullYear() - props.year_of_birth
      : null;
  };

  const calculateYearOfBirth = (age) => {
    return today.getFullYear() - age;
  };

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem>
        <AgeQuestion
          title={t('onboarding.generalSection.step1.ageTitle')}
          age={calculateAge(overmindState.profile.year_of_birth)}
          onChange={(newAge) => {
            actions.ageChanged(calculateYearOfBirth(newAge));
            actions.setEnableForward(getForwardEnabled(newAge));
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
