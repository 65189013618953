import React, { useState, useEffect } from 'react';
import {
  AppleLogo,
  FacebookLogo,
  GoogleLogo,
  WhiteRightArrow,
} from 'assets/iconsList';
import TextInput from 'components/atoms/Inputs/TextInput/TextInput';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import { useSignInStyles, inputProps } from './signInStyles';
import { useTranslation } from 'react-i18next';
import {
  sha256,
  getCrypto,
  bufferToBase64UrlEncoded,
} from 'components/helpers/auth0Helper';
import { useAuth0 } from '@auth0/auth0-react';
import {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  PUBLIC_URL,
  DB_CONNECTION,
  getRedirectUriForSignin,
  EMAIL_REGEX,
} from 'components/helpers/auth0Helper';
import Button from 'components/atoms/Inputs/Buttons/Button';
import * as auth0 from 'auth0-js';
import LandingModal from 'components/molecules/LandingModal/LandingModal';

export default function SignIn(props) {
  const signInStyles = useSignInStyles();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [warningContent, setWarningContent] = useState(null);

  const options = ['google', 'facebook', 'apple'];

  const { isLoading, loginWithRedirect } = useAuth0();

  const [webAuth] = useState(
    new auth0.WebAuth({
      domain: REACT_APP_AUTH0_DOMAIN || 'auth.lifenome.com',
      clientID: REACT_APP_AUTH0_CLIENT_ID || 'tSw6gdmNrsPDUalcY3aKOfsY2fI4M9eS',
      redirectUri: `${window.location.origin}${PUBLIC_URL}`,
      responseType: 'code token',
    })
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getSignInRedirectUrl(provider) {
    if (!props.inStorybook && !isLoading) {
      const charset =
        '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
      let random = '';
      const randomValues = Array.from(
        getCrypto().getRandomValues(new Uint8Array(43))
      );
      randomValues.forEach((v) => (random += charset[v % charset.length]));
      const verifier = random;
      const code_challengeBuffer = await sha256(verifier);
      const challenge = bufferToBase64UrlEncoded(code_challengeBuffer);

      window.localStorage.setItem('verifier', verifier);
      const url = `https://${REACT_APP_AUTH0_DOMAIN}/authorize?response_type=code&code_challenge=${challenge}&code_challenge_method=S256&client_id=${REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${getRedirectUriForSignin()}&scope=email%20name%20openid&connection=${provider}&audience=${REACT_APP_AUTH0_AUDIENCE}`;
      window.open(url, '_self');
    }
  }

  const loginUser = () => {
    trackEvent('SigninStarted', 'Auth0');
    webAuth.login(
      {
        realm: DB_CONNECTION,
        email: email,
        password: password,
        audience: REACT_APP_AUTH0_AUDIENCE,
      },
      (error, result) => {
        if (error) {
          setWarningContent(error.error_description);
          setShowWarning(true);
        }
      }
    );
  };

  const handleLogin = () => {
    let wrongEmail = EMAIL_REGEX.test(email) === false;

    if (!wrongEmail) {
      loginUser();
    } else {
      setWarningContent(
        <span>
          {t('onboarding.introSection.landing.wrongEmail')}
          <br></br>
        </span>
      );
      setShowWarning(true);
    }
  };

  return (
    <Box style={{ padding: '0 22px' }}>
      <Box marginBottom="30px">
        <Typography
          style={{ marginTop: '60px' }}
          className={signInStyles.titleForSignUp}
        >
          {t('onboarding.introSection.landing.signInSubtitle')}
        </Typography>

        {t('onboarding.introSection.landing.optionalText') !== '' ? (
          <Typography
            style={{ marginTop: '60px' }}
            className={signInStyles.subtitleForSignUp}
          >
            {t('onboarding.introSection.landing.optionalText')}
          </Typography>
        ) : null}
      </Box>

      {options.map((option, ix) => {
        return (
          <Box
            style={{
              borderTop: '1px solid white',
              borderBottom: ix === options.length - 1 && '1px solid white',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py="6px"
              onClick={() => {
                if (option === 'google') {
                  trackEvent('SigninStarted', 'Google');
                  setTimeout(function () {
                    window.open(getSignInRedirectUrl('google-oauth2'), '_self');
                  }, 500);
                } else if (option === 'facebook') {
                  trackEvent('SigninStarted', 'Facebook');
                  setTimeout(function () {
                    window.open(getSignInRedirectUrl('facebook'), '_self');
                  }, 500);
                } else if (option === 'apple') {
                  trackEvent('SigninStarted', 'Apple');
                  setTimeout(function () {
                    window.open(getSignInRedirectUrl('apple'), '_self');
                  }, 500);
                }
              }}
              style={{
                cursor: 'pointer',
                color: 'white',
                userSelect: 'none',
              }}
              key={ix}
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box style={{ marginRight: '12px', padding: '5px 0' }}>
                  {option === 'google' ? (
                    <GoogleLogo />
                  ) : option === 'facebook' ? (
                    <FacebookLogo />
                  ) : (
                    <AppleLogo />
                  )}
                </Box>
                <Typography
                  className={signInStyles.subtitleForSignUp}
                  style={{
                    fontSize: '16px',
                    textAlign: 'center',
                    lineHeight: '24px',
                    paddingLeft: '15px',
                  }}
                >
                  {option === 'google'
                    ? t('onboarding.introSection.landing.signInGmail')
                    : option === 'facebook'
                    ? t('onboarding.introSection.landing.signInFacebook')
                    : t('onboarding.introSection.landing.signInApple')}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <WhiteRightArrow style={{ height: '25px' }} />
              </Box>
            </Box>
          </Box>
        );
      })}

      <Box>
        <Typography
          style={{
            ...inputProps,
            marginTop: '34px',
          }}
        >
          {t('onboarding.introSection.landing.signInWithEmail')}
        </Typography>
        <TextInput
          onChange={(e) => setEmail(e.target.value)}
          className={signInStyles.emailContainer}
          style={{
            marginTop: '25px',
            width: '100%',
          }}
          placeholder="Your email"
          inputProps={{ style: inputProps }}
          color="primary"
        />
        <TextInput
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          className={signInStyles.passwordContainer}
          style={{
            marginTop: '16px',
            width: '100%',
          }}
          placeholder="Password"
          inputProps={{ style: inputProps }}
        />
        {/* commented out for further specification*/}
        <Box
          display="flex"
          style={{ marginTop: 10, width: '100%' }}
          onClick={() => props.goToResetPassword && props.goToResetPassword()}
        >
          <Typography
            style={{
              ...inputProps,
              cursor: 'pointer',
            }}
          >
            Forgot your password?{' '}
          </Typography>
          <Box justifyContent="center" align="center">
            <WhiteRightArrow
              style={{
                height: '10px',
                width: '10px',
                paddingLeft: '4px',
              }}
            />
          </Box>
        </Box>
        <Box style={{ marginTop: 10 }}>
          <Button
            className={signInStyles.confirmButton}
            disabled={!email || !password}
            style={{ margin: 0, marginTop: 10 }}
            variant="contained"
            onClick={handleLogin}
          >
            <Typography className={signInStyles.buttonText}>
              {t('onboarding.introSection.landing.signIn').toUpperCase()}
            </Typography>
          </Button>
        </Box>
      </Box>

      <Typography
        style={{ margin: '23px 0 16.5px 0' }}
        className={signInStyles.otherText}
      >
        {'OR'}
      </Typography>

      <Box
        style={{
          borderTop: '1px solid white',
          borderBottom: '1px solid white',
        }}
        onClick={() => props.toggleForm && props.toggleForm()}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py="15px"
          style={{
            cursor: 'pointer',
            backgroundColor: 'transparent',
            color: 'white',
          }}
        >
          <Typography
            className={signInStyles.subtitleForSignUp}
            style={{
              textAlign: 'start',
              display: 'inline-block',
              fontSize: '14px',
              lineHeight: 1.43,
              paddingLeft: '5.5px',
              userSelect: 'none',
            }}
          >
            {t('onboarding.introSection.landing.createAccountWithEmail')}{' '}
            <span
              style={{
                textDecoration: 'underline',
              }}
            >
              {t('onboarding.introSection.landing.email')}
            </span>
          </Typography>
          <WhiteRightArrow
            style={{
              height: '20px',
              width: '22px',
              paddingRight: '14px',
            }}
          />
        </Box>
      </Box>
      <Typography className={signInStyles.statement}>
        {t('onboarding.introSection.landing.statement')}{' '}
        <a
          className={signInStyles.statement}
          style={{ textDecoration: 'underline' }}
          href="https://www.lifenome.com/terms-of-service/"
          target="_blank"
          rel="noreferrer"
        >
          {t('onboarding.introSection.landing.tos')}{' '}
        </a>
        {' & '}
        <a
          className={signInStyles.statement}
          style={{ textDecoration: 'underline' }}
          href="https://www.lifenome.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          {t('onboarding.introSection.landing.privacyPolicy')}
        </a>
      </Typography>
      <LandingModal
        content={warningContent}
        opened={showWarning}
        close={() => setShowWarning(false)}
      />
    </Box>
  );
}
