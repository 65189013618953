import { makeStyles } from '@material-ui/core';

export const assessmentTraitsStyles = makeStyles((theme) => ({
  root: {},
  card: (props) => ({
    padding: '20px',
    paddingBottom: '60px',
    backgroundColor: props.color,
    borderTopLeftRadius: '40px',
    borderTopRightRadius: '40px',
  }),
  title: {
    lineHeight: '19px',
    marginBottom: '20px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  seeAll: {
    fontSize: '12px',
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '20px',
  },
}));
