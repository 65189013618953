import {
  HomeGrayIcon,
  HomeOrangeIcon,
  HomeGreenIcon,
  RegimenGrayIcon,
  RegimenOrangeIcon,
  RegimenGreenIcon,
  NutritionGrayIcon,
  NutritionOrangeIcon,
  NutritionGreenIcon,
  GeneticsGrayIcon,
  GeneticsOrangeIcon,
  GeneticsGreenIcon,
  ProfileGrayIcon,
  ProfileOrangeIcon,
  ProfileGreenIcon,
} from 'assets/iconsList';

const isDesktop = window.innerWidth > 800;
export const getMenuOptions = (theme, selectedItem) => {
  return [
    {
      label: 'Home',
      showLabel: true,
      value: '/assessment',
      icon:
        selectedItem === '/assessment' ? (
          isDesktop ? (
            <HomeGreenIcon style={{ height: '30px' }} />
          ) : (
            <HomeOrangeIcon style={{ height: '30px' }} />
          )
        ) : (
          <HomeGrayIcon style={{ height: '30px' }} />
        ),
    },
    {
      label: 'Routine',
      showLabel: true,
      value: '/assessment/regimen',
      icon:
        selectedItem === '/assessment/regimen' ? (
          isDesktop ? (
            <RegimenGreenIcon style={{ height: '30px' }} />
          ) : (
            <RegimenOrangeIcon style={{ height: '30px' }} />
          )
        ) : (
          <RegimenGrayIcon style={{ height: '30px' }} />
        ),
    },
    {
      label: 'Nutrition',
      showLabel: true,
      value: '/assessment/nutrition',
      icon:
        selectedItem === '/assessment/nutrition' ? (
          isDesktop ? (
            <NutritionGreenIcon style={{ height: '30px' }} />
          ) : (
            <NutritionOrangeIcon style={{ height: '30px' }} />
          )
        ) : (
          <NutritionGrayIcon style={{ height: '30px' }} />
        ),
    },
    {
      label: 'Genetics',
      showLabel: true,
      value: '/assessment/genetics',
      icon:
        selectedItem === '/assessment/genetics' ? (
          isDesktop ? (
            <GeneticsGreenIcon style={{ height: '30px' }} />
          ) : (
            <GeneticsOrangeIcon style={{ height: '30px' }} />
          )
        ) : (
          <GeneticsGrayIcon style={{ height: '30px' }} />
        ),
    },
    {
      label: 'Profile',
      showLabel: true,
      value: '/assessment/profile',
      icon:
        selectedItem === '/assessment/profile' ? (
          isDesktop ? (
            <ProfileGreenIcon style={{ height: '30px' }} />
          ) : (
            <ProfileOrangeIcon style={{ height: '30px' }} />
          )
        ) : (
          <ProfileGrayIcon style={{ height: '30px' }} />
        ),
    },
  ];
};
