import { makeStyles } from '@material-ui/core';

export const useCalendarItemStyles = makeStyles((theme) => ({
  circle: (props) => ({
    width: props.isDesktop ? '60px' : '44px',
    height: props.isDesktop ? '60px' : '44px',
    margin: props.isDesktop && '0 15px',
    borderRadius: '50px',
    backgroundColor: props.selected && props.type === 'day' ? '#dcedeb' : '',
    textAlign: 'center',
    lineHeight: props.isDesktop ? '18px' : '14px',
    color: '#342f38',
    cursor: 'pointer',
  }),
  text1: (props) => ({
    paddingTop: props.isDesktop ? '12px' : '8px',
    lineHeight: 'inherit',
    fontSize: props.isDesktop ? '14px' : '12px',
  }),
  text2: (props) => ({
    paddingBottom: props.isDesktop ? '12px' : '8px',
    lineHeight: 'inherit',
    fontSize: props.isDesktop ? '14px' : '12px',
  }),
}));
