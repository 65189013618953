import React from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import SleepQuestion from 'components/organisms/Questions/Lifestyle/SleepQuestion';

import {
  lifestyleSliderSleepIcon1,
  lifestyleSliderSleepIcon2,
  lifestyleSliderSleepIcon3,
  lifestyleSliderSleepIcon4,
  lifestyleSliderSleepIcon5,
} from 'assets/iconsList';

import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

export default function LifestyleSleepPage(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const action = useActions();

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <GridItem
        style={{
          maxWidth: '700px',
          marginBottom: '20px',
        }}
      >
        <SleepQuestion
          title={t('onboarding.lifestyleSection.step1.question1.title')}
          subtitle={t('onboarding.lifestyleSection.step1.question1.subtitle')}
          data={[
            {
              illustration: lifestyleSliderSleepIcon1,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question1.slide1'),
            },
            {
              illustration: lifestyleSliderSleepIcon2,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question1.slide2'),
            },
            {
              illustration: lifestyleSliderSleepIcon3,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question1.slide3'),
            },
            {
              illustration: lifestyleSliderSleepIcon4,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question1.slide4'),
            },
            {
              illustration: lifestyleSliderSleepIcon5,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question1.slide5'),
            },
          ]}
          selectedIllustrationIndex={
            overmindState.blueprintProfile.profile.sleep_level
              ? overmindState.blueprintProfile.profile.sleep_level
              : 0
          }
          onChange={(index) => action.changeLifestyleSleepLevel(index)}
        />
      </GridItem>
    </GridContainer>
  );
}
