import React, { useEffect } from 'react';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import NameQuestion from 'components/organisms/Questions/General/NameQuestion';
import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';

export default function NamePage(props) {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const classes = usePageStyles(props);

  useEffect(() => {
    actions.setEnableForward(
      getForwardEnabled(overmindState.metaData.user_metadata.firstName)
    );
  });

  const getForwardEnabled = (name) => {
    return name != null && name !== '';
  };

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem>
        <NameQuestion
          title={t('onboarding.generalSection.step1.nameTitle')}
          name={overmindState.metaData.user_metadata.firstName}
          onChange={(newName) => {
            actions.nameChanged(newName);
            actions.setEnableForward(getForwardEnabled(newName));
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
