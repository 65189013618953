import React from 'react';
import { uploadDNATheme } from 'themes';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useCheckScreenSize } from 'components/helpers/useCheckScreenSize';

import ContentCard from 'components/molecules/Common/ContentCard/ContentCard';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';
import SvgIcon from 'components/atoms/SvgIcon/SvgIcon';

import { UploadDNAIcon } from 'assets/iconsList';

export default function DnaAssessmentSection(props) {
  const { t } = useTranslation();

  return (
    <ContentCard color="#f7f0ed">
      <ThemeProvider theme={uploadDNATheme}>
        <GridContainer justify="center" alignItems="center">
          <GridItem
            xs={12}
            style={{
              marginBottom: '20px',
              textAlign: 'center',
            }}
          >
            <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
              {t('home.dnaAssessmentReport.title')}
            </Typography>
          </GridItem>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <GridItem xs={useCheckScreenSize().isDesktop ? 2 : 4}>
              <SvgIcon height={70} icon={UploadDNAIcon} />
            </GridItem>
            <GridItem xs={useCheckScreenSize().isDesktop ? 6 : 8}>
              <Typography
                variant="body2"
                style={{
                  width: '90%',
                }}
              >
                {t('home.dnaAssessmentReport.assessmentText')}
              </Typography>
            </GridItem>
            <GridItem
              xs={useCheckScreenSize().isDesktop ? 4 : 12}
              style={{
                margin: '20px auto 0 auto',
                textAlign: 'center',
              }}
            >
              <Button
                color="secondary"
                component="span"
                variant="contained"
                onClick={() => {
                  props.onSummaryClick && props.onSummaryClick();
                }}
              >
                {t('home.dnaAssessmentReport.summaryButtonText')}
              </Button>
            </GridItem>
          </GridContainer>
          {/* <GridItem xs={12} style={{ marginBottom: '20px' }}>
            <GridContainer
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <GridItem style={{ marginLeft: '14px' }}>
                {overmindState.report && overmindState.report.download_url ? (
                  <Button
                    color="secondary"
                    component="span"
                    variant="contained"
                    onClick={handleDownload}
                  >
                    {t('home.dnaAssessmentReport.downloadButtonText')}
                  </Button>
                ) : (
                  (overmindState.upload.successful ||
                    !overmindState.traits ||
                    overmindState.traits.length === 0 ||
                    (overmindState.traits?.length > 0 &&
                      !overmindState.report?.download_url)) && (
                    <Typography>
                      {t('home.dnaAssessmentReport.processing')}
                    </Typography>
                  )
                )}
                </GridItem>
            </GridContainer>
          </GridItem> */}
        </GridContainer>
      </ThemeProvider>
    </ContentCard>
  );
}
