import { makeStyles } from '@material-ui/core';

export const useHomeStyles = makeStyles((theme) => ({
  homeIntroComponent: {
    minHeight: '150px',
    padding: '50px 10px 40px 10px',
    textAlign: 'center',
  },
  homeRecipesComponent: {
    padding: '40px 20px',
    backgroundColor: '#f8f5f2',
    borderRadius: '60px 60px 0 0',
    marginTop: '-100px',
    paddingTop: '-20px',
  },
  subtitle: {
    fontWeight: 'bold',
    paddingBottom: '20px',
    textAlign: 'center',
    fontSize: '20px',
  },
  infoCardImage: {
    textAlign: 'end',
  },
  title: {
    fontWeight: 'bold',
    paddingBottom: '20px',
    textAlign: 'center',
    fontSize: '24px',
  },
  transparentBg: {
    backgroundColor: 'transparent',
  },
  scienceFactComponent: {
    backgroundColor: '#e8dfd8',
    padding: '20px',
  },
}));

export const usePersonalizedTipsStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '20px',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '20px',
  },
}));

export const useEnvironmentalImpactStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  card: {
    minHeight: '170px',
    padding: '10px',
    color: '#2a343f',
  },
  subtitle: {
    fontFamily: 'MuseoSans-700',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
  },
}));
