import React, { useEffect, useState } from 'react';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import ZipCodeQuestion from 'components/organisms/Questions/General/ZipCodeQuestion';
import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';

export default function ZipCodePage(props) {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const classes = usePageStyles(props);
  const [zipCode, setZipCode] = useState(
    overmindState.blueprintProfile.profile.zip_code
  );

  useEffect(() => {
    if (
      Object.keys(overmindState.blueprintProfile.profile).length === 0 &&
      overmindState.blueprintProfile.loading == null
    ) {
      actions.setBlueprintProfileLoading(true);
      actions.fetchBlueprintProfile();
    } else {
      setZipCode(overmindState.blueprintProfile.profile.zip_code);
      actions.setEnableForward(
        getForwardEnabled(overmindState.blueprintProfile.profile.zip_code)
      );
    }
  }, [actions, overmindState.blueprintProfile.loading]);

  const getForwardEnabled = (zipCode) => {
    return zipCode != null && zipCode !== '';
  };

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem>
        <ZipCodeQuestion
          title={t('onboarding.generalSection.zipCodeStep.title')}
          zipCode={zipCode}
          onChange={(newZipCode) => {
            setZipCode(newZipCode);
            actions.setZipCode(newZipCode);
            actions.setEnableForward(getForwardEnabled(newZipCode));
          }}
          zipCodeIsInvalid={
            overmindState.errors.errorSavingBlueprintProfile != null &&
            overmindState.errors.errorSavingBlueprintProfile !== ''
          }
          zipCodeInvalidMessage={t(
            'onboarding.generalSection.zipCodeStep.invalidMessage'
          )}
        />
      </GridItem>
    </GridContainer>
  );
}
