import React, { useState, useEffect } from 'react';
import Button from 'components/atoms/Inputs/Buttons/Button';
import TextInput from 'components/atoms/Inputs/TextInput/TextInput';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import { useSignUpStyles, inputProps } from './signUpStyles';
import * as auth0 from 'auth0-js';
import { useTranslation } from 'react-i18next';
import { WhiteRightArrow } from 'assets/iconsList';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  PUBLIC_URL,
  EMAIL_REGEX,
  DB_CONNECTION,
} from 'components/helpers/auth0Helper';
import LandingModal from 'components/molecules/LandingModal/LandingModal';

export default function SignUp(props) {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationError, setValidationError] = useState({
    wrongEmailFormat: false,
    wrongPassword: false,
  });
  const [showWarning, setShowWarning] = useState(false);
  const [warningContent, setWarningContent] = useState(null);
  const [webAuth] = useState(
    new auth0.WebAuth({
      domain: REACT_APP_AUTH0_DOMAIN || 'auth.lifenome.com',
      clientID: REACT_APP_AUTH0_CLIENT_ID || 'tSw6gdmNrsPDUalcY3aKOfsY2fI4M9eS',
      redirectUri: `${window.location.origin}${PUBLIC_URL}`,
      responseType: 'code token',
    })
  );
  const signUpClasses = useSignUpStyles(validationError);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash.includes('access_token')) {
      webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
        if (err) {
          setWarningContent(err?.description);
          setShowWarning(true);
        } else {
          window.localStorage.setItem('access_token', authResult.accessToken);
          props.signIn();
        }
      });
    }
  }, [window.location]);

  const loginUser = () => {
    trackEvent('SigninStarted', 'Auth0');
    webAuth.login(
      {
        realm: DB_CONNECTION,
        email: email,
        password: password,
        audience: REACT_APP_AUTH0_AUDIENCE,
      },
      (error, result) => {
        if (error) {
          setWarningContent(error.description);
          setShowWarning(true);
        }
      }
    );
  };

  const signUp = () => {
    trackEvent('SignupStarted', 'Auth0');
    webAuth.signup(
      {
        connection: DB_CONNECTION,
        email: email,
        password: password,
        user: { testField: 'true' },
      },
      (error, result) => {
        if (error?.description) {
          setWarningContent(error.description);
          setShowWarning(true);
        } else if (result) {
          loginUser();
        }
      }
    );
  };

  const resetValidationError = () => {
    const errorObj = {
      wrongEmailFormat: false,
      wrongPassword: false,
    };
    setValidationError(errorObj);
    return errorObj;
  };

  const handleCreateAccount = () => {
    let errorObj = resetValidationError();

    EMAIL_REGEX.test(email) === false
      ? (errorObj = { ...errorObj, wrongEmailFormat: true })
      : (errorObj = { ...errorObj, wrongEmailFormat: false });

    password !== confirmPassword
      ? (errorObj = { ...errorObj, wrongPassword: true })
      : (errorObj = { ...errorObj, wrongPassword: false });
    setValidationError(errorObj);

    const isError = Object.keys(errorObj).some(
      (field) => errorObj[field] === true
    );
    if (!isError) {
      signUp();
    } else {
      setWarningContent(getErrorMessages(errorObj));
      setShowWarning(true);
    }
  };

  const getErrorMessages = (errorObj) => {
    let msgArr = [];
    errorObj.wrongEmailFormat &&
      msgArr.push(
        <span>
          {t('onboarding.introSection.landing.wrongEmail')}
          <br></br>
        </span>
      );
    errorObj.wrongPassword &&
      msgArr.push(
        <span>
          {t('onboarding.introSection.landing.wrongPassword')}
          <br></br>
        </span>
      );
    return <>{msgArr}</>;
  };

  const isCreateButtonDisabled = () => {
    return (
      email.length === 0 ||
      password.length === 0 ||
      confirmPassword.length === 0
    );
  };

  return (
    <Box
      style={{
        padding: '0 15px',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '600px',
      }}
    >
      <Typography className={signUpClasses.title}>
        {t('onboarding.introSection.landing.createAccount')}
      </Typography>
      <Typography className={signUpClasses.subTitle}>
        {t('onboarding.introSection.landing.signUpSubtitle')}
      </Typography>
      <Box style={{ marginLeft: '18px', marginRight: '18px' }}>
        <TextInput
          onChange={(e) => setEmail(e.target.value)}
          className={signUpClasses.emailContainer}
          style={{ marginTop: '25px' }}
          placeholder={t('onboarding.introSection.landing.yourEmail')}
          inputProps={{ style: inputProps }}
          color="primary"
        />
        <TextInput
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          className={signUpClasses.passwordContainer}
          style={{ marginTop: '16px' }}
          placeholder={t('onboarding.introSection.landing.password')}
          inputProps={{ style: inputProps }}
        />
        <TextInput
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          className={signUpClasses.confirmPasswordContainer}
          style={{ marginTop: '16px' }}
          placeholder={t('onboarding.introSection.landing.confirmPassword')}
          inputProps={{ style: inputProps }}
        />
      </Box>
      <Box
        style={{
          margin: '0px 18px',
        }}
      >
        <Button
          variant="contained"
          onClick={handleCreateAccount}
          className={signUpClasses.confirmButton}
          disabled={isCreateButtonDisabled()}
        >
          <Typography className={signUpClasses.buttonText}>
            {t('onboarding.introSection.landing.createAccount')}
          </Typography>
        </Button>
        <Box
          className={signUpClasses.signInContainer}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => props.toggleForm && props.toggleForm()}
        >
          <Typography className={signUpClasses.signInText}>
            {t('onboarding.introSection.landing.signIn')}
          </Typography>
          <WhiteRightArrow style={{ height: '25px', paddingRight: '15px' }} />
        </Box>
        <Typography className={signUpClasses.statement}>
          {t('onboarding.introSection.landing.statement')}{' '}
          <a
            className={signUpClasses.statement}
            style={{ textDecoration: 'underline' }}
            href="https://www.lifenome.com/terms-of-service/"
            target="_blank"
            rel="noreferrer"
          >
            {t('onboarding.introSection.landing.tos')}{' '}
          </a>
          {' & '}
          <a
            className={signUpClasses.statement}
            style={{ textDecoration: 'underline' }}
            href="https://www.lifenome.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            {t('onboarding.introSection.landing.privacyPolicy')}
          </a>
        </Typography>
        <Typography className={signUpClasses.learnMoreText}>
          {t('onboarding.introSection.landing.learnMore')}{' '}
          <a
            className={signUpClasses.pageLink}
            href="https://www.blueprint.beauty/"
            target="_blank"
            rel="noreferrer"
          >
            {t('onboarding.introSection.landing.blueprintBeauty')}
          </a>
        </Typography>
      </Box>
      <LandingModal
        content={warningContent}
        opened={showWarning}
        close={() => setShowWarning(false)}
      />
    </Box>
  );
}
