import React, { useState } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import BiologicalSexQuestion from 'components/organisms/Questions/General/BiologicalSexQuestion';
import { useActions, useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import { usePageStyles } from 'components/pages/commonStyles';
import { FemaleIcon, MaleIcon } from 'assets/iconsList';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

export default function BiologicalSexPage(props) {
  const classes = usePageStyles(props);
  const actions = useActions();
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const [selectedGender, setSelectedGender] = useState(
    overmindState.profile && overmindState.profile.gender
      ? overmindState.profile.gender
      : undefined
  );
  const questions = [
    {
      icon: <FemaleIcon height={'66px'} />,
      label: t('onboarding.generalSection.step4.female'),
      checked: selectedGender === 'female',
      id: '1',
      uid: 'female',
    },
    {
      icon: <MaleIcon height={'66px'} />,
      label: t('onboarding.generalSection.step4.male'),
      checked: selectedGender === 'male',
      id: '2',
      uid: 'male',
    },
  ];

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem>
        <BiologicalSexQuestion
          title={t('onboarding.generalSection.step4.title')}
          items={questions}
          onAnswered={(value) => {
            const gender =
              value === '1' ? 'female' : value === '2' ? 'male' : undefined;
            setSelectedGender(gender);
            actions.genderChanged(gender);
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
