import {
  PescatarianIcon,
  VeganIcon,
  VegetarianIcon,
  Lactose,
  Gluten,
  Egg,
  Nut,
  Shellfish,
  Sugar,
  Sesame,
  Soy,
  Peanut,
  PorkFree,
  NoneIcon,
} from 'assets/iconsList';
import i18n from 'i18n';

export function getNutritionData(profileNutrition) {
  return [
    {
      id: '1',
      uid: 'vegetarian',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.vegetarian'),
      icon: VegetarianIcon,
      checked: profileNutrition && profileNutrition.vegetarian,
    },
    {
      id: '2',
      uid: 'vegan',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.vegan'),
      icon: VeganIcon,
      checked: profileNutrition && profileNutrition.vegan,
    },
    {
      id: '3',
      uid: 'pescatarian',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.pescatarian'),
      icon: PescatarianIcon,
      checked: profileNutrition && profileNutrition.pescatarian,
    },
    {
      id: '4',
      uid: 'pork_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.porkFree'),
      icon: PorkFree,
      checked: profileNutrition && profileNutrition.pork_free,
    },
    {
      id: '0',
      uid: 'no_preference',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.noneAbove'),
      icon: NoneIcon,
      checked: profileNutrition && profileNutrition.no_preference,
    },
  ];
}

export function getNutritionObject(arr) {
  if (arr === undefined) {
    return;
  }

  const valuesArr = getNutritionData();

  return {
    // Dietary Preferences
    vegetarian: valuesArr.find(
      (i) => i.uid === 'vegetarian' && arr.includes(i.id)
    )
      ? true
      : false,
    vegan: valuesArr.find((i) => i.uid === 'vegan' && arr.includes(i.id))
      ? true
      : false,
    pescatarian: valuesArr.find(
      (i) => i.uid === 'pescatarian' && arr.includes(i.id)
    )
      ? true
      : false,
    fish_free: valuesArr.find(
      (i) => i.uid === 'fish_free' && arr.includes(i.id)
    )
      ? true
      : false,
    pork_free: valuesArr.find(
      (i) => i.uid === 'pork_free' && arr.includes(i.id)
    )
      ? true
      : false,
    no_preference: valuesArr.find(
      (i) => i.uid === 'no_preference' && arr.includes(i.id)
    )
      ? true
      : false,

    // Calories
    low_carb: valuesArr.find((i) => i.uid === 'low_carb' && arr.includes(i.id))
      ? true
      : false,
    low_fat: valuesArr.find((i) => i.uid === 'low_fat' && arr.includes(i.id))
      ? true
      : false,
    low_calorie: valuesArr.find(
      (i) => i.uid === 'low_calorie' && arr.includes(i.id)
    )
      ? true
      : false,
  };
}

export function getFoodAllergiesData(profileNutrition) {
  return [
    {
      id: '5',
      uid: 'dairy_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.lactose'),
      icon: Lactose,
      checked: profileNutrition && profileNutrition.dairy_free,
    },
    {
      id: '6',
      uid: 'gluten_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.gluten'),
      icon: Gluten,
      checked: profileNutrition && profileNutrition.gluten_free,
    },
    {
      id: '7',
      uid: 'egg_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.egg'),
      icon: Egg,
      checked: profileNutrition && profileNutrition.egg_free,
    },
    {
      id: '8',
      uid: 'nut_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.nut'),
      icon: Nut,
      checked: profileNutrition && profileNutrition.nut_free,
    },
    {
      id: '9',
      uid: 'shellfish_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.shellfish'),
      icon: Shellfish,
      checked: profileNutrition && profileNutrition.shellfish_free,
    },
    {
      id: '10',
      uid: 'soy_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.soy'),
      icon: Soy,
      checked: profileNutrition && profileNutrition.soy_free,
    },
    {
      id: '11',
      uid: 'sugar_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.sugar'),
      icon: Sugar,
      checked: profileNutrition && profileNutrition.sugar_free,
    },
    {
      id: '12',
      uid: 'peanut_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.peanut'),
      icon: Peanut,
      checked: profileNutrition && profileNutrition.peanut_free,
    },
    {
      id: '13',
      uid: 'sesame_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.sesame'),
      icon: Sesame,
      checked: profileNutrition && profileNutrition.sesame_free,
    },
    {
      id: '0',
      uid: 'no_allergies',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.none'),
      icon: NoneIcon,
      checked: profileNutrition && profileNutrition.no_allergies,
    },
  ];
}

export function getFoodAllergiesObject(arr) {
  if (arr === undefined) {
    return;
  }

  const valuesArr = getFoodAllergiesData();

  return {
    dairy_free: valuesArr.find(
      (i) => i.uid === 'dairy_free' && arr.includes(i.id)
    )
      ? true
      : false,
    gluten_free: valuesArr.find(
      (i) => i.uid === 'gluten_free' && arr.includes(i.id)
    )
      ? true
      : false,
    egg_free: valuesArr.find((i) => i.uid === 'egg_free' && arr.includes(i.id))
      ? true
      : false,
    nut_free: valuesArr.find((i) => i.uid === 'nut_free' && arr.includes(i.id))
      ? true
      : false,

    shellfish_free: valuesArr.find(
      (i) => i.uid === 'shellfish_free' && arr.includes(i.id)
    )
      ? true
      : false,
    soy_free: valuesArr.find((i) => i.uid === 'soy_free' && arr.includes(i.id))
      ? true
      : false,
    sugar_free: valuesArr.find(
      (i) => i.uid === 'sugar_free' && arr.includes(i.id)
    )
      ? true
      : false,
    peanut_free: valuesArr.find(
      (i) => i.uid === 'peanut_free' && arr.includes(i.id)
    )
      ? true
      : false,
    sesame_free: valuesArr.find(
      (i) => i.uid === 'sesame_free' && arr.includes(i.id)
    )
      ? true
      : false,
    no_allergies: valuesArr.find(
      (i) => i.uid === 'no_allergies' && arr.includes(i.id)
    )
      ? true
      : false,
  };
}
