import React from 'react';
import Typography from 'components/atoms/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';

export default function ShowMoreButton(props) {
  const { showMoreValue, textShowLess, textShowMore } = props;

  return (
    <Box style={{ marginTop: '12px' }}>
      <Typography
        variant="body1"
        style={{
          fontFamily: 'MuseoSans-700',
          cursor: 'pointer',
          textDecoration: 'underline',
          textTransform: 'uppercase',
          fontSize: '12px',
        }}
        onClick={() => props.onClick && props.onClick()}
      >
        {showMoreValue ? textShowLess : textShowMore}
      </Typography>
    </Box>
  );
}
