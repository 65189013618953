import React, { useState, useEffect } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import GeneralSection from './Details/GeneralSection';
import NutritionSection from './Details/NutritionSection';
import EnvironmentSection from './Details/EnvironmentSection';
import SignoutSection from './Details/SignoutSection';
import DeleteSection from './Details/DeleteSection';

import { useOvermindState, useActions } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getNutritionData,
  getFoodAllergiesData,
} from 'components/helpers/nutritionHelper';
import SkinSection from './Details/SkinSection';
import { getItemsForStep } from 'components/helpers/skinHelper';
import ModalEditWindow from './ModalEditWindow';

export default function ProfileView(props) {
  const { PUBLIC_URL } = process.env;
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const { logout } = useAuth0();

  const [dietaryPreferences, setDietaryPreferences] = useState();
  const [dietaryPreferencesIcons, setDietaryPreferencesIcons] = useState();
  const [allergiesRestrictions, setAllergiesRestrictions] = useState();
  const [allergiesRestrictionsIcons, setAllergiesRestrictionsIcons] =
    useState();
  const [skinTypes, setSkinTypes] = useState();
  const [skinTypesIcons, setSkinTypesIcons] = useState();
  const [skinGoals, setSkinGoals] = useState();
  const [skinGoalsIcons, setSkinGoalsIcons] = useState();
  const [eyeAreaConcerns, setEyeAreaConcerns] = useState();
  const [eyeAreaConcernsIcons, setEyeAreaConcernsIcons] = useState();
  const [zipCode, setZipCode] = useState();
  const [area, setArea] = useState();
  const [sunExposureLevel, setSunExposureLevel] = useState();
  const [spfUsageLevel, setSpfUsageLevel] = useState();
  const [airconUsageLevel, setAirconUsageLevel] = useState();
  const [bluelightExposureLevel, setBluelightExposureLevel] = useState();

  const [openedModal, setOpenedModal] = useState(false);

  useEffect(() => {
    setDietaryPreferences(
      getNutritionData(overmindState.profileNutrition)
        .filter((i) => i.checked)
        .map((i) => i.label)
    );
    setDietaryPreferencesIcons(
      getNutritionData(overmindState.profileNutrition)
        .filter((i) => i.checked)
        .map((i) => i.icon)
    );
    setAllergiesRestrictions(
      getFoodAllergiesData(overmindState.profileNutrition)
        .filter((i) => i.checked)
        .map((i) => i.label)
    );
    setAllergiesRestrictionsIcons(
      getFoodAllergiesData(overmindState.profileNutrition)
        .filter((i) => i.checked)
        .map((i) => i.icon)
    );
    setSkinTypes(
      getItemsForStep('step1', overmindState.blueprintProfile)
        .filter((i) => i.checked)
        .map((i) => i.label)
    );
    setSkinTypesIcons(
      getItemsForStep('step1', overmindState.blueprintProfile)
        .filter((i) => i.checked)
        .map((i) => i.icon)
    );
    setSkinGoals(
      getItemsForStep('step2', overmindState.blueprintProfile)
        .filter((i) => i.checked)
        .map((i) => i.label)
    );
    setSkinGoalsIcons(
      getItemsForStep('step2', overmindState.blueprintProfile)
        .filter((i) => i.checked)
        .map((i) => i.icon)
    );
    setEyeAreaConcerns(
      getItemsForStep('step3', overmindState.blueprintProfile)
        .filter((i) => i.checked)
        .map((i) => i.label)
    );
    setEyeAreaConcernsIcons(
      getItemsForStep('step3', overmindState.blueprintProfile)
        .filter((i) => i.checked)
        .map((i) => i.icon)
    );
    setZipCode(overmindState.blueprintProfile.profile.zip_code);
    setArea(
      overmindState.blueprintProfile.profile.place_type === 'urban'
        ? t(`onboarding.environmentSection.step1.answer1`)
        : overmindState.blueprintProfile.profile.place_type === 'suburban'
        ? t(`onboarding.environmentSection.step1.answer2`)
        : t(`onboarding.environmentSection.step1.answer3`)
    );
    setSunExposureLevel(
      overmindState.blueprintProfile.profile.sun_exposure_level === 0
        ? t(`onboarding.environmentSection.step2.answer1`)
        : overmindState.blueprintProfile.profile.sun_exposure_level === 1
        ? t(`onboarding.environmentSection.step2.answer2`)
        : t(`onboarding.environmentSection.step2.answer3`)
    );
    setSpfUsageLevel(
      overmindState.blueprintProfile.profile.spf_usage_level === 0
        ? t(`onboarding.environmentSection.step3.answer1`)
        : overmindState.blueprintProfile.profile.spf_usage_level === 1
        ? t(`onboarding.environmentSection.step3.answer2`)
        : t(`onboarding.environmentSection.step3.answer3`)
    );
    setAirconUsageLevel(
      overmindState.blueprintProfile.profile.aircon_usage_level === 0
        ? t(`onboarding.environmentSection.step4.answer1`)
        : overmindState.blueprintProfile.profile.aircon_usage_level === 1
        ? t(`onboarding.environmentSection.step4.answer2`)
        : t(`onboarding.environmentSection.step4.answer3`)
    );
    setBluelightExposureLevel(
      overmindState.blueprintProfile.profile.blue_light_exposure_level === 0
        ? t(`onboarding.environmentSection.step5.answer1`)
        : overmindState.blueprintProfile.profile.blue_light_exposure_level === 1
        ? t(`onboarding.environmentSection.step5.answer2`)
        : t(`onboarding.environmentSection.step5.answer3`)
    );
  }, [overmindState.profileNutrition, overmindState.blueprintProfile.profile]);

  const deleteProfile = () => {
    actions.deleteUserData().then(() => {
      window.localStorage.removeItem('seenLandingPage');
      logOutAndRemoveToken(true);
    });
  };

  const logOutAndRemoveToken = (profileDeleted) => {
    let url = `${window.location.origin}${PUBLIC_URL}`;
    if (profileDeleted) {
      url = url + '/?profileDeleted=true';
    }
    logout({
      returnTo: url,
      federated: true,
    });
    window.localStorage.removeItem('access_token');
  };

  return (
    <GridContainer
      style={{
        marginBottom: '20px',
        paddingLeft: '23px',
        paddingRight: '23px',
        maxWidth: '1200px',
      }}
    >
      <GridContainer>
        <GridItem
          style={{
            marginTop: '40px',
            textAlign: 'left',
          }}
        >
          <Typography style={{}}>
            {t('myProfile.profileDetailsInfo')}
          </Typography>
        </GridItem>
      </GridContainer>

      <GeneralSection
        zipCode={zipCode}
        onTileClick={(componentToEdit) => {
          actions.setComponentForModalWindow(componentToEdit);
          setOpenedModal(true);
        }}
      />
      <NutritionSection
        dietaryPreferences={dietaryPreferences}
        dietaryPreferencesIcons={dietaryPreferencesIcons}
        allergiesRestrictions={allergiesRestrictions}
        allergiesRestrictionsIcons={allergiesRestrictionsIcons}
        onTileClick={(componentToEdit) => {
          actions.setComponentForModalWindow(componentToEdit);
          setOpenedModal(true);
        }}
      />
      <SkinSection
        skinTypes={skinTypes}
        skinTypesIcons={skinTypesIcons}
        skinGoals={skinGoals}
        skinGoalsIcons={skinGoalsIcons}
        eyeAreaConcerns={eyeAreaConcerns}
        eyeAreaConcernsIcons={eyeAreaConcernsIcons}
        onTileClick={(componentToEdit) => {
          actions.setComponentForModalWindow(componentToEdit);
          setOpenedModal(true);
        }}
      />
      <EnvironmentSection
        area={area}
        sunExposureLevel={sunExposureLevel}
        spfUsageLevel={spfUsageLevel}
        airconUsageLevel={airconUsageLevel}
        bluelightExposureLevel={bluelightExposureLevel}
        onTileClick={(componentToEdit) => {
          actions.setComponentForModalWindow(componentToEdit);
          setOpenedModal(true);
        }}
      />
      <DeleteSection onDeleteClick={deleteProfile} />
      <SignoutSection onSignout={() => logOutAndRemoveToken(false)} />

      <ModalEditWindow
        opened={openedModal}
        onClose={() => setOpenedModal(false)}
        hideSave={false}
      />
    </GridContainer>
  );
}
